import { MDBBox, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBContainer, MDBDataTable, MDBIcon, MDBRow, MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions, MDBSpinner } from 'mdbreact';
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import Product from 'src/core/models/coffee-core/product/product.model';
import _ from 'lodash';
import * as COMMON from 'src/core/utils/common';
import PickDeep from 'src/core/models/pick-deep.modal';
import ProductStateType from 'src/core/models/coffee-core/product/product-state.model';
import * as ProductAction from 'src/core/reduxs/coffee-core/product/product.action';
import * as CategoryAction from 'src/core/reduxs/coffee-core/category/category.action'
import ProductUpdateModalComponent from 'src/features/components/product/product-update-modal.component';
import ProductDeleteModalComponent from 'src/features/components/product/product-delete-modal.component';
import PagingRequest from 'src/core/models/paging-request.model';
import { NOT_AVAILABLE_IMAGE } from 'src/core/utils/constants';
import CategoryStateType from 'src/core/models/coffee-core/category/category-state.model';
import Category from 'src/core/models/coffee-core/category/category.model';
import { GetProductsByCategoryRequest } from 'src/core/models/coffee-core/product/product-request-params.model';

type ProductDataTable = {
    imageUrl: JSX.Element;
    name: string;
    price: string;
    edit: JSX.Element;
    [rest: string]: any;
}

const initProductDataTable: ProductDataTable[] = [];
const initProductDetail = new Product();
const ProductListComponent = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const columns = [
        {
            label: '#',
            field: 'imageUrl',
        },
        {
            label: intl.formatMessage({ id: 'PRODUCT.LIST.LABEL_NAME' }),
            field: 'name',
        },
        {
            label: intl.formatMessage({ id: 'PRODUCT.LIST.LABEL_PRICE' }),
            field: 'price'
        },
        {
            label: intl.formatMessage({ id: 'PRODUCT.LIST.LABEL_EDIT' }),
            field: 'edit',

        }
    ]
    const [dataTable, setDataTable] = useState(initProductDataTable);
    const products: PickDeep<ProductStateType, 'products'> = useSelector((state: any) => state.productReducer.products)
    const productCategories: PickDeep<CategoryStateType, 'productCategories'> = useSelector((state: any) => state.categoryReducer.productCategories)
    const [productDetail, setProductDetail] = useState<Product>(initProductDetail);
    const [categoryIdFilter, setCategoryIdFilter] = useState(0)
    const [isOpenToppingUpdateModal, setIsOpenProductModal] = useState(false);
    const [isOpenDeleteToppingModal, setIsOpenDeleteProductModal] = useState(false);

    const prepareDataRow = useCallback((listTopping: Product[]) => {
        if (_.isEmpty(listTopping)) return;

        const data: ProductDataTable[] = [];
        listTopping.forEach((item: Product) => {
            const row = {
                id: item.id,
                imageUrl: (
                    <>
                        <MDBContainer className='m-0 p-0'>
                            <img
                                src={item.imageUrl || NOT_AVAILABLE_IMAGE}
                                className='product-list-row-img'
                            />
                        </MDBContainer>
                    </>
                ),
                name: item.name || '',
                price: COMMON.currencyFormatter(item.price || 0),
                edit: (
                    <>
                        <MDBRow className="product-list-item">
                            <button className="product-list-item-btn edit" onClick={() => { openUpdateToppingModal(item) }}>
                                <MDBIcon icon="edit" className="product-list-item-icon edit" />
                            </button>
                            <button className="product-list-item-btn delete" onClick={() => { openDeleteToppingModal(item) }}>
                                <MDBIcon icon="trash-alt" className="product-list-item-icon delete" />
                            </button>
                        </MDBRow>
                    </>
                ),
            }
            data.push(row)
        })
        setDataTable(data)
    }, [])

    const openUpdateToppingModal = (item: Product) => {
        if (item) {
            setProductDetail(item);
            setIsOpenProductModal(true);
        }
    }

    const openDeleteToppingModal = (item: Product) => {
        if (item) {
            setProductDetail(item);
            setIsOpenDeleteProductModal(true);
        }
    }

    const onModalUpdateClosed = () => {
        setIsOpenProductModal(false);
        setProductDetail(initProductDetail);
    }
    const onModalDeleteClosed = () => {
        setIsOpenDeleteProductModal(false);
        setProductDetail(initProductDetail);
    }


    const handleChangeCategory = (categoryId: number) => {
        setCategoryIdFilter(categoryId)
        if (categoryId > 0) {
            const params: GetProductsByCategoryRequest = {
                pageId: 0,
                categoryId: categoryId
            }
            dispatch(ProductAction.actGetListProductByCategory(params))
        } else {
            const params: PagingRequest = {
                pageId: 0,
            }
            dispatch(ProductAction.actGetListProduct(params))
        }
    }

    const refreshProductList = () => {
        if (categoryIdFilter > 0) {
            const params: GetProductsByCategoryRequest = {
                pageId: 0,
                categoryId: categoryIdFilter
            }
            dispatch(ProductAction.actGetListProductByCategory(params))
        } else {
            const params: PagingRequest = {
                pageId: 0,
            }
            dispatch(ProductAction.actGetListProduct(params))
        }
    }


    const productDataTable = useMemo(() => {
        return (
            <MDBDataTable
                responsive
                striped
                borderless
                searching={true}
                sortable={false}
                noBottomColumns={true}
                paging={true}
                entries={5}
                pagesAmount={5}
                paginationLabel={[intl.formatMessage({ id: 'PRODUCT.LIST.LABEL_PREVIOUS_PAGE' }), intl.formatMessage({ id: 'PRODUCT.LIST.LABEL_NEXT_PAGE' })]}
                displayEntries={false}
                info={false}
                noRecordsFoundLabel={intl.formatMessage({ id: 'PRODUCT.LIST.LABEL_NOT_FOUND_PRODUCT' })}
                data={{ columns: columns, rows: dataTable }
                }
            />
        )
    }, [dataTable])

    useEffect(() => {
        const params: PagingRequest = {
            pageId: 0,
        }
        dispatch(ProductAction.actGetListProduct(params))
        dispatch(CategoryAction.actGetListProductCategory(params))
    }, [dispatch])

    useEffect(() => {
        if (products.data) {
            prepareDataRow(products.data)
        }
    }, [products.data, prepareDataRow])
    return (
        <MDBCard className="product-list-container" >
            <MDBCardTitle className="product-list-title">
                <MDBBox className="product-list-title-text" >
                    {intl.formatMessage({ id: 'PRODUCT.LIST.TITLE' })}
                </MDBBox>
                <MDBBtn size="sm" floating color='dark-green' onClick={() => { refreshProductList() }} >
                    <MDBIcon icon="sync" className="product-list-title-btn-add" />
                </MDBBtn>
            </MDBCardTitle>
            <MDBCardBody className="m-0 p-0 mt-4">
                <MDBBox className='product-list-category-filter'>
                    <MDBSelect className='m-0 p-0' label={intl.formatMessage({ id: 'CATEGORY.CREATE.LABEL_TYPE' })} getValue={(value: any) => { handleChangeCategory(value[0]) }}>
                        <MDBSelectInput />
                        <MDBSelectOptions >
                            <MDBSelectOption value={0} checked>--- Tất cả ---</MDBSelectOption>
                            {
                                productCategories && productCategories.data &&
                                productCategories.data.map((category: Category) => {
                                    return (
                                        <MDBSelectOption key={category.id} value={category.id} >{category.name}</MDBSelectOption>
                                    )
                                })
                            }
                        </MDBSelectOptions>
                    </MDBSelect>
                </MDBBox>
                {products.isLoading && <MDBRow className="d-flex justify-content-center mb-5"><MDBSpinner small /></MDBRow>}
                {!products.isLoading && products.data && productDataTable}
            </MDBCardBody>
            <ProductUpdateModalComponent isOpen={isOpenToppingUpdateModal} productId={productDetail.id} onModalClosed={onModalUpdateClosed} categoryIdFilter={categoryIdFilter} />
            <ProductDeleteModalComponent isOpen={isOpenDeleteToppingModal} productDetail={productDetail} onModalClosed={onModalDeleteClosed} />
        </ MDBCard>
    )
}

export default ProductListComponent
