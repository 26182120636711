/** COMMON */
import * as Types from 'src/core/reduxs/coffee-core/product/product.type';
import PagingRequest from 'src/core/models/paging-request.model'
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import {ProductCreateRequest ,ProductDeleteRequest,ProductUpdateRequest, GetProductsByCategoryRequest} from 'src/core/models/coffee-core/product/product-request-params.model';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
const productsRepository = Repositories[RepositoryName.PRODUCT];

export const actGetListProduct = (params: PagingRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_PRODUCTS_REQUEST, Types.GET_PRODUCTS_SUCCESS, Types.GET_PRODUCTS_FAILURE],
        callAPI: () => productsRepository.getListProduct(params)
    })
};

export const actGetDetailProduct = (id: number) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_PRODUCT_SUCCESS, Types.GET_PRODUCT_SUCCESS, Types.GET_PRODUCT_FAILURE],
        callAPI: () => productsRepository.getDetailProduct(id)
    })
};

export const actGetListProductByCategory = (params: GetProductsByCategoryRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_PRODUCTS_REQUEST, Types.GET_PRODUCTS_SUCCESS, Types.GET_PRODUCTS_FAILURE],
        callAPI: () => productsRepository.getListProductByCategory(params)
    })
};

export const actCreateProduct = (params: ProductCreateRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_PRODUCT_REQUEST, Types.CREATE_PRODUCT_SUCCESS, Types.CREATE_PRODUCT_FAILURE],
        callAPI: () => productsRepository.createProduct(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}

export const actUpdateProduct = (params: ProductUpdateRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.UPDATE_PRODUCT_REQUEST, Types.UPDATE_PRODUCT_SUCCESS, Types.UPDATE_PRODUCT_FAILURE],
        callAPI: () => productsRepository.updateProduct(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}

export const actDeleteProduct = (params: ProductDeleteRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.DELETE_PRODUCT_REQUEST, Types.DELETE_PRODUCT_SUCCESS, Types.DELETE_PRODUCT_FAILURE],
        callAPI: () => productsRepository.deleteProduct(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}


