import { MDBBadge, MDBBox, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBInput, MDBRow, MDBSpinner } from 'mdbreact'
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PickDeep from 'src/core/models/pick-deep.modal';
import { SizeGroupCreateRequest } from 'src/core/models/coffee-core/size-group/size-group-request-params.model';
import SizeGroupStateType from 'src/core/models/coffee-core/size-group/size-group-state.model';
import SizeStateType from 'src/core/models/coffee-core/size/size-state.model';
import * as GroupAction from 'src/core/reduxs/coffee-core/size-group/size-group.action'

const initChosenSizes: number[] = []
const SizeGroupCreateFormComponent = (): JSX.Element => {
    const intl = useIntl();
    const [chosenSizes, setChosenSizes] = useState(initChosenSizes);
    const [sizeGroupName, setSizeGroupName] = useState('');

    const dispatch = useDispatch();
    const createSizeGroup: PickDeep<SizeGroupStateType, 'createSizeGroup'> = useSelector((state: any) => state.sizeGroupReducer.createSizeGroup)
    const sizes: PickDeep<SizeStateType, 'sizes'> = useSelector((state: any) => state.sizeReducer.sizes)

    const handleToggleSize = (id: number) => {
        if (chosenSizes.includes(id)) {
            const newChosenSizes = chosenSizes.filter((size) => size !== id)
            setChosenSizes(newChosenSizes)
        }
        else {
            setChosenSizes((oldSizes) => [...oldSizes, id])
        }
    }

    const createSuccess = () => {
        setChosenSizes(initChosenSizes);
        setSizeGroupName('')
        toast.success(intl.formatMessage({ id: 'SIZE_GROUP.CREATE.TOAST_SUCCESS' }), { duration: 3000 })
    }

    const handleCreateSizeGroup = () => {
        const params: SizeGroupCreateRequest = {
            data: {
                name: sizeGroupName,
                sizeIds: chosenSizes
            },
            successCallback: () => createSuccess(),
            errorCallback: () => toast.error(intl.formatMessage({ id: 'SIZE_GROUP.CREATE.TOAST_FAILURE' }), { duration: 3000 })
        }
        dispatch(GroupAction.actCreateSizeGroup(params))
    }

    return (
        <MDBCard className="size-group-create">
            <MDBCardTitle className="size-group-create-title">
                <MDBBox>
                    {intl.formatMessage({ id: 'SIZE_GROUP.CREATE.TITLE' })}
                </MDBBox>
            </MDBCardTitle>
            <MDBCardBody className="size-group-create-body">
                <MDBBox className="size-group-create-body-form">
                    <form
                        className='needs-validation was-validated'
                        onSubmit={(event: React.FormEvent) => { event.preventDefault(); handleCreateSizeGroup() }}
                    >
                        <MDBBox className='form-inputs'>
                            <MDBInput
                                label={intl.formatMessage({ id: 'SIZE_GROUP.CREATE.LABEL_NAME' })}
                                outline={true}
                                value={sizeGroupName}
                                onChange={(event) => { setSizeGroupName(event.currentTarget.value) }}
                                required
                                disabled={createSizeGroup.isLoading}
                            />
                        </MDBBox>
                        <MDBBox className='form-list-sizes'>
                            <MDBBox className='form-list-sizes-label'>{intl.formatMessage({ id: 'SIZE_GROUP.CREATE.LABEL_SIZES' })}</MDBBox>
                            {(sizes.isLoading || createSizeGroup.isLoading) && <MDBRow className="d-flex justify-content-center mb-5 w-100 h-100 mt-1"><MDBSpinner small /></MDBRow>}
                            {!sizes.isLoading && !createSizeGroup.isLoading && sizes.data.length === 0 && <p style={{ fontSize: '0.9rem', fontWeight: 300 }}>{intl.formatMessage({ id: 'SIZE_GROUP.CREATE.LABEL_SIZES_NOT_FOUND' })}</p>}
                            {!sizes.isLoading && !createSizeGroup.isLoading && sizes.data.length > 0 &&
                                (sizes.data.map((size) => {
                                    return (
                                        <MDBBadge
                                            key={size.id}
                                            className={(chosenSizes.includes(size.id)) ? 'chosen-badge' : 'remain-badge'}
                                            onClick={() => handleToggleSize(size.id)}
                                        >
                                            {size.name}
                                        </MDBBadge>
                                    )
                                }))
                            }
                        </MDBBox>

                        <MDBBox className='form-submit-box'>
                            <MDBBtn color='dark-green' className='form-submit-btn' type="submit">
                                {intl.formatMessage({ id: 'SIZE_GROUP.CREATE.LABEL_BUTTON' })}
                                <MDBIcon icon='plus' className='ml-3' />
                            </MDBBtn>
                        </MDBBox>
                    </form>
                </MDBBox>
            </MDBCardBody>
        </MDBCard>
    )
}

export default SizeGroupCreateFormComponent
