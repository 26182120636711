import BaseRepository from 'src/core/repositories/base.repository';
import { CreateImportRequest, Import, UpdateImportRequest } from 'src/core/models/management/import.model';
import * as CONST from 'src/core/utils/constants';
import ResponseCustom from 'src/core/models/response-custom.model';
import { AxiosResponse } from 'axios';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';

export default class ImportRepository extends BaseRepository {

    createImport(request: CreateImportRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.CREATE_IMPORT).create<ResponseCustom>(request)
    }

    searchImport(request: SearchAndFilterRequest): Promise<AxiosResponse<Import>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.SEARCH_IMPORT).search<Import>(request)
    }

    getImportDetails(code: string): Promise<AxiosResponse<Import>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_IMPORT_DETAILS).query<Import>({code})
    }

    deleteImport(code: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.DELETE_IMPORT).delete<ResponseCustom>({code})
    }

    updateOutcomePaidStatus(code: string, isOutcomePaid: boolean): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.UPDATE_IMPORT_OUTCOME_PAID_STATUS).create<ResponseCustom>({code, isOutcomePaid})
    }

    updateImport(request: UpdateImportRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.UPDATE_IMPORT).create<ResponseCustom>(request)
    }
}
