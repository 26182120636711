import React, { ReactElement } from 'react';
import 'src/shared/components/not-found/not-found.style.scss';

const Required = (): ReactElement => {
    return (
        <span className="text-danger"> * </span>
    )
}

export default Required;
