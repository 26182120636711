import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import {
    MDBBtn,
    MDBBtnGroup,
    MDBCol,
    MDBDataTable,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle, MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow,
    MDBSpinner,
} from 'mdbreact';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import * as OrderAction from 'src/core/reduxs/coffee-core/order/order.action';
import * as CONST from 'src/core/utils/constants';
import { OrderItemAddition } from 'src/core/models/coffee-core/order-item.model';
import Theme from 'src/core/utils/theme';
import * as COMMON from 'src/core/utils/common';
import PickDeep from 'src/core/models/pick-deep.modal';
import OrderState from 'src/core/reduxs/coffee-core/order/order.state';
import { Order } from 'src/core/models/coffee-core/order.model';
import Topping from 'src/core/models/coffee-core/topping/topping.model';

const columns = [
    {
        label: 'STT',
        field: 'no',
        width: 20,
    },
    {
        label: 'Tên món',
        field: 'name',
    },
    {
        label: 'Số lượng',
        field: 'quantity',
        width: 50,
    },
    {
        label: 'Thành tiền',
        field: 'totalPrice',
        width: 100,
    },
]

type Props = {
    isOpen: boolean,
    orderId: number,
    onModalClosed: () => void,
    fetchOrderListByReducer: () => void
}

type DataTable = {
    no: number;
    name: JSX.Element;
    totalPrice: string;
    quantity: number;
    [rest: string]: any;
}

const initItemsState: DataTable[] = [];

const OrderDetailComponent = (props: Props): JSX.Element => {

    const {orderId, isOpen, onModalClosed} = props
    const {formatMessage} = useIntl();
    const dispatch = useDispatch()
    const orderDetails: any = useSelector((state: any) => state.orderReducer.orderDetail);
    const order: Order = orderDetails.data;
    const updateOrder: PickDeep<OrderState, 'updateOrder'> = useSelector((state: any) => state.orderReducer.updateOrder);

    const [statusValue, setStatusValue] = useState('')
    const [items, setDataItems] = useState(initItemsState)

    const toggleStatus = (status: string) => {
        setStatusValue(status)
    }

    const onCloseModal = () => {
        // reset state
        setStatusValue('');
        setDataItems([]);

        onModalClosed();
    }

    const onChangeStatusSuccess = () => {
        props.fetchOrderListByReducer();
        onCloseModal()
    }

    const updateOrderDetail = () => {
        if (statusValue === orderDetails?.data?.status) return;

        const params = {
            id: orderDetails?.data?.id,
            status: statusValue
        }
        dispatch(OrderAction.actUpdateOrderStatus(params, onChangeStatusSuccess))
    }

    const buildItemAdditions = (additions: OrderItemAddition[]): JSX.Element[] => {
        if (_.isEmpty(additions)) return [<></>];

        const addition = additions[0];
        const result: JSX.Element[] = [];
        if (!_.isEmpty(addition?.size)) {
            result.push(
                <span
                    key={addition?.size.id + addition?.size.name}>{formatMessage({id: 'ORDER.LABEL.SIZE'}) + ': ' + addition?.size.name}</span>
            )
        }

        result.push(<br key={addition?.size.name + addition?.size.id}/>);

        if (!_.isEmpty(addition.toppings)) {
            result.push(
                <span key={addition?.toppings[0]?.name}>{formatMessage({id: 'ORDER.LABEL.TOPPING'}) + ': ' +
                    _.map(addition.toppings, item => ' ' + item.name)
                }</span>
            );
        }

        return result;
    }

    const prepareDataRow = () => {
        const data: DataTable[] = [];

        for (let i = 0; i < orderDetails?.data?.items.length; i++) {
            const item = orderDetails?.data?.items[i]
            const toppingNames = item.additions[0].toppings?.length > 0 ? item.additions[0].toppings.map((t: Topping) => t.name).join(', ') : undefined;
            const row = {
                no: i + 1,
                name: <div>
                    <div className='font-weight-normal'>{item.product.name || ''} ({item.additions[0].size.name})</div>
                    {toppingNames && <div>- {toppingNames}</div>}
                    {item.note && <div>- {item.note}</div>}
                </div>,
                totalPrice: COMMON.currencyFormatter(item.totalPrice || 0),
                quantity: item.quantity || 0,
            };
            data.push(row);
        }
        !_.isEmpty(data) && setDataItems(data)
    }

    function printBill() {
        const billHTML = generateBill();
        const printWindow = window.open('', '_blank');
        if (printWindow) {
            printWindow.document.write(billHTML);
            printWindow.document.close();
            printWindow.onafterprint = () => {
                printWindow.close();
            };
            printWindow.print();
        }
    }

    const generateBill = () => {
        // Generate bill as HTML using the billDetails
        let billHTML = '<html>';
        billHTML += '<head>';
        billHTML += '<style>';
        billHTML += 'body {font-family: Arial, sans-serif;}';
        billHTML += 'h1 {text-align: center; color: #333;}';
        billHTML += 'h2 {text-align: center; color: #333;}';
        billHTML += 'table {text-align: center;}';
        billHTML += '.bill-details {margin: 50px auto;}';
        billHTML += '.bill-details th, .bill-details td {padding: 15px; text-align: left; border-bottom: 1px solid #ddd;}';
        billHTML += '</style>';
        billHTML += '</head>';
        billHTML += '<body>';
        billHTML += '<h1>Tiệm Trà Sữa Thính</h1>';
        billHTML += '<p style="text-align: center">182 Rừng Sác, Bình Khánh, Cần Giờ</p>';
        billHTML += '<h2>PHIẾU THANH TOÁN</h2>';
        billHTML += '<table class=\'bill-details\'>';
        billHTML += '<tr><th>Tên món</th><th>Số lượng</th><th>Thành tiền</th></tr>';
        for (let i = 0; i < orderDetails?.data?.items.length; i++) {
            const item = orderDetails?.data?.items[i]
            const toppingNames = item.additions[0].toppings?.length > 0 ? item.additions[0].toppings.map((t: Topping) => t.name).join(', ') : undefined;
            billHTML += '<tr><td>' +
                '<div>' + item.product.name + ' (' + item.additions[0].size.name + ')</div>';
            if (toppingNames) {
                billHTML += `<div>- ${toppingNames}</div>`
            }
            if (item.note) {
                billHTML += `<div>- ${item.note}</div>`
            }
            billHTML += '</td><td>' + item.quantity + '</td><td>' + COMMON.currencyFormatter(item.totalPrice || 0);
            billHTML += '</td></tr>';
        }
        billHTML += '<tr><td colspan=\'2\'><b>Tổng tiền</b></td><td><b>' + COMMON.currencyFormatter(_.subtract(order.totalPrice || 0, order.promotion || 0)) + '</b></td></tr>';
        billHTML += '</table>';
        billHTML += '</body>';
        billHTML += '</html>';
        return billHTML;
    }

    useEffect(() => {
        dispatch(OrderAction.actGetOrderDetail(orderId))
    }, [orderId])

    useEffect(() => {
        if (!_.isEmpty(orderDetails.data)) {
            prepareDataRow()
            setStatusValue(_.get(orderDetails, 'data.status', ''))
        }
    }, [orderDetails.data])

    const renderDropDown = useMemo(() => {
        const dataStatus = _.values(CONST.ORDER_STATUS)
        return (
            <MDBBtnGroup className="btn-status">
                <MDBDropdown>
                    <MDBDropdownToggle caret color={_.get(Theme.color.dropdownOrderStatus, statusValue)}>
                        {_.get(CONST.ORDER_STATUS, `${statusValue}.value`, '')}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic color='info'>
                        {dataStatus.map((value, index) => {
                            return (
                                <MDBDropdownItem
                                    onClick={() => toggleStatus(value.key)}
                                    key={index}>{value.value}</MDBDropdownItem>
                            )
                        })}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </MDBBtnGroup>
        )
    }, [statusValue])

    return (
        <MDBModal
            animation="top"
            fade={true}
            size='lg'
            isOpen={isOpen}
            toggle={onCloseModal}
            inline={false}
            noClickableBodyWithoutBackdrop
            overflowScroll
            disableBackdrop
        >
            <MDBModalHeader>{formatMessage({id: 'ORDER.LABEL.DETAIL'})}: {orderDetails?.data?.code || ''}</MDBModalHeader>
            <MDBModalBody>
                {!order &&
                    <div className='m-5 text-center'>
                        {orderDetails.isLoading ? <MDBSpinner/>
                            : <div className='m-5 text-center'>
                                Không tìm thấy đơn hàng
                            </div>
                        }
                    </div>
                }
                {order &&
                    <Fragment>
                        <div className="">
                            <span className="font-weight-bold">{formatMessage({id: 'ORDER.LABEL.CUSTOMER'})}</span>
                            {
                                order.receiver &&
                                <MDBRow className="d-flex align-items-center mt-3">
                                    <MDBCol><span
                                        className="font-weight-normal">{formatMessage({id: 'ORDER.CUSTOMER.NAME'})}</span>
                                    </MDBCol>
                                    <MDBCol><span
                                        className="font-weight-lighter">{order.receiver}</span>
                                    </MDBCol>
                                </MDBRow>
                            }
                            {
                                order.phone &&
                                <MDBRow className="d-flex align-items-center mt-3">
                                    <MDBCol><span
                                        className="font-weight-normal">{formatMessage({id: 'ORDER.CUSTOMER.PHONE'})}</span>
                                    </MDBCol>
                                    <MDBCol><span
                                        className="font-weight-lighter">{order.phone}</span>
                                    </MDBCol>
                                </MDBRow>
                            }
                            {
                                order.address &&
                                <MDBRow className="d-flex align-items-center mt-3">
                                    <MDBCol> <span
                                        className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.ADDRESS'})}</span></MDBCol>
                                    <MDBCol>
                                        <span
                                            className="font-weight-lighter"
                                            style={{whiteSpace: 'pre-wrap'}}>{order.address}</span>
                                    </MDBCol>
                                </MDBRow>
                            }
                            {
                                order.tableNumber &&
                                <MDBRow className="d-flex align-items-center mt-3">
                                    <MDBCol> <span
                                        className="font-weight-normal">Số bàn</span></MDBCol>
                                    <MDBCol>
                                        <span className="font-weight-lighter">{order.tableNumber}</span>
                                    </MDBCol>
                                </MDBRow>
                            }
                        </div>
                        <div className='mt-5'>
                            <span className="font-weight-bold">{formatMessage({id: 'ORDER.LABEL.ORDER'})}</span>
                            <MDBRow className="d-flex align-items-center mt-3">
                                <MDBCol className="d-flex align-items-center"><span
                                    className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.STATUS'})}</span></MDBCol>
                                <MDBCol>
                                    {renderDropDown}
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="d-flex align-items-center mt-3">
                                <MDBCol className="d-flex align-items-center"> <span
                                    className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.METHOD'})}</span>
                                </MDBCol>
                                <MDBCol>
                                    <div
                                        className='font-weight-normal text-primary'>{_.get(CONST.ORDER_METHOD, `${orderDetails.data.method}.value`)}</div>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="d-flex align-items-center mt-3">
                                <MDBCol className="d-flex align-items-center"> <span
                                    className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.DISCOUNT'})}</span></MDBCol>
                                <MDBCol><span
                                    className="font-weight-lighter"
                                    style={{whiteSpace: 'pre-wrap'}}>{_.get(orderDetails, 'data.promotion', 0)}</span>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="d-flex align-items-center mt-3">
                                <MDBCol> <span
                                    className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.NOTE'})}</span></MDBCol>
                                <MDBCol><span
                                    className="font-weight-lighter"
                                    style={{whiteSpace: 'pre-wrap'}}>{_.get(orderDetails, 'data.note', '')}</span>
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <div className='mt-5'>
                            <span className="font-weight-bold">{formatMessage({id: 'ORDER.LABEL.ITEM'})}</span>
                            <MDBDataTable
                                responsive
                                entries={CONST.DEFAULT_PAGE_SIZE}
                                noBottomColumns={true}
                                paging={false}
                                proSelect
                                displayEntries={false}
                                data={{columns: columns, rows: items}}
                                noRecordsFoundLabel="Không tìm thấy sản phẩm nào"
                            />
                        </div>

                        <div className='justify-content-between'>
                            <MDBRow className="d-flex align-items-center mt-3">
                                <MDBCol size='8'>
                                    <span
                                        className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.TOTAL_PRICE'})}:</span>
                                </MDBCol>
                                <MDBCol>
                                    <span
                                        className="font-weight-normal">{COMMON.currencyFormatter(order.totalPrice || 0)}</span>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className="d-flex align-items-center">
                                <MDBCol size='8'>
                                    <span
                                        className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.TOTAL_PAYMENT'})}:</span>
                                </MDBCol>
                                <MDBCol>
                                    <span
                                        className="font-weight-bold">{COMMON.currencyFormatter(_.subtract(order.totalPrice || 0, order.promotion || 0))}</span>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    </Fragment>
                }
            </MDBModalBody>
            <MDBModalFooter>
                <MDBRow>
                    <MDBCol size='12'>
                        <div className='w-100 text-right'>
                            <MDBBtn
                                color='primary' onClick={() => printBill()}>
                                <MDBIcon icon='print' className='mr-2'/>
                                In hóa đơn
                            </MDBBtn>
                            <MDBBtn
                                color='primary' onClick={() => updateOrderDetail()}
                                disabled={statusValue === orderDetails?.data?.status}>
                                {formatMessage({id: 'ORDER.BUTTON.SAVE'}).toUpperCase()}
                            </MDBBtn>
                            <MDBBtn color='danger' onClick={onCloseModal}>
                                {formatMessage({id: 'ORDER.BUTTON.EXIT'}).toUpperCase()}
                            </MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBModalFooter>
        </MDBModal>
    );
}

export default React.memo(OrderDetailComponent);
