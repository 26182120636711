import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import { CreateExportRequest, Export, UpdateExportRequest } from 'src/core/models/management/export.model';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';

export default class ExportRepository extends BaseRepository {

    createExport(request: CreateExportRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.CREATE_EXPORT).create<ResponseCustom>(request)
    }

    searchExport(request: SearchAndFilterRequest): Promise<AxiosResponse<Export>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.SEARCH_EXPORT).search<Export>(request)
    }

    getExportDetails(code: string): Promise<AxiosResponse<Export>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_EXPORT_DETAILS).query<Export>({code})
    }

    deleteExport(code: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.DELETE_EXPORT).delete<ResponseCustom>({code})
    }

    updateExport(request: UpdateExportRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.UPDATE_EXPORT).create<ResponseCustom>(request)
    }
}
