import { ApiURI } from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { CreateBlackPearlCodesRequest } from 'src/core/models/black-pearl-code.model';
import { AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import { AccumulateBlackPearlRequest } from 'src/core/models/black-pearl.model';

export default class BlackPearlRepository extends BaseRepository {
    updateBlackPearlCodeStatus(blackPearlCodeIds: number[], status: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/blackPearl/updateBlackPearlCodeStatus`).create<ResponseCustom>({
            blackPearlCodeIds,
            status
        });
    }

    accumulateBlackPearl(request: AccumulateBlackPearlRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/blackPearl/accumulate`).create<ResponseCustom>(request);
    }
}
