import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import PagingRequest from 'src/core/models/paging-request.model';
import ResponseCustom from 'src/core/models/response-custom.model';
import { ProductCreateRequest, ProductUpdateRequest, ProductDeleteRequest, GetProductsByCategoryRequest } from 'src/core/models/coffee-core/product/product-request-params.model';
import Product from 'src/core/models/coffee-core/product/product.model';

export default class ProductRepository extends BaseRepository {

    getListProduct(params: PagingRequest): Promise<AxiosResponse<Product[]>> {
        const request = {
            ...params,
            itemsPerPage: CONST.DEFAULT_PAGE_SIZE,
            sortBy: 'display_order',
            sortType: 'DESC'
        };
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_PRODUCT).query<Product[]>(request);
    }
    getListProductByCategory(params: GetProductsByCategoryRequest): Promise<AxiosResponse<Product[]>> {
        const request = {
            ...params,
            itemsPerPage: CONST.DEFAULT_PAGE_SIZE,
            sortBy: 'display_order',
            sortType: 'DESC',
        };
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_PRODUCT_BY_CATEGORY).query<Product[]>(request);
    }
    getDetailProduct(id: number) : Promise<AxiosResponse<Product>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_DETAIL_PRODUCT).query<Product>({id})
    }
    createProduct(params: ProductCreateRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.CREATE_PRODUCT).create<ResponseCustom>(params.data)
    }
    updateProduct(params: ProductUpdateRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.UPDATE_PRODUCT).create<ResponseCustom>(params.data)
    }
    deleteProduct(params: ProductDeleteRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.DELETE_PRODUCT).create<ResponseCustom>(params.data)
    }
}
