import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBDataTable, MDBRow, MDBSpinner, } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import 'src/features/pages/material-management/export/export.style.scss';
import { StringParam, useQueryParam } from 'use-query-params';
import { AppURI, ImportTypes } from 'src/core/utils/constants';
import { Link } from 'react-router-dom';
import { currencyFormatter, getScreenShot } from 'src/core/utils/common';
import { Import, ImportItem } from 'src/core/models/management/import.model';
import { actGetImportDetails, actUpdateOutcomePaidStatus } from 'src/core/reduxs/management/import/import.action';
import ConfirmModal from 'src/shared/components/confirm-modal/confirm-modal.component';
import TransactionDocumentsComponent from 'src/features/components/transaction/transaction-documents.component';
import ImportUpdateModal from 'src/features/components/import/import-update-modal.component';

type DataTable = {
    no: number;
    materialName: string;
    unitPrice: string;
    quantity: number;
    price: string;
    [rest: string]: any;
}

const columns = [
    {
        label: 'STT',
        field: 'no',
    },
    {
        label: 'Nguyên liệu',
        field: 'materialName',
    },
    {
        label: 'Đơn giá',
        field: 'unitPrice',
    },
    {
        label: 'Số lượng',
        field: 'quantity',
    },
    {
        label: 'Giá',
        field: 'price',
    }
]

const ImportDetailContainer = (): JSX.Element => {

    const [code, setCode] = useQueryParam('code', StringParam);
    const dispatch = useDispatch();
    const importDetailsSelector = useSelector((state: any) => state.importReducer.importDetails);
    const importDetails: Import = importDetailsSelector.data;
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [isVisibleUpdateOutcomePaidStatusModal, setIsVisibleUpdateOutcomePaidStatusModal] = useState(false);
    const updateOutcomePaidStatusSelector = useSelector((state: any) => state.importReducer.updateOutcomePaidStatus);
    const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false);

    useEffect(() => {
        if (code) {
            dispatch(actGetImportDetails(code));
        }
    }, [code]);

    const renderDataRow = useCallback((importItemList: ImportItem[]) => {
        const data: DataTable[] = [];
        importItemList?.forEach((item: ImportItem, index: number) => {
            const row = {
                no: index + 1,
                code: item.code,
                materialName: item.material.name,
                unitPrice: currencyFormatter(item.unitPrice),
                quantity: item.quantity,
                price: currencyFormatter(item.price)
            };
            data.push(row);
        });
        setDataTable(data);
    }, [])

    useEffect(() => {
        if (importDetails) {
            renderDataRow(importDetails.items);
        }
    }, [importDetails]);

    const onClickUpdateOutcomePaidStatus = () => {
        setIsVisibleUpdateOutcomePaidStatusModal(true);
    }

    const updateOutcomePaidStatus = () => {
        dispatch(
            actUpdateOutcomePaidStatus(importDetails.code, true,
                () => dispatch(actGetImportDetails(importDetails.code))));
        setIsVisibleUpdateOutcomePaidStatusModal(false);
    }

    const renderDataTable = useMemo(() => {
        return (
            <MDBDataTable
                className='common-table'
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không tìm thấy mục nhập nào"
                data={{columns: columns, rows: dataTable}}
            />
        )
    }, [dataTable]);

    return (
        <Fragment>
            <MDBContainer className='py-3' fluid>
                {!importDetails || importDetailsSelector.isLoading ?
                    <div className='m-5 text-center'>
                        <MDBSpinner/>
                    </div>
                    :
                    <Fragment>
                        <div id='import-details' className='px-2 pt-2'>
                            <div className='text-center'>
                                <p className='font-size-20'>Chi tiết phiếu nhập</p>
                            </div>
                            <MDBRow>
                                <MDBCol size='12'>
                                    <div>
                                        <MDBRow className='mt-2'>
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Kho</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                <div>{importDetails.stock.name}</div>
                                            </MDBCol>
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Mã phiếu</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                <div>{importDetails.code}</div>
                                            </MDBCol>
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Loại nhập</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                <div>{ImportTypes[importDetails.type]}</div>
                                            </MDBCol>
                                            {
                                                importDetails.supplier &&
                                                <Fragment>
                                                    <MDBCol md="2">
                                                        <div className='font-weight-normal'>Nhập từ</div>
                                                    </MDBCol>
                                                    <MDBCol md="10">
                                                        <div>{importDetails.supplier.name}</div>
                                                    </MDBCol>
                                                </Fragment>
                                            }
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Nội dung</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                <div>{importDetails.title}</div>
                                            </MDBCol>
                                            {
                                                importDetails.actorDeposit &&
                                                <Fragment>
                                                    <MDBCol md="2">
                                                        <div className='font-weight-normal'>Ví chi</div>
                                                    </MDBCol>
                                                    <MDBCol md="10">
                                                        <div>{importDetails.actorDeposit.name}</div>
                                                    </MDBCol>
                                                </Fragment>
                                            }
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Tổng số tiền</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                <div>{currencyFormatter(importDetails.totalPrice)}</div>
                                            </MDBCol>
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Trạng thái</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                {
                                                    importDetails.isOutcomePaid
                                                        ? <div className='text-success font-weight-normal'>Đã thanh toán</div>
                                                        : <div className='text-danger font-weight-normal'>Chưa thanh toán</div>
                                                }
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </MDBCol>
                                <MDBCol size='12'>
                                    <div className="font-weight-normal mt-4">DANH SÁCH MỤC NHẬP</div>
                                    <MDBCard className='container-fluid mt-2'>
                                        <MDBCardBody>
                                            {renderDataTable}
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <MDBRow className='px-2'>
                            <MDBCol size='12'>
                                <div className='mt-3 text-center'>
                                    {
                                        !importDetails.isOutcomePaid &&
                                        <MDBBtn
                                            color="info"
                                            onClick={onClickUpdateOutcomePaidStatus}
                                            disabled={updateOutcomePaidStatusSelector.isLoading}
                                        >
                                            Đã thanh toán
                                        </MDBBtn>
                                    }
                                    {
                                        updateOutcomePaidStatusSelector.isLoading &&
                                        <div>
                                            <MDBSpinner small className='primary'/>
                                        </div>
                                    }
                                    <MDBBtn
                                        color="primary"
                                        onClick={() => setIsVisibleUpdateModal(true)}
                                    >
                                        Chỉnh sửa
                                    </MDBBtn>
                                    <MDBBtn
                                        color="dark-green"
                                        onClick={() => getScreenShot('import-details')}
                                    >
                                        Chụp màn hình
                                    </MDBBtn>
                                </div>
                                <TransactionDocumentsComponent
                                    transactionId={importDetails.transaction?.id}
                                />
                                <div className='mt-3'>
                                    <Link to={AppURI.IMPORT}>&larr; Danh sách phiếu nhập</Link>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </Fragment>
                }
            </MDBContainer>
            <ConfirmModal
                isVisible={isVisibleUpdateOutcomePaidStatusModal}
                setIsVisible={setIsVisibleUpdateOutcomePaidStatusModal}
                title='Cập nhật trạng thái thanh toán'
                message='Bạn có chắc muốn chuyển trạng thái phiếu nhập này sang đã thanh toán?'
                color='info'
                onClickConfirm={updateOutcomePaidStatus}
            />
            {
                code &&
                <ImportUpdateModal
                    isVisible={isVisibleUpdateModal}
                    setIsVisible={setIsVisibleUpdateModal}
                    importCode={code}
                />
            }
        </Fragment>
    );
}

export default React.memo(ImportDetailContainer);
