import { MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalHeader } from 'mdbreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as ToppingGroupAction from 'src/core/reduxs/coffee-core/topping-group/topping-group.action'
import PickDeep from 'src/core/models/pick-deep.modal'
import { toast } from 'react-hot-toast'
import ToppingGroup from 'src/core/models/coffee-core/topping-group/topping-group.model';
import ToppingGroupStateType from 'src/core/models/coffee-core/topping-group/topping-group-state.model';
import { ToppingGroupDeleteRequest } from 'src/core/models/coffee-core/topping-group/topping-group-request-params.model';
import { useIntl } from 'react-intl';
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component';


type Props = {
    isOpen: boolean,
    toppingGroupDetail: ToppingGroup,
    onModalClosed: () => void
}
const ToppingGroupDeleteModalComponent = (props: Props): JSX.Element => {
    const { toppingGroupDetail, isOpen, onModalClosed } = props;
    const intl = useIntl();
    const dispatch = useDispatch()
    const deleteToppingGroup: PickDeep<ToppingGroupStateType, 'deleteToppingGroup'> = useSelector((state: any) => state.toppingGroupReducer.deleteToppingGroup)
    const onCloseModal = () => {
        onModalClosed()
    }

    const onSuccessDelete = () => {
        onCloseModal();
        toast.success(intl.formatMessage({ id: 'TOPPING_GROUP.DELETE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureDelete = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'TOPPING_GROUP.DELETE.TOAST_FAILURE' }), { duration: 3000 })
    }
    const handleDeleteToppingGroup = () => {
        const params: ToppingGroupDeleteRequest = {
            data: {
                id: toppingGroupDetail.id,
            },
            successCallback: onSuccessDelete,
            errorCallback: onFailureDelete
        }
        dispatch(ToppingGroupAction.actDeleteToppingGroup(params))
    }
    return (
        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'TOPPING_GROUP.DELETE.TITLE' })}
            type='delete'
        >
            <div className='text-center my-3'>{`${intl.formatMessage({ id: 'TOPPING_GROUP.DELETE.DESCRIPTION' })} ${toppingGroupDetail.name}`}</div>
            <div className='text-center mb-1-half'>
                <MDBBtn
                    className='mb-2'
                    outline
                    color="danger"
                    disabled={deleteToppingGroup.isLoading}
                    onClick={onCloseModal}
                >
                    {intl.formatMessage({ id: 'TOPPING_GROUP.DELETE.LABEL_CANCEL_BUTTON' })}
                    <MDBIcon icon='times' className='ml-3' />
                </MDBBtn>
                <MDBBtn
                    className='mb-2 '
                    color="danger"
                    onClick={handleDeleteToppingGroup}
                    disabled={deleteToppingGroup.isLoading}
                >
                    {intl.formatMessage({ id: 'TOPPING_GROUP.DELETE.LABEL_CONFIRM_BUTTON' })}
                    <MDBIcon icon='check' className='ml-3' />
                </MDBBtn>
            </div>
        </CustomModal>
    )
}

export default ToppingGroupDeleteModalComponent
