import React, { useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PickDeep from 'src/core/models/pick-deep.modal';
import CategoryStateType from 'src/core/models/coffee-core/category/category-state.model'
import toast from 'react-hot-toast';
import { CategoryCreateRequest } from 'src/core/models/coffee-core/category/category-request-params.model';
import * as CategoryAction from 'src/core/reduxs/coffee-core/category/category.action'
import { MDBBtn, MDBFileInput, MDBIcon, MDBInput, MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions } from 'mdbreact';
import { CategoryType } from 'src/core/models/coffee-core/category/category.model';
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component';
type Props = {
    isOpen: boolean,
    onModalClosed: () => void
}
const CategoryCreateModalComponent = (props: Props) => {
    const intl = useIntl();
    const { isOpen, onModalClosed } = props;
    const dispatch = useDispatch();
    const [categoryName, setCategoryName] = useState('')
    const [categoryType, setCategoryType] = useState<CategoryType>('PRODUCT');
    const [imageFile, setImageFile] = useState<File | null>(null)
    const createCategory: PickDeep<CategoryStateType, 'createCategory'> = useSelector((state: any) => state.categoryReducer.createCategory)

    const onCloseModal = () => {
        setImageFile(null)
        onModalClosed()
    }
    const onSuccessCreate = () => {
        onCloseModal();
        toast.success(intl.formatMessage({ id: 'CATEGORY.CREATE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureCreate = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'CATEGORY.CREATE.TOAST_FAILURE' }), { duration: 3000 })
    }

    const handleCreate = () => {
        const formData = new FormData();
        formData.append('name', categoryName)
        formData.append('type', categoryType)

        if (imageFile) {
            if (imageFile?.type.includes('image')) {
                formData.append('image', imageFile)
            } else {
                toast.error(intl.formatMessage({ id: 'CATEGORY.CREATE.TOAST_WARNING_NOT_IMAGE_FILE' }))
                return;
            }
        }

        const params: CategoryCreateRequest = {
            data: formData,
            successCallback: onSuccessCreate,
            errorCallback: onFailureCreate
        }
        console.log(params)
        dispatch(CategoryAction.actCreateCategory(params))
    }
    return (
        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'CATEGORY.CREATE.TITLE' })}
            type='create'
        >
            <form
                onSubmit={(event: React.FormEvent) => { event.preventDefault(); handleCreate() }}
                className='needs-validation was-validated'
            >
                <MDBInput
                    label={intl.formatMessage({ id: 'CATEGORY.CREATE.LABEL_NAME' })}
                    value={categoryName}
                    onChange={(event) => { setCategoryName(event.currentTarget.value) }}
                    required
                    disabled={createCategory.isLoading}
                />
                <MDBSelect label={intl.formatMessage({ id: 'CATEGORY.CREATE.LABEL_TYPE' })} getValue={(value: any) => { setCategoryType(value[0]) }}>
                    <MDBSelectInput />
                    <MDBSelectOptions >
                        <MDBSelectOption value="PRODUCT" selected={categoryType === 'PRODUCT'} >Product</MDBSelectOption>
                        <MDBSelectOption value="NEWS" selected={categoryType === 'NEWS'} >News</MDBSelectOption>
                    </MDBSelectOptions>
                </MDBSelect>
                <MDBFileInput
                    btnTitle={intl.formatMessage({ id: 'CATEGORY.CREATE.LABEL_BUTTON_CHOOSE_IMAGE' })}
                    btnColor='dark-green'
                    textFieldTitle={intl.formatMessage({ id: 'CATEGORY.CREATE.LABEL_TITLE_CHOOSE_IMAGE' })}
                    getValue={(files: File[]) => setImageFile(files[0])}
                    accept="image/*"
                />
                {
                    imageFile?.type?.includes('image') && (
                        <img
                            src={URL.createObjectURL(imageFile as Blob)}
                            className='category-image-review'
                        />
                    )
                }

                <div className='text-center mb-1-half'>
                    <MDBBtn
                        className='mb-2'
                        color='dark-green'
                        type="submit"
                        disabled={createCategory.isLoading}
                    >
                        {intl.formatMessage({ id: 'CATEGORY.CREATE.LABEL_BUTTON' })}
                        <MDBIcon icon='check' className='ml-3' />
                    </MDBBtn>
                </div>
            </form>
        </CustomModal>
    )
}

export default CategoryCreateModalComponent
