import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { MCategory } from 'src/core/models/management/mcategory.model';
import ResponseCustom from 'src/core/models/response-custom.model';
import { CreateOutcomeRequest, Outcome } from 'src/core/models/management/outcome.model';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';

export default class OutcomeRepository extends BaseRepository {

    getListCategory(): Promise<AxiosResponse<MCategory[]>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_LIST_OUTCOME_CATEGORY).getAll();
    }

    createOutcome(request: CreateOutcomeRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.CREATE_OUTCOME).create<ResponseCustom>(request)
    }

    searchOutcome(request: SearchAndFilterRequest): Promise<AxiosResponse<Outcome>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.SEARCH_OUTCOME).search<Outcome>(request)
    }

    getOutcomeDetails(code: string): Promise<AxiosResponse<Outcome>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_OUTCOME_DETAILS).query<Outcome>({code})
    }

    deleteOutcome(code: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.DELETE_OUTCOME).delete<ResponseCustom>({code})
    }
}
