import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBDatePicker,
    MDBInput,
    MDBInputGroup,
    MDBRow,
    MDBSelect,
    MDBSpinner,
} from 'mdbreact';
import 'src/features/pages/material-management/export/export-create.style.scss';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as CONST from 'src/core/utils/constants';
import { DATE_FORMAT_FOR_CODE, ExportCreateTypes } from 'src/core/utils/constants';
import ExportDetailCreationListComponent from 'src/features/components/export/export-detail-creation-list.component';
import ExportDetailCreateForm from 'src/features/components/export/export-detail-create-form.component';
import { actGetListStock } from 'src/core/reduxs/management/stock/stock.action';
import { Stock } from 'src/core/models/management/stock.model';
import { actGetListPartnerOfStore } from 'src/core/reduxs/management/partner/partner.action';
import { Partner } from 'src/core/models/management/partner.model';
import { CreateExportRequest, ExportItem, ExportItemRequest } from 'src/core/models/management/export.model';
import { toast } from 'react-hot-toast';
import { formatDateForApi } from 'src/core/utils/common';
import moment from 'moment';
import { actCreateExport } from 'src/core/reduxs/management/export/export.action';
import { useHistory } from 'react-router-dom';
import { actGetListDepositOfStore } from 'src/core/reduxs/management/deposit/deposit.action';
import { Deposit } from 'src/core/models/management/deposit.model';
import { actResetExportDetailItem } from 'src/core/reduxs/management/export-detail/export-detail.action';
import Select from 'react-select';

const ExportCreateContainer = (): JSX.Element => {

    const history = useHistory();
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const dispatch = useDispatch();
    const stocks: Stock[] = useSelector((state: any) => state.stockReducer.stocks.data);
    const deposits: Deposit[] = useSelector((state: any) => state.depositReducer.storeDeposits.data);
    const partners: Partner[] = useSelector((state: any) => state.partnerReducer.partners.data);
    const exportDetails: ExportItem[] = useSelector((state: any) => state.exportDetailReducer.createExportDetails.items);
    const [selectedStock, setSelectedStock] = useState<Stock | undefined>();
    const [selectedType, setSelectedType] = useState<string | undefined>();
    const [selectedExportTime, setSelectedExportTime] = useState<Date | undefined>();
    const [exportCodePrefix, setExportCodePrefix] = useState<string | undefined>('');
    const creating: boolean = useSelector((state: any) => state.exportReducer.createExport.loading);

    useEffect(() => {
        dispatch(actGetListStock());
    }, []);

    useEffect(() => {
        if ('DOI_TAC' === selectedType && selectedStock) {
            dispatch(actGetListPartnerOfStore(selectedStock.store.id));
        }
    }, [selectedType, selectedStock]);

    useEffect(() => {
        if (selectedStock) {
            dispatch(actGetListDepositOfStore(selectedStock.store.id));
        }
    }, [selectedStock]);

    useEffect(() => {
        if (selectedStock && selectedExportTime) {
            const dateString = moment(selectedExportTime).format(DATE_FORMAT_FOR_CODE);
            setExportCodePrefix(`${selectedStock.store.code}_${dateString}_XUAT_`)
        }
    }, [selectedStock, selectedExportTime]);

    const stockOptions = useMemo(() => {
        if (!_.isEmpty(stocks)) {
            return stocks.map((s: Stock) => ({
                value: s.id,
                label: s.name
            }));
        }
        return [];
    }, [stocks]);

    const depositOptions = useMemo(() => {
        if (!_.isEmpty(deposits)) {
            return deposits.map((d: Deposit) => ({
                label: d.name,
                value: d.id
            }));
        }
        return [];
    }, [deposits]);

    const partnerOptions = useMemo(() => {
        if (!_.isEmpty(partners)) {
            return partners.map((s: Partner) => ({
                label: s.name,
                value: s.id
            }));
        }
        return [];
    }, [partners]);

    const typeOptions = useMemo(() => {
        return Object.keys(ExportCreateTypes).map(key => ({
            label: ExportCreateTypes[key],
            value: key
        }))
    }, []);

    const onChangeStock = (stockId: number) => {
        const stock = stocks.find(s => s.id === stockId)
        setSelectedStock(stock);
    }

    const onCreateSuccess = useCallback(() => {
        dispatch(actResetExportDetailItem())
        toast.success('Tạo phiếu xuất thành công', { duration: 2000 })
        history.push(CONST.AppURI.EXPORT);
    }, [])

    const onCreateFailure = useCallback(() => {
        toast.error('Tạo phiếu xuất thất bại', { duration: 2000 })
    }, [])

    const onSubmit = (data: any) => {
        if (_.isEmpty(exportDetails)) {
            toast.error('Bạn chưa nhập chi tiết phiếu xuất', { duration: 2000 })
            return
        }
        const exportDetailRequests: ExportItemRequest[] = exportDetails.map(e => ({
            materialId: e.material.id,
            unitPrice: e.unitPrice,
            quantity: e.quantity
        }))
        const request: CreateExportRequest = {
            type: data.type,
            stockId: data.stockId,
            actorDepositId: data.actorDepositId,
            partnerId: data.partnerId,
            title: data.title,
            exportDetails: exportDetailRequests,
            exportTime: data.exportTime,
            code: exportCodePrefix + data.code
        }
        dispatch(actCreateExport(request, onCreateSuccess, onCreateFailure))
    }

    return (
        <MDBContainer className='py-3' fluid>
            <div className='text-center'>
                <h2 className='page-title'>Tạo phiếu xuất</h2>
            </div>
            <MDBRow className='pb-3'>
                <MDBCol size='12'>
                    <p className="font-weight-normal mt-4">THÔNG TIN PHIẾU XUẤT</p>
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Nội dung</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBInput
                        {...register('title', {
                            required: 'Nội dung là bắt buộc',
                            maxLength: {
                                value: 255,
                                message: 'Nội dung không được nhiều hơn 255 ký tự',
                            }
                        })}
                        placeholder='Nội dung'
                        onChange={(e: any) => {
                            setValue('title', e.target.value)
                        }}
                        containerClass='my-0 max-width-400'
                    />
                    {errors.title && <ErrorMessage>{errors.title.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Kho</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('stockId', {
                            required: 'Kho là bắt buộc'
                        })}
                        options={stockOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                const stockId = newValue.value
                                setValue('stockId', stockId);
                                onChangeStock(stockId)
                            }
                        }}
                        placeholder='(chọn kho)'
                        className='max-width-400 my-1'
                    />
                    {errors.stockId && <ErrorMessage>{errors.stockId.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Loại xuất</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('type', {
                            required: 'Loại xuất là bắt buộc'
                        })}
                        options={typeOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('type', newValue.value);
                                setSelectedType(newValue.value);
                            }
                        }}
                        placeholder='(chọn loại xuất)'
                        className='max-width-400 my-1'
                    />
                    {errors.type && <ErrorMessage>{errors.type.message}</ErrorMessage>}
                </MDBCol>
                {
                    'BAN_NL' === selectedType &&
                    <React.Fragment>
                        <MDBCol md="2" className='flex-row'>
                            <div className="pt-2">Ví thu</div>
                        </MDBCol>
                        <MDBCol md="10">
                            <Select
                                {...register('actorDepositId', {
                                    required: 'Ví thu là bắt buộc'
                                })}
                                options={depositOptions}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        setValue('actorDepositId', newValue.value);
                                    }
                                }}
                                placeholder='(chọn ví)'
                                className='max-width-400 my-1'
                            />
                            {errors.actorDepositId && <ErrorMessage>{errors.actorDepositId.message}</ErrorMessage>}
                        </MDBCol>
                    </React.Fragment>
                }
                {
                    'DOI_TAC' === selectedType &&
                    <React.Fragment>
                        <MDBCol md="2" className='flex-row'>
                            <div className="pt-2">Đối tác</div>
                        </MDBCol>
                        <MDBCol md="10">
                            <Select
                                {...register('partnerId', {
                                    required: 'Đối tác là bắt buộc'
                                })}
                                options={partnerOptions}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        setValue('partnerId', newValue.value);
                                    }
                                }}
                                placeholder='(chọn đối tác)'
                                className='max-width-400 my-1'
                            />
                            {errors.partnerId && <ErrorMessage>{errors.partnerId.message}</ErrorMessage>}
                        </MDBCol>
                    </React.Fragment>
                }
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Ngày xuất</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBDatePicker
                        {...register('exportTime', {
                            required: 'Ngày xuất là bắt buộc'
                        })}
                        clearable
                        format='DD-MM-YYYY'
                        autoOk
                        getValue={(e) => {
                            setValue('exportTime', e ? formatDateForApi(e) : null)
                            setSelectedExportTime(e)
                        }}
                        className='mt-0'
                        valueDefault={null as any}
                        emptyLabel='(chọn ngày)'
                    />
                    {errors.exportTime && <ErrorMessage>{errors.exportTime.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Mã phiếu xuất</div>
                </MDBCol>
                <MDBCol md="10">
                    <div
                        {...register('code', {
                            required: 'Mã phiếu xuất là bắt buộc'
                        })}
                    >
                        <MDBInputGroup
                            material
                            containerClassName="my-0 max-width-400"
                            className='font-weight-bold text-primary'
                            prepend={exportCodePrefix}
                            onChange={(e: any) => setValue('code', e.target.value)}
                        >
                        </MDBInputGroup>
                    </div>
                    {errors.code && <ErrorMessage>{errors.code.message}</ErrorMessage>}
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size='7'>
                    <ExportDetailCreationListComponent/>
                </MDBCol>
                <MDBCol size='5'>
                    <ExportDetailCreateForm/>
                </MDBCol>
                <MDBCol size='12'>
                    <div className='text-center'>
                        <MDBBtn color="primary" onClick={handleSubmit(onSubmit)} disabled={creating}>Tạo phiếu xuất</MDBBtn>
                        {creating &&
                            <MDBSpinner small className='b-primary' />
                        }
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default React.memo(ExportCreateContainer);
