import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import NewsState from 'src/core/reduxs/coffee-core/news/news.state';
import Types from 'src/core/reduxs/coffee-core/news/news.type';
import { News } from 'src/core/models/coffee-core/news.model';
import PageModel from 'src/core/models/paging-response.model';

const initialState: NewsState = {
    newsCategories: {
        data: [],
        error: null,
        isLoading: false,
    },
    createNews: {
        data: {},
        error: null,
        isLoading: false
    },
    updateNews: {
        data: {},
        error: null,
        isLoading: false
    },
    searchedNews: {
        data: undefined,
        error: null,
        isLoading: false,
    },
    newsDetails: {
        data: undefined,
        error: null,
        isLoading: false
    },
};

const newsReducer = createReducer(initialState, {
    [Types.GET_LIST_NEWS_CATEGORY_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            newsCategories: {
                ...state.newsCategories,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_NEWS_CATEGORY_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            newsCategories: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_NEWS_CATEGORY_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            newsCategories: {
                ...state.newsCategories,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.CREATE_NEWS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createNews: {
                ...state.createNews,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_NEWS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createNews: {
                ...state.createNews,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_NEWS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createNews: {
                ...state.createNews,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_NEWS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateNews: {
                ...state.updateNews,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_NEWS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateNews: {
                ...state.updateNews,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_NEWS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateNews: {
                ...state.updateNews,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.SEARCH_NEWS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedNews: {
                ...state.searchedNews,
                isLoading: true,
            }
        };
    },
    [Types.SEARCH_NEWS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedNews: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.SEARCH_NEWS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedNews: {
                ...state.searchedNews,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.GET_NEWS_DETAILS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            newsDetails: {
                ...state.newsDetails,
                isLoading: true,
            }
        };
    },
    [Types.GET_NEWS_DETAILS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            newsDetails: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_NEWS_DETAILS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            newsDetails: {
                ...state.newsDetails,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default newsReducer;
