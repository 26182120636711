import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import PartnerState from 'src/core/reduxs/management/partner/partner.state';
import Types from 'src/core/reduxs/management/partner/partner.type';

const initialState: PartnerState = {
    partners: {
        data: [] ,
        error: null,
        isLoading: false,
    }
};

const partnerReducer = createReducer(initialState, {
    [Types.GET_LIST_PARTNER_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            partners: {
                ...state.partners,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_PARTNER_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            partners: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_PARTNER_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            partners: {
                ...state.partners,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default partnerReducer;
