const Types = {
    CREATE_IMPORT_REQUEST: 'CREATE_IMPORT_REQUEST',
    CREATE_IMPORT_SUCCESS: 'CREATE_IMPORT_SUCCESS',
    CREATE_IMPORT_FAILURE: 'CREATE_IMPORT_FAILURE',
    SEARCH_IMPORT_REQUEST: 'SEARCH_IMPORT_REQUEST',
    SEARCH_IMPORT_SUCCESS: 'SEARCH_IMPORT_SUCCESS',
    SEARCH_IMPORT_FAILURE: 'SEARCH_IMPORT_FAILURE',
    GET_IMPORT_DETAILS_REQUEST: 'GET_IMPORT_DETAILS_REQUEST',
    GET_IMPORT_DETAILS_SUCCESS: 'GET_IMPORT_DETAILS_SUCCESS',
    GET_IMPORT_DETAILS_FAILURE: 'GET_IMPORT_DETAILS_FAILURE',
    DELETE_IMPORT_REQUEST: 'DELETE_IMPORT_REQUEST',
    DELETE_IMPORT_SUCCESS: 'DELETE_IMPORT_SUCCESS',
    DELETE_IMPORT_FAILURE: 'DELETE_IMPORT_FAILURE',
    UPDATE_OUTCOME_PAID_STATUS_REQUEST: 'UPDATE_OUTCOME_PAID_STATUS_REQUEST',
    UPDATE_OUTCOME_PAID_STATUS_SUCCESS: 'UPDATE_OUTCOME_PAID_STATUS_SUCCESS',
    UPDATE_OUTCOME_PAID_STATUS_FAILURE: 'UPDATE_OUTCOME_PAID_STATUS_FAILURE',
    UPDATE_IMPORT_REQUEST: 'UPDATE_IMPORT_REQUEST',
    UPDATE_IMPORT_SUCCESS: 'UPDATE_IMPORT_SUCCESS',
    UPDATE_IMPORT_FAILURE: 'UPDATE_IMPORT_FAILURE',
}

export default Types;