import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import BlackPearlState from 'src/core/reduxs/coffee-core/black-pearl/black-pearl.state';
import Types from 'src/core/reduxs/coffee-core/black-pearl/black-pearl.type';

const initialState: BlackPearlState = {
    accumulateBlackPearl: {
        data: {},
        error: null,
        isLoading: false
    }
};

const blackPearlReducer = createReducer(initialState, {
    [Types.ACCUMULATE_BLACK_PEARL_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            accumulateBlackPearl: {
                ...state.accumulateBlackPearl,
                isLoading: true,
            }
        };
    },
    [Types.ACCUMULATE_BLACK_PEARL_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            accumulateBlackPearl: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.ACCUMULATE_BLACK_PEARL_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            accumulateBlackPearl: {
                ...state.accumulateBlackPearl,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default blackPearlReducer;
