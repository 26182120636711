import { MDBBox, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBDataTable, MDBIcon, MDBRow, MDBSpinner } from 'mdbreact';
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';

import Topping from 'src/core/models/coffee-core/topping/topping.model';
import _ from 'lodash';
import * as COMMON from 'src/core/utils/common';
import PickDeep from 'src/core/models/pick-deep.modal';
import ToppingStateType from 'src/core/models/coffee-core/topping/topping-state.model';
import * as ToppingAction from 'src/core/reduxs/coffee-core/topping/topping.action'
import ToppingUpdateModalComponent from 'src/features/components/topping/topping-update-modal.component';
import ToppingCreateModalComponent from 'src/features/components/topping/topping-create-modal.component';
import ToppingDeleteModalComponent from 'src/features/components/topping/topping-delete-modal.component';
import PagingRequest from 'src/core/models/paging-request.model';
type ToppingDataTable = {
    code: string;
    name: string;
    price: string;
    edit: JSX.Element;
    [rest: string]: any;
}




const initToppingDataTable: ToppingDataTable[] = [];
const initToppingDetail = new Topping();
const ToppingListComponent = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const columns = [
        {
            label: '#',
            field: 'code',
        },
        {
            label: intl.formatMessage({ id: 'TOPPING.LIST.LABEL_NAME' }),
            field: 'name',
        },
        {
            label: intl.formatMessage({ id: 'TOPPING.LIST.LABEL_PRICE' }),
            field: 'price'
        },
        {
            label: intl.formatMessage({ id: 'TOPPING.LIST.LABEL_EDIT' }),
            field: 'edit',

        }
    ]
    const [dataTable, setDataTable] = useState(initToppingDataTable);
    const toppings: PickDeep<ToppingStateType, 'toppings'> = useSelector((state: any) => state.toppingReducer.toppings)

    const [toppingDetail, setToppingDetail] = useState<Topping>(initToppingDetail);
    const [isOpenToppingUpdateModal, setIsOpenToppingUpdateModal] = useState(false);

    const [isOpenCreateToppingModal, setIsOpenCreateToppingModal] = useState(false)
    const [isOpenDeleteToppingModal, setIsOpenDeleteToppingModal] = useState(false)

    const prepareDataRow = useCallback((listTopping: Topping[]) => {
        if (_.isEmpty(listTopping)) return;

        const data: ToppingDataTable[] = [];
        listTopping.forEach((item: Topping) => {
            const row = {
                id: item.id,
                code: item.code || '',
                name: item.name || '',
                price: COMMON.currencyFormatter(item.price || 0),
                edit: (
                    <>
                        <MDBRow className="topping-list-item">
                            <button className="topping-list-item-btn edit" onClick={() => { openUpdateToppingModal(item) }}>
                                <MDBIcon icon="edit" className="topping-list-item-icon edit" />
                            </button>
                            <button className="topping-list-item-btn delete" onClick={() => { openDeleteToppingModal(item) }}>
                                <MDBIcon icon="trash-alt" className="topping-list-item-icon delete" />
                            </button>
                        </MDBRow>
                    </>
                ),
            }
            data.push(row)
        })
        setDataTable(data)
    }, [])

    const openUpdateToppingModal = (item: Topping) => {
        if (item) {
            setToppingDetail(item);
            setIsOpenToppingUpdateModal(true);
        }
    }

    const openDeleteToppingModal = (item: Topping) => {
        if (item) {
            setToppingDetail(item);
            setIsOpenDeleteToppingModal(true);
        }
    }

    const onModalUpdateClosed = () => {
        setIsOpenToppingUpdateModal(false);
        setToppingDetail(initToppingDetail);
    }
    const onModalDeleteClosed = () => {
        setIsOpenDeleteToppingModal(false);
        setToppingDetail(initToppingDetail);
    }

    const onModalCreateClosed = () => {
        setIsOpenCreateToppingModal(false);
    }


    const toppingDataTable = useMemo(() => {
        return (
            <MDBDataTable
                responsive
                striped
                borderless
                searching={true}
                sortable={false}
                noBottomColumns={true}
                paging={true}
                entries={5}
                pagesAmount={5}
                paginationLabel={[intl.formatMessage({ id: 'TOPPING.LIST.LABEL_PREVIOUS_PAGE' }), intl.formatMessage({ id: 'TOPPING.LIST.LABEL_NEXT_PAGE' })]}
                displayEntries={false}
                info={false}
                noRecordsFoundLabel={intl.formatMessage({ id: 'TOPPING.LIST.LABEL_NOT_FOUND_TOPPING' })}
                data={{ columns: columns, rows: dataTable }
                }
            />
        )
    }, [dataTable])

    useEffect(() => {
        const params: PagingRequest = {
            pageId: 0,
        }
        dispatch(ToppingAction.actGetListTopping(params))
    }, [dispatch])

    useEffect(() => {
        if (toppings.data) {
            prepareDataRow(toppings.data)
        }
    }, [toppings.data, prepareDataRow])
    return (
        <MDBCard className="topping-list-container" >
            <MDBCardTitle className="topping-list-title">
                <MDBBox className="topping-list-title-text" >
                    {intl.formatMessage({ id: 'TOPPING.LIST.TITLE' })}
                </MDBBox>

                <MDBBtn size="sm" floating color='dark-green' onClick={() => { setIsOpenCreateToppingModal(true) }} >
                    <MDBIcon icon="plus" className="topping-list-title-btn-add" />
                </MDBBtn>
            </MDBCardTitle>
            <MDBCardBody className="m-0 p-0">
                {toppings.isLoading && <MDBRow className="d-flex justify-content-center mb-5"><MDBSpinner small /></MDBRow>}
                {!toppings.isLoading && toppings.data && toppingDataTable}
            </MDBCardBody>
            <ToppingUpdateModalComponent isOpen={isOpenToppingUpdateModal} toppingDetail={toppingDetail} onModalClosed={onModalUpdateClosed} />
            <ToppingDeleteModalComponent isOpen={isOpenDeleteToppingModal} toppingDetail={toppingDetail} onModalClosed={onModalDeleteClosed} />
            <ToppingCreateModalComponent isOpen={isOpenCreateToppingModal} onModalClosed={onModalCreateClosed} />
        </ MDBCard>
    )
}

export default ToppingListComponent
