import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import IncomeState from 'src/core/reduxs/management/income/income.state';
import Types from 'src/core/reduxs/management/income/income.type';
import { IncomeItem } from 'src/core/models/management/income.model';

const initialState: IncomeState = {
    createIncomeItems: {
        data: [],
    },
    incomeCategories: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    createIncome: {
        data: {},
        error: null,
        isLoading: false
    },
    searchedIncomes: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    incomeDetails: {
        data: undefined,
        error: null,
        isLoading: false
    },
    deleteIncome: {
        data: undefined,
        error: undefined,
        isLoading: false
    },
};

const incomeReducer = createReducer(initialState, {
    [Types.ADD_INCOME_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const incomeItem: IncomeItem = action.payload;
        const data = [...state.createIncomeItems.data, incomeItem];

        return {
            ...state,
            createIncomeItems: {
                data
            }
        };
    },
    [Types.REMOVE_INCOME_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const index = action.payload;
        const data = [...state.createIncomeItems.data]
        data.splice(index, 1);

        return {
            ...state,
            createIncomeItems: {
                data
            }
        };
    },
    [Types.RESET_INCOME_ITEM]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createIncomeItems: {
                data: []
            }
        };
    },
    [Types.GET_LIST_INCOME_CATEGORY_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            incomeCategories: {
                ...state.incomeCategories,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_INCOME_CATEGORY_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            incomeCategories: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_INCOME_CATEGORY_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            incomeCategories: {
                ...state.incomeCategories,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.SEARCH_INCOME_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedIncomes: {
                ...state.searchedIncomes,
                isLoading: true,
            }
        };
    },
    [Types.SEARCH_INCOME_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedIncomes: {
                isLoading: false,
                error: null,
                data: action.payload.data
            }
        };
    },
    [Types.SEARCH_INCOME_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedIncomes: {
                ...state.searchedIncomes,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.GET_INCOME_DETAILS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            incomeDetails: {
                ...state.incomeDetails,
                isLoading: true,
            }
        };
    },
    [Types.GET_INCOME_DETAILS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            incomeDetails: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_INCOME_DETAILS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            incomeDetails: {
                ...state.incomeDetails,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.DELETE_INCOME_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteIncome: {
                ...state.deleteIncome,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_INCOME_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteIncome: {
                ...state.deleteIncome,
                isLoading: false,
                error: undefined,
                data: action.payload
            }
        };
    },
    [Types.DELETE_INCOME_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteIncome: {
                ...state.deleteIncome,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default incomeReducer;
