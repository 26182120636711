import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import StockState from 'src/core/reduxs/management/stock/stock.state';
import Types from 'src/core/reduxs/management/stock/stock.type';

const initialState: StockState = {
    stocks: {
        data: [] ,
        error: null,
        isLoading: false,
    }
};

const stockReducer = createReducer(initialState, {
    [Types.GET_LIST_STOCK_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            stocks: {
                ...state.stocks,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_STOCK_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            stocks: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_STOCK_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            stocks: {
                ...state.stocks,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default stockReducer;
