import Size from 'src/core/models/coffee-core/size/size.model';

export default class SizeGroup {
    id: number;
    code: string;
    name: string;
    sizes: Size[] ;

    constructor(
        id?: number,
        code?: string,
        name?: string,
        sizes?: Size[]
    ) {
        this.id = id || 0;
        this.code = code || '';
        this.name = name || '';
        this.sizes = sizes || [];
    }
}
