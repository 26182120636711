import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import ExportDetailState from 'src/core/reduxs/management/export-detail/export-detail.state';
import Types from 'src/core/reduxs/management/export-detail/export-detail.type';
import { ExportItem } from 'src/core/models/management/export.model';

const initialState: ExportDetailState = {
    createExportDetails: {
        items: [],
    }
};

const exportDetailReducer = createReducer(initialState, {
    [Types.ADD_EXPORT_DETAIL_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const exportDetail: ExportItem = action.payload;
        const items = [...state.createExportDetails.items, exportDetail];

        return {
            ...state,
            createExportDetails: {
                items
            }
        };
    },
    [Types.REMOVE_EXPORT_DETAIL_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const index = action.payload;
        const items = [...state.createExportDetails.items]
        items.splice(index, 1);

        return {
            ...state,
            createExportDetails: {
                items
            }
        };
    },
    [Types.RESET_EXPORT_DETAIL_ITEM]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createExportDetails: {
                items: []
            }
        };
    },
})

export default exportDetailReducer;
