export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILURE = 'GET_CATEGORIES_FAILURE';

export const GET_PRODUCT_CATEGORIES_REQUEST = 'GET_PRODUCT_CATEGORIES_REQUEST';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_FAILURE = 'GET_PRODUCT_CATEGORIES_FAILURE';

export const GET_NEWS_CATEGORIES_REQUEST = 'GET_NEWS_CATEGORIES_REQUEST';
export const GET_NEWS_CATEGORIES_SUCCESS = 'GET_NEWS_CATEGORIES_SUCCESS';
export const GET_NEWS_CATEGORIES_FAILURE = 'GET_NEWS_CATEGORIES_FAILURE';

export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_FAILURE = 'GET_CATEGORY_FAILURE';
export const RESET_CATEGORY = 'RESET_CATEGORY';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';
