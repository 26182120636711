import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
    MDBBtnGroup,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBRow,
    MDBSpinner
} from 'mdbreact';
import { useParams } from 'react-router-dom';
import 'src/features/pages/public-order/public-order.style.scss';
import { Order } from 'src/core/models/coffee-core/order.model';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { actGetOrderDetails, actResetOrderDetails } from 'src/core/reduxs/coffee-core/guest/guest-order.action';
import _ from 'lodash';
import Theme from 'src/core/utils/theme';
import * as CONST from 'src/core/utils/constants';
import { ORDER_STATUS } from 'src/core/utils/constants';
import * as COMMON from 'src/core/utils/common';
import { currencyFormatter } from 'src/core/utils/common';
import { OrderItemAddition } from 'src/core/models/coffee-core/order-item.model';
import { actUpdateOrderStatus } from 'src/core/reduxs/coffee-core/order/order.action';
import ConfirmModal from 'src/shared/components/confirm-modal/confirm-modal.component';

const columns = [
    {
        label: 'Tên',
        field: 'name',
    },
    {
        label: 'Số lượng',
        field: 'quantity',
    },
    {
        label: 'Thành tiền',
        field: 'totalPrice',
    },
]

type DataTable = {
    no: number;
    name: JSX.Element;
    totalPrice: string;
    quantity: number;
    [rest: string]: any;
}

const initItemsState: DataTable[] = [];

const PublicOrderContainer = (): JSX.Element => {
    const {formatMessage} = useIntl();
    const dispatch = useDispatch();
    const {orderId} = useParams<{ orderId: string }>();
    const orderDetails = useSelector((state: any) => state.guestOrderReducer.orderDetails);
    const order: Order = orderDetails.data;
    const [items, setDataItems] = useState(initItemsState)
    const [orderStatus, setOrderStatus] = useState('')
    const [isVisibleUpdateOrderStatusModal, setIsVisibleUpdateOrderStatusModal] = useState(false);

    useEffect(() => {
        if (orderId) {
            getOrderDetails();
        }
        return () => {
            setOrderStatus('')
            setDataItems([])
            dispatch(actResetOrderDetails())
        }
    }, []);

    useEffect(() => {
        if (order) {
            prepareDataRow()
            setOrderStatus(order.status)
        }
    }, [order])

    const getOrderDetails = () => {
        dispatch(actGetOrderDetails(Number(orderId)))
    }

    const toggleStatus = (status: string) => {
        setOrderStatus(status)
        setIsVisibleUpdateOrderStatusModal(true);
    }

    const updateOrderStatus = () => {
        if (!order || orderStatus === order.status) return;

        const params = {
            id: order.id,
            status: orderStatus
        }
        dispatch(actUpdateOrderStatus(params, onChangeStatusSuccess));
    }

    const onChangeStatusSuccess = () => {
        getOrderDetails();
        setIsVisibleUpdateOrderStatusModal(false);
    }

    const renderOrderStatusDropDown = useMemo(() => {
        if (!order) {
            return <></>;
        }
        const orderStatusOptions = _.values(CONST.ORDER_STATUS)
        return (
            <MDBBtnGroup className="btn-status">
                <MDBDropdown>
                    <MDBDropdownToggle caret color={_.get(Theme.color.dropdownOrderStatus, order.status)}>
                        {_.get(CONST.ORDER_STATUS, `${order.status}.value`, '')}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu basic color='info'>
                        {orderStatusOptions.map((value, index) => {
                            return (
                                <MDBDropdownItem onClick={() => toggleStatus(value.key)}
                                    key={index}>{value.value}</MDBDropdownItem>
                            )
                        })}
                    </MDBDropdownMenu>
                </MDBDropdown>
            </MDBBtnGroup>
        )
    }, [order])

    const buildItemAdditions = (additions: OrderItemAddition[]): JSX.Element[] => {
        if (_.isEmpty(additions)) return [<></>];

        const addition = additions[0];
        const result: JSX.Element[] = [];
        if (!_.isEmpty(addition?.size)) {
            result.push(
                <span
                    key={addition?.size.id + addition?.size.name}>{formatMessage({id: 'ORDER.LABEL.SIZE'}) + ': ' + addition?.size.name}</span>
            )
        }

        result.push(<br key={addition?.size.name + addition?.size.id}/>);

        if (!_.isEmpty(addition.toppings)) {
            result.push(
                <span key={addition?.toppings[0]?.name}>{formatMessage({id: 'ORDER.LABEL.TOPPING'}) + ': ' +
                    _.map(addition.toppings, item => ' ' + item.name)
                }</span>
            );
        }

        return result;
    }

    const prepareDataRow = () => {
        const data: DataTable[] = [];

        _.forEach(order?.items, (item, index) => {
            const row = {
                no: index + 1,
                name: <div style={{width: 190}}>
                    <div className='font-weight-normal'>{item?.product?.name || ''}</div>
                    {item.additions && buildItemAdditions(item.additions)}
                    {item.note && <div style={{whiteSpace: 'pre-wrap'}}>Ghi chú: {item.note}</div>}
                </div>,
                totalPrice: COMMON.currencyFormatter(item.totalPrice || 0),
                quantity: item.quantity || 0,
            };
            data.push(row);
        })
        !_.isEmpty(data) && setDataItems(data)
    }

    return (
        <div id='public-order'>
            <MDBContainer>
                <MDBRow>
                    <MDBCol sm='12' md='10' lg='8' xl='6' className='mt-5 mx-auto'>
                        {!order &&
                            <div className='m-5 text-center'>
                                {orderDetails.isLoading ? <MDBSpinner/>
                                    : <div className='m-5 text-center'>
                                        Không tìm thấy đơn hàng
                                    </div>
                                }
                            </div>
                        }
                        {order &&
                            <Fragment>
                                <div className="text-center">
                                    <p className="h4 company-name">Đơn hàng: {order.code || ''}</p>
                                </div>
                                <div className="mt-4">
                                    <span
                                        className="font-weight-bold">{formatMessage({id: 'ORDER.LABEL.CUSTOMER'})}</span>
                                    <MDBRow className="d-flex align-items-center mt-2">
                                        <MDBCol><span
                                            className="font-weight-normal">{formatMessage({id: 'ORDER.CUSTOMER.NAME'})}</span>
                                        </MDBCol>
                                        <MDBCol><span
                                            className="font-weight-lighter">{order.receiver}</span>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="d-flex align-items-center mt-2">
                                        <MDBCol><span
                                            className="font-weight-normal">{formatMessage({id: 'ORDER.CUSTOMER.PHONE'})}</span>
                                        </MDBCol>
                                        <MDBCol><span
                                            className="font-weight-lighter">{order.phone}</span>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="d-flex align-items-center mt-2">
                                        <MDBCol> <span
                                            className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.ADDRESS'})}</span></MDBCol>
                                        <MDBCol><span className="font-weight-lighter"
                                            style={{whiteSpace: 'pre-wrap'}}>{order.address}</span>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                                <div className='mt-3'>
                                    <span
                                        className="font-weight-bold">{formatMessage({id: 'ORDER.LABEL.ORDER'})}</span>
                                    <MDBRow className="d-flex align-items-center mt-2">
                                        <MDBCol className="d-flex align-items-center"><span
                                            className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.STATUS'})}</span></MDBCol>
                                        <MDBCol>
                                            {order.status !== ORDER_STATUS.FINISHED.key && order.status !== ORDER_STATUS.CANCELED.key &&
                                                renderOrderStatusDropDown}
                                            {order.status === ORDER_STATUS.FINISHED.key &&
                                                <div className='text-success font-weight-normal'>{ORDER_STATUS.FINISHED.value}</div>
                                            }
                                            {order.status === ORDER_STATUS.CANCELED.key &&
                                                <div className='text-danger font-weight-normal'>{ORDER_STATUS.CANCELED.value}</div>
                                            }
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="d-flex align-items-center mt-2">
                                        <MDBCol className="d-flex align-items-center"> <span
                                            className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.METHOD'})}</span></MDBCol>
                                        <MDBCol>
                                            <div className='font-weight-normal text-primary'>{_.get(CONST.ORDER_METHOD, `${order.method}.value`)}</div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="d-flex align-items-center mt-2">
                                        <MDBCol className="d-flex align-items-center"> <span
                                            className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.DISCOUNT'})}</span></MDBCol>
                                        <MDBCol>
                                            <span className="font-weight-lighter"
                                                style={{whiteSpace: 'pre-wrap'}}>{currencyFormatter(order.promotion || 0)}</span>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="d-flex align-items-center mt-2">
                                        <MDBCol> <span
                                            className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.NOTE'})}</span></MDBCol>
                                        <MDBCol>
                                            <span className="font-weight-lighter"
                                                style={{whiteSpace: 'pre-wrap'}}>{order.note}</span>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                                <div className='mt-3'>
                                    <span
                                        className="font-weight-bold">{formatMessage({id: 'ORDER.LABEL.ITEM'})}</span>
                                    <MDBDataTable
                                        responsive
                                        hover
                                        entries={CONST.DEFAULT_PAGE_SIZE}
                                        noBottomColumns={true}
                                        paging={false}
                                        proSelect
                                        displayEntries={false}
                                        data={{columns: columns, rows: items}}
                                        noRecordsFoundLabel="Không tìm thấy sản phẩm nào"
                                    />
                                </div>
                                <div className='justify-content-between'>
                                    <MDBRow className="d-flex align-items-center mt-3">
                                        <MDBCol size='8'>
                                            <span className="font-weight-normal">{formatMessage({id: 'ORDER.LABEL.TOTAL_PRICE'})}:</span>
                                        </MDBCol>
                                        <MDBCol>
                                            <span className="font-weight-normal">{COMMON.currencyFormatter(order.totalPrice || 0)}</span>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className="d-flex align-items-center">
                                        <MDBCol size='8'>
                                            <span className="font-weight-normal">{formatMessage({ id: 'ORDER.LABEL.TOTAL_PAYMENT' })}:</span>
                                        </MDBCol>
                                        <MDBCol>
                                            <span className="font-weight-bold">{COMMON.currencyFormatter(_.subtract(order.totalPrice || 0, order.promotion || 0))}</span>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                                <ConfirmModal
                                    isVisible={isVisibleUpdateOrderStatusModal}
                                    setIsVisible={setIsVisibleUpdateOrderStatusModal}
                                    title='Cập nhật trạng thái đơn hàng'
                                    message={`Bạn có chắc muốn chuyển trạng thái đơn hàng này sang ${ORDER_STATUS[orderStatus]?.value?.toUpperCase()}?`}
                                    color='info'
                                    onClickConfirm={updateOrderStatus}
                                />
                            </Fragment>
                        }
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default PublicOrderContainer;
