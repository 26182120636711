import Types from 'src/core/reduxs/management/export-detail/export-detail.type';
import { ExportItem } from 'src/core/models/management/export.model';

export const actAddExportDetailItem = (exportDetail: ExportItem) => (dispatch: any): void => {
    dispatch({
        type: Types.ADD_EXPORT_DETAIL_ITEM,
        payload: exportDetail
    });
};

export const actRemoveExportDetailItem = (index: number) => (dispatch: any): void => {
    dispatch({
        type: Types.REMOVE_EXPORT_DETAIL_ITEM,
        payload: index
    });
};

export const actResetExportDetailItem = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_EXPORT_DETAIL_ITEM,
    });
};
