import Repositories, {RepositoryName} from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/management/partner/partner.type';

const partnerRepository = Repositories[RepositoryName.PARTNER];

export const actGetListPartnerOfStore = (storeId: number) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_PARTNER_REQUEST, Types.GET_LIST_PARTNER_SUCCESS, Types.GET_LIST_PARTNER_FAILURE],
        callAPI: () => partnerRepository.getListPartnerOfStore(storeId)
    })
};
