import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import * as Types from 'src/core/reduxs/coffee-core/product/product.type';
import ProductStateType from 'src/core/models/coffee-core/product/product-state.model';

const initialState: ProductStateType = {
    products: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    productDetail: {
        data: undefined,
        error: null,
        isLoading: false,
    },
    updateProduct: {
        data: {},
        error: null,
        isLoading: false
    },
    createProduct: {
        data: {},
        error: null,
        isLoading: false
    },
    deleteProduct: {
        data: {},
        error: null,
        isLoading: false
    },
};

const productReducer = createReducer(initialState, {
    [Types.GET_PRODUCTS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            products: {
                ...state.products,
                isLoading: true,
            }
        };
    },
    [Types.GET_PRODUCTS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            products: {
                ...state.products,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_PRODUCTS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            products: {
                ...state.products,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.GET_PRODUCT_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            productDetail: {
                ...state.productDetail,
                isLoading: true,
            }
        };
    },
    [Types.GET_PRODUCT_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            productDetail: {
                ...state.productDetail,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_PRODUCT_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            productDetail: {
                ...state.productDetail,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.CREATE_PRODUCT_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createProduct: {
                ...state.createProduct,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_PRODUCT_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createProduct: {
                ...state.createProduct,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_PRODUCT_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createProduct: {
                ...state.createProduct,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_PRODUCT_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateProduct: {
                ...state.updateProduct,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_PRODUCT_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateProduct: {
                ...state.updateProduct,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_PRODUCT_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateProduct: {
                ...state.updateProduct,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.DELETE_PRODUCT_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteProduct: {
                ...state.deleteProduct,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_PRODUCT_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteProduct: {
                ...state.deleteProduct,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.DELETE_PRODUCT_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteProduct: {
                ...state.deleteProduct,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default productReducer;
