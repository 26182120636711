import { MDBCol, MDBRow } from 'mdbreact'
import React, { Component } from 'react'
import ProductCreateFormComponent from 'src/features/components/product/product-create-form.component'
import ProductListComponent from 'src/features/components/product/product-list.component'
import 'src/features/pages/product/product.style.scss'
import PageHeader from 'src/shared/components/page-header/page-header.component'

export default class Products extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="product">
                    <PageHeader title='PRODUCT.PAGE.TITLE' />
                    <MDBRow className="m-0" >
                        <MDBCol lg='7' className="p-0 m-0 space-right2">
                            <ProductListComponent />
                        </MDBCol>
                        <MDBCol lg='5' className="p-0 m-0">
                            <ProductCreateFormComponent />
                        </MDBCol>
                    </MDBRow>
                </div>
            </React.Fragment>
        )
    }
}
