import React, { Component, RefObject } from 'react';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { bindActionCreators } from 'redux';
import * as CONST from 'src/core/utils/constants';
import 'src/features/pages/order/order.style.scss';
import * as OrderAction from 'src/core/reduxs/coffee-core/order/order.action';
import * as FirebaseAction from 'src/core/reduxs/coffee-core/firebase/firebase.action';
import PagingRequest from 'src/core/models/paging-request.model'
import OrderListComponent from 'src/features/components/order-list/order-list.component'
import OrderDetailComponent from 'src/features/components/order-list/order-detail.component'
import { firebaseApp } from 'src/core/utils/firebase';
import NotificationCustom from 'src/core/models/notification-custom.model';
import _ from 'lodash';
import { isNotificationSupported } from 'src/core/utils/common';
import OrderState from 'src/core/reduxs/coffee-core/order/order.state';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { toast } = require('mdbreact');

type Props = {
    intl: IntlShape,
    orderReducer: OrderState,
    getListOrder: (params: PagingRequest) => (dispatch: any) => void,
    resetOrderDetail: () => (dispatch: any) => void,
    receiveNotification: (orderId: string, notification: NotificationCustom) => (dispatch: any) => void,
    createMobileSession: (deviceToken: string) => (dispatch: any) => void
}

type States = {
    isOpen: boolean,
    orderId?: any
}

type RefType = {
    onPageChange: (activePage: number) => void
}

class Orders extends Component<Props, States> {
    messaging: any = null;
    orderRef: RefObject<RefType> = React.createRef();

    constructor(props: Props) {
        super(props)
        this.state = {
            isOpen: false,
            orderId: null
        }
    }

    onModalClosed = () => {
        this.setState({
            isOpen: false,
            orderId: null
        });
        this.props.resetOrderDetail();
    }

    fetchOrderListByReducer = () => {
        this.orderRef.current?.onPageChange(_.get(this.props.orderReducer, 'orders.data.pageId', 0) + 1);
    }

    getOrderDetail = (row: any) => {
        this.setState({
            isOpen: true,
            orderId: row.id
        })
    }

    componentDidMount() {
        if (isNotificationSupported) {
            Notification.requestPermission().then((permission: NotificationPermission) => {
                if (permission === 'granted') {
                    firebaseApp.messaging().getToken().then(token => {
                        localStorage.setItem(CONST.LocalStorage.FCM_TOKEN, token);
                        this.props.createMobileSession(token);

                        this.messaging = firebaseApp.messaging().onMessage(
                            value => {
                                const orderId = _.get(value, 'data.orderId');
                                if (orderId) {
                                    const title = _.get(value, 'notification.title', 'Bạn có đơn hàng mới!');
                                    const body = _.get(value, 'notification.body', 'Vui lòng kiểm tra đơn hàng');
                                    toast.info('Bạn có đơn hàng mới!', {
                                        closeButton: true
                                    });
                                    this.props.receiveNotification(orderId, new NotificationCustom(title, body));
                                }
                            },
                            () => { console.log('Failure') },
                            () => { console.log('Ok') }
                        );
                    });
                }
            });
        }
    }

    componentWillUnmount() {
        !_.isNil(this.messaging) && typeof this.messaging === 'function' && this.messaging();
    }

    render() {
        return (
            <div id="order">
                <OrderListComponent
                    ref={this.orderRef}
                    isLoading={this.props.orderReducer.orders.isLoading}
                    orders={this.props.orderReducer.orders.data}
                    getOrderDetail={this.getOrderDetail}
                />

                <OrderDetailComponent
                    orderId={this.state.orderId}
                    isOpen={this.state.isOpen}
                    onModalClosed={this.onModalClosed}
                    fetchOrderListByReducer={this.fetchOrderListByReducer}
                />
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    orderReducer: state.orderReducer
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    getListOrder: OrderAction.actGetListOrder,
    resetOrderDetail: OrderAction.actResetOrderDetail,
    receiveNotification: FirebaseAction.actReceiveNotification,
    createMobileSession: FirebaseAction.actCreateMobileSession
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Orders));
