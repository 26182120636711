const Types = {
    CREATE_EXPORT_REQUEST: 'CREATE_EXPORT_REQUEST',
    CREATE_EXPORT_SUCCESS: 'CREATE_EXPORT_SUCCESS',
    CREATE_EXPORT_FAILURE: 'CREATE_EXPORT_FAILURE',
    SEARCH_EXPORT_REQUEST: 'SEARCH_EXPORT_REQUEST',
    SEARCH_EXPORT_SUCCESS: 'SEARCH_EXPORT_SUCCESS',
    SEARCH_EXPORT_FAILURE: 'SEARCH_EXPORT_FAILURE',
    GET_EXPORT_DETAILS_REQUEST: 'GET_EXPORT_DETAILS_REQUEST',
    GET_EXPORT_DETAILS_SUCCESS: 'GET_EXPORT_DETAILS_SUCCESS',
    GET_EXPORT_DETAILS_FAILURE: 'GET_EXPORT_DETAILS_FAILURE',
    DELETE_EXPORT_REQUEST: 'DELETE_EXPORT_REQUEST',
    DELETE_EXPORT_SUCCESS: 'DELETE_EXPORT_SUCCESS',
    DELETE_EXPORT_FAILURE: 'DELETE_EXPORT_FAILURE',
    UPDATE_EXPORT_REQUEST: 'UPDATE_EXPORT_REQUEST',
    UPDATE_EXPORT_SUCCESS: 'UPDATE_EXPORT_SUCCESS',
    UPDATE_EXPORT_FAILURE: 'UPDATE_EXPORT_FAILURE',
}

export default Types;