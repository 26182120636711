import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { Stock } from 'src/core/models/management/stock.model';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';
import { CreateMaterialRequest, Material, UpdateMaterialRequest } from 'src/core/models/management/material.model';
import ResponseCustom from 'src/core/models/response-custom.model';
import { MCategory } from 'src/core/models/management/mcategory.model';

export default class MaterialRepository extends BaseRepository {

    getListMaterial(): Promise<AxiosResponse<Stock[]>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_LIST_MATERIAL).getAll();
    }

    searchMaterial(request: SearchAndFilterRequest): Promise<AxiosResponse<Material>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.SEARCH_MATERIAL).search<Material>(request)
    }

    getMaterial(materialId: number): Promise<AxiosResponse<Material>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_MATERIAL_BY_ID).query<Material>({materialId})
    }

    getListCategory(): Promise<AxiosResponse<MCategory[]>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_LIST_MATERIAL_CATEGORY).getAll();
    }

    createMaterial(request: CreateMaterialRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.CREATE_MATERIAL).create<ResponseCustom>(request)
    }

    updateMaterial(request: UpdateMaterialRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.UPDATE_MATERIAL).create<ResponseCustom>(request)
    }
}
