import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import UserState from 'src/core/reduxs/coffee-core/user/user.state';
import Types from 'src/core/reduxs/coffee-core/user/user.type';

const initialState: UserState = {
    user: {
        data: undefined,
        error: null,
        isLoading: false,
    },
};

const userReducer = createReducer(initialState, {
    [Types.GET_USER_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            user: {
                ...state.user,
                isLoading: true,
            }
        };
    },
    [Types.GET_USER_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            user: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_USER_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            user: {
                ...state.user,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.RESET_USER]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            user: {
                ...initialState.user
            }
        };
    },
})

export default userReducer;
