import { MDBBox, MDBBtn, MDBCol, MDBRow } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import CategoryStateType from 'src/core/models/coffee-core/category/category-state.model'
import Category from 'src/core/models/coffee-core/category/category.model'
import PagingRequest from 'src/core/models/paging-request.model'
import PickDeep from 'src/core/models/pick-deep.modal'
import * as CategoryAction from 'src/core/reduxs/coffee-core/category/category.action'
import CategoryCreateModalComponent from 'src/features/components/category/category-create-modal.component'
import CategoryListComponent from 'src/features/components/category/category-list.component'
import CategoryDeleteModalComponent from './category-delete-modal.component'
import CategoryUpdateModalComponent from './category-update-modal.component'

const initCategory = new Category();
const CategoryWrapperComponent = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const productCategories: PickDeep<CategoryStateType, 'productCategories'> = useSelector((state: any) => state.categoryReducer.productCategories)
    const newsCategories: PickDeep<CategoryStateType, 'newsCategories'> = useSelector((state: any) => state.categoryReducer.newsCategories)
    const [isOpenCreateCategoryModal, setIsOpenCreateCategoryModal] = useState(false)
    const [isOpenUpdateCategoryModal, setIsOpenUpdateCategoryModal] = useState(false)
    const [isOpenDeleteCategoryModal, setIsOpenDeleteCategoryModal] = useState(false)
    const [chosenCategoryItem, setChosenCategoryItem] = useState<Category>(initCategory)

    const openCreateModal = () => {
        setIsOpenCreateCategoryModal(true)
    }
    const onModalCreateClosed = () => {
        setIsOpenCreateCategoryModal(false)
    }
    const onModalUpdateClosed = () => {
        setIsOpenUpdateCategoryModal(false)
    }
    const onModalDeleteClosed = () => {
        setIsOpenDeleteCategoryModal(false)
    }

    const onUpdateClick = (category: Category) => {
        console.log(category)
        setChosenCategoryItem(category)
        setIsOpenUpdateCategoryModal(true)
    }

    const onDeleteClick = (category: Category) => {
        setChosenCategoryItem(category)
        setIsOpenDeleteCategoryModal(true)
    }

    useEffect(() => {
        const params: PagingRequest = {
            pageId: 0,
        }
        dispatch(CategoryAction.actGetListProductCategory(params))
        dispatch(CategoryAction.actGetListNewsCategory(params))
    }, [])
    return (
        <div className='category'>
            <MDBBox
                className='d-flex justify-content-end mb-4'
            >
                <MDBBtn
                    outline
                    color='dark-green'
                    onClick={openCreateModal}
                >
                    {intl.formatMessage({ id: 'CATEGORY.CREATE.TITLE' })}
                </MDBBtn>
            </MDBBox>
            <MDBRow className='m-0 p-0'>
                <MDBCol lg='6' className='p-0 m-0 pr-lg-3' >
                    <CategoryListComponent
                        categoryList={productCategories.data}
                        listTitle={intl.formatMessage({ id: 'CATEGORY.LIST.TITLE_PRODUCT' })}
                        isLoading={productCategories.isLoading}
                        onClickUpdateCategory={onUpdateClick}
                        onClickDeleteCategory={onDeleteClick}
                    />
                </MDBCol>
                <MDBCol lg='6' className='p-0 m-0 pl-lg-3' >
                    <CategoryListComponent
                        categoryList={newsCategories.data}
                        listTitle={intl.formatMessage({ id: 'CATEGORY.LIST.TITLE_NEWS' })}
                        isLoading={newsCategories.isLoading}
                        onClickUpdateCategory={onUpdateClick}
                        onClickDeleteCategory={onDeleteClick}
                    />
                </MDBCol>
            </MDBRow>
            <CategoryCreateModalComponent isOpen={isOpenCreateCategoryModal} onModalClosed={onModalCreateClosed} />
            <CategoryUpdateModalComponent isOpen={isOpenUpdateCategoryModal} onModalClosed={onModalUpdateClosed} categoryDetail={chosenCategoryItem} />
            <CategoryDeleteModalComponent isOpen={isOpenDeleteCategoryModal} onModalClosed={onModalDeleteClosed} categoryDetail={chosenCategoryItem} />
        </div>
    )
}

export default CategoryWrapperComponent
