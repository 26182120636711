import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { Stock } from 'src/core/models/management/stock.model';

export default class PartnerRepository extends BaseRepository {

    getListPartnerOfStore(storeId: number): Promise<AxiosResponse<Stock[]>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_LIST_PARTNER_OF_STORE).query({storeId});
    }
}
