import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/management/transaction/transaction.type';

const transactionRepository = Repositories[RepositoryName.TRANSACTION];

export const actGetTransactionDetails = (id: number) => (dispatch: any) : void => {
    dispatch({
        types: [Types.GET_TRANSACTION_DETAILS_REQUEST, Types.GET_TRANSACTION_DETAILS_SUCCESS, Types.GET_TRANSACTION_DETAILS_FAILURE],
        callAPI: () => transactionRepository.getTransactionDetails(id),
    })
}
