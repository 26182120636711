import Repositories, {RepositoryName} from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/management/store/store.type';

const storeRepository = Repositories[RepositoryName.STORE];

export const actGetListStore = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_STORE_REQUEST, Types.GET_LIST_STORE_SUCCESS, Types.GET_LIST_STORE_FAILURE],
        callAPI: () => storeRepository.getListStore()
    })
};
