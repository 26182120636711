import { MDBBtn, MDBCard, MDBCardBody, MDBDataTable, MDBIcon } from 'mdbreact';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormatter } from 'src/core/utils/common';
import ConfirmModal from 'src/shared/components/confirm-modal/confirm-modal.component';
import { OutcomeItem } from 'src/core/models/management/outcome.model';
import { actRemoveOutcomeItem } from 'src/core/reduxs/management/outcome/outcome.action';

type DataTable = {
    no: number;
    title: string;
    categoryName: string;
    amount: string;
    [rest: string]: any;
}

const columns = [
    {
        label: 'STT',
        field: 'no',
    },
    {
        label: 'Nội dung chi',
        field: 'title',
    },
    {
        label: 'Loại chi',
        field: 'categoryName',
    },
    {
        label: 'Số tiền',
        field: 'amount',
    },
    {
        label: '',
        field: 'command',
    }
]

const OutcomeItemCreationListComponent = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [totalAmount, setTotalAmount] = useState<number | 0>(0);
    const outcomeItems: OutcomeItem[] = useSelector((state: any) => state.outcomeReducer.createOutcomeItems.data);
    const [isVisibleRemoveModal, setIsVisibleRemoveModal] = useState(false);
    const [selectedOutcomeItemIndex, setSelectedOutcomeItemIndex] = useState<number | undefined>();

    const prepareDataRow = useCallback((outcomeItemList: OutcomeItem[]) => {
        const data: DataTable[] = [];
        let totalAmount = 0;
        outcomeItemList.forEach((item, index) => {
            const row = {
                no: index + 1,
                title: item.title || '',
                categoryName: item.category.name,
                amount: currencyFormatter(item.amount),
                command: <MDBBtn flat rounded color="danger" className="px-3 py-2 my-0" onClick={() => onClickRemove(index)}>
                    <MDBIcon size='lg' icon="trash-alt" className='red-text'/>
                </MDBBtn>
            }
            data.push(row)
            totalAmount += item.amount
        })
        setDataTable(data)
        setTotalAmount(totalAmount)
    }, [])

    const outcomeItemDataTable = useMemo(() => {
        return (
            <Fragment>
                <MDBDataTable
                    className='common-table'
                    searching={false}
                    responsive
                    striped
                    borderless
                    sortable={false}
                    noBottomColumns={true}
                    paging={false}
                    displayEntries={false}
                    info={false}
                    noRecordsFoundLabel='Chưa có mục chi nào'
                    data={{columns: columns, rows: dataTable}
                    }
                />
                <div>Tổng: <span className='font-weight-bold text-primary'>{currencyFormatter(totalAmount)}</span></div>
            </Fragment>
        )
    }, [dataTable])

    useEffect(() => {
        if (outcomeItems) {
            prepareDataRow(outcomeItems)
        }
    }, [outcomeItems, prepareDataRow])

    const onClickRemove = (index: number) => {
        setSelectedOutcomeItemIndex(index);
        setIsVisibleRemoveModal(true);
    }

    const removeImportDetail = () => {
        if (selectedOutcomeItemIndex !== undefined) {
            dispatch(actRemoveOutcomeItem(selectedOutcomeItemIndex));
            setIsVisibleRemoveModal(false);
        }
    }

    return (
        <Fragment>
            <MDBCard className='container-fluid'>
                <MDBCardBody>
                    <p className="font-weight-normal mt-3">DANH SÁCH MỤC CHI</p>
                    {outcomeItemDataTable}
                </MDBCardBody>
            </MDBCard>
            <ConfirmModal
                isVisible={isVisibleRemoveModal}
                setIsVisible={setIsVisibleRemoveModal}
                title='Xoá mục chi'
                message='Bạn có chắc muốn xoá mục chi này?'
                color='danger'
                onClickConfirm={removeImportDetail}
            />
        </Fragment>
    )
}

export default React.memo(OutcomeItemCreationListComponent)
