import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/coffee-core/tenant/tenant.type';

const tenantRepository = Repositories[RepositoryName.TENANT];

export const actGetListTenant = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_TENANT_REQUEST, Types.GET_LIST_TENANT_SUCCESS, Types.GET_LIST_TENANT_FAILURE],
        callAPI: () => tenantRepository.getListTenant()
    })
};

