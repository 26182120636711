import React, { Ref, useCallback, useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBDatePicker, MDBInput, MDBInputGroup, MDBRow, MDBSpinner, } from 'mdbreact';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as CONST from 'src/core/utils/constants';
import { DATE_FORMAT_FOR_CODE, OutcomeTypes } from 'src/core/utils/constants';
import { toast } from 'react-hot-toast';
import { formatDateForApi } from 'src/core/utils/common';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { actGetListSupplier } from 'src/core/reduxs/management/supplier/supplier.action';
import { actGetListStore } from 'src/core/reduxs/management/store/store.action';
import { Store } from 'src/core/models/management/store.model';
import { actGetListDepositOfStore } from 'src/core/reduxs/management/deposit/deposit.action';
import { Deposit } from 'src/core/models/management/deposit.model';
import OutcomeItemCreateFormComponent from 'src/features/components/outcome/outcome-item-create-form.component';
import OutcomeItemCreationListComponent from 'src/features/components/outcome/outcome-item-creation-list.component';
import { CreateOutcomeRequest, OutcomeItem, OutcomeItemRequest } from 'src/core/models/management/outcome.model';
import { actCreateOutcome, actResetOutcomeItem } from 'src/core/reduxs/management/outcome/outcome.action';
import Select from 'react-select';

const IncomeCreateContainer = (): JSX.Element => {

    const history = useHistory();
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const dispatch = useDispatch();
    const stores: Store[] = useSelector((state: any) => state.storeReducer.stores.data);
    const deposits: Deposit[] = useSelector((state: any) => state.depositReducer.storeDeposits.data);
    const outcomeItems: OutcomeItem[] = useSelector((state: any) => state.outcomeReducer.createOutcomeItems.data);
    const [selectedStore, setSelectedStore] = useState<Store | undefined>();
    const [selectedType, setSelectedType] = useState<string | undefined>();
    const [selectedDeposit, setSelectedDeposit] = useState<number | undefined>();
    const [selectedOutcomeTime, setSelectedOutcomeTime] = useState<Date | undefined>();
    const [outcomeCodePrefix, setOutcomeCodePrefix] = useState<string | undefined>('');
    const creating: boolean = useSelector((state: any) => state.outcomeReducer.createOutcome.loading);
    let depositSelectRef: any = null;
    let toDepositSelectRef: any = null;

    useEffect(() => {
        dispatch(actGetListStore());
        dispatch(actGetListSupplier())
    }, []);

    useEffect(() => {
        if (selectedStore) {
            dispatch(actGetListDepositOfStore(selectedStore.id));
            resetDepositSelect()
            if ('CHUYEN_DOI' === selectedType) {
                resetToDepositSelect()
            }
        }
    }, [selectedStore]);

    useEffect(() => {
        if (selectedStore && selectedOutcomeTime) {
            const dateString = moment(selectedOutcomeTime).format(DATE_FORMAT_FOR_CODE);
            setOutcomeCodePrefix(`${selectedStore.code}_${dateString}_CHI_`)
        }
    }, [selectedStore, selectedOutcomeTime]);

    useEffect(() => {
        if ('CHUYEN_DOI' === selectedType && selectedDeposit) {
            resetToDepositSelect()
        }
    }, [selectedType, selectedDeposit]);

    const resetDepositSelect = () => {
        setValue('depositId', undefined);
        setSelectedDeposit(undefined)
        depositSelectRef?.clearValue()
    }

    const resetToDepositSelect = () => {
        setValue('toDepositId', undefined);
        toDepositSelectRef?.clearValue()
    }

    const storeOptions = useMemo(() => {
        if (!_.isEmpty(stores)) {
            return stores.map((s: Store) => ({
                label: s.name,
                value: s.id
            }));
        }
        return [];
    }, [stores]);

    const typeOptions = useMemo(() => {
        return Object.keys(OutcomeTypes).map(key => ({
            label: OutcomeTypes[key],
            value: key
        }))
    }, []);

    const depositOptions = useMemo(() => {
        if (!_.isEmpty(deposits)) {
            return deposits.map((d: Deposit) => ({
                label: d.name,
                value: d.id
            }));
        }
        return [];
    }, [deposits]);

    const toDepositOptions = useMemo(() => {
        if (_.isEmpty(deposits)) {
            return [];
        }
        if ('CHUYEN_DOI' === selectedType && selectedDeposit) {
            return deposits.filter(d => d.id !== selectedDeposit).map((d: Deposit) => ({
                label: d.name,
                value: d.id
            }));
        }
        return [];
    }, [deposits, selectedType, selectedDeposit]);

    const onChangeStore = (storeId: number) => {
        const store = stores.find(s => s.id === storeId)
        setSelectedStore(store);
    }

    const onCreateSuccess = useCallback(() => {
        dispatch(actResetOutcomeItem())
        toast.success('Tạo phiếu chi thành công', {duration: 2000})
        history.push(CONST.AppURI.OUTCOME);
    }, [])

    const onCreateFailure = useCallback(() => {
        toast.error('Tạo phiếu chi thất bại', {duration: 2000})
    }, [])

    const onSubmit = (data: any) => {
        if (_.isEmpty(outcomeItems)) {
            toast.error('Bạn chưa nhập mục chi', {duration: 2000})
            return
        }
        const outcomeItemRequests: OutcomeItemRequest[] = outcomeItems.map(o => ({
            categoryId: o.category.id,
            title: o.title,
            amount: o.amount
        }))
        const request: CreateOutcomeRequest = {
            storeId: data.storeId,
            type: data.type,
            depositId: data.depositId,
            toDepositId: data.toDepositId,
            title: data.title,
            items: outcomeItemRequests,
            outcomeTime: data.outcomeTime,
            code: outcomeCodePrefix + data.code
        }
        dispatch(actCreateOutcome(request, onCreateSuccess, onCreateFailure))
    }

    return (
        <MDBContainer className='py-3' fluid>
            <div className='text-center'>
                <h2 className='page-title'>Tạo phiếu chi</h2>
            </div>
            <MDBRow className='pb-3'>
                <MDBCol size='12'>
                    <p className="font-weight-normal mt-4">THÔNG TIN PHIẾU CHI</p>
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Nội dung</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBInput
                        {...register('title', {
                            required: 'Nội dung là bắt buộc',
                            maxLength: {
                                value: 255,
                                message: 'Nội dung không được nhiều hơn 255 ký tự',
                            }
                        })}
                        placeholder='Nội dung'
                        onChange={(e: any) => {
                            setValue('title', e.target.value)
                        }}
                        containerClass='my-0 max-width-400'
                    />
                    {errors.title && <ErrorMessage>{errors.title.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Cửa hàng</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('storeId', {
                            required: 'Cửa hàng là bắt buộc'
                        })}
                        options={storeOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                const storeId = newValue.value
                                setValue('storeId', storeId);
                                onChangeStore(storeId);
                            }
                        }}
                        placeholder='(chọn cửa hàng)'
                        className='max-width-400 my-1'
                    />
                    {errors.storeId && <ErrorMessage>{errors.storeId.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Loại chi</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('type')}
                        options={typeOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('type', newValue.value);
                                setSelectedType(newValue.value);
                            }
                        }}
                        placeholder='(chọn loại chi)'
                        className='max-width-400 my-1'
                    />
                    {errors.type && <ErrorMessage>{errors.type.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Ví chi</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('depositId', {
                            required: 'Ví chi là bắt buộc'
                        })}
                        ref={ref => {
                            depositSelectRef = ref;
                        }}
                        options={depositOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('depositId', newValue.value);
                                setSelectedDeposit(newValue.value)
                            }
                        }}
                        placeholder='(chọn ví)'
                        className='max-width-400 my-1'
                    />
                    {errors.depositId && <ErrorMessage>{errors.depositId.message}</ErrorMessage>}
                </MDBCol>
                {
                    'CHUYEN_DOI' === selectedType &&
                    <React.Fragment>
                        <MDBCol md="2" className='flex-row'>
                            <div className="pt-2">Ví nhận</div>
                        </MDBCol>
                        <MDBCol md="10">
                            <Select
                                {...register('toDepositId', {
                                    required: 'Ví nhận là bắt buộc'
                                })}
                                ref={ref => {
                                    toDepositSelectRef = ref;
                                }}
                                options={toDepositOptions}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        setValue('toDepositId', newValue.value);
                                    }
                                }}
                                placeholder='(chọn ví)'
                                className='max-width-400 my-1'
                            />
                            {errors.toDepositId && <ErrorMessage>{errors.toDepositId.message}</ErrorMessage>}
                        </MDBCol>
                    </React.Fragment>
                }
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Ngày chi</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBDatePicker
                        {...register('outcomeTime', {
                            required: 'Ngày chi là bắt buộc'
                        })}
                        clearable
                        format='DD-MM-YYYY'
                        autoOk
                        getValue={(e) => {
                            setValue('outcomeTime', e ? formatDateForApi(e) : null)
                            setSelectedOutcomeTime(e)
                        }}
                        className='mt-0'
                        valueDefault={null as any}
                        emptyLabel='(chọn ngày)'
                    />
                    {errors.time && <ErrorMessage>{errors.time.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Mã phiếu chi</div>
                </MDBCol>
                <MDBCol md="10">
                    <div
                        {...register('code', {
                            required: 'Mã phiếu chi là bắt buộc'
                        })}>
                        <MDBInputGroup
                            material
                            containerClassName="my-0 max-width-400"
                            className='font-weight-bold text-primary'
                            prepend={outcomeCodePrefix}
                            onChange={(e: any) => setValue('code', e.target.value)}
                        />
                    </div>
                    {errors.code && <ErrorMessage>{errors.code.message}</ErrorMessage>}
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size='7'>
                    <OutcomeItemCreationListComponent/>
                </MDBCol>
                <MDBCol size='5'>
                    <OutcomeItemCreateFormComponent/>
                </MDBCol>
                <MDBCol size='12'>
                    <div className='text-center'>
                        <MDBBtn color="primary" onClick={handleSubmit(onSubmit)} disabled={creating}>Tạo phiếu
                            chi</MDBBtn>
                        {creating &&
                            <MDBSpinner small className='b-primary'/>
                        }
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default React.memo(IncomeCreateContainer);
