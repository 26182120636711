import React, { useMemo } from 'react';
import 'src/features/pages/home/home.style.scss';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';
import * as COMMON from 'src/core/utils/common';
import { UserInfo } from 'src/core/models/user-info.model';
import * as CONST from 'src/core/utils/constants';
import { Link } from 'react-router-dom';

const HomeContainer = (): JSX.Element => {

    const userInfo = useMemo(() => COMMON.getLocalStorage<UserInfo>(CONST.LocalStorage.USER_INFO), [])

    return (
        <MDBContainer className='py-3' fluid>
            <div className='text-center'>
                <h2 className='page-title'>Trang chủ</h2>
            </div>
            <MDBRow className='pb-3'>
                <MDBCol size='12'>
                    <p className="font-weight-normal mt-4">QUẢN LÝ NGUYÊN LIỆU</p>
                    <ul>
                        <li><Link to={CONST.AppURI.EXPORT_CREATE}>Tạo phiếu xuất</Link></li>
                        <li><Link to={CONST.AppURI.IMPORT_CREATE}>Tạo phiếu nhập</Link></li>
                        <li><Link to={CONST.AppURI.INCOME_CREATE}>Tạo phiếu thu</Link></li>
                        <li><Link to={CONST.AppURI.OUTCOME_CREATE}>Tạo phiếu chi</Link></li>
                    </ul>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default React.memo(HomeContainer);
