import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/coffee-core/coupon/coupon.type';
import {
    AddUserCouponsRequest,
    CreateCouponCodesRequest,
    UserCouponItem
} from 'src/core/models/coffee-core/coupon.model';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import { toast } from 'react-hot-toast';

const couponRepository = Repositories[RepositoryName.COUPON];

export const actGetListCouponByTypes = (types: string[], tenantId: number) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_COUPON_BY_TYPES_REQUEST, Types.GET_LIST_COUPON_BY_TYPES_SUCCESS, Types.GET_LIST_COUPON_BY_TYPES_FAILURE],
        callAPI: () => couponRepository.getListCouponByTypes(types, tenantId)
    })
};

export const actAddUserCouponItem = (item: UserCouponItem) => (dispatch: any): void => {
    dispatch({
        type: Types.ADD_USER_COUPON_ITEM,
        payload: item
    });
};

export const actRemoveUserCouponItem = (index: number) => (dispatch: any): void => {
    dispatch({
        type: Types.REMOVE_USER_COUPON_ITEM,
        payload: index
    });
};

export const actResetUserCouponItemList = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_USER_COUPON_ITEM_LIST,
    });
};

export const actAddUserCoupons = (request: AddUserCouponsRequest, successCallback = () => {}) => (dispatch: any) : void => {
    dispatch({
        types: [Types.ADD_USER_COUPONS_REQUEST, Types.ADD_USER_COUPONS_SUCCESS, Types.ADD_USER_COUPONS_FAILURE],
        callAPI: () => couponRepository.addUserCoupons(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Gửi ưu đãi thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                toast.error('Gửi ưu đãi thất bại', { duration: 2000 })
            }
        }
    })
}

export const actGetListCouponCode = (tenantId: number, status = 'NEW') => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_COUPON_CODE_REQUEST, Types.GET_LIST_COUPON_CODE_SUCCESS, Types.GET_LIST_COUPON_CODE_FAILURE],
        callAPI: () => couponRepository.getListCouponCode(status, tenantId)
    })
};

export const actUpdateCouponCodeStatus = (couponCodeIds: number[], status: string, successCallback = () => {}) => (dispatch: any): void => {
    dispatch({
        types: [Types.UPDATE_COUPON_CODE_STATUS_REQUEST, Types.UPDATE_COUPON_CODE_STATUS_SUCCESS, Types.UPDATE_COUPON_CODE_STATUS_FAILURE],
        callAPI: () => couponRepository.updateCouponCodeStatus(couponCodeIds, status),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
            }
        }
    })
};

export const actCreateCouponCodes = (request: CreateCouponCodesRequest, successCallback = () => {}) => (dispatch: any): void => {
    dispatch({
        types: [Types.CREATE_COUPON_CODES_REQUEST, Types.CREATE_COUPON_CODES_SUCCESS, Types.CREATE_COUPON_CODES_FAILURE],
        callAPI: () => couponRepository.createCouponCodes(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Tạo mã ưu đãi thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                toast.error('Tạo mã ưu đãi thất bại', { duration: 2000 })
            }
        }
    })
};
