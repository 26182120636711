import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl';
import Category from 'src/core/models/coffee-core/category/category.model'
import { MDBBox, MDBCard, MDBCardBody, MDBCardTitle, MDBContainer, MDBDataTable, MDBIcon, MDBRow, MDBSpinner } from 'mdbreact';
import _ from 'lodash';
import { NOT_AVAILABLE_IMAGE } from 'src/core/utils/constants';

type Props = {
    categoryList: Category[];
    listTitle: string;
    isLoading: boolean;
    onClickUpdateCategory: (category: Category) => void;
    onClickDeleteCategory: (category: Category) => void;
}

type CategoryDataTable = {
    imgUrl: JSX.Element;
    code: string;
    name: string;
    edit: JSX.Element;
    // type: string;
    [rest: string]: any;
}

const initCategoryDataTable: CategoryDataTable[] = [];

const CategoryListComponent = (props: Props): JSX.Element => {
    const { listTitle, categoryList, isLoading, onClickDeleteCategory, onClickUpdateCategory } = props;
    const intl = useIntl();
    const columns = [
        {
            label: intl.formatMessage({ id: 'CATEGORY.LIST.LABEL_IMAGE' }),
            field: 'imgUrl',
        },
        // {
        //     label: intl.formatMessage({ id: 'CATEGORY.LIST.LABEL_CODE' }),
        //     field: 'code'
        // },
        {
            label: intl.formatMessage({ id: 'CATEGORY.LIST.LABEL_NAME' }),
            field: 'name',
        },
        {
            label: intl.formatMessage({ id: 'CATEGORY.LIST.LABEL_EDIT' }),
            field: 'edit',
        }
    ]
    const [dataTable, setDataTable] = useState(initCategoryDataTable);
    const prepareDataRow = useCallback((listCategory: Category[]) => {
        if (_.isEmpty(listCategory)) return;

        const data: CategoryDataTable[] = [];
        listCategory.forEach((item: Category) => {
            const row = {
                id: item.id,
                imgUrl: (
                    <>
                        <MDBContainer className='m-0 p-0'>
                            <img
                                src={item.imgUrl || NOT_AVAILABLE_IMAGE}
                                className='category-list-row-img'
                            />
                        </MDBContainer>
                    </>
                ),
                code: item.code || '',
                name: item.name || '',
                edit: (
                    <>
                        <MDBRow className="category-list-item">
                            <button className="category-list-item-btn edit" onClick={() => { onClickUpdateCategory(item) }}>
                                <MDBIcon icon="edit" className="category-list-item-icon edit" />
                            </button>
                            <button className="category-list-item-btn delete" onClick={() => { onClickDeleteCategory(item) }}>
                                <MDBIcon icon="trash-alt" className="category-list-item-icon delete" />
                            </button>
                        </MDBRow>
                    </>
                ),
            }
            data.push(row)
        })
        setDataTable(data)
    }, []);

    const categoryDataTable = useMemo(() => {
        return (
            <MDBDataTable
                responsive
                striped
                borderless
                searching={true}
                sortable={false}
                noBottomColumns={true}
                paging={true}
                entries={5}
                pagesAmount={5}
                paginationLabel={[intl.formatMessage({ id: 'CATEGORY.LIST.LABEL_PREVIOUS_PAGE' }), intl.formatMessage({ id: 'CATEGORY.LIST.LABEL_NEXT_PAGE' })]}
                displayEntries={false}
                info={false}
                noRecordsFoundLabel={intl.formatMessage({ id: 'CATEGORY.LIST.LABEL_NOT_FOUND_CATEGORY' })}
                data={{ columns: columns, rows: dataTable }
                }
            />
        )
    }, [dataTable, intl])

    useEffect(() => {
        if (categoryList) {
            prepareDataRow(categoryList)
        }
    }, [categoryList, prepareDataRow])
    return (
        <MDBCard className="category-list-container" >
            <MDBCardTitle className="category-list-title">
                <MDBBox className="category-list-title-text" >
                    {listTitle}
                </MDBBox>
            </MDBCardTitle>
            <MDBCardBody className="m-0 p-0">
                {isLoading && <MDBRow className="d-flex justify-content-center mb-5"><MDBSpinner small /></MDBRow>}
                {!isLoading && categoryList && categoryDataTable}
            </MDBCardBody>
        </ MDBCard>
    )
}

export default CategoryListComponent
