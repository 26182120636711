import React, { Component } from 'react';
import { MDBCol, MDBRow } from 'mdbreact'
import 'src/features/pages/create-order/create-order.style.scss';
import OrderProductListComponent from 'src/features/components/order-list/order-product-list.component';
import OrderItemListComponent from 'src/features/components/order-list/order-item-list.component';

export default class CreateOrderContainer extends Component {
    render() {
        return (
            <div id="create-order">
                <MDBRow className='mb-4'>
                    <MDBCol md='12'>
                        <h4>Tạo đơn hàng</h4>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md='5'>
                        <OrderProductListComponent/>
                    </MDBCol>
                    <MDBCol md='7'>
                        <OrderItemListComponent/>
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}
