const Types = {
    GET_LIST_MATERIAL_REQUEST: 'GET_LIST_MATERIAL_REQUEST',
    GET_LIST_MATERIAL_SUCCESS: 'GET_LIST_MATERIAL_SUCCESS',
    GET_LIST_MATERIAL_FAILURE: 'GET_LIST_MATERIAL_FAILURE',
    SEARCH_MATERIAL_REQUEST: 'SEARCH_MATERIAL_REQUEST',
    SEARCH_MATERIAL_SUCCESS: 'SEARCH_MATERIAL_SUCCESS',
    SEARCH_MATERIAL_FAILURE: 'SEARCH_MATERIAL_FAILURE',
    GET_LIST_MATERIAL_CATEGORY_REQUEST: 'GET_LIST_MATERIAL_CATEGORY_REQUEST',
    GET_LIST_MATERIAL_CATEGORY_SUCCESS: 'GET_LIST_MATERIAL_CATEGORY_SUCCESS',
    GET_LIST_MATERIAL_CATEGORY_FAILURE: 'GET_LIST_MATERIAL_CATEGORY_FAILURE',
    GET_MATERIAL_REQUEST: 'GET_MATERIAL_REQUEST',
    GET_MATERIAL_SUCCESS: 'GET_MATERIAL_SUCCESS',
    GET_MATERIAL_FAILURE: 'GET_MATERIAL_FAILURE',
    RESET_MATERIAL: 'RESET_MATERIAL',
    CREATE_MATERIAL_REQUEST: 'CREATE_MATERIAL_REQUEST',
    CREATE_MATERIAL_SUCCESS: 'CREATE_MATERIAL_SUCCESS',
    CREATE_MATERIAL_FAILURE: 'CREATE_MATERIAL_FAILURE',
    UPDATE_MATERIAL_REQUEST: 'UPDATE_MATERIAL_REQUEST',
    UPDATE_MATERIAL_SUCCESS: 'UPDATE_MATERIAL_SUCCESS',
    UPDATE_MATERIAL_FAILURE: 'UPDATE_MATERIAL_FAILURE',
}

export default Types;