import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBRow, MDBSelect } from 'mdbreact';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { MCategory } from 'src/core/models/management/mcategory.model';
import { actAddIncomeItem, actGetListIncomeCategory } from 'src/core/reduxs/management/income/income.action';
import { IncomeItem } from 'src/core/models/management/income.model';
import _ from 'lodash';
import { numberToString, stringToNumber } from 'src/core/utils/common';
import Select from 'react-select';

const IncomeItemCreateForm = (): JSX.Element => {
    const dispatch = useDispatch()
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const categories: MCategory[] = useSelector((state: any) => state.incomeReducer.incomeCategories.data);
    const [amount, setAmount] = useState<string | undefined>();

    useEffect(() => {
        dispatch(actGetListIncomeCategory());
    }, [])

    const categoryOptions = useMemo(() => {
        if (!_.isEmpty(categories)) {
            return categories.map((c) => ({
                label: c.name,
                value: c.id
            }));
        }
        return [];
    }, [categories]);

    const onSubmit = (data: any) => {
        const categoryId = data.categoryId;
        const item: IncomeItem = {
            category: categories.find(c => c.id === categoryId) as MCategory,
            title: data.title,
            amount: data.amount,
        }
        dispatch(actAddIncomeItem(item))
    }

    return (
        <MDBCard className='container-fluid'>
            <MDBCardBody>
                <p className="font-weight-normal mt-3">TẠO MỤC THU</p>
                <MDBRow className='pb-3'>
                    <MDBCol md="4">
                        <div className="pt-2">Loại thu</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <Select
                            {...register('categoryId', {
                                required: 'Loại thu là bắt buộc'
                            })}
                            options={categoryOptions}
                            onChange={(newValue) => {
                                if (newValue) {
                                    setValue('categoryId', newValue.value);
                                }
                            }}
                            placeholder='(chọn loại thu)'
                            className='max-width-400 my-1'
                        />
                        {errors.categoryId && <ErrorMessage>{errors.categoryId.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol md="4">
                        <div className="pt-2">Nội dung</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBInput
                            {...register('title', {
                                required: 'Nội dung là bắt buộc'
                            })}
                            placeholder='Nhập nội dung'
                            onChange={(e: any) => {
                                setValue('title', e.target.value)
                            }}
                            containerClass='my-0'
                        />
                        {errors.title && <ErrorMessage>{errors.title.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol md="4">
                        <div className="pt-2">Số tiền</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBInput
                            {...register('amount', {
                                required: 'Số tiền là bắt buộc',
                            })}
                            placeholder='Nhập số tiền'
                            onChange={(e: any) => {
                                const inputValue = e.target.value;
                                if (inputValue.length < 3) {
                                    setAmount(inputValue);
                                    return
                                }
                                const numberValue = stringToNumber(e.target.value);
                                setValue('amount', numberValue)
                                const formattedValue = numberToString(numberValue);
                                setAmount(formattedValue);
                            }}
                            value={amount}
                            containerClass='my-0'
                        />
                        {errors.amount && <ErrorMessage>{errors.amount.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol size='12'>
                        <div className='text-center'>
                            <MDBBtn color="primary" onClick={handleSubmit(onSubmit)}>Tạo mục thu</MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    )
}

export default React.memo(IncomeItemCreateForm)
