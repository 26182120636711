import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import PagingResponse from 'src/core/models/paging-response.model';
import SaveOrder from 'src/core/models/update-order-status-request.model'
import PagingRequest from 'src/core/models/paging-request.model'
import { CreateOrderRequest, Order } from 'src/core/models/coffee-core/order.model';
import ResponseCustom from 'src/core/models/response-custom.model';
import { ApiURI } from 'src/core/utils/constants';

export default class OrderRepository extends BaseRepository {

    getListOrder(params: PagingRequest): Promise<AxiosResponse<PagingResponse<Order>>> {
        const request = {
            ...params,
            itemsPerPage: CONST.DEFAULT_PAGE_SIZE,
            sortBy: 'createdTime',
            sortType: 'ASC'
        };
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_ORDER).query<PagingResponse<Order>>(request );
    }

    getOrderDetail(orderId: number): Promise<AxiosResponse<Order[]>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_ORDER_DETAIL).getOne(orderId);
    }

    actUpdateOrderStatus(params: SaveOrder): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.UPDATE_ORDER_STATUS).create(params);
    }

    createOrder(request: CreateOrderRequest) {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/order/createOrder`).create<ResponseCustom>(request);
    }
}
