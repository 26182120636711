import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBDataTable, MDBIcon, MDBInput, MDBRow } from 'mdbreact';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrderItemAddition } from 'src/core/models/coffee-core/order-item.model';
import { currencyFormatter } from 'src/core/utils/common';
import ConfirmModal from 'src/shared/components/confirm-modal/confirm-modal.component';
import { actCreateOrder, actRemoveOrderItem, actResetOrderItem } from 'src/core/reduxs/coffee-core/order/order.action';
import * as CONST from 'src/core/utils/constants';
import { DEFAULT_PAGE_SIZE, ORDER_METHOD } from 'src/core/utils/constants';
import Pagination from 'src/shared/components/pagination/pagination.component';
import Divider from 'src/shared/components/divider/divider.component';
import _ from 'lodash';
import { toast } from 'react-hot-toast';
import { CreateOrderRequest, OrderItemRequest } from 'src/core/models/coffee-core/order.model';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { actGetUserByCode, actGetUserByPhone } from 'src/core/reduxs/coffee-core/user/user.action';
import { User } from 'src/core/models/coffee-core/user.model';
import ScanQRCodeModal from 'src/features/components/order-list/scan-qr-code-modal.component';

type DataTable = {
    name: JSX.Element;
    quantity: number;
    amount: string;
    [rest: string]: any;
}

const columns = [
    {
        label: 'Tên',
        field: 'name',
    },
    {
        label: 'Số lượng',
        field: 'quantity'
    },
    {
        label: 'Thành tiền',
        field: 'amount'
    },
    {
        label: '',
        field: 'command',
    }
]

const OrderItemListComponent = (): JSX.Element => {
    const THINH_TENANT_ID = 2;

    const dispatch = useDispatch();
    const history = useHistory();
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const orderItems: OrderItemAddition[] = useSelector((state: any) => state.orderReducer.orderItems.data);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isVisibleRemoveModal, setIsVisibleRemoveModal] = useState(false);
    const [isVisibleScanUserCodeModal, setIsVisibleScanUserCodeModal] = useState(false);
    const [selectedOrderItemIndex, setSelectedOrderItemIndex] = useState<number | undefined>();
    const [page, setPage] = useState(1);
    const [tableNumber, setTableNumber] = useState<string>('');
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const [searchUserText, setSearchUserText] = useState<string>('');
    const [user, setUser] = useState<User | undefined>(undefined);

    const prepareDataRow = useCallback((orderItemList: OrderItemAddition[]) => {
        const data: DataTable[] = [];

        const pageAmount = DEFAULT_PAGE_SIZE;
        // Calculate the start index for the requested page
        const startIndex = (page - 1) * pageAmount;

        // Extract the data for the specified page
        const pagingData = orderItemList.slice(startIndex, startIndex + pageAmount);
        pagingData.forEach((item, index) => {
            const toppingNames = item.toppings.length > 0 ? item.toppings.map(t => t.name).join(', ') : undefined;
            const row = {
                name: <div>
                    <div className='font-weight-normal'>{item.product.name || ''} ({item.size.name})</div>
                    {toppingNames && <div>- {toppingNames}</div>}
                    {item.note && <div>- {item.note}</div>}
                </div>,
                quantity: item.quantity,
                amount: currencyFormatter(item.amount),
                command: <MDBBtn flat rounded color="danger" className="px-3 py-2 my-0" onClick={() => onClickRemove(index)}>
                    <MDBIcon size='lg' icon="trash-alt" className='red-text'/>
                </MDBBtn>
            }
            data.push(row)
        })
        setDataTable(data)
    }, [page])

    const onPageChanged = (activePage: number) => {
        setPage(activePage);
    }

    const orderItemsDataTable = useMemo(() => {
        return (
            <Fragment>
                <MDBDataTable
                    className='common-table'
                    searching={false}
                    responsive
                    striped
                    borderless
                    sortable={false}
                    noBottomColumns={true}
                    paging={false}
                    displayEntries={false}
                    info={false}
                    noRecordsFoundLabel='Chưa có sản phẩm nào'
                    data={{columns: columns, rows: dataTable}
                    }
                />
                <Pagination
                    activeIndex={page || 0}
                    itemCount={orderItems.length}
                    pageSize={DEFAULT_PAGE_SIZE}
                    onPageChange={onPageChanged}
                />
                <div>Tổng: <span className='font-weight-bold text-primary'>{currencyFormatter(totalAmount)}</span></div>
            </Fragment>
        )
    }, [orderItems, dataTable])

    useEffect(() => {
        if (orderItems) {
            prepareDataRow(orderItems)
        }
        setTotalAmount(orderItems.reduce((sum, current) => sum + current.amount, 0));
    }, [orderItems, prepareDataRow])

    const onClickRemove = (index: number) => {
        setSelectedOrderItemIndex(index);
        setIsVisibleRemoveModal(true);
    }

    const removeOrderItems = () => {
        if (selectedOrderItemIndex !== undefined) {
            dispatch(actRemoveOrderItem(selectedOrderItemIndex));
            setIsVisibleRemoveModal(false);
        }
    }

    const onClickSearchUser = () => {
        let searchValue = searchUserText.replaceAll(' ', '');
        if (searchValue.startsWith('USER-')) {
            dispatch(actGetUserByCode(searchValue, THINH_TENANT_ID, onSearchSuccess));
            return
        }
        if (searchValue.startsWith('0')) {
            searchValue = '+84' + searchValue.substring(1);
        }
        dispatch(actGetUserByPhone(searchValue, THINH_TENANT_ID, onSearchSuccess));
    }

    const onScanUserCodeSuccess = (userCode: string) => {
        if (!userCode.startsWith('USER-')) {
            toast.error('Quét mã thất bại!', {duration: 2000})
            return;
        }
        toast.success('Quét mã thành công!', {duration: 2000})
        dispatch(actGetUserByCode(userCode, THINH_TENANT_ID, onSearchSuccess));
        setIsVisibleScanUserCodeModal(false);
    }

    const onSearchSuccess = (user: User) => {
        setUser(user)
    }

    const onSubmit = (data: any) => {
        if (_.isEmpty(orderItems)) {
            toast.error('Hóa đơn chưa có sản phẩm nào', {duration: 2000})
            return
        }
        const orderItemRequests: OrderItemRequest[] = orderItems.map(o => ({
            product: {
                id: o.product.id,
                toppings: o.toppings.map(t => ({id: t.id})),
                size: {
                    id: o.size.id
                }
            },
            quantity: o.quantity,
            note: o.note,
            amount: o.amount
        }))

        const request: CreateOrderRequest = {
            receiver: user ? `${user.lastName || ''} ${user.firstName || ''}` : '',
            phone: user?.phone ?? '',
            address: '',
            method: ORDER_METHOD.AT_STORE.key,
            note: data.note,
            userId: user?.id,
            items: orderItemRequests,
            tableNumber,
        }
        dispatch(actCreateOrder(request, onCreateSuccess))
    }

    const onCreateSuccess = useCallback(() => {
        dispatch(actResetOrderItem())
        history.push(CONST.AppURI.ORDER);
    }, [])

    return (
        <Fragment>
            <MDBCard>
                <MDBCardBody>
                    <MDBRow>
                        <MDBCol md='12'>
                            <p className="font-weight-normal">THÔNG TIN THANH TOÁN</p>
                            {orderItemsDataTable}
                        </MDBCol>
                    </MDBRow>
                    <Divider/>
                    <MDBRow>
                        <p className="font-weight-normal mt-3 mx-3">THÔNG TIN KHÁCH HÀNG</p>
                        <MDBBtn
                            color="primary"
                            className="py-2 m-2"
                            onClick={() => setIsVisibleScanUserCodeModal(true)}>Quét QR
                        </MDBBtn>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol md="3">
                            <div className="pt-2">Thông tin user</div>
                        </MDBCol>
                        <MDBCol md="5">
                            <MDBInput
                                placeholder='Nhập SĐT hoặc mã user'
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    setSearchUserText(value);
                                }}
                                value={searchUserText}
                                containerClass='my-0'
                            />
                        </MDBCol>
                        <MDBCol md='4'>
                            <MDBBtn
                                color="primary"
                                className="py-2 px-4"
                                onClick={onClickSearchUser}
                            >
                                Tìm
                            </MDBBtn>
                        </MDBCol>
                        {
                            user &&
                            <Fragment>
                                <MDBCol md="3">
                                    <div className="pt-2">Mã User</div>
                                </MDBCol>
                                <MDBCol md="9">
                                    <div className="pt-2 font-weight-light">{user.code}</div>
                                </MDBCol>
                                <MDBCol md="3">
                                    <div className="pt-2">Tên</div>
                                </MDBCol>
                                <MDBCol md="9">
                                    <div className="pt-2 font-weight-light">{`${user.lastName || ''} ${user.firstName || ''}`}</div>
                                </MDBCol>
                                <MDBCol md="3">
                                    <div className="pt-2">Số điện thoại</div>
                                </MDBCol>
                                <MDBCol md="9">
                                    <div className="pt-2 font-weight-light">{user.phone}</div>
                                </MDBCol>
                            </Fragment>
                        }
                    </MDBRow>
                    <Divider/>
                    <MDBRow>
                        <MDBCol md="3">
                            <div className="pt-2">Số bàn</div>
                        </MDBCol>
                        <MDBCol md="9">
                            <MDBInput
                                placeholder='Nhập số bàn'
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    setTableNumber(value);
                                }}
                                value={tableNumber}
                                containerClass='my-0 max-width-400'
                            />
                        </MDBCol>
                        <MDBCol md="3">
                            <div className="pt-2">Ghi chú</div>
                        </MDBCol>
                        <MDBCol md="9">
                            <MDBInput
                                {...register('note', {
                                    maxLength: {
                                        value: 255,
                                        message: 'Ghi chú không quá 12 ký tự',
                                    }
                                })}
                                placeholder='Nhập ghi chú'
                                onChange={(e: any) => {
                                    const value = e.target.value;
                                    setValue('note', value);
                                }}
                                containerClass='my-0 max-width-400'
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size='12'>
                            <div className='text-center'>
                                <MDBBtn color='primary' onClick={handleSubmit(onSubmit)}>
                                    Đặt hàng
                                </MDBBtn>
                            </div>
                            {/*<MDBBtn color="primary" onClick={handleSubmit(onSubmit)} disabled={creating}>Tạo phiếu*/}
                            {/*    chi</MDBBtn>*/}
                            {/*{creating &&*/}
                            {/*    <MDBSpinner small className='b-primary'/>*/}
                            {/*}*/}
                        </MDBCol>
                    </MDBRow>
                </MDBCardBody>
            </MDBCard>
            <ConfirmModal
                isVisible={isVisibleRemoveModal}
                setIsVisible={setIsVisibleRemoveModal}
                title='Xoá sản phẩm đã chọn'
                message='Bạn có chắc muốn xoá sản phẩm này?'
                color='danger'
                onClickConfirm={removeOrderItems}
            />
            <ScanQRCodeModal
                isVisible={isVisibleScanUserCodeModal}
                setIsVisible={setIsVisibleScanUserCodeModal}
                onScanSuccess={onScanUserCodeSuccess}
            />
        </Fragment>
    )
}

export default OrderItemListComponent
