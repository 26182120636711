import { MDBBadge, MDBBox, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBFileInput, MDBIcon, MDBInput, MDBRow, MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions, MDBSpinner } from 'mdbreact'
import React, { useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PickDeep from 'src/core/models/pick-deep.modal';
import { ProductCreateRequest } from 'src/core/models/coffee-core/product/product-request-params.model';
import ProductStateType from 'src/core/models/coffee-core/product/product-state.model';
import CategoryStateType from 'src/core/models/coffee-core/category/category-state.model';
import SizeGroupStateType from 'src/core/models/coffee-core/size-group/size-group-state.model';
import TopppingGroupStateType from 'src/core/models/coffee-core/topping-group/topping-group-state.model';
import * as ProductAction from 'src/core/reduxs/coffee-core/product/product.action';
import * as SizeGroupAction from 'src/core/reduxs/coffee-core/size-group/size-group.action'
import * as ToppingGroupAction from 'src/core/reduxs/coffee-core/topping-group/topping-group.action'
import PagingRequest from 'src/core/models/paging-request.model';
import SizeGroup from 'src/core/models/coffee-core/size-group/size-group.model';
import ToppingGroup from 'src/core/models/coffee-core/topping-group/topping-group.model';
import { useForm } from 'react-hook-form'
import ErrorMessage from 'src/shared/components/error-message/error-message.component';

type CreateProductForm = {
    productName: string,
    productPrice: number,
    productSizeGroupId: number,
    productToppingGroupId: number,
    // imageFile: File | null,
}

const initChosenCategories: number[] = []
const ProductCreateFormComponent = (): JSX.Element => {
    const intl = useIntl();
    const { register, unregister, handleSubmit, setValue, trigger, formState: { errors } } = useForm<CreateProductForm>()
    const [chosenCategories, setChosenCategories] = useState(initChosenCategories);
    const [imageFile, setImageFile] = useState<File | null>(null)
    const dispatch = useDispatch();
    const createProduct: PickDeep<ProductStateType, 'createProduct'> = useSelector((state: any) => state.productReducer.createProduct)
    const productCategories: PickDeep<CategoryStateType, 'productCategories'> = useSelector((state: any) => state.categoryReducer.productCategories)
    const sizeGroups: PickDeep<SizeGroupStateType, 'sizeGroups'> = useSelector((state: any) => state.sizeGroupReducer.sizeGroups)
    const toppingGroups: PickDeep<TopppingGroupStateType, 'toppingGroups'> = useSelector((state: any) => state.toppingGroupReducer.toppingGroups)

    const handleToggleCategory = (id: number) => {
        if (chosenCategories.includes(id)) {
            const newChosenCategories = chosenCategories.filter((category) => category !== id)
            setChosenCategories(newChosenCategories)
        }
        else {
            setChosenCategories((oldCategories) => [...oldCategories, id])
        }
    }

    const handleChangeSizeGroup = (sizeGroupId: number) => {
        handleChangeInput('productSizeGroupId', sizeGroupId)
    }

    const handleChangeToppingGroup = (toppingGroupId: number) => {
        handleChangeInput('productToppingGroupId', toppingGroupId)
    }

    const createSuccess = () => {
        setChosenCategories(initChosenCategories);
        toast.success(intl.formatMessage({ id: 'PRODUCT.CREATE.TOAST_SUCCESS' }), { duration: 3000 })
    }

    useEffect(() => {
        registerForm();
        return () => {
            unregister(['productName', 'productPrice', 'productSizeGroupId', 'productToppingGroupId']);
        }
    }, []);

    const registerForm = () => {
        register('productName', {
            required: {
                value: true,
                message: intl.formatMessage({ id: 'PRODUCT.CREATE.TOAST_WARNING_NOT_NAME' }),
            }
        });
        register('productPrice', {
            valueAsNumber: true,
            required: {
                value: true,
                message: intl.formatMessage({ id: 'PRODUCT.CREATE.TOAST_WARNING_NOT_PRICE' }),
            },
            min: {
                value: 1,
                message: intl.formatMessage({ id: 'PRODUCT.CREATE.TOAST_WARNING_MIN_PRICE' }),
            },
        });
        register('productSizeGroupId', {
            valueAsNumber: true,
            required: {
                value: true,
                message: intl.formatMessage({ id: 'PRODUCT.CREATE.TOAST_WARNING_NOT_SIZE_GROUP' }),
            }
        });
        register('productToppingGroupId', {
            valueAsNumber: true,
            required: {
                value: true,
                message: intl.formatMessage({ id: 'PRODUCT.CREATE.TOAST_WARNING_NOT_TOPPING_GROUP' }),
            }
        });
    }

    const handleChangeInput = async (name: keyof CreateProductForm, value: any) => {
        setValue(name, value);
        await trigger(name);
    }

    const handleCreateProduct = (data: CreateProductForm) => {
        const formData = new FormData();

        for (const id of chosenCategories) {
            formData.append('categoryIds[]', JSON.stringify(id))
        }

        if (imageFile) {
            if (imageFile?.type.includes('image')) {
                formData.append('image', imageFile)
            } else {
                toast.error(intl.formatMessage({ id: 'PRODUCT.CREATE.TOAST_WARNING_NOT_IMAGE_FILE' }))
                return;
            }
        }
        formData.append('name', data.productName)
        formData.append('price', data.productPrice.toString())
        formData.append('sizeGroupId', data.productSizeGroupId?.toString())
        formData.append('toppingGroupId', data.productToppingGroupId?.toString())

        const params: ProductCreateRequest = {
            data: formData,
            successCallback: () => createSuccess(),
            errorCallback: () => toast.error(intl.formatMessage({ id: 'PRODUCT.CREATE.TOAST_FAILURE' }), { duration: 3000 })
        }
        dispatch(ProductAction.actCreateProduct(params))
    }

    useEffect(() => {
        const params: PagingRequest = {
            pageId: 0
        }
        dispatch(SizeGroupAction.actGetListSizeGroup(params))
        dispatch(ToppingGroupAction.actGetListToppingGroup(params))
    }, [])

    return (
        <MDBCard className="product-create">
            <MDBCardTitle className="product-create-title">
                <MDBBox>
                    {intl.formatMessage({ id: 'PRODUCT.CREATE.TITLE' })}
                </MDBBox>
            </MDBCardTitle>
            <MDBCardBody className="product-create-body">
                <MDBBox className="product-create-body-form">
                    <form
                        onSubmit={handleSubmit(handleCreateProduct)}
                    >
                        <MDBBox className='form-inputs'>
                            <MDBBox className='form-input-wrapper'>
                                <MDBInput
                                    label={intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_NAME' })}
                                    outline
                                    onChange={(e) => handleChangeInput('productName', e.currentTarget.value)}
                                    disabled={createProduct.isLoading}
                                />
                                {errors.productName && <ErrorMessage>{errors.productName.message}</ErrorMessage>}
                            </MDBBox>
                            <MDBBox className='form-input-wrapper'>
                                <MDBInput
                                    label={intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_PRICE' })}
                                    outline
                                    onChange={(e) => handleChangeInput('productPrice', e.currentTarget.value)}
                                    disabled={createProduct.isLoading}
                                    type='number'
                                />
                                {errors.productPrice && errors.productPrice.type === 'required' && <ErrorMessage>{errors.productPrice.message}</ErrorMessage>}
                                {errors.productPrice && errors.productPrice.type === 'min' && <ErrorMessage>{errors.productPrice.message}</ErrorMessage>}
                            </MDBBox>
                            <MDBBox className='form-input-wrapper'>
                                <MDBSelect outline className='m-0 p-0' label={intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_SIZE_GROUP' })} getValue={(value: any) => { handleChangeSizeGroup(value[0]) }}>
                                    <MDBSelectInput />
                                    <MDBSelectOptions >
                                        {
                                            sizeGroups && sizeGroups.data &&
                                            sizeGroups.data.map((sizeGroup: SizeGroup) => {
                                                return (
                                                    <MDBSelectOption key={sizeGroup.id} value={sizeGroup.id} >{sizeGroup.name}</MDBSelectOption>
                                                )
                                            })
                                        }
                                    </MDBSelectOptions>
                                </MDBSelect>
                                {errors.productSizeGroupId && <ErrorMessage>{errors.productSizeGroupId.message}</ErrorMessage>}
                            </MDBBox>
                            <MDBBox className='form-input-wrapper'>
                                <MDBSelect className='mb-0' outline label={intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_TOPPING_GROUP' })} getValue={(value: any) => { handleChangeToppingGroup(value[0]) }}>
                                    <MDBSelectInput className='mb-0' />
                                    <MDBSelectOptions >
                                        {
                                            toppingGroups && toppingGroups.data &&
                                            toppingGroups.data.map((toppingGroup: ToppingGroup) => {
                                                return (
                                                    <MDBSelectOption key={toppingGroup.id} value={toppingGroup.id} >{toppingGroup.name}</MDBSelectOption>
                                                )
                                            })
                                        }
                                    </MDBSelectOptions>
                                </MDBSelect>

                                {/* <SelectOption
                                    label={intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_TOPPING_GROUP' })}
                                    placeHolder={intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_TOPPING_GROUP' })}
                                    onValueChange={(value: any) => { handleChangeToppingGroup(value[0]) }}
                                    options={toppingGroupOption}
                                    outline
                                ></SelectOption> */}
                                {errors.productToppingGroupId && <ErrorMessage>{errors.productToppingGroupId.message}</ErrorMessage>}
                            </MDBBox>

                        </MDBBox>
                        <MDBBox className='form-list-categories'>
                            <MDBBox className='form-list-categories-label'>{intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_CATEGORIES' })}</MDBBox>
                            {(productCategories.isLoading || createProduct.isLoading) && <MDBRow className="d-flex justify-content-center mb-5 w-100 h-100 mt-1"><MDBSpinner small /></MDBRow>}
                            {!productCategories.isLoading && !createProduct.isLoading && productCategories.data.length === 0 && <p style={{ fontSize: '0.9rem', fontWeight: 300 }}>{intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_CATEGORIES_NOT_FOUND' })}</p>}
                            {!productCategories.isLoading && !createProduct.isLoading && productCategories.data.length > 0 &&
                                (productCategories.data.map((category) => {
                                    return (
                                        <MDBBadge
                                            key={category.id}
                                            className={(chosenCategories.includes(category.id)) ? 'chosen-badge' : 'remain-badge'}
                                            onClick={() => handleToggleCategory(category.id)}
                                        >
                                            {category.name}
                                        </MDBBadge>
                                    )
                                }))
                            }
                        </MDBBox>
                        <MDBBox className='form-input-image'>
                            <MDBFileInput
                                btnTitle={intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_BUTTON_CHOOSE_IMAGE' })}
                                btnColor='grey'
                                textFieldTitle={intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_TITLE_CHOOSE_IMAGE' })}
                                getValue={(files: File[]) => setImageFile(files[0])}
                                accept="image/*"
                            />
                            {
                                imageFile?.type?.includes('image') && (
                                    <img
                                        src={URL.createObjectURL(imageFile as Blob)}
                                        className='product-image-review'
                                    />
                                )
                            }
                        </MDBBox>

                        <MDBBox className='form-submit-box'>
                            <MDBBtn color='dark-green' className='form-submit-btn' type="submit" disabled={createProduct.isLoading}>
                                {intl.formatMessage({ id: 'PRODUCT.CREATE.LABEL_BUTTON' })}
                                <MDBIcon icon='plus' className='ml-3' />
                            </MDBBtn>
                        </MDBBox>
                    </form>
                </MDBBox>
            </MDBCardBody>
        </MDBCard>
    )
}

export default ProductCreateFormComponent
