export const GET_TOPPINGS_REQUEST = 'GET_TOPPINGS_REQUEST';
export const GET_TOPPINGS_SUCCESS = 'GET_TOPPINGS_SUCCESS';
export const GET_TOPPINGS_FAILURE = 'GET_TOPPINGS_FAILURE';

export const GET_TOPPING_REQUEST = 'GET_TOPPING_REQUEST';
export const GET_TOPPING_SUCCESS = 'GET_TOPPING_SUCCESS';
export const GET_TOPPING_FAILURE = 'GET_TOPPING_FAILURE';
export const RESET_TOPPING = 'RESET_TOPPING';

export const UPDATE_TOPPING_REQUEST = 'UPDATE_TOPPING_REQUEST';
export const UPDATE_TOPPING_SUCCESS = 'UPDATE_TOPPING_SUCCESS';
export const UPDATE_TOPPING_FAILURE = 'UPDATE_TOPPING_FAILURE';

export const CREATE_TOPPING_REQUEST = 'CREATE_TOPPING_REQUEST';
export const CREATE_TOPPING_SUCCESS = 'CREATE_TOPPING_SUCCESS';
export const CREATE_TOPPING_FAILURE = 'CREATE_TOPPING_FAILURE';

export const DELETE_TOPPING_REQUEST = 'DELETE_TOPPING_REQUEST';
export const DELETE_TOPPING_SUCCESS = 'DELETE_TOPPING_SUCCESS';
export const DELETE_TOPPING_FAILURE = 'DELETE_TOPPING_FAILURE';
