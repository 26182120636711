import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import LoginInfo from 'src/core/models/login-info.model';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import { UserInfo } from 'src/core/models/user-info.model';
import ResponseCustom from 'src/core/models/response-custom.model';

export default class CommonRepository extends BaseRepository {
    constructor() {
        super(CONST.ApiURI.COFFEE_CORE_SERVICE.TODO);
    }

    login(userName: string, password: string): Promise<AxiosResponse<LoginInfo>> {
        const data = qs.stringify({
            username: userName,
            password: password,
            scope: 'read',
            grant_type: 'password'
        });

        const config: AxiosRequestConfig = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}${CONST.ApiURI.AUTH_SERVICE.AUTHENTICATE}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': process.env.REACT_APP_BASIC_AUTH
            },
            data: data
        };

        return this.customConfig(config);
    }

    getUserInfo(): Promise<AxiosResponse<UserInfo>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.USER.GET_USER_INFO).getAll<UserInfo>();
    }

    createFirebaseToken(deviceToken: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.THIRD_PARTY_SERVICE.ADMIN.CREATE_FIREBASE_TOKEN).create<ResponseCustom>({ deviceToken } );
    }

    unsubscribeAllTopics(deviceToken: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.THIRD_PARTY_SERVICE.GUEST.UNSUBSRIBE_ALL_TOPIC).create<ResponseCustom>({ deviceToken } );
    }
}
