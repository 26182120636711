export const GET_LIST_BLACK_PEARL_CODE_REQUEST = 'GET_LIST_BLACK_PEARL_CODE_REQUEST';
export const GET_LIST_BLACK_PEARL_CODE_SUCCESS = 'GET_LIST_BLACK_PEARL_CODE_SUCCESS';
export const GET_LIST_BLACK_PEARL_CODE_FAILURE = 'GET_LIST_BLACK_PEARL_CODE_FAILURE';

export const UPDATE_BLACK_PEARL_CODE_STATUS_REQUEST = 'UPDATE_BLACK_PEARL_CODE_STATUS_REQUEST';
export const UPDATE_BLACK_PEARL_CODE_STATUS_SUCCESS = 'UPDATE_BLACK_PEARL_CODE_STATUS_SUCCESS';
export const UPDATE_BLACK_PEARL_CODE_STATUS_FAILURE = 'UPDATE_BLACK_PEARL_CODE_STATUS_FAILURE';

export const CREATE_BLACK_PEARL_CODES_REQUEST = 'CREATE_BLACK_PEARL_CODES_REQUEST';
export const CREATE_BLACK_PEARL_CODES_SUCCESS = 'CREATE_BLACK_PEARL_CODES_SUCCESS';
export const CREATE_BLACK_PEARL_CODES_FAILURE = 'CREATE_BLACK_PEARL_CODES_FAILURE';
