import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/management/import/import.type';
import { CreateImportRequest, UpdateImportRequest } from 'src/core/models/management/import.model';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';
import { toast } from 'react-hot-toast';

const importRepository = Repositories[RepositoryName.IMPORT];

export const actCreateImport = (request: CreateImportRequest, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_IMPORT_REQUEST, Types.CREATE_IMPORT_SUCCESS, Types.CREATE_IMPORT_FAILURE],
        callAPI: () => importRepository.createImport(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
            },
            failure: (error: AxiosError) => {
                errorCallback()
            }
        }
    })
}

export const actSearchImport = (request: SearchAndFilterRequest) => (dispatch: any) : void => {
    dispatch({
        types: [Types.SEARCH_IMPORT_REQUEST, Types.SEARCH_IMPORT_SUCCESS, Types.SEARCH_IMPORT_FAILURE],
        callAPI: () => importRepository.searchImport(request),
    })
}

export const actGetImportDetails = (code: string) => (dispatch: any) : void => {
    dispatch({
        types: [Types.GET_IMPORT_DETAILS_REQUEST, Types.GET_IMPORT_DETAILS_SUCCESS, Types.GET_IMPORT_DETAILS_FAILURE],
        callAPI: () => importRepository.getImportDetails(code),
    })
}

export const actDeleteImport = (code: string, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.DELETE_IMPORT_REQUEST, Types.DELETE_IMPORT_SUCCESS, Types.DELETE_IMPORT_FAILURE],
        callAPI: () => importRepository.deleteImport(code),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Xoá phiếu nhập thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                errorCallback()
                toast.error('Xoá phiếu nhập thất bại', { duration: 2000 })
            }
        }
    })
}

export const actUpdateOutcomePaidStatus = (code: string, isOutcomePaid = true, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.UPDATE_OUTCOME_PAID_STATUS_REQUEST, Types.UPDATE_OUTCOME_PAID_STATUS_SUCCESS, Types.UPDATE_OUTCOME_PAID_STATUS_FAILURE],
        callAPI: () => importRepository.updateOutcomePaidStatus(code, isOutcomePaid),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Cập nhật trạng thái thanh toán thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                errorCallback()
                toast.error('Cập nhật trạng thái thanh toán thất bại', { duration: 2000 })
            }
        }
    })
}

export const actUpdateImport = (request: UpdateImportRequest, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    toast.loading('Đang cập nhật', {
        id: 'importUpdating',
    });
    dispatch({
        types: [Types.UPDATE_IMPORT_REQUEST, Types.UPDATE_IMPORT_SUCCESS, Types.UPDATE_IMPORT_FAILURE],
        callAPI: () => importRepository.updateImport(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.dismiss('importUpdating');
                toast.success('Cập nhật phiếu nhập thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                errorCallback()
                toast.dismiss('importUpdating');
                toast.error('Cập nhật phiếu nhập thất bại', { duration: 2000 })
            }
        }
    })
}
