import Types from 'src/core/reduxs/coffee-core/black-pearl/black-pearl.type';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import { toast } from 'react-hot-toast';
import { AccumulateBlackPearlRequest } from 'src/core/models/black-pearl.model';

const blackPearlRepository = Repositories[RepositoryName.BLACK_PEARL];

export const actAccumulateBlackPearl = (request: AccumulateBlackPearlRequest, successCallback = () => {}) => (dispatch: any): void => {
    dispatch({
        types: [Types.ACCUMULATE_BLACK_PEARL_REQUEST, Types.ACCUMULATE_BLACK_PEARL_SUCCESS, Types.ACCUMULATE_BLACK_PEARL_FAILURE],
        callAPI: () => blackPearlRepository.accumulateBlackPearl(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Tích điểm thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                toast.error('Tích điểm thất bại', { duration: 2000 })
            }
        }
    })
};
