import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBFileInput, MDBInput, MDBRow, MDBSpinner, } from 'mdbreact';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as CONST from 'src/core/utils/constants';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { NewsTypes } from 'src/core/models/coffee-core/news.model';
import Select from 'react-select';
import Category from 'src/core/models/coffee-core/category/category.model';
import _ from 'lodash';
import { actCreateNews, actGetListNewsCategoryByTenant } from 'src/core/reduxs/coffee-core/news/news.action';
import { Tenant } from 'src/core/models/tenant.model';
import { actGetListTenant } from 'src/core/reduxs/coffee-core/tenant/tenant.action';

const NewsCreateContainer = (): JSX.Element => {

    const history = useHistory();
    const {register, handleSubmit, setValue, formState: {errors}} = useForm();
    const dispatch = useDispatch();
    const creating: boolean = useSelector((state: any) => state.newsReducer.createNews.loading);
    const [pushNotificationChecked, setPushNotificationChecked] = useState(false);
    const [imageFile, setImageFile] = useState<File | undefined>(undefined)
    const tenants: Tenant[] = useSelector((state: any) => state.tenantReducer.tenants.data);
    const [selectedTenantId, setSelectedTenantId] = useState<number | undefined>(undefined);
    const categories: Category[] = useSelector((state: any) => state.newsReducer.newsCategories.data);

    useEffect(() => {
        dispatch(actGetListTenant());
    }, [])

    useEffect(() => {
        if (selectedTenantId !== undefined) {
            dispatch(actGetListNewsCategoryByTenant(selectedTenantId));
        }
    }, [selectedTenantId])

    const onCreateSuccess = useCallback(() => {
        history.push(CONST.AppURI.NEWS);
    }, [])

    const newsTypeOptions = useMemo(() => {
        return NewsTypes.map(type => ({
            label: type.name,
            value: type.id
        }))
    }, []);

    const tenantOptions = useMemo(() => {
        if (!_.isEmpty(tenants)) {
            return tenants.map((t) => ({
                label: t.name,
                value: t.id
            }));
        }
        return [];
    }, [tenants]);

    const categoryOptions = useMemo(() => {
        if (!_.isEmpty(categories)) {
            return categories.map((c) => ({
                label: c.name,
                value: c.id
            }));
        }
        return [];
    }, [categories]);

    const onSubmit = (data: any) => {
        if (!imageFile || !imageFile.type.includes('image')) {
            toast.error('Bạn chưa cập nhật hình ảnh cho tin tức', {duration: 2000})
            return
        }

        const formData = new FormData();
        formData.append('newsTypeId', data.newsTypeId)
        formData.append('title', data.title)
        formData.append('shortContent', data.shortContent)
        formData.append('content', data.content)
        formData.append('image', imageFile)
        formData.append('isPushNotification', String(pushNotificationChecked))
        formData.append('categoryId', data.categoryId)
        formData.append('tenantId', data.tenantId)

        dispatch(actCreateNews(formData, onCreateSuccess))
    }

    return (
        <MDBContainer className='py-3' fluid>
            <div className='text-center'>
                <h2 className='page-title'>Tạo tin tức</h2>
            </div>
            <MDBRow className='pb-3'>
                <MDBCol md="2">
                    <div className="pt-2">Tiêu đề</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBInput
                        {...register('title', {
                            required: 'Tiêu đề là bắt buộc',
                            maxLength: {
                                value: 255,
                                message: 'Tiêu đề không được nhiều hơn 255 ký tự',
                            }
                        })}
                        placeholder='Tiêu đề'
                        onChange={(e: any) => {
                            setValue('title', e.target.value)
                        }}
                        containerClass='my-0 max-width-400'
                    />
                    {errors.title && <ErrorMessage>{errors.title.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Loại tin tức</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('newsTypeId', {
                            required: 'Loại tin tức là bắt buộc'
                        })}
                        options={newsTypeOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('newsTypeId', newValue.value);
                            }
                        }}
                        placeholder='(chọn loại)'
                        className='max-width-400 my-1'
                    />
                    {errors.newsTypeId && <ErrorMessage>{errors.newsTypeId.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Chọn brand</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('tenantId', {
                            required: 'Brand bắt buộc'
                        })}
                        options={tenantOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('tenantId', newValue.value);
                                setSelectedTenantId(newValue.value)
                            }
                        }}
                        placeholder='(chọn brand)'
                        className='max-width-400 my-1'
                    />
                    {errors.tenantId && <ErrorMessage>{errors.tenantId.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Danh mục</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('categoryId', {
                            required: 'Danh mục là bắt buộc'
                        })}
                        options={categoryOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('categoryId', newValue.value);
                            }
                        }}
                        placeholder='(chọn danh mục)'
                        className='max-width-400 my-1'
                        isDisabled={selectedTenantId === undefined}
                    />
                    {errors.categoryId && <ErrorMessage>{errors.categoryId.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Mô tả ngắn</div>
                </MDBCol>
                <MDBCol md="10">
                    <textarea
                        {...register('shortContent', {
                            required: 'Mô tả ngắn là bắt buộc',
                            maxLength: {
                                value: 255,
                                message: 'Mô tả ngắn không được nhiều hơn 255 ký tự',
                            }
                        })}
                        className="form-control my-2"
                        rows={3}
                        onChange={(e: any) => {
                            setValue('shortContent', e.target.value)
                        }}
                    />
                    {errors.shortContent && <ErrorMessage>{errors.shortContent.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Nội dung</div>
                </MDBCol>
                <MDBCol md="10">
                    <textarea
                        {...register('content', {
                            required: 'Nội dung là bắt buộc'
                        })}
                        className="form-control my-2"
                        rows={5}
                        onChange={(e: any) => {
                            setValue('content', e.target.value)
                        }}
                    />
                    {errors.content && <ErrorMessage>{errors.content.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="md-form">Gửi thông báo</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBInput
                        filled
                        checked={pushNotificationChecked}
                        onChange={() => setPushNotificationChecked(!pushNotificationChecked)}
                        type="checkbox"
                        containerClass='md-form mt-3 pl-0'
                        id="is-push-notification-checkbox"
                        label="Gửi thông báo tới thiết bị của khách hàng"
                    />
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="md-form">Hình ảnh</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBFileInput
                        btnTitle='Chọn hình ảnh'
                        btnColor='grey'
                        textFieldTitle='Chọn hình ảnh cho tin tức'
                        getValue={(files: File[]) => setImageFile(files[0])}
                        accept="image/*"
                    />
                    {
                        imageFile?.type?.includes('image') && (
                            <img
                                src={URL.createObjectURL(imageFile as Blob)}
                                alt='image'
                            />
                        )
                    }
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size='12'>
                    <div className='text-center'>
                        <MDBBtn color="primary" onClick={handleSubmit(onSubmit)} disabled={creating}>Tạo tin
                            tức</MDBBtn>
                        {creating &&
                            <MDBSpinner small className='b-primary'/>
                        }
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default React.memo(NewsCreateContainer);
