import React, { ReactNode } from 'react';
import _ from 'lodash';
import RouteType from 'src/core/models/route.model';
import RouteWithComponent from 'src/shared/hoc/route-with-component.hoc';
import moment from 'moment';
import html2canvas from 'html2canvas';
import { toast } from 'react-hot-toast';

export const buildRoutes = (routes: Array<RouteType>): ReactNode[] => {
    const elementRoute: ReactNode[] = [];
    if (_.isEmpty(routes)) {
        return elementRoute;
    }

    const length = routes.length;
    for (let index = 0; index < length; index++) {
        const route = routes[index];

        elementRoute.push(
            <RouteWithComponent
                key={route.name + index}
                exact={route.exact}
                path={route.path}
                component={route.main}
            />
        )
    }

    return elementRoute;
}

/**
 * Converts a string to its html characters completely.
 *
 * @param {String} str String with unescaped HTML characters
 **/
export const encode = (str: any): string => {
    const buf = [];

    for (let i = str.length - 1; i >= 0; i--) {
        buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
    }

    return buf.join('');
};

/**
 * Converts an html characterSet into its original character.
 *
 * @param {String} str htmlSet entities
 **/
export const decode = (str: string): string => {
    return str.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
    });
};

export const isNaNDate = (value: Date): boolean => {
    if (_.isNil(value) || _.isNaN(value.getDate()) || _.isNaN(value.getMonth()) || _.isNaN(value.getFullYear())) {
        return true;
    }

    return false;
}

export const currencyFormatter = (value: number, locale = 'vi', currency = 'VND', decimalDigits = 0): string => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: decimalDigits,
        // the default value for minimumFractionDigits depends on the currency
        // and is usually already 2
    });

    return _.isFinite(value) ? formatter.format(value) : '';
};

export const roundUp = (num: number, precision: number): number | string => {
    if (!_.isFinite(num)) return '';

    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
};

export const roundDown = (num: number, precision: number): number | string => {
    if (!_.isFinite(num)) return '';

    precision = Math.pow(10, precision);
    return Math.floor(num * precision) / precision;
};

export function setLocalStorage(key: string, data = {}): void {
    localStorage.setItem(key, JSON.stringify(data || {}));
}

export function getLocalStorage<T = any>(key: string): T {
    try {
        return JSON.parse(localStorage.getItem(key) || '{}');
    } catch (error) {
        return {} as any;
    }
}

export function removeLocalStorage(key: string): void {
    localStorage.removeItem(key);
}

export function clearLocalStorage(): void {
    localStorage.clear();
}

export function setSessionStorage(key: string, data = {}): void {
    sessionStorage.setItem(key, JSON.stringify(data || {}));
}

export function getSessionStorage<T = any>(key: string): T {
    try {
        return JSON.parse(sessionStorage.getItem(key) || '{}');
    } catch (error) {
        return {} as any;
    }
}

export function removeSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
}

export function clearSessionStorage(): void {
    sessionStorage.clear();
}

export const formatDateForApi = (date: Date): string => {
    return moment(date).format('YYYY-MM-DD');
}

export const isNotificationSupported = 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

export const numberToString = (num: number | undefined, fix = 0) => {
    return num
        ?.toFixed(fix) // always two decimal digits
        .replace('.', ',') // replace decimal point character with ,
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') // use . as a separator
}

export const stringToNumber = (str: string) => {
    const digitRegex = /[0-9]|\./;
    if (!digitRegex.test(str)) {
        return 0;
    }
    return Number(str.replaceAll('.', ''))
}

export const roundPrice = (num: number) => {
    return Math.ceil(num / 100) * 100;
}

export const getScreenShot = async (elementId: string): Promise<void> => {
    const src = document.getElementById(elementId);
    const canvas = await html2canvas(src as HTMLElement);
    canvas.toBlob((blob) => {
        if (!blob) {
            return
        }
        navigator.clipboard
            .write([
                new ClipboardItem(
                    Object.defineProperty({}, blob.type, {
                        value: blob,
                        enumerable: true
                    })
                )
            ])
            .then(function() {
                toast.success('Đã sao chép', { duration: 2000 })
            });
    });
}