import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import * as Types from 'src/core/reduxs/coffee-core/firebase/firebase.type';
import FirebaseStateType from 'src/core/models/firebase-state.model';

const initialState: FirebaseStateType = {
    createMobileSession: {
        data: {},
        error: null,
        isLoading: false
    },
    notification: {}
};

const firebaseReducer = createReducer(initialState, {
    [Types.CREATE_MOBILE_SESSION_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createMobileSession: {
                ...state.createMobileSession,
                isLoading: true
            }
        };
    },
    [Types.CREATE_MOBILE_SESSION_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createMobileSession: {
                ...state.createMobileSession,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_MOBILE_SESSION_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createMobileSession: {
                ...state.createMobileSession,
                isLoading: false,
                data: {},
                error: action.payload
            }
        };
    },
    [Types.RECEIVE_NOTIFICATION]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            notification: {
                ...state.notification,
                [action.payload.orderId]: {
                    data: action.payload.notification,
                    isRead: false
                }
            }
        };
    },
    [Types.CLICK_NOTIFICATION]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            notification: {
                ...state.notification,
                [action.payload]: {
                    ...state.notification[action.payload],
                    isRead: true
                }
            }
        };
    }
})

export default firebaseReducer;
