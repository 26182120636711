import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/coffee-core/news/news.type';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import { toast } from 'react-hot-toast';
import PagingRequest from 'src/core/models/paging-request.model';

const categoryRepository = Repositories[RepositoryName.CATEGORY];
const newsRepository = Repositories[RepositoryName.NEWS];

export const actGetListNewsCategoryByTenant = (tenantId: number) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_NEWS_CATEGORY_REQUEST, Types.GET_LIST_NEWS_CATEGORY_SUCCESS, Types.GET_LIST_NEWS_CATEGORY_FAILURE],
        callAPI: () => categoryRepository.getListCategoryByTenantIdAndType(tenantId, 'NEWS')
    })
};

export const actCreateNews = (request: FormData, successCallback = () => {}) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_NEWS_REQUEST, Types.CREATE_NEWS_SUCCESS, Types.CREATE_NEWS_FAILURE],
        callAPI: () => newsRepository.createNews(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Tạo tin tức thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                console.log(error.toJSON())
                toast.error('Tạo tin tức thất bại', { duration: 2000 })
            }
        }
    })
}

export const actSearchNews = (request: PagingRequest) => (dispatch: any) : void => {
    dispatch({
        types: [Types.SEARCH_NEWS_REQUEST, Types.SEARCH_NEWS_SUCCESS, Types.SEARCH_NEWS_FAILURE],
        callAPI: () => newsRepository.getListNews(request),
    })
}

export const actGetNewsDetails = (id: number) => (dispatch: any) : void => {
    dispatch({
        types: [Types.GET_NEWS_DETAILS_REQUEST, Types.GET_NEWS_DETAILS_SUCCESS, Types.GET_NEWS_DETAILS_FAILURE],
        callAPI: () => newsRepository.getNewsDetails(id),
    })
}

export const actUpdateNews = (request: FormData, successCallback = () => {}) => (dispatch: any) : void => {
    dispatch({
        types: [Types.UPDATE_NEWS_REQUEST, Types.UPDATE_NEWS_SUCCESS, Types.UPDATE_NEWS_FAILURE],
        callAPI: () => newsRepository.updateNews(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Cập nhật tin tức thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                console.log(error.toJSON())
                toast.error('Cập nhật tin tức thất bại', { duration: 2000 })
            }
        }
    })
}
