const Types = {
    GET_ORDERS_REQUEST: 'GET_ORDERS_REQUEST',
    GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
    GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',
    GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',
    GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
    GET_ORDER_FAILURE: 'GET_ORDER_FAILURE',
    RESET_ORDER: 'RESET_ORDER',
    UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',
    UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_FAILURE: 'UPDATE_ORDER_FAILURE',
    ADD_ORDER_ITEM: 'ADD_ORDER_ITEM',
    REMOVE_ORDER_ITEM: 'REMOVE_ORDER_ITEM',
    RESET_ORDER_ITEM: 'RESET_ORDER_ITEM',
    CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
    CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
    CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',
}

export default Types;
