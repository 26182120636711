import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { Store } from 'src/core/models/management/store.model';

export default class StoreRepository extends BaseRepository {

    getListStore(): Promise<AxiosResponse<Store[]>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_LIST_STORE).getAll();
    }
}
