const Types = {
    GET_LIST_COUPON_BY_TYPES_REQUEST: 'GET_LIST_COUPON_BY_TYPES_REQUEST',
    GET_LIST_COUPON_BY_TYPES_SUCCESS: 'GET_LIST_COUPON_BY_TYPES_SUCCESS',
    GET_LIST_COUPON_BY_TYPES_FAILURE: 'GET_LIST_COUPON_BY_TYPES_FAILURE',
    ADD_USER_COUPON_ITEM: 'ADD_USER_COUPON_ITEM',
    REMOVE_USER_COUPON_ITEM: 'REMOVE_USER_COUPON_ITEM',
    RESET_USER_COUPON_ITEM_LIST: 'RESET_USER_COUPON_ITEM_LIST',
    ADD_USER_COUPONS_REQUEST: 'ADD_USER_COUPONS_REQUEST',
    ADD_USER_COUPONS_SUCCESS: 'ADD_USER_COUPONS_SUCCESS',
    ADD_USER_COUPONS_FAILURE: 'ADD_USER_COUPONS_FAILURE',
    GET_LIST_COUPON_CODE_REQUEST: 'GET_LIST_BLACK_PEARL_CODE_REQUEST',
    GET_LIST_COUPON_CODE_SUCCESS: 'GET_LIST_BLACK_PEARL_CODE_SUCCESS',
    GET_LIST_COUPON_CODE_FAILURE: 'GET_LIST_BLACK_PEARL_CODE_FAILURE',
    UPDATE_COUPON_CODE_STATUS_REQUEST: 'UPDATE_BLACK_PEARL_CODE_STATUS_REQUEST',
    UPDATE_COUPON_CODE_STATUS_SUCCESS: 'UPDATE_BLACK_PEARL_CODE_STATUS_SUCCESS',
    UPDATE_COUPON_CODE_STATUS_FAILURE: 'UPDATE_BLACK_PEARL_CODE_STATUS_FAILURE',
    CREATE_COUPON_CODES_REQUEST: 'CREATE_BLACK_PEARL_CODES_REQUEST',
    CREATE_COUPON_CODES_SUCCESS: 'CREATE_BLACK_PEARL_CODES_SUCCESS',
    CREATE_COUPON_CODES_FAILURE: 'CREATE_BLACK_PEARL_CODES_FAILURE',
}

export default Types;
