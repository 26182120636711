import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { Stock } from 'src/core/models/management/stock.model';

export default class StockRepository extends BaseRepository {

    getListStock(): Promise<AxiosResponse<Stock[]>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_LIST_STOCK).getAll();
    }
}
