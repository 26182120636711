import { MDBBtn, MDBIcon, } from 'mdbreact'
import React from 'react'
import { ProductDeleteRequest } from 'src/core/models/coffee-core/product/product-request-params.model';
import { useDispatch, useSelector } from 'react-redux';
import * as ProductAction from 'src/core/reduxs/coffee-core/product/product.action'
import PickDeep from 'src/core/models/pick-deep.modal'
import ProductStateType from 'src/core/models/coffee-core/product/product-state.model'
import { toast } from 'react-hot-toast'
import { useIntl } from 'react-intl';
import Product from 'src/core/models/coffee-core/product/product.model';
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component';


type Props = {
    isOpen: boolean,
    productDetail: Product,
    onModalClosed: () => void
}
const ProductDeleteModalComponent = (props: Props): JSX.Element => {
    const { productDetail, isOpen, onModalClosed } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const deleteProduct: PickDeep<ProductStateType, 'deleteProduct'> = useSelector((state: any) => state.productReducer.deleteProduct)
    const onCloseModal = () => {
        onModalClosed()
    }

    const onSuccessDelete = () => {
        onCloseModal();
        toast.success(intl.formatMessage({ id: 'PRODUCT.DELETE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureDelete = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'PRODUCT.DELETE.TOAST_FAILURE' }), { duration: 3000 })
    }
    const handleDeleteCategory = () => {
        const params: ProductDeleteRequest = {
            data: {
                id: productDetail.id,
            },
            successCallback: onSuccessDelete,
            errorCallback: onFailureDelete
        }
        console.log(params)
        dispatch(ProductAction.actDeleteProduct(params))
    }
    return (
        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'PRODUCT.DELETE.TITLE' })}
            type='delete'
        >
            <div className='text-center my-3'>{`${intl.formatMessage({ id: 'PRODUCT.DELETE.DESCRIPTION' })} ${productDetail.name}`}</div>
            <div className='text-center mb-1-half'>
                <MDBBtn
                    className='mb-2'
                    outline
                    color="danger"
                    disabled={deleteProduct.isLoading}
                    onClick={onCloseModal}
                >
                    {intl.formatMessage({ id: 'PRODUCT.DELETE.LABEL_CANCEL_BUTTON' })}
                    <MDBIcon icon='times' className='ml-3' />
                </MDBBtn>
                <MDBBtn
                    className='mb-2 '
                    color="danger"
                    onClick={handleDeleteCategory}
                    disabled={deleteProduct.isLoading}
                >
                    {intl.formatMessage({ id: 'PRODUCT.DELETE.LABEL_CONFIRM_BUTTON' })}
                    <MDBIcon icon='check' className='ml-3' />
                </MDBBtn>
            </div>
        </CustomModal>
    )
}

export default ProductDeleteModalComponent
