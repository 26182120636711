import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBSpinner,
} from 'mdbreact';
import { DEFAULT_PAGE_SIZE, MaterialLevels } from 'src/core/utils/constants';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'src/features/pages/material-management/income/income.style.scss';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import SearchAndFilterRequest, { Filter } from 'src/core/models/search-and-filter-request.model';
import Pagination from 'src/shared/components/pagination/pagination.component';
import { currencyFormatter } from 'src/core/utils/common';
import { Material } from 'src/core/models/management/material.model';
import { actSearchMaterial } from 'src/core/reduxs/management/material/material.action';
import MaterialCreateModal from 'src/features/components/material/material-create-modal.component';
import MaterialUpdateModal from 'src/features/components/material/material-update-modal.component';

type DataTable = {
    id: number
    name: JSX.Element
    unit: string
    price: string
    level: string
    categoryName: string
    commands: JSX.Element
}

const columns = [
    {
        label: 'ID',
        field: 'id',
    },
    {
        label: 'Tên nguyên liệu',
        field: 'name',
    },
    {
        label: 'Đơn vị tính',
        field: 'unit',
    },
    {
        label: 'Đơn giá',
        field: 'price',
    },
    {
        label: 'Level',
        field: 'level',
    },
    {
        label: 'Loại',
        field: 'categoryName',
    },
    {
        label: '',
        field: 'commands',
    },
]

const MaterialContainer = (): JSX.Element => {
    const history = useHistory();

    const dispatch = useDispatch();
    const searchData = useSelector((state: any) => state.materialReducer.searchedMaterials.data);
    const materials: Material[] = searchData.items;
    const isLoading = useSelector((state: any) => state.materialReducer.searchedMaterials.isLoading);
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [queryParams, setQueryParams] = useQueryParams({
        keyword: StringParam,
    });
    const [page, setPage] = useQueryParam('page', NumberParam);
    const [isVisibleCreateModal, setIsVisibleCreateModal] = useState(false);
    const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false);
    const [selectedMaterial, setSelectedMaterial] = useState<number | undefined>(undefined);

    useEffect(() => {
        onPageChanged(page || 1);
    }, [queryParams]);

    useEffect(() => {
        renderDataRow(materials);
    }, [materials]);

    const onPageChanged = (activePage: number) => {
        setPage(activePage);
        const filters: Filter = {}
        if (queryParams.keyword) {
            filters['name'] = queryParams.keyword
        }
        const request: SearchAndFilterRequest = {
            pageIndex: activePage - 1,
            pageSize: DEFAULT_PAGE_SIZE,
            filters,
            sorts: ['id:asc']
        }
        dispatch(actSearchMaterial(request));
    }

    const changeQueryParams = (name: string, value: string | undefined) => {
        setQueryParams({
            ...queryParams,
            [name]: value ? value : undefined,
        });
        setPage(1);
    };

    const onClickUpdate = (material: Material) => {
        setSelectedMaterial(material.id);
        setIsVisibleUpdateModal(true);
    }

    const reset = () => {
        setQueryParams({}, 'push');
        onPageChanged(1);
    };

    const reload = () => {
        if (page) {
            onPageChanged(page);
        }
    };

    const renderDataRow = useCallback((materialList: Material[]) => {
        const data: DataTable[] = [];
        materialList?.forEach((item: Material, index: number) => {
            const row = {
                id: item.id,
                name: <div style={{maxWidth: 200}}>{item.name}</div>,
                unit: item.unit,
                price: currencyFormatter(item.price),
                level: MaterialLevels[item.levelId],
                categoryName: item.category?.name || '',
                commands: <div style={{width: 90}}>
                    <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => onClickUpdate(item)}>
                        <MDBIcon icon='edit' size='lg' className='text-info'/>
                    </MDBBtn>
                </div>
            };
            data.push(row);
        });
        setDataTable(data);
    }, []);

    const renderDataTable = useMemo(() => {
        if (isLoading) {
            return <MDBCardBody className="d-flex justify-content-center"><MDBSpinner small/></MDBCardBody>;
        }

        return (
            <MDBDataTable
                className='common-table'
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không tìm thấy nguyên liệu nào"
                data={{columns: columns, rows: dataTable}}
            />
        )
    }, [dataTable, isLoading]);

    return (
        <Fragment>
            <MDBContainer className='py-3' fluid>
                <div className='text-center'>
                    <h2 className='page-title'>Quản lý nguyên liệu</h2>
                    <MDBBtn flat rounded className='m-0' onClick={reset}>
                        <MDBIcon icon='undo' size='lg'/>
                    </MDBBtn>
                </div>
                <MDBRow className='pb-3'>

                    <MDBCol md='4'>
                        <MDBInput
                            label='Tìm kiếm'
                            onChange={(e: any) => changeQueryParams('keyword', e.target.value)}
                            containerClass='mt-0'
                        />
                    </MDBCol>

                    <MDBCol md='5'>
                    </MDBCol>

                    <MDBCol md='3' className='text-right'>
                        <MDBBtn
                            color="success"
                            onClick={() => setIsVisibleCreateModal(true)}
                        >Tạo nguyên liệu mới</MDBBtn>
                    </MDBCol>

                    <MDBCol md='12'>
                        <MDBCard>
                            <MDBCardBody>
                                {renderDataTable}
                                <Pagination
                                    activeIndex={page || 0}
                                    itemCount={searchData.total}
                                    pageSize={DEFAULT_PAGE_SIZE}
                                    onPageChange={onPageChanged}
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <MaterialCreateModal
                isVisible={isVisibleCreateModal}
                setIsVisible={setIsVisibleCreateModal}
                onSuccess={reload}
            />
            {
                selectedMaterial &&
                <MaterialUpdateModal
                    isVisible={isVisibleUpdateModal}
                    setIsVisible={setIsVisibleUpdateModal}
                    materialId={selectedMaterial}
                    onSuccess={reload}
                />
            }
        </Fragment>
    );
}

export default MaterialContainer;
