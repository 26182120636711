import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBRow, MDBSelect } from 'mdbreact';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { actGetListMaterial } from 'src/core/reduxs/management/material/material.action';
import { Material } from 'src/core/models/management/material.model';
import _ from 'lodash';
import { ImportItem } from 'src/core/models/management/import.model';
import { actAddImportDetailItem } from 'src/core/reduxs/management/import-detail/import-detail.action';
import { currencyFormatter, numberToString, roundPrice, stringToNumber } from 'src/core/utils/common';
import Select from 'react-select';

const ImportDetailCreateForm = (): JSX.Element => {
    const dispatch = useDispatch()
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const materials: Material[] = useSelector((state: any) => state.materialReducer.materials.data);
    const [selectedMaterial, setSelectedMaterial] = useState<number | undefined>();
    const [unit, setUnit] = useState<string | undefined>();
    const [unitPrice, setUnitPrice] = useState<string | undefined>();
    const [price, setPrice] = useState<string | undefined>();

    useEffect(() => {
        dispatch(actGetListMaterial());
    }, [])

    useEffect(() => {
        if (selectedMaterial) {
            const material = materials.find(m => m.id === selectedMaterial);
            if (!material) {
                setUnit(undefined)
                return
            }
            setUnit(material.unit)
            setValue('unitPrice', material.price)
            setUnitPrice(numberToString(material.price))
        }
    }, [selectedMaterial]);

    const materialOptions = useMemo(() => {
        if (!_.isEmpty(materials)) {
            return materials.map((m) => ({
                label: m.name,
                value: m.id
            }));
        }
        return [];
    }, [materials]);

    const calculatePrice = () => {
        const values = getValues();
        let price = 0;
        if (values.quantity && values.unitPrice) {
            price = roundPrice(values.quantity * values.unitPrice);
        }
        setPrice(currencyFormatter(price))
    }

    const onSubmit = (data: any) => {
        const materialId = data.materialId;
        const importDetail: ImportItem = {
            material: materials.find(m => m.id === materialId) as Material,
            quantity: data.quantity,
            unitPrice: data.unitPrice,
            price: roundPrice(data.quantity * data.unitPrice)
        }
        dispatch(actAddImportDetailItem(importDetail))
    }

    return (
        <MDBCard className='container-fluid'>
            <MDBCardBody>
                <p className="font-weight-normal mt-3">TẠO CHI TIẾT PHIẾU NHẬP</p>
                <MDBRow className='pb-3'>
                    <MDBCol md="4">
                        <div className="pt-2">Nguyên liệu</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <Select
                            {...register('materialId', {
                                required: 'Nguyên liệu là bắt buộc'
                            })}
                            options={materialOptions}
                            onChange={(newValue) => {
                                if (newValue) {
                                    setValue('materialId', newValue.value);
                                    setSelectedMaterial(newValue.value)
                                }
                            }}
                            placeholder='(chọn nguyên liệu)'
                            className='max-width-400 my-1'
                        />
                        {errors.materialId && <ErrorMessage>{errors.materialId.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol md="4">
                        <div className="pb-2 mt-2">Đơn vị</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <div className="pb-2 mt-2">{unit}</div>
                    </MDBCol>
                    <MDBCol md="4">
                        <div className="pt-2">Đơn giá</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBInput
                            {...register('unitPrice', {
                                required: 'Đơn giá là bắt buộc'
                            })}
                            placeholder='Nhập đơn giá'
                            onChange={(e: any) => {
                                const numberValue = stringToNumber(e.target.value);
                                setValue('unitPrice', numberValue)
                                const formattedValue = numberToString(numberValue);
                                setUnitPrice(formattedValue);
                                calculatePrice();
                            }}
                            containerClass='my-0'
                            value={unitPrice}
                        />
                        {errors.unitPrice && <ErrorMessage>{errors.unitPrice.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol md="4">
                        <div className="pt-2">Số lượng</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBInput
                            {...register('quantity', {
                                required: 'Số lượng là bắt buộc'
                            })}
                            type='number'
                            placeholder='Nhập số lượng'
                            onChange={(e: any) => {
                                setValue('quantity', Number(e.target.value))
                                calculatePrice();
                            }}
                            containerClass='my-0'
                        />
                        {errors.quantity && <ErrorMessage>{errors.quantity.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol md="4">
                        <div className="pb-2">Giá</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <div className="pb-2 text-primary font-weight-bold">{price}</div>
                    </MDBCol>
                    <MDBCol size='12'>
                        <div className='text-center'>
                            <MDBBtn color="primary" onClick={handleSubmit(onSubmit)}>Tạo mục nhập</MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    )
}

export default React.memo(ImportDetailCreateForm)
