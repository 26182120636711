export const GET_SIZES_REQUEST = 'GET_SIZES_REQUEST';
export const GET_SIZES_SUCCESS = 'GET_SIZES_SUCCESS';
export const GET_SIZES_FAILURE = 'GET_SIZES_FAILURE';

export const GET_SIZE_REQUEST = 'GET_SIZE_REQUEST';
export const GET_SIZE_SUCCESS = 'GET_SIZE_SUCCESS';
export const GET_SIZE_FAILURE = 'GET_SIZE_FAILURE';
export const RESET_SIZE = 'RESET_SIZE';

export const UPDATE_SIZE_REQUEST = 'UPDATE_SIZE_REQUEST';
export const UPDATE_SIZE_SUCCESS = 'UPDATE_SIZE_SUCCESS';
export const UPDATE_SIZE_FAILURE = 'UPDATE_SIZE_FAILURE';

export const CREATE_SIZE_REQUEST = 'CREATE_SIZE_REQUEST';
export const CREATE_SIZE_SUCCESS = 'CREATE_SIZE_SUCCESS';
export const CREATE_SIZE_FAILURE = 'CREATE_SIZE_FAILURE';

export const DELETE_SIZE_REQUEST = 'DELETE_SIZE_REQUEST';
export const DELETE_SIZE_SUCCESS = 'DELETE_SIZE_SUCCESS';
export const DELETE_SIZE_FAILURE = 'DELETE_SIZE_FAILURE';
