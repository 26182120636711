import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import CouponState from 'src/core/reduxs/coffee-core/coupon/coupon.state';
import Types from 'src/core/reduxs/coffee-core/coupon/coupon.type';
import { User } from 'src/core/models/coffee-core/user.model';

const initialState: CouponState = {
    coupons: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    userCouponItems: {
        data: [],
    },
    addUserCoupons: {
        data: {},
        error: null,
        isLoading: false
    },
    couponCodes: {
        data: [] ,
        error: null,
        isLoading: false,
    }
};

const couponReducer = createReducer(initialState, {
    [Types.GET_LIST_COUPON_BY_TYPES_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            coupons: {
                ...state.coupons,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_COUPON_BY_TYPES_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            coupons: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_COUPON_BY_TYPES_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            coupons: {
                ...state.coupons,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.ADD_USER_COUPON_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const user: User = action.payload;
        const data = [...state.userCouponItems.data, user];

        return {
            ...state,
            userCouponItems: {
                data
            }
        };
    },
    [Types.REMOVE_USER_COUPON_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const index = action.payload;
        const data = [...state.userCouponItems.data]
        data.splice(index, 1);

        return {
            ...state,
            userCouponItems: {
                data
            }
        };
    },
    [Types.RESET_USER_COUPON_ITEM_LIST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            userCouponItems: {
                data: []
            }
        };
    },
    [Types.ADD_USER_COUPONS_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            addUserCoupons: {
                ...state.addUserCoupons,
                isLoading: true,
            }
        };
    },
    [Types.ADD_USER_COUPONS_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            addUserCoupons: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.ADD_USER_COUPONS_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            addUserCoupons: {
                ...state.addUserCoupons,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.GET_LIST_COUPON_CODE_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            couponCodes: {
                ...state.couponCodes,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_COUPON_CODE_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            couponCodes: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_COUPON_CODE_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            couponCodes: {
                ...state.couponCodes,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default couponReducer;
