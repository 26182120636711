import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import PagingRequest from 'src/core/models/paging-request.model';
import ResponseCustom from 'src/core/models/response-custom.model';
import { SizeCreateRequest, SizeUpdateRequest, SizeDeleteRequest } from 'src/core/models/coffee-core/size/size-request-params.model';
import Size from 'src/core/models/coffee-core/size/size.model';

export default class SizeRepository extends BaseRepository {

    getListSize(params: PagingRequest): Promise<AxiosResponse<Size[]>> {
        const request = {
            ...params,
            itemsPerPage: CONST.DEFAULT_PAGE_SIZE,
            sortBy: 'display_order',
            sortType: 'DESC'
        };
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_SIZE).query<Size[]>(request, );
    }

    createSize(params: SizeCreateRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.CREATE_SIZE).create<ResponseCustom>(params.data)
    }

    updateSize(params: SizeUpdateRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.UPDATE_SIZE).create<ResponseCustom>(params.data)
    }

    deleteSize(params: SizeDeleteRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.DELETE_SIZE).create<ResponseCustom>(params.data )
    }
}
