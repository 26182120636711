import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { MCategory } from 'src/core/models/management/mcategory.model';
import { CreateIncomeRequest, Income } from 'src/core/models/management/income.model';
import ResponseCustom from 'src/core/models/response-custom.model';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';

export default class IncomeRepository extends BaseRepository {

    getListCategory(): Promise<AxiosResponse<MCategory[]>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_LIST_INCOME_CATEGORY).getAll();
    }

    createIncome(request: CreateIncomeRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.CREATE_INCOME).create<ResponseCustom>(request)
    }

    searchIncome(request: SearchAndFilterRequest): Promise<AxiosResponse<Income>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.SEARCH_INCOME).search<Income>(request)
    }

    getIncomeDetails(code: string): Promise<AxiosResponse<Income>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_INCOME_DETAILS).query<Income>({code})
    }

    deleteIncome(code: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.DELETE_INCOME).delete<ResponseCustom>({code})
    }
}
