export const GET_LIST_CITY_REQUEST = 'GET_LIST_CITY_REQUEST';
export const GET_LIST_CITY_SUCCESS = 'GET_LIST_CITY_SUCCESS';
export const GET_LIST_CITY_FAILURE = 'GET_LIST_CITY_FAILURE';

export const PERFORM_LOGIN_REQUEST = 'PERFORM_LOGIN_REQUEST';
export const PERFORM_LOGIN_SUCCESS = 'PERFORM_LOGIN_SUCCESS';
export const PERFORM_LOGIN_FAILURE = 'PERFORM_LOGIN_FAILURE';

export const GET_USER_INFO_REQUEST = 'GET_USER_INFO_REQUEST';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILURE = 'GET_USER_INFO_FAILURE';
export const RESET_LOGIN_STATE = 'RESET_LOGIN_STATE';
