import Repositories, {RepositoryName} from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/management/supplier/supplier.type';

const supplierRepository = Repositories[RepositoryName.SUPPLIER];

export const actGetListSupplier = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_SUPPLIER_REQUEST, Types.GET_LIST_SUPPLIER_SUCCESS, Types.GET_LIST_SUPPLIER_FAILURE],
        callAPI: () => supplierRepository.getListSupplier()
    })
};
