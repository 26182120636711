import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import ImportState from 'src/core/reduxs/management/import/import.state';
import Types from 'src/core/reduxs/management/import/import.type';

const initialState: ImportState = {
    createImport: {
        data: {},
        error: null,
        isLoading: false
    },
    searchedImports: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    importDetails: {
        data: undefined,
        error: null,
        isLoading: false
    },
    deleteImport: {
        data: undefined,
        error: undefined,
        isLoading: false
    },
    updateOutcomePaidStatus: {
        data: undefined,
        error: null,
        isLoading: false
    },
    updateImport: {
        data: {},
        error: null,
        isLoading: false
    },
};

const importReducer = createReducer(initialState, {
    [Types.CREATE_IMPORT_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createImport: {
                ...state.createImport,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_IMPORT_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createImport: {
                ...state.createImport,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_IMPORT_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createImport: {
                ...state.createImport,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.SEARCH_IMPORT_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedImports: {
                ...state.searchedImports,
                isLoading: true,
            }
        };
    },
    [Types.SEARCH_IMPORT_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedImports: {
                isLoading: false,
                error: null,
                data: action.payload.data
            }
        };
    },
    [Types.SEARCH_IMPORT_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedImports: {
                ...state.searchedImports,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.GET_IMPORT_DETAILS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            importDetails: {
                ...state.importDetails,
                isLoading: true,
            }
        };
    },
    [Types.GET_IMPORT_DETAILS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            importDetails: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_IMPORT_DETAILS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            importDetails: {
                ...state.importDetails,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.DELETE_IMPORT_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteImport: {
                ...state.deleteImport,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_IMPORT_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteImport: {
                ...state.deleteImport,
                isLoading: false,
                error: undefined,
                data: action.payload
            }
        };
    },
    [Types.DELETE_IMPORT_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteImport: {
                ...state.deleteImport,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_OUTCOME_PAID_STATUS_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateOutcomePaidStatus: {
                ...state.updateOutcomePaidStatus,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_OUTCOME_PAID_STATUS_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateOutcomePaidStatus: {
                ...state.updateOutcomePaidStatus,
                isLoading: false,
                error: undefined,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_OUTCOME_PAID_STATUS_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateOutcomePaidStatus: {
                ...state.updateOutcomePaidStatus,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_IMPORT_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateImport: {
                ...state.updateImport,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_IMPORT_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateImport: {
                ...state.updateImport,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_IMPORT_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateImport: {
                ...state.updateImport,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default importReducer;
