import _ from 'lodash';
import { MDBBadge, MDBBox, MDBCard, MDBCardBody, MDBCardTitle, MDBDataTable, MDBIcon, MDBRow, MDBSpinner } from 'mdbreact';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PagingRequest from 'src/core/models/paging-request.model';
import PickDeep from 'src/core/models/pick-deep.modal';
import SizeGroupStateType from 'src/core/models/coffee-core/size-group/size-group-state.model';
import SizeGroup from 'src/core/models/coffee-core/size-group/size-group.model';
import Size from 'src/core/models/coffee-core/size/size.model';
import * as SizeGroupAction from 'src/core/reduxs/coffee-core/size-group/size-group.action'
import SizeGroupUpdateModalComponent from 'src/features/components/size-group/size-group-update-modal.component';
import SizeGroupDeleteModalComponent from 'src/features/components/size-group/size-group-delete-modal.compoent';

type SizeGroupDataTable = {
    code: string;
    name: string;
    sizes: JSX.Element;
    edit: JSX.Element;
    [rest: string]: any;
}



const initSizeGroupDataTable: SizeGroupDataTable[] = [];
const initSizeGroupDetail = new SizeGroup()
const SizeGroupListComponent = (): JSX.Element => {
    const intl = useIntl();
    const columns = [
        {
            label: intl.formatMessage({ id: 'SIZE_GROUP.LIST.LABEL_CODE' }),
            field: 'code',
        },
        {
            label: intl.formatMessage({ id: 'SIZE_GROUP.LIST.LABEL_NAME' }),
            field: 'name',
        },
        {
            label: intl.formatMessage({ id: 'SIZE_GROUP.LIST.LABEL_SIZES' }),
            field: 'sizes',

        },
        {
            label: intl.formatMessage({ id: 'SIZE_GROUP.LIST.LABEL_EDIT' }),
            field: 'edit',
        },
    ]
    const dispatch = useDispatch();
    const [dataTable, setDataTable] = useState(initSizeGroupDataTable);
    const sizeGroups: PickDeep<SizeGroupStateType, 'sizeGroups'> = useSelector((state: any) => state.sizeGroupReducer.sizeGroups)

    const [sizeGroupDetail, setSizeGroupDetail] = useState<SizeGroup>(initSizeGroupDetail)
    const [isOpenSizeGroupUpdateModal, setIsOpenSizeGroupUpdateModal] = useState(false);
    const [isOpenSizeGroupDeleteModal, setIsOpenSizeGroupDeleteModal] = useState(false);
    const prepareDataRow = useCallback((listSizeGroup: SizeGroup[]) => {
        if (_.isEmpty(listSizeGroup)) return;

        const data: SizeGroupDataTable[] = [];
        listSizeGroup.forEach((item: SizeGroup) => {
            const row = {
                id: item.id,
                code: item.code || '',
                name: item.name || '',
                sizes: (
                    <>
                        <MDBRow className='size-list-row'>
                            {
                                item.sizes && item.sizes.length > 0 &&
                                item.sizes.map((size: Size) => {
                                    return (
                                        <MDBBadge className='size-badge' key={size.id} >{size.name}</MDBBadge>
                                    )
                                })
                            }
                            {
                                item.sizes && item.sizes.length === 0 && <span className='ml-2'>{intl.formatMessage({ id: 'SIZE_GROUP.LIST.LABEL_NOT_FOUND_SIZE_IN_GROUP' })}</span>
                            }
                            {
                                !item.sizes && <span className='ml-2'>{intl.formatMessage({ id: 'SIZE_GROUP.LIST.LABEL_NOT_FOUND_SIZE_IN_GROUP' })}</span>
                            }
                        </MDBRow>
                    </>
                ),
                edit: (
                    <>
                        <MDBRow className="size-list-item">
                            <button className="size-list-item-btn edit" onClick={() => { openUpdateSizeGroupModal(item) }}>
                                <MDBIcon icon="edit" className="size-list-item-icon edit" />
                            </button>
                            <button className="size-list-item-btn delete" onClick={() => { openDeleteSizeGroupModal(item) }}>
                                <MDBIcon icon="trash-alt" className="size-list-item-icon delete" />
                            </button>
                        </MDBRow>
                    </>
                ),
            }
            data.push(row)
        })
        setDataTable(data)
    }, [])

    const SizeGroupDataTable = useMemo(() => {
        return (
            <MDBDataTable
                responsive
                striped
                borderless
                searching={true}
                sortable={false}
                noBottomColumns={true}
                paging={true}
                entries={5}
                pagesAmount={10}
                paginationLabel={[intl.formatMessage({ id: 'SIZE_GROUP.LIST.LABEL_PREVIOUS_PAGE' }), intl.formatMessage({ id: 'SIZE_GROUP.LIST.LABEL_NEXT_PAGE' })]}
                displayEntries={false}
                info={false}
                noRecordsFoundLabel={intl.formatMessage({ id: 'SIZE_GROUP.LIST.LABEL_NOT_FOUND_SIZE_GROUP' })}
                data={{ columns: columns, rows: dataTable }
                }
            />
        )
    }, [dataTable])

    const openUpdateSizeGroupModal = (item: SizeGroup) => {
        if (item) {
            setSizeGroupDetail(item);
            setIsOpenSizeGroupUpdateModal(true);
        }
    }
    const onModalUpdateClosed = () => {
        setIsOpenSizeGroupUpdateModal(false);
        setSizeGroupDetail(initSizeGroupDetail);
    }

    const openDeleteSizeGroupModal = (item: SizeGroup) => {
        if (item) {
            setSizeGroupDetail(item);
            setIsOpenSizeGroupDeleteModal(true);
        }
    }
    const onModalDeleteClosed = () => {
        setIsOpenSizeGroupDeleteModal(false);
        setSizeGroupDetail(initSizeGroupDetail);
    }

    useEffect(() => {
        const params: PagingRequest = {
            pageId: 0,
        }
        dispatch(SizeGroupAction.actGetListSizeGroup(params))
    }, [dispatch])

    useEffect(() => {
        if (sizeGroups.data) {
            prepareDataRow(sizeGroups.data)
        }
    }, [sizeGroups.data, prepareDataRow])

    return (
        <MDBCard className='size-group-list-container'>
            <MDBCardTitle className='size-group-list-title'>
                <MDBBox className='size-group-list-title-text'>
                    {intl.formatMessage({ id: 'SIZE_GROUP.LIST.TITLE' })}
                </MDBBox>
            </MDBCardTitle>
            <MDBCardBody className='m-0 p-0'>
                {sizeGroups.isLoading && <MDBRow className="d-flex justify-content-center mb-5"><MDBSpinner small /></MDBRow>}
                {!sizeGroups.isLoading && sizeGroups.data && SizeGroupDataTable}
            </MDBCardBody>
            <SizeGroupUpdateModalComponent isOpen={isOpenSizeGroupUpdateModal} sizeGroupDetail={sizeGroupDetail} onModalClosed={onModalUpdateClosed} />
            <SizeGroupDeleteModalComponent isOpen={isOpenSizeGroupDeleteModal} sizeGroupDetail={sizeGroupDetail} onModalClosed={onModalDeleteClosed} />
        </MDBCard>
    )
}

export default SizeGroupListComponent
