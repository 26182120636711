import React, { Component } from 'react';
import {
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBIcon,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBBadge,
    MDBContainer,
    MDBCol,
    MDBRow
} from 'mdbreact';
import * as COMMON from 'src/core/utils/common';
import * as CONST from 'src/core/utils/constants';
import history from 'src/core/utils/history';
import { connect } from 'react-redux';
import * as FirebaseAction from 'src/core/reduxs/coffee-core/firebase/firebase.action';
import * as CommonAction from 'src/core/reduxs/common/common.action';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import NotificationCustom from 'src/core/models/notification-custom.model';

type Props = {
    toggle: boolean,
    className: string,
    onSideNavToggleClick: () => void,
    unsubscribeAllTopics: (deviceToken: string) => (dispatch: any) => void,
    clickNotification: (orderId: string) => (dispatch: any) => void
    resetLoginState: () => (dispatch: any) => void
    notification: {
        [key: string]: {
            data: NotificationCustom,
            isRead: boolean
        }
    }
}

class TopNavigation extends Component<Props> {
    constructor(props: Props) {
        super(props);
    }

    handleLogout = () => {
        const fcmToken = localStorage.getItem(CONST.LocalStorage.FCM_TOKEN);
        if (fcmToken) {
            this.props.unsubscribeAllTopics(fcmToken);
        }
        this.props.resetLoginState();
        COMMON.clearLocalStorage();
        history.push(CONST.AppURI.LOGIN);
    }

    clickNotification = (orderId: string) => {
        this.props.clickNotification(orderId);
    }

    renderNotificationItem = () => {
        const items: JSX.Element[] = [];

        _.forEachRight(_.keys(this.props.notification), (value, index) => {
            const notification = this.props.notification[value];
            items.push(
                <div key={value}>
                    <MDBDropdownItem active={!notification.isRead} onClick={() => this.clickNotification(value)}>
                        <MDBRow>
                            <MDBCol md='1' className='d-flex align-items-center'>
                                <MDBIcon icon='coffee' />
                            </MDBCol>
                            <MDBCol md='11'>
                                <strong><h5>{notification.data.title}</h5></strong>
                                <h6>{notification.data.body}</h6>
                            </MDBCol>
                        </MDBRow>
                    </MDBDropdownItem>
                    {index !== 0 && <hr className='mt-0 mb-0' />}
                </div>
            )
        })
        return items;
    }

    render(): JSX.Element {
        const navStyle = {
            paddingLeft: this.props.toggle ? '16px' : '240px',
            transition: 'padding-left .3s'
        };
        return (
            <MDBNavbar
                className='flexible-MDBNavbar'
                light
                expand='md'
                scrolling
                fixed='top'
                style={{ zIndex: 99, height: '5rem' }}
            >
                <div
                    onClick={this.props.onSideNavToggleClick}
                    key='sideNavToggleA'
                    style={{
                        lineHeight: '32px',
                        marginLeft: '1em',
                        verticalAlign: 'middle',
                        cursor: 'pointer'
                    }}
                >
                    <MDBIcon icon='bars' color='white' size='lg' />
                </div>

                <MDBNavbarBrand href='/' style={navStyle}>
                </MDBNavbarBrand>
                <MDBContainer className='justify-content-center' style={{ fontSize: '1.5rem' }}>
                    <strong>Trà Sữa Crush</strong>
                </MDBContainer>
                <MDBNavbarNav expand='sm' right style={{ flexDirection: 'row' }}>
                    <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                            <MDBBadge color='red' className='mr-2'>
                                {_.size(this.props.notification)}
                            </MDBBadge>
                            <MDBIcon icon='bell' />{' '}
                            <span className='d-none d-md-inline'>Thông báo</span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu right style={{ minWidth: '400px', maxHeight: '700px', overflow: 'scroll' }}>
                            {this.renderNotificationItem()}
                        </MDBDropdownMenu>
                    </MDBDropdown>
                    <MDBDropdown>
                        <MDBDropdownToggle nav caret>
                            <MDBIcon icon='user' />{' '}
                            <span className='d-none d-md-inline'>Tài khoản</span>
                        </MDBDropdownToggle>
                        <MDBDropdownMenu right style={{ minWidth: '200px' }}>
                            <MDBDropdownItem onClick={this.handleLogout}>Đăng xuất</MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavbarNav>
            </MDBNavbar>
        );
    }
}

const mapStateToProps = (state: any) => ({
    notification: state.firebaseReducer.notification
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    unsubscribeAllTopics: FirebaseAction.actUnsubscribeAllTopics,
    clickNotification: FirebaseAction.actClickNotification,
    resetLoginState: CommonAction.actResetLoginState
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation);
