import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { ApiURI } from 'src/core/utils/constants';
import { Tenant } from 'src/core/models/tenant.model';

export default class TenantRepository extends BaseRepository {
    getListTenant(): Promise<AxiosResponse<Tenant[]>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/tenant/getListTenant`).getAll();
    }
}
