import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import ExportState from 'src/core/reduxs/management/export/export.state';
import Types from 'src/core/reduxs/management/export/export.type';

const initialState: ExportState = {
    createExport: {
        data: {},
        error: null,
        isLoading: false
    },
    searchedExports: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    exportDetails: {
        data: undefined,
        error: null,
        isLoading: false
    },
    deleteExport: {
        data: undefined,
        error: undefined,
        isLoading: false
    },
    updateExport: {
        data: {},
        error: null,
        isLoading: false
    },
};

const exportReducer = createReducer(initialState, {
    [Types.CREATE_EXPORT_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createExport: {
                ...state.createExport,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_EXPORT_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createExport: {
                ...state.createExport,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_EXPORT_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createExport: {
                ...state.createExport,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.SEARCH_EXPORT_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedExports: {
                ...state.searchedExports,
                isLoading: true,
            }
        };
    },
    [Types.SEARCH_EXPORT_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedExports: {
                isLoading: false,
                error: null,
                data: action.payload.data
            }
        };
    },
    [Types.SEARCH_EXPORT_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedExports: {
                ...state.searchedExports,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.GET_EXPORT_DETAILS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            exportDetails: {
                ...state.exportDetails,
                isLoading: true,
            }
        };
    },
    [Types.GET_EXPORT_DETAILS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            exportDetails: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_EXPORT_DETAILS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            exportDetails: {
                ...state.exportDetails,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.DELETE_EXPORT_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteExport: {
                ...state.deleteExport,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_EXPORT_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteExport: {
                ...state.deleteExport,
                isLoading: false,
                error: undefined,
                data: action.payload
            }
        };
    },
    [Types.DELETE_EXPORT_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteExport: {
                ...state.deleteExport,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_EXPORT_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateExport: {
                ...state.updateExport,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_EXPORT_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateExport: {
                ...state.updateExport,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_EXPORT_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateExport: {
                ...state.updateExport,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default exportReducer;
