import _ from 'lodash';
import { MDBBtn, MDBCard, MDBCardBody, MDBDataTable, MDBIcon } from 'mdbreact';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ExportItem } from 'src/core/models/management/export.model';
import { currencyFormatter } from 'src/core/utils/common';
import ConfirmModal from 'src/shared/components/confirm-modal/confirm-modal.component';
import { actRemoveExportDetailItem } from 'src/core/reduxs/management/export-detail/export-detail.action';

type DataTable = {
    no: number;
    materialName: string;
    unitPrice: string;
    price: string;
    [rest: string]: any;
}

const columns = [
    {
        label: 'STT',
        field: 'no',
    },
    {
        label: 'Nguyên liệu',
        field: 'materialName',
    },
    {
        label: 'Số lượng',
        field: 'quantity',
    },
    {
        label: 'Đơn giá',
        field: 'unitPrice',
    },
    {
        label: 'Giá',
        field: 'price',
    },
    {
        label: '',
        field: 'command',
    }
]

const ExportDetailCreationListComponent = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [totalPrice, setTotalPrice] = useState<number | 0>(0);
    const exportDetails: ExportItem[] = useSelector((state: any) => state.exportDetailReducer.createExportDetails.items);
    const [isVisibleRemoveModal, setIsVisibleRemoveModal] = useState(false);
    const [selectedExportDetailIndex, setSelectedExportDetailIndex] = useState<number | undefined>();

    const prepareDataRow = useCallback((exportDetailList: ExportItem[]) => {
        const data: DataTable[] = [];
        let totalPrice = 0;
        exportDetailList.forEach((item, index) => {
            const row = {
                no: index + 1,
                materialName: item.material.name || '',
                quantity: item.quantity,
                unitPrice: currencyFormatter(item.unitPrice),
                price: currencyFormatter(item.price),
                command: <MDBBtn flat rounded color="danger" className="px-3 py-2 my-0" onClick={() => onClickRemove(index)}>
                    <MDBIcon size='lg' icon="trash-alt" className='red-text'/>
                </MDBBtn>
            }
            data.push(row)
            totalPrice += item.price
        })
        setDataTable(data)
        setTotalPrice(totalPrice)
    }, [])

    const exportDetailDataTable = useMemo(() => {
        return (
            <Fragment>
                <MDBDataTable
                    className='common-table'
                    searching={false}
                    responsive
                    striped
                    borderless
                    sortable={false}
                    noBottomColumns={true}
                    paging={false}
                    displayEntries={false}
                    info={false}
                    noRecordsFoundLabel='Chưa có mục xuất nào'
                    data={{columns: columns, rows: dataTable}
                    }
                />
                <div>Tổng: <span className='font-weight-bold text-primary'>{currencyFormatter(totalPrice)}</span></div>
            </Fragment>
        )
    }, [dataTable])

    useEffect(() => {
        if (exportDetails) {
            prepareDataRow(exportDetails)
        }
    }, [exportDetails, prepareDataRow])

    const onClickRemove = (index: number) => {
        setSelectedExportDetailIndex(index);
        setIsVisibleRemoveModal(true);
    }

    const removeExportDetail = () => {
        if (selectedExportDetailIndex !== undefined) {
            dispatch(actRemoveExportDetailItem(selectedExportDetailIndex));
            setIsVisibleRemoveModal(false);
        }
    }

    return (
        <Fragment>
            <MDBCard className='container-fluid'>
                <MDBCardBody>
                    <p className="font-weight-normal mt-3">DANH SÁCH MỤC XUẤT</p>
                    {exportDetailDataTable}
                </MDBCardBody>
            </MDBCard>
            <ConfirmModal
                isVisible={isVisibleRemoveModal}
                setIsVisible={setIsVisibleRemoveModal}
                title='Xoá chi tiết phiếu xuất'
                message='Bạn có chắc muốn xoá chi tiết phiếu xuất này?'
                color='danger'
                onClickConfirm={removeExportDetail}
            />
        </Fragment>
    )
}

export default ExportDetailCreationListComponent
