import React, { useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { MCategory } from 'src/core/models/management/mcategory.model';
import _ from 'lodash';
import {
    actGetListMaterialCategory,
    actGetMaterial,
    actResetMaterial, actUpdateMaterial
} from 'src/core/reduxs/management/material/material.action';
import Select from 'react-select';
import { MaterialLevels } from 'src/core/utils/constants';
import { numberToString, stringToNumber } from 'src/core/utils/common';
import { Material, UpdateMaterialRequest } from 'src/core/models/management/material.model';
import { SelectOption } from 'src/core/models/select-option.model';

type Props = {
    isVisible: boolean,
    setIsVisible: (value: boolean) => void,
    materialId: number,
    onSuccess: () => void,
}

const MaterialUpdateModal = (props: Props): JSX.Element => {

    const dispatch = useDispatch();

    const materialSelector = useSelector((state: any) => state.materialReducer.material);
    const material: Material = materialSelector.data;
    const categories: MCategory[] = useSelector((state: any) => state.materialReducer.materialCategories.data);
    const creating = useSelector((state: any) => state.importReducer.updateImport.isLoading);
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const [price, setPrice] = useState<string | undefined>();
    const [selectedCategory, setSelectedCategory] = useState<SelectOption | undefined>(undefined)
    const [selectedLevel, setSelectedLevel] = useState<SelectOption | undefined>(undefined)

    useEffect(() => {
        if (props.isVisible && props.materialId) {
            dispatch(actGetMaterial(props.materialId));
            return;
        }
        dispatch(actResetMaterial())
    }, [props.isVisible, props.materialId]);

    useEffect(() => {
        dispatch(actGetListMaterialCategory());
    }, [])

    useEffect(() => {
        if (material) {
            // patch value

            setValue('name', material.name)
            setValue('price', material.price)
            const formattedValue = numberToString(material.price);
            setPrice(formattedValue);
            const category = categoryOptions.find(c => c.value === material.category?.id)
            if (category) {
                setValue('categoryId', material.category?.id)
                setSelectedCategory(category)
            }
            const level = levelOptions.find(l => Number(l.value) === material.levelId)
            if (level) {
                setValue('levelId', material.levelId)
                setSelectedLevel(level)
            }
            setValue('unit', material.unit)
        }
    }, [material]);

    const categoryOptions = useMemo(() => {
        if (!_.isEmpty(categories)) {
            return categories.map((c) => ({
                label: c.name,
                value: c.id
            }));
        }
        return [];
    }, [categories]);

    const levelOptions = useMemo(() => {
        return Object.keys(MaterialLevels).map(key => ({
            label: MaterialLevels[key],
            value: key
        }))
    }, []);

    const onSubmit = (data: any) => {
        const request: UpdateMaterialRequest = {
            id: props.materialId,
            name: data.name,
            price: data.price,
            categoryId: data.categoryId,
            levelId: data.levelId,
            unit: data.unit
        }
        dispatch(actUpdateMaterial(request, onUpdateSuccess))
    }

    const onUpdateSuccess = () => {
        props.onSuccess()
        props.setIsVisible(false)
    }

    return (
        <MDBModal
            isOpen={props.isVisible}
            fullHeight={false}
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll
            size='lg'
        >
            <MDBModalHeader>Cập nhật nguyên liệu</MDBModalHeader>
            {
                !_.isNil(material) &&
                <MDBModalBody>
                    <MDBRow>
                        <MDBCol md="3">
                            <div className="pt-2">Tên nguyên liệu</div>
                        </MDBCol>
                        <MDBCol md="9">
                            <MDBInput
                                {...register('name', {
                                    required: 'Tên nguyên liệu là bắt buộc',
                                    maxLength: {
                                        value: 255,
                                        message: 'Tên nguyên liệu không được nhiều hơn 255 ký tự',
                                    }
                                })}
                                placeholder='Tên nguyên liệu'
                                onChange={(e: any) => {
                                    setValue('name', e.target.value)
                                }}
                                valueDefault={material.name}
                                containerClass='my-0 max-width-400'
                            />
                            {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}
                        </MDBCol>
                        <MDBCol md="3">
                            <div className="pt-2">Đơn giá</div>
                        </MDBCol>
                        <MDBCol md="9">
                            <MDBInput
                                {...register('price', {
                                    required: 'Đơn giá là bắt buộc',
                                })}
                                placeholder='Nhập đơn giá'
                                onChange={(e: any) => {
                                    const inputValue = e.target.value;
                                    if (inputValue.length < 3) {
                                        setPrice(inputValue);
                                        return
                                    }
                                    const numberValue = stringToNumber(e.target.value);
                                    setValue('price', numberValue)
                                    const formattedValue = numberToString(numberValue);
                                    setPrice(formattedValue);
                                }}
                                value={price}
                                containerClass='my-0'
                            />
                            {errors.price && <ErrorMessage>{errors.price.message}</ErrorMessage>}
                        </MDBCol>
                        <MDBCol md="3">
                            <div className="pt-2">Loại nguyên liệu</div>
                        </MDBCol>
                        <MDBCol md="9">
                            <Select
                                {...register('categoryId', {
                                    required: 'Loại nguyên liệu là bắt buộc'
                                })}
                                options={categoryOptions}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        setValue('categoryId', newValue.value);
                                        setSelectedCategory(newValue)
                                    }
                                }}
                                value={selectedCategory}
                                placeholder='(chọn loại nguyên liệu)'
                                className='max-width-400 my-1'
                            />
                            {errors.categoryId && <ErrorMessage>{errors.categoryId.message}</ErrorMessage>}
                        </MDBCol>
                        <MDBCol md="3" className='flex-row'>
                            <div className="pt-2">Level</div>
                        </MDBCol>
                        <MDBCol md="9">
                            <Select
                                {...register('levelId', {
                                    required: 'Level là bắt buộc'
                                })}
                                options={levelOptions}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        setValue('levelId', newValue.value);
                                        setSelectedLevel(newValue)
                                    }
                                }}
                                value={selectedLevel}
                                placeholder='(chọn level)'
                                className='max-width-400 my-1'
                            />
                            {errors.level && <ErrorMessage>{errors.level.message}</ErrorMessage>}
                        </MDBCol>
                        <MDBCol md="3">
                            <div className="pt-2">Đơn vị tính</div>
                        </MDBCol>
                        <MDBCol md="9">
                            <MDBInput
                                {...register('unit', {
                                    required: 'Đơn vị tính là bắt buộc',
                                    maxLength: {
                                        value: 255,
                                        message: 'Đơn vị tính không được nhiều hơn 255 ký tự',
                                    }
                                })}
                                placeholder='Đơn vị tính'
                                onChange={(e: any) => {
                                    setValue('unit', e.target.value)
                                }}
                                valueDefault={material.unit}
                                containerClass='my-0 max-width-400'
                            />
                            {errors.unit && <ErrorMessage>{errors.unit.message}</ErrorMessage>}
                        </MDBCol>
                    </MDBRow>
                </MDBModalBody>
            }
            <MDBModalFooter>
                <MDBBtn color='primary' outline onClick={() => props.setIsVisible(false)}>Huỷ</MDBBtn>
                <MDBBtn color='primary' onClick={handleSubmit(onSubmit)} disabled={creating}>Tạo</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
}

export default MaterialUpdateModal;
