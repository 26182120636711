import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import * as Types from 'src/core/reduxs/coffee-core/topping/topping.type';
import ToppingStateType from 'src/core/models/coffee-core/topping/topping-state.model';

const initialState: ToppingStateType = {
    toppings: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    toppingDetail: {
        data: undefined,
        error: null,
        isLoading: false,
    },
    updateTopping: {
        data: {},
        error: null,
        isLoading: false
    },
    createTopping: {
        data: {},
        error: null,
        isLoading: false
    },
    deleteTopping: {
        data: {},
        error: null,
        isLoading: false
    },
};

const toppingReducer = createReducer(initialState, {
    [Types.GET_TOPPINGS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            toppings: {
                ...state.toppings,
                isLoading: true,
            }
        };
    },
    [Types.GET_TOPPINGS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            toppings: {
                ...state.toppings,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_TOPPINGS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            toppings: {
                ...state.toppings,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.CREATE_TOPPING_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createTopping: {
                ...state.createTopping,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_TOPPING_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createTopping: {
                ...state.createTopping,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_TOPPING_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createTopping: {
                ...state.createTopping,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_TOPPING_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateTopping: {
                ...state.updateTopping,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_TOPPING_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateTopping: {
                ...state.updateTopping,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_TOPPING_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateTopping: {
                ...state.updateTopping,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.DELETE_TOPPING_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteTopping: {
                ...state.deleteTopping,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_TOPPING_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteTopping: {
                ...state.deleteTopping,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.DELETE_TOPPING_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteTopping: {
                ...state.deleteTopping,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default toppingReducer;
