/** COMMON */
import * as Types from 'src/core/reduxs/coffee-core/topping/topping.type';
import PagingRequest from 'src/core/models/paging-request.model'
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import {ToppingCreateRequest ,ToppingDeleteRequest,ToppingUpdateRequest} from 'src/core/models/coffee-core/topping/topping-request-params.model';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
const toppingsRepository = Repositories[RepositoryName.TOPPING];

export const actGetListTopping = (params: PagingRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_TOPPINGS_REQUEST, Types.GET_TOPPINGS_SUCCESS, Types.GET_TOPPINGS_FAILURE],
        callAPI: () => toppingsRepository.getListTopping(params)
    })
};

export const actCreateTopping = (params: ToppingCreateRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_TOPPING_REQUEST, Types.CREATE_TOPPING_SUCCESS, Types.CREATE_TOPPING_FAILURE],
        callAPI: () => toppingsRepository.createTopping(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListTopping({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}

export const actUpdateTopping = (params: ToppingUpdateRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.UPDATE_TOPPING_REQUEST, Types.UPDATE_TOPPING_SUCCESS, Types.UPDATE_TOPPING_FAILURE],
        callAPI: () => toppingsRepository.updateTopping(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListTopping({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}

export const actDeleteTopping = (params: ToppingDeleteRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.DELETE_TOPPING_REQUEST, Types.DELETE_TOPPING_SUCCESS, Types.DELETE_TOPPING_FAILURE],
        callAPI: () => toppingsRepository.deleteTopping(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListTopping({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}


