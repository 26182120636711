import Repositories, {RepositoryName} from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/management/stock/stock.type';

const stockRepository = Repositories[RepositoryName.STOCK];

export const actGetListStock = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_STOCK_REQUEST, Types.GET_LIST_STOCK_SUCCESS, Types.GET_LIST_STOCK_FAILURE],
        callAPI: () => stockRepository.getListStock()
    })
};
