import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import OutcomeState from 'src/core/reduxs/management/outcome/outcome.state';
import Types from 'src/core/reduxs/management/outcome/outcome.type';
import { OutcomeItem } from 'src/core/models/management/outcome.model';

const initialState: OutcomeState = {
    createOutcomeItems: {
        data: [],
    },
    outcomeCategories: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    createOutcome: {
        data: {},
        error: null,
        isLoading: false
    },
    searchedOutcomes: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    outcomeDetails: {
        data: undefined,
        error: null,
        isLoading: false
    },
    deleteOutcome: {
        data: undefined,
        error: undefined,
        isLoading: false
    },
};

const outcomeReducer = createReducer(initialState, {
    [Types.ADD_OUTCOME_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const outcomeItem: OutcomeItem = action.payload;
        const data = [...state.createOutcomeItems.data, outcomeItem];

        return {
            ...state,
            createOutcomeItems: {
                data
            }
        };
    },
    [Types.REMOVE_OUTCOME_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const index = action.payload;
        const data = [...state.createOutcomeItems.data]
        data.splice(index, 1);

        return {
            ...state,
            createOutcomeItems: {
                data
            }
        };
    },
    [Types.RESET_OUTCOME_ITEM]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createOutcomeItems: {
                data: []
            }
        };
    },
    [Types.GET_LIST_OUTCOME_CATEGORY_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            outcomeCategories: {
                ...state.outcomeCategories,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_OUTCOME_CATEGORY_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            outcomeCategories: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_OUTCOME_CATEGORY_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            outcomeCategories: {
                ...state.outcomeCategories,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.SEARCH_OUTCOME_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedOutcomes: {
                ...state.searchedOutcomes,
                isLoading: true,
            }
        };
    },
    [Types.SEARCH_OUTCOME_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedOutcomes: {
                isLoading: false,
                error: null,
                data: action.payload.data
            }
        };
    },
    [Types.SEARCH_OUTCOME_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedOutcomes: {
                ...state.searchedOutcomes,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.GET_OUTCOME_DETAILS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            outcomeDetails: {
                ...state.outcomeDetails,
                isLoading: true,
            }
        };
    },
    [Types.GET_OUTCOME_DETAILS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            outcomeDetails: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_OUTCOME_DETAILS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            outcomeDetails: {
                ...state.outcomeDetails,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.DELETE_OUTCOME_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteOutcome: {
                ...state.deleteOutcome,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_OUTCOME_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteOutcome: {
                ...state.deleteOutcome,
                isLoading: false,
                error: undefined,
                data: action.payload
            }
        };
    },
    [Types.DELETE_OUTCOME_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteOutcome: {
                ...state.deleteOutcome,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default outcomeReducer;
