import * as CONST from 'src/core/utils/constants';
import * as COMMON from 'src/core/utils/common';
import axios, { AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import qs from 'qs';
import LoginInfo from 'src/core/models/login-info.model';
import history from 'src/core/utils/history';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';
import { AppURI } from 'src/core/utils/constants';

export default class BaseRepository {
    uri: string;
    repository: AxiosInstance;

    constructor(uri = '') {
        this.uri = uri;
        this.repository = this.axiosClient();
        this.repository.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
            const loginInfo: LoginInfo = await COMMON.getLocalStorage(CONST.LocalStorage.LOGIN_INFO);
            if (_.isEmpty(loginInfo)) return config;

            config.headers['Authorization'] = `Bearer ${loginInfo.access_token}`;
            return config;
        });
        this.repository.interceptors.response.use(this.handleSuccess, this.handleError);
    }

    axiosClient(headers = {}): AxiosInstance {
        const baseURL = process.env.REACT_APP_BASE_URL;
        return axios.create({
            baseURL,
            headers: {
                ...headers,
            }
        });
    }

    handleSuccess(response: AxiosResponse): AxiosResponse {
        return response;
    }

    handleError = (error: AxiosError) => {
        console.error('axios error:', error.toJSON());
        switch (error?.response?.status) {
            case CONST.HttpStatus.UNAUTHORIZED: {
                const fcmToken = localStorage.getItem(CONST.LocalStorage.FCM_TOKEN);
                !_.isEmpty(fcmToken) && this.setUri( CONST.ApiURI.THIRD_PARTY_SERVICE.GUEST.UNSUBSRIBE_ALL_TOPIC).create({ deviceToken: fcmToken })
                localStorage.removeItem(CONST.LocalStorage.LOGIN_INFO)
                // localStorage.removeItem(CONST.LocalStorage.USER_INFO)
                const currentPath = window.location.pathname;
                history.push(`${AppURI.LOGIN}?redirectUrl=${currentPath}`)
                break;
            }
            case CONST.HttpStatus.NOT_FOUND:
                break;
            default:
                break;
        }

        return Promise.reject(error);
    }

    setUri(uri: string) : BaseRepository {
        this.uri = uri;
        return this;
    }

    _invalidObject(payload = {}): string | null {
        if (!_.isObject(payload)) return 'Payload is invalid';
        if ((payload instanceof FormData) && (_.isNil(payload) || payload.entries().next().done)) {
            return 'Payload is Empty';
        }

        return null;
    }

    getById<T>(id: number): Promise<AxiosResponse<T>> {
        //alert('hi')
        if (!_.isNumber(id)) return Promise.reject('Id is not a number');
        alert('bhi')
        return this.repository.get(`${this.uri}/${id}`);
    }

    getOne<T>(id: number): Promise<AxiosResponse<T>> {
        if (!_.isNumber(id)) return Promise.reject('Id is not a number');

        return this.repository.get(`${this.uri}?id=${id}`);
    }

    getAll<T>(): Promise<AxiosResponse<T>> {
        return this.repository.get(this.uri);
    }

    /**
    * @param {object} is a object query
    * @example {a: 1, b: '2', c: 'string'}
    * ⟹ a=1&b=2&c=string
    */
    query<T>(object: Record<string, any>): Promise<AxiosResponse<T>> {
        const invalidMessage = this._invalidObject(object);
        if (invalidMessage) return Promise.reject(invalidMessage);

        return this.repository.get(`${this.uri}?${qs.stringify(object)}`);
    }

    pagination<T>({ pageIndex = 0, limit = 10, sortBy = 'createdTime', sortType = 'DESC' }: { pageIndex: number, limit?: number, sortBy?: string, sortType?: string }): Promise<AxiosResponse<T>> {
        return this.repository.get(`${this.uri}?itemsPerPage=${limit}&pageId=${pageIndex}&sortBy=${sortBy}&sortType=${sortType}`);
    }

    search<T>(request: SearchAndFilterRequest): Promise<AxiosResponse<T>> {
        const invalidMessage = this._invalidObject(request);
        if (invalidMessage) return Promise.reject(invalidMessage);

        return this.repository.post(`${this.uri}`, request);
    }

    create<T>(payload = {}): Promise<AxiosResponse<T>> {
        const invalidMessage = this._invalidObject(payload);
        if (invalidMessage) return Promise.reject(invalidMessage);

        return this.repository.post(`${this.uri}`, payload);
    }

    update<T>(payload = {}): Promise<AxiosResponse<T>> {
        const invalidMessage = this._invalidObject(payload);
        if (invalidMessage) return Promise.reject(invalidMessage);

        return this.repository.put(`${this.uri}`, payload);
    }

    delete<T>(payload = {}): Promise<AxiosResponse<T>> {
        const invalidMessage = this._invalidObject(payload);
        if (invalidMessage) return Promise.reject(invalidMessage);

        return this.repository.delete(`${this.uri}?${qs.stringify(payload)}`);
    }

    customConfig<T>(config: AxiosRequestConfig): AxiosPromise<T> {
        return axios(config);
    }
}
