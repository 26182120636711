import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/management/income/income.type';
import { CreateIncomeRequest, IncomeItem } from 'src/core/models/management/income.model';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';
import { toast } from 'react-hot-toast';

const incomeRepository = Repositories[RepositoryName.INCOME];

export const actGetListIncomeCategory = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_INCOME_CATEGORY_REQUEST, Types.GET_LIST_INCOME_CATEGORY_SUCCESS, Types.GET_LIST_INCOME_CATEGORY_FAILURE],
        callAPI: () => incomeRepository.getListCategory()
    })
};

export const actAddIncomeItem = (incomeItem: IncomeItem) => (dispatch: any): void => {
    dispatch({
        type: Types.ADD_INCOME_ITEM,
        payload: incomeItem
    });
};

export const actRemoveIncomeItem = (index: number) => (dispatch: any): void => {
    dispatch({
        type: Types.REMOVE_INCOME_ITEM,
        payload: index
    });
};

export const actResetIncomeItem = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_INCOME_ITEM,
    });
};

export const actCreateIncome = (request: CreateIncomeRequest, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_INCOME_REQUEST, Types.CREATE_INCOME_SUCCESS, Types.CREATE_INCOME_FAILURE],
        callAPI: () => incomeRepository.createIncome(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
            },
            failure: (error: AxiosError) => {
                errorCallback()
            }
        }
    })
}

export const actSearchIncome = (request: SearchAndFilterRequest) => (dispatch: any) : void => {
    dispatch({
        types: [Types.SEARCH_INCOME_REQUEST, Types.SEARCH_INCOME_SUCCESS, Types.SEARCH_INCOME_FAILURE],
        callAPI: () => incomeRepository.searchIncome(request),
    })
}

export const actGetIncomeDetails = (code: string) => (dispatch: any) : void => {
    dispatch({
        types: [Types.GET_INCOME_DETAILS_REQUEST, Types.GET_INCOME_DETAILS_SUCCESS, Types.GET_INCOME_DETAILS_FAILURE],
        callAPI: () => incomeRepository.getIncomeDetails(code),
    })
}

export const actDeleteIncome = (code: string, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.DELETE_INCOME_REQUEST, Types.DELETE_INCOME_SUCCESS, Types.DELETE_INCOME_FAILURE],
        callAPI: () => incomeRepository.deleteIncome(code),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Xoá phiếu thu thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                errorCallback()
                toast.error('Xoá phiếu thu thất bại', { duration: 2000 })
            }
        }
    })
}