import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import * as Types from 'src/core/reduxs/coffee-core/size/size.type';
import SizeStateType from 'src/core/models/coffee-core/size/size-state.model';

const initialState: SizeStateType = {
    sizes: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    updateSize: {
        data: {},
        error: null,
        isLoading: false
    },
    createSize: {
        data: {},
        error: null,
        isLoading: false
    },
    deleteSize: {
        data: {},
        error: null,
        isLoading: false
    },
};

const sizeReducer = createReducer(initialState, {
    [Types.GET_SIZES_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            sizes: {
                ...state.sizes,
                isLoading: true,
            }
        };
    },
    [Types.GET_SIZES_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            sizes: {
                ...state.sizes,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_SIZES_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            sizes: {
                ...state.sizes,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.CREATE_SIZE_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createSize: {
                ...state.createSize,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_SIZE_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createSize: {
                ...state.createSize,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_SIZE_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createSize: {
                ...state.createSize,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_SIZE_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateSize: {
                ...state.updateSize,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_SIZE_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateSize: {
                ...state.updateSize,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_SIZE_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateSize: {
                ...state.updateSize,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.DELETE_SIZE_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteSize: {
                ...state.deleteSize,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_SIZE_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteSize: {
                ...state.deleteSize,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.DELETE_SIZE_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteSize: {
                ...state.deleteSize,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default sizeReducer;
