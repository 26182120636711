import OrderRepository from 'src/core/repositories/order.repository';
import CommonRepository from 'src/core/repositories/common.repository';
import ToppingRepository from 'src/core/repositories/topping.respository';
import ToppingGroupRepository from 'src/core/repositories/topping-group.repository';
import SizeRepository from 'src/core/repositories/size.repository';
import SizeGroupRepository from 'src/core/repositories/size-group.repository';
import CategoryRepository from 'src/core/repositories/category.repository';
import ProductRepository from 'src/core/repositories/product.repository';
import BlackPearlCodeRepository from 'src/core/repositories/black-pearl-code.repository';
import SupplierRepository from 'src/core/repositories/supplier.repository';
import StockRepository from 'src/core/repositories/stock.repository';
import PartnerRepository from 'src/core/repositories/partner.repository';
import MaterialRepository from 'src/core/repositories/material.repository';
import ExportRepository from 'src/core/repositories/export.repository';
import ImportRepository from 'src/core/repositories/import.repository';
import DepositRepository from 'src/core/repositories/deposit.repository';
import StoreRepository from 'src/core/repositories/store.repository';
import IncomeRepository from 'src/core/repositories/income.repository';
import OutcomeRepository from 'src/core/repositories/outcome.repository';
import TransactionRepository from 'src/core/repositories/transaction.repository';
import NewsRepository from 'src/core/repositories/news.repository';
import GuestOrderRepository from 'src/core/repositories/guest/guest-order.repository';
import CouponRepository from 'src/core/repositories/coupon.repository';
import UserRepository from 'src/core/repositories/user.repository';
import TenantRepository from 'src/core/repositories/tenant.repository';
import BlackPearlRepository from 'src/core/repositories/black-pearl.repository';

export enum RepositoryName {
    COMMON,
    ORDER,
    TOPPING,
    TOPPING_GROUP,
    SIZE,
    SIZE_GROUP,
    CATEGORY,
    PRODUCT,
    BLACK_PEARL_CODE,
    BLACK_PEARL,
    SUPPLIER,
    STOCK,
    PARTNER,
    MATERIAL,
    EXPORT,
    IMPORT,
    DEPOSIT,
    STORE,
    INCOME,
    OUTCOME,
    TRANSACTION,
    NEWS,
    GUEST_ORDER,
    COUPON,
    USER,
    TENANT,
}

type RepositoryValue = {
    [RepositoryName.COMMON]: CommonRepository,
    [RepositoryName.ORDER]: OrderRepository,
    [RepositoryName.TOPPING]: ToppingRepository,
    [RepositoryName.TOPPING_GROUP]: ToppingGroupRepository,
    [RepositoryName.SIZE]: SizeRepository,
    [RepositoryName.SIZE_GROUP]: SizeGroupRepository,
    [RepositoryName.CATEGORY]: CategoryRepository,
    [RepositoryName.PRODUCT]: ProductRepository,
    [RepositoryName.BLACK_PEARL_CODE]: BlackPearlCodeRepository,
    [RepositoryName.BLACK_PEARL]: BlackPearlRepository,
    [RepositoryName.SUPPLIER]: SupplierRepository,
    [RepositoryName.STOCK]: StockRepository,
    [RepositoryName.PARTNER]: PartnerRepository,
    [RepositoryName.MATERIAL]: MaterialRepository,
    [RepositoryName.EXPORT]: ExportRepository,
    [RepositoryName.IMPORT]: ImportRepository,
    [RepositoryName.DEPOSIT]: DepositRepository,
    [RepositoryName.STORE]: StoreRepository,
    [RepositoryName.INCOME]: IncomeRepository,
    [RepositoryName.OUTCOME]: OutcomeRepository,
    [RepositoryName.TRANSACTION]: TransactionRepository,
    [RepositoryName.NEWS]: NewsRepository,
    [RepositoryName.GUEST_ORDER]: GuestOrderRepository,
    [RepositoryName.COUPON]: CouponRepository,
    [RepositoryName.USER]: UserRepository,
    [RepositoryName.TENANT]: TenantRepository,
}

type RepositoryType = {
    [key in RepositoryName]: RepositoryValue[key];
}

const Repositories: RepositoryType = {
    [RepositoryName.COMMON]: new CommonRepository(),
    [RepositoryName.ORDER]: new OrderRepository(),
    [RepositoryName.TOPPING]: new ToppingRepository(),
    [RepositoryName.TOPPING_GROUP]: new ToppingGroupRepository(),
    [RepositoryName.SIZE]: new SizeRepository(),
    [RepositoryName.SIZE_GROUP]: new SizeGroupRepository(),
    [RepositoryName.CATEGORY]: new CategoryRepository(),
    [RepositoryName.PRODUCT]: new ProductRepository(),
    [RepositoryName.BLACK_PEARL_CODE]: new BlackPearlCodeRepository(),
    [RepositoryName.BLACK_PEARL]: new BlackPearlRepository(),
    [RepositoryName.SUPPLIER]: new SupplierRepository(),
    [RepositoryName.STOCK]: new StockRepository(),
    [RepositoryName.PARTNER]: new PartnerRepository(),
    [RepositoryName.MATERIAL]: new MaterialRepository(),
    [RepositoryName.EXPORT]: new ExportRepository(),
    [RepositoryName.IMPORT]: new ImportRepository(),
    [RepositoryName.DEPOSIT]: new DepositRepository(),
    [RepositoryName.STORE]: new StoreRepository(),
    [RepositoryName.INCOME]: new IncomeRepository(),
    [RepositoryName.OUTCOME]: new OutcomeRepository(),
    [RepositoryName.TRANSACTION]: new TransactionRepository(),
    [RepositoryName.NEWS]: new NewsRepository(),
    [RepositoryName.GUEST_ORDER]: new GuestOrderRepository(),
    [RepositoryName.COUPON]: new CouponRepository(),
    [RepositoryName.USER]: new UserRepository(),
    [RepositoryName.TENANT]: new TenantRepository(),
};

export default Repositories;
