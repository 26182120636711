import _ from 'lodash';
import { MDBBadge, MDBBox, MDBCard, MDBCardBody, MDBCardTitle, MDBDataTable, MDBIcon, MDBRow, MDBSpinner } from 'mdbreact';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PagingRequest from 'src/core/models/paging-request.model';
import PickDeep from 'src/core/models/pick-deep.modal';
import ToppingGroupStateType from 'src/core/models/coffee-core/topping-group/topping-group-state.model';
import ToppingGroup from 'src/core/models/coffee-core/topping-group/topping-group.model';
import Topping from 'src/core/models/coffee-core/topping/topping.model';
import * as ToppingGroupAction from 'src/core/reduxs/coffee-core/topping-group/topping-group.action'
import ToppingGroupDeleteModalComponent from 'src/features/components/topping-group/topping-group-delete-modal.component';
import ToppingGroupUpdateModalComponent from 'src/features/components/topping-group/topping-group-update-modal.component';

type ToppingGroupDataTable = {
    code: string;
    name: string;
    toppings: JSX.Element;
    edit: JSX.Element;
    [rest: string]: any;
}



const initToppingGroupDataTable: ToppingGroupDataTable[] = [];
const initToppingGroupDetail = new ToppingGroup()
const ToppingGroupListComponent = (): JSX.Element => {
    const intl = useIntl();
    const columns = [
        {
            label: intl.formatMessage({ id: 'TOPPING_GROUP.LIST.LABEL_CODE' }),
            field: 'code',
        },
        {
            label: intl.formatMessage({ id: 'TOPPING_GROUP.LIST.LABEL_NAME' }),
            field: 'name',
        },
        {
            label: intl.formatMessage({ id: 'TOPPING_GROUP.LIST.LABEL_TOPPINGS' }),
            field: 'toppings',

        },
        {
            label: intl.formatMessage({ id: 'TOPPING_GROUP.LIST.LABEL_EDIT' }),
            field: 'edit',
        },
    ]
    const dispatch = useDispatch();
    const [dataTable, setDataTable] = useState(initToppingGroupDataTable);
    const toppingGroups: PickDeep<ToppingGroupStateType, 'toppingGroups'> = useSelector((state: any) => state.toppingGroupReducer.toppingGroups)

    const [toppingGroupDetail, setToppingGroupDetail] = useState<ToppingGroup>(initToppingGroupDetail)
    const [isOpenToppingGroupUpdateModal, setIsOpenToppingGroupUpdateModal] = useState(false);
    const [isOpenToppingGroupDeleteModal, setIsOpenToppingGroupDeleteModal] = useState(false);
    const prepareDataRow = useCallback((listToppingGroup: ToppingGroup[]) => {
        if (_.isEmpty(listToppingGroup)) return;

        const data: ToppingGroupDataTable[] = [];
        listToppingGroup.forEach((item: ToppingGroup) => {
            const row = {
                id: item.id,
                code: item.code || '',
                name: item.name || '',
                toppings: (
                    <>
                        <MDBRow className='topping-list-row'>
                            {
                                item.toppings && item.toppings.length > 0 &&
                                item.toppings.map((topping: Topping) => {
                                    return (
                                        <MDBBadge className='topping-badge' key={topping.id} >{topping.name}</MDBBadge>
                                    )
                                })
                            }
                            {
                                item.toppings && item.toppings.length === 0 && <span className='ml-2'>{intl.formatMessage({ id: 'TOPPING_GROUP.LIST.LABEL_NOT_FOUND_TOPPING_IN_GROUP' })}</span>
                            }
                        </MDBRow>
                    </>
                ),
                edit: (
                    <>
                        <MDBRow className="topping-list-item">
                            <button className="topping-list-item-btn edit" onClick={() => { openUpdateToppingGroupModal(item) }}>
                                <MDBIcon icon="edit" className="topping-list-item-icon edit" />
                            </button>
                            <button className="topping-list-item-btn delete" onClick={() => { openDeleteToppingGroupModal(item) }}>
                                <MDBIcon icon="trash-alt" className="topping-list-item-icon delete" />
                            </button>
                        </MDBRow>
                    </>
                ),
            }
            data.push(row)
        })
        setDataTable(data)
    }, [])

    const toppingGroupDataTable = useMemo(() => {
        return (
            <MDBDataTable
                responsive
                striped
                borderless
                searching={true}
                sortable={false}
                noBottomColumns={true}
                paging={true}
                entries={5}
                pagesAmount={10}
                paginationLabel={[intl.formatMessage({ id: 'TOPPING_GROUP.LIST.LABEL_PREVIOUS_PAGE' }), intl.formatMessage({ id: 'TOPPING_GROUP.LIST.LABEL_NEXT_PAGE' })]}
                displayEntries={false}
                info={false}
                noRecordsFoundLabel={intl.formatMessage({ id: 'TOPPING_GROUP.LIST.LABEL_NOT_FOUND_TOPPING_GROUP' })}
                data={{ columns: columns, rows: dataTable }
                }
            />
        )
    }, [dataTable])

    const openUpdateToppingGroupModal = (item: ToppingGroup) => {
        if (item) {
            setToppingGroupDetail(item);
            setIsOpenToppingGroupUpdateModal(true);
        }
    }
    const onModalUpdateClosed = () => {
        setIsOpenToppingGroupUpdateModal(false);
        setToppingGroupDetail(initToppingGroupDetail);
    }

    const openDeleteToppingGroupModal = (item: ToppingGroup) => {
        if (item) {
            setToppingGroupDetail(item);
            setIsOpenToppingGroupDeleteModal(true);
        }
    }
    const onModalDeleteClosed = () => {
        setIsOpenToppingGroupDeleteModal(false);
        setToppingGroupDetail(initToppingGroupDetail);
    }

    useEffect(() => {
        const params: PagingRequest = {
            pageId: 0,
        }
        dispatch(ToppingGroupAction.actGetListToppingGroup(params))
    }, [dispatch])

    useEffect(() => {
        if (toppingGroups.data) {
            prepareDataRow(toppingGroups.data)
        }
    }, [toppingGroups.data, prepareDataRow])

    return (
        <MDBCard className='topping-group-list-container'>
            <MDBCardTitle className='topping-group-list-title'>
                <MDBBox className='topping-group-list-title-text'>
                    {intl.formatMessage({ id: 'TOPPING_GROUP.LIST.TITLE' })}
                </MDBBox>
            </MDBCardTitle>
            <MDBCardBody className='m-0 p-0'>
                {toppingGroups.isLoading && <MDBRow className="d-flex justify-content-center mb-5"><MDBSpinner small /></MDBRow>}
                {!toppingGroups.isLoading && toppingGroups.data && toppingGroupDataTable}
            </MDBCardBody>
            <ToppingGroupUpdateModalComponent isOpen={isOpenToppingGroupUpdateModal} toppingGroupDetail={toppingGroupDetail} onModalClosed={onModalUpdateClosed} />
            <ToppingGroupDeleteModalComponent isOpen={isOpenToppingGroupDeleteModal} toppingGroupDetail={toppingGroupDetail} onModalClosed={onModalDeleteClosed} />
        </MDBCard>
    )
}

export default ToppingGroupListComponent
