import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/coffee-core/guest/guest-order.type';

const guestOrderRepository = Repositories[RepositoryName.GUEST_ORDER];

export const actResetOrderDetails = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_ORDER_DETAILS,
    });
};

export const actGetOrderDetails = (id: number) => (dispatch: any) : void => {
    dispatch({
        types: [Types.GET_ORDER_DETAILS_REQUEST, Types.GET_ORDER_DETAILS_SUCCESS, Types.GET_ORDER_DETAILS_FAILURE],
        callAPI: () => guestOrderRepository.getOrderDetails(id),
    })
}
