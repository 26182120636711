import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';

import PagingRequest from 'src/core/models/paging-request.model';
import ResponseCustom from 'src/core/models/response-custom.model';
import {ToppingGroupCreateRequest, ToppingGroupDeleteRequest, ToppingGroupUpdateRequest} from 'src/core/models/coffee-core/topping-group/topping-group-request-params.model';
import ToppingGroup from 'src/core/models/coffee-core/topping-group/topping-group.model';

export default class ToppingGroupRepository extends BaseRepository {

    getListToppingGroup(params: PagingRequest): Promise<AxiosResponse<ToppingGroup[]>> {
        const request = {
            ...params,
            itemsPerPage: CONST.DEFAULT_PAGE_SIZE,
            sortBy: 'display_order',
            sortType: 'DESC'
        };
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_TOPPING_GROUP).query<ToppingGroup[]>(request, );
    }

    createToppingGroup(params: ToppingGroupCreateRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.CREATE_TOPPING_GROUP).create<ResponseCustom>(params.data)
    }

    updateToppingGroup(params: ToppingGroupUpdateRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.UPDATE_TOPPING_GROUP).create<ResponseCustom>(params.data)
    }

    deleteToppingGroup(params: ToppingGroupDeleteRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.DELETE_TOPPING_GROUP).create<ResponseCustom>(params.data )
    }
}
