import {
    MDBBox,
    MDBCard,
    MDBCardBody,
    MDBContainer,
    MDBDataTable,
    MDBRow,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOption,
    MDBSelectOptions,
    MDBSpinner
} from 'mdbreact';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Product from 'src/core/models/coffee-core/product/product.model';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PickDeep from 'src/core/models/pick-deep.modal';
import ProductStateType from 'src/core/models/coffee-core/product/product-state.model';
import CategoryStateType from 'src/core/models/coffee-core/category/category-state.model';
import _ from 'lodash';
import { NOT_AVAILABLE_IMAGE } from '../../../core/utils/constants';
import * as COMMON from '../../../core/utils/common';
import { GetProductsByCategoryRequest } from '../../../core/models/coffee-core/product/product-request-params.model';
import * as ProductAction from '../../../core/reduxs/coffee-core/product/product.action';
import PagingRequest from '../../../core/models/paging-request.model';
import * as CategoryAction from '../../../core/reduxs/coffee-core/category/category.action';
import Category from '../../../core/models/coffee-core/category/category.model';
import AddOrderItemModal from 'src/features/components/order-list/add-order-item-modal.component';

type ProductDataTable = {
    imageUrl: JSX.Element;
    name: string;
    price: string;
    [rest: string]: any;
}

const initProductDataTable: ProductDataTable[] = [];
const initProductDetail = new Product();
const OrderProductListComponent = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const columns = [
        {
            label: '#',
            field: 'imageUrl',
        },
        {
            label: intl.formatMessage({id: 'PRODUCT.LIST.LABEL_NAME'}),
            field: 'name',
        },
        {
            label: intl.formatMessage({id: 'PRODUCT.LIST.LABEL_PRICE'}),
            field: 'price'
        },
    ]
    const [dataTable, setDataTable] = useState(initProductDataTable);
    const products: PickDeep<ProductStateType, 'products'> = useSelector((state: any) => state.productReducer.products)
    const productCategories: PickDeep<CategoryStateType, 'productCategories'> = useSelector((state: any) => state.categoryReducer.productCategories)
    const [productDetail, setProductDetail] = useState<Product>(initProductDetail);
    const [categoryIdFilter, setCategoryIdFilter] = useState(0)
    const [selectedProductId, setSelectedProductId] = useState<number | undefined>(undefined);
    const [isVisibleAddOrderItemModal, setIsVisibleAddOrderItemModal] = useState<boolean>(false);

    const prepareDataRow = useCallback((productList: Product[]) => {
        if (_.isEmpty(productList)) return;

        const data: ProductDataTable[] = [];
        productList.forEach((item: Product) => {
            const row = {
                id: item.id,
                imageUrl: (
                    <>
                        <MDBContainer className='m-0 p-0'>
                            <img
                                src={item.imageUrl || NOT_AVAILABLE_IMAGE}
                                className='order-list-row-img'
                            />
                        </MDBContainer>
                    </>
                ),
                name: item.name || '',
                price: COMMON.currencyFormatter(item.price || 0),
                clickEvent: (row: any) => onClickAddOrderItem(row.id)
            }
            data.push(row)
        })
        setDataTable(data)
    }, [])

    const handleChangeCategory = (categoryId: number) => {
        setCategoryIdFilter(categoryId)
        if (categoryId > 0) {
            const params: GetProductsByCategoryRequest = {
                pageId: 0,
                categoryId: categoryId
            }
            dispatch(ProductAction.actGetListProductByCategory(params))
        } else {
            const params: PagingRequest = {
                pageId: 0,
            }
            dispatch(ProductAction.actGetListProduct(params))
        }
    }

    const refreshProductList = () => {
        if (categoryIdFilter > 0) {
            const params: GetProductsByCategoryRequest = {
                pageId: 0,
                categoryId: categoryIdFilter
            }
            dispatch(ProductAction.actGetListProductByCategory(params))
        } else {
            const params: PagingRequest = {
                pageId: 0,
            }
            dispatch(ProductAction.actGetListProduct(params))
        }
    }

    const onClickAddOrderItem = (productId: number) => {
        setSelectedProductId(productId);
        setIsVisibleAddOrderItemModal(true);
    }

    useEffect(() => {
        const params: PagingRequest = {
            pageId: 0,
        }
        dispatch(ProductAction.actGetListProduct(params))
        dispatch(CategoryAction.actGetListProductCategory(params))
    }, [dispatch])

    useEffect(() => {
        if (products.data) {
            prepareDataRow(products.data)
        }
    }, [products.data, prepareDataRow])

    return (
        <Fragment>
            <MDBCard>
                <MDBCardBody>
                    <p className="font-weight-normal">DANH SÁCH SẢN PHẨM</p>
                    {/*<MDBBtn size="sm" floating color='dark-green' onClick={() => {*/}
                    {/*    refreshProductList()*/}
                    {/*}}>*/}
                    {/*    <MDBIcon icon="sync" className="order-list-title-btn-add"/>*/}
                    {/*</MDBBtn>*/}
                    <MDBBox className='mt-4'>
                        <MDBSelect
                            className='m-0 p-0' label={intl.formatMessage({id: 'CATEGORY.CREATE.LABEL_TYPE'})}
                            getValue={(value: any) => {
                                handleChangeCategory(value[0])
                            }}>
                            <MDBSelectInput/>
                            <MDBSelectOptions>
                                <MDBSelectOption value={0} checked>--- Tất cả ---</MDBSelectOption>
                                {
                                    productCategories && productCategories.data &&
                                    productCategories.data.map((category: Category) => {
                                        return (
                                            <MDBSelectOption
                                                key={category.id}
                                                value={category.id}>{category.name}</MDBSelectOption>
                                        )
                                    })
                                }
                            </MDBSelectOptions>
                        </MDBSelect>
                    </MDBBox>
                    {products.isLoading &&
                        <MDBRow className="d-flex justify-content-center mb-5"><MDBSpinner small/></MDBRow>}
                    {!products.isLoading && products.data &&
                        <MDBDataTable
                            responsive
                            hover
                            searching={true}
                            sortable={false}
                            noBottomColumns={true}
                            paging={true}
                            entries={5}
                            pagesAmount={5}
                            paginationLabel={[intl.formatMessage({id: 'PRODUCT.LIST.LABEL_PREVIOUS_PAGE'}), intl.formatMessage({id: 'PRODUCT.LIST.LABEL_NEXT_PAGE'})]}
                            displayEntries={false}
                            info={false}
                            noRecordsFoundLabel={intl.formatMessage({id: 'PRODUCT.LIST.LABEL_NOT_FOUND_PRODUCT'})}
                            data={{columns: columns, rows: dataTable}}
                        />
                    }
                </MDBCardBody>
            </ MDBCard>
            {
                selectedProductId &&
                <AddOrderItemModal
                    isVisible={isVisibleAddOrderItemModal}
                    setIsVisible={setIsVisibleAddOrderItemModal}
                    productId={selectedProductId}
                />
            }
        </Fragment>
    )
}

export default OrderProductListComponent
