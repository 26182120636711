import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBFileInput,
    MDBInput,
    MDBModal,
    MDBModalBody,
    MDBModalFooter,
    MDBModalHeader,
    MDBRow,
    MDBSpinner
} from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import {
    actGetListNewsCategoryByTenant,
    actGetNewsDetails,
    actUpdateNews
} from 'src/core/reduxs/coffee-core/news/news.action';
import { News, NewsTypes } from 'src/core/models/coffee-core/news.model';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import Category from 'src/core/models/coffee-core/category/category.model';
import _ from 'lodash';
import { SelectOption } from 'src/core/models/select-option.model';

type Props = {
    isVisible: boolean,
    setIsVisible: (value: boolean) => void,
    newsId: number,
}

const NewsUpdateModal = (props: Props): JSX.Element => {

    const dispatch = useDispatch();

    const {register, handleSubmit, setValue, formState: {errors}} = useForm();
    const newsDetailsSelector = useSelector((state: any) => state.newsReducer.newsDetails);
    const newsDetails: News = newsDetailsSelector.data;
    const updating = useSelector((state: any) => state.newsReducer.updateNews.isLoading);
    const categories: Category[] = useSelector((state: any) => state.newsReducer.newsCategories.data);
    const [imageFile, setImageFile] = useState<File | undefined>(undefined)
    const [selectedTenant, setSelectedTenant] = useState<SelectOption | undefined>(undefined)
    const [selectedCategoryId, setSelectedCategoryId] = useState<SelectOption | undefined>(undefined)
    const [selectedNewsTypeId, setSelectedNewsTypeId] = useState<SelectOption | undefined>(undefined)

    useEffect(() => {
        if (props.isVisible && props.newsId) {
            dispatch(actGetNewsDetails(props.newsId));
        }
    }, [props.isVisible, props.newsId]);

    useEffect(() => {
        if (newsDetails) {
            // patch value
            setValue('title', newsDetails.title)
            setValue('shortContent', newsDetails.shortContent)
            setValue('content', newsDetails.content)
            const category = categoryOptions.find(c => c.value === newsDetails.category?.id)
            if (category) {
                setValue('categoryId', newsDetails.category?.id)
                setSelectedCategoryId(category)
            }
            const newsTypeId = newsTypeOptions.find(n => n.value === newsDetails.type.id)
            if (newsTypeId) {
                setValue('newsTypeId', newsDetails.type.id)
                setSelectedNewsTypeId(newsTypeId)
            }
            dispatch(actGetListNewsCategoryByTenant(newsDetails.tenant.id));
        }
    }, [newsDetails]);

    const onSubmit = (data: any) => {
        const formData = new FormData();
        formData.append('id', String(newsDetails.id))
        formData.append('newsTypeId', data.newsTypeId)
        formData.append('title', data.title)
        formData.append('shortContent', data.shortContent)
        formData.append('content', data.content)
        formData.append('categoryId', data.categoryId)
        if (imageFile && imageFile.type.includes('image')) {
            formData.append('image', imageFile)
        }

        dispatch(actUpdateNews(formData, onUpdateSuccess))
    }

    const onUpdateSuccess = () => {
        props.setIsVisible(false)
        actGetNewsDetails(newsDetails.id);
    }

    const newsTypeOptions = useMemo(() => {
        return NewsTypes.map(type => ({
            label: type.name,
            value: type.id
        }))
    }, []);

    const categoryOptions = useMemo(() => {
        if (!_.isEmpty(categories)) {
            return categories.map((c) => ({
                label: c.name,
                value: c.id
            }));
        }
        return [];
    }, [categories]);

    return (
        <MDBModal
            isOpen={props.isVisible}
            fullHeight={false}
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll
            size='fluid'
        >
            <MDBModalHeader>Cập nhật tin tức</MDBModalHeader>
            <MDBModalBody>
                <Fragment>
                    {!newsDetails || newsDetailsSelector.isLoading ?
                        <div className='m-5 text-center'>
                            <MDBSpinner/>
                        </div>
                        : <MDBRow>
                            <MDBCol md="2">
                                <div className="pt-2">Tiêu đề</div>
                            </MDBCol>
                            <MDBCol md="10">
                                <MDBInput
                                    {...register('title', {
                                        required: 'Tiêu đề là bắt buộc',
                                        maxLength: {
                                            value: 255,
                                            message: 'Tiêu đề không được nhiều hơn 255 ký tự',
                                        }
                                    })}
                                    placeholder='Tiêu đề'
                                    onChange={(e: any) => {
                                        setValue('title', e.target.value)
                                    }}
                                    containerClass='my-0 max-width-400'
                                    valueDefault={newsDetails.title}
                                />
                                {errors.title && <ErrorMessage>{errors.title.message}</ErrorMessage>}
                            </MDBCol>
                            <MDBCol md="2">
                                <div className="pt-2">Loại tin tức</div>
                            </MDBCol>
                            <MDBCol md="10">
                                <Select
                                    {...register('newsTypeId', {
                                        required: 'Loại tin tức là bắt buộc'
                                    })}
                                    options={newsTypeOptions}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setValue('newsTypeId', newValue.value);
                                        }
                                    }}
                                    placeholder='(chọn loại)'
                                    className='max-width-400 my-1'
                                    defaultValue={selectedNewsTypeId}
                                />
                                {errors.newsTypeId && <ErrorMessage>{errors.newsTypeId.message}</ErrorMessage>}
                            </MDBCol>
                            <MDBCol md="2">
                                <div className="pt-2">Danh mục</div>
                            </MDBCol>
                            <MDBCol md="10">
                                <Select
                                    {...register('categoryId', {
                                        required: 'Danh mục là bắt buộc'
                                    })}
                                    options={categoryOptions}
                                    onChange={(newValue) => {
                                        if (newValue) {
                                            setValue('categoryId', newValue.value);
                                        }
                                    }}
                                    placeholder='(chọn danh mục)'
                                    className='max-width-400 my-1'
                                    defaultValue={selectedCategoryId}
                                />
                                {errors.categoryId && <ErrorMessage>{errors.categoryId.message}</ErrorMessage>}
                            </MDBCol>
                            <MDBCol md="2">
                                <div className="pt-2">Mô tả ngắn</div>
                            </MDBCol>
                            <MDBCol md="10">
                                <textarea
                                    {...register('shortContent', {
                                        required: 'Mô tả ngắn là bắt buộc',
                                        maxLength: {
                                            value: 255,
                                            message: 'Mô tả ngắn không được nhiều hơn 255 ký tự',
                                        }
                                    })}
                                    className="form-control my-2"
                                    rows={3}
                                    onChange={(e: any) => {
                                        setValue('shortContent', e.target.value)
                                    }}
                                />
                                {errors.shortContent && <ErrorMessage>{errors.shortContent.message}</ErrorMessage>}
                            </MDBCol>
                            <MDBCol md="2">
                                <div className="pt-2">Nội dung</div>
                            </MDBCol>
                            <MDBCol md="10">
                                <textarea
                                    {...register('content', {
                                        required: 'Nội dung là bắt buộc'
                                    })}
                                    className="form-control my-2"
                                    rows={5}
                                    onChange={(e: any) => {
                                        setValue('content', e.target.value)
                                    }}
                                />
                                {errors.content && <ErrorMessage>{errors.content.message}</ErrorMessage>}
                            </MDBCol>
                            <MDBCol md="2" className='flex-row'>
                                <div className="md-form">Hình ảnh</div>
                            </MDBCol>
                            <MDBCol md="10">
                                <MDBFileInput
                                    btnTitle='Chọn hình ảnh'
                                    btnColor='grey'
                                    textFieldTitle='Chọn hình ảnh cho tin tức'
                                    getValue={(files: File[]) => setImageFile(files[0])}
                                    accept="image/*"
                                />
                                {
                                    imageFile?.type?.includes('image') && (
                                        <img
                                            src={URL.createObjectURL(imageFile as Blob)}
                                            alt='image'
                                            height={400}
                                        />
                                    )
                                }
                                {!imageFile && newsDetails.thumbnail &&
                                    <img
                                        src={newsDetails.thumbnail}
                                        alt='image'
                                        height={400}
                                    />
                                }
                            </MDBCol>
                        </MDBRow>
                    }
                </Fragment>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color='info' outline onClick={() => props.setIsVisible(false)}>Huỷ</MDBBtn>
                <MDBBtn color='info' onClick={handleSubmit(onSubmit)} disabled={updating}>Cập nhật</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
}

export default NewsUpdateModal;
