import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import { Export } from 'src/core/models/management/export.model';
import ImportDetailState from 'src/core/reduxs/management/import-detail/import-detail.state';
import Types from 'src/core/reduxs/management/import-detail/import-detail.type';

const initialState: ImportDetailState = {
    createImportDetails: {
        items: [],
    }
};

const importDetailReducer = createReducer(initialState, {
    [Types.ADD_IMPORT_DETAIL_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const importDetail: Export = action.payload;
        const items = [...state.createImportDetails.items, importDetail];

        return {
            ...state,
            createImportDetails: {
                items
            }
        };
    },
    [Types.REMOVE_IMPORT_DETAIL_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const index = action.payload;
        const items = [...state.createImportDetails.items]
        items.splice(index, 1);

        return {
            ...state,
            createImportDetails: {
                items
            }
        };
    },
    [Types.RESET_IMPORT_DETAIL_ITEM]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createImportDetails: {
                items: []
            }
        };
    },
})

export default importDetailReducer;
