import React, { useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBInput, MDBRow, MDBSpinner, } from 'mdbreact';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Select from 'react-select';
import Required from 'src/shared/components/required/required.component';
import { actGetListTenant } from 'src/core/reduxs/coffee-core/tenant/tenant.action';
import { Tenant } from 'src/core/models/tenant.model';
import { actGetUserByCode, actGetUserByPhone, actResetUser } from 'src/core/reduxs/coffee-core/user/user.action';
import { User } from 'src/core/models/coffee-core/user.model';
import { toast } from 'react-hot-toast';
import { AccumulateBlackPearlRequest } from 'src/core/models/black-pearl.model';
import { numberToString, stringToNumber } from 'src/core/utils/common';
import { actAccumulateBlackPearl } from 'src/core/reduxs/coffee-core/black-pearl/black-pearl.action';

const AccumulatePointContainer = (): JSX.Element => {
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const dispatch = useDispatch();
    const userSelector = useSelector((state: any) => state.userReducer.user);
    const user: User = userSelector.data;
    const accumulating: boolean = useSelector((state: any) => state.blackPearlReducer.accumulateBlackPearl.loading);
    const tenants: Tenant[] = useSelector((state: any) => state.tenantReducer.tenants.data);
    const [selectedTenantId, setSelectedTenantId] = useState<number | undefined>(undefined);
    const [searchText, setSearchText] = useState<string>('');
    const [amount, setAmount] = useState<string | undefined>()
    const [note, setNote] = useState<string>('');

    useEffect(() => {
        dispatch(actGetListTenant());
    }, [])

    const tenantOptions = useMemo(() => {
        if (!_.isEmpty(tenants)) {
            return tenants.map((t) => ({
                label: t.name,
                value: t.id
            }));
        }
        return [];
    }, [tenants]);

    const onClickSearch = () => {
        if (selectedTenantId === undefined) {
            toast.error('Bạn chưa chọn brand', { duration: 2000 })
            return;
        }
        let searchValue = searchText.replaceAll(' ', '');
        if (_.isEmpty(searchValue)) {
            toast.error('Bạn chưa nhập thông tin user', { duration: 2000 })
            return
        }
        if (searchValue.startsWith('USER-')) {
            dispatch(actGetUserByCode(searchValue, selectedTenantId));
            return
        }
        if (searchValue.startsWith('0')) {
            searchValue = '+84' + searchValue.substring(1);
        }
        dispatch(actGetUserByPhone(searchValue, selectedTenantId));
    }

    const onSuccess = () => {
        dispatch(actResetUser())
        setSearchText('');
        setAmount('');
        setNote('');
        setValue('amount', undefined);
        setValue('note', undefined);
    }

    const onSubmit = (data: any) => {
        const request: AccumulateBlackPearlRequest = {
            userCode: user.code,
            amount: data.amount,
            note: data.note,
        }
        dispatch(actAccumulateBlackPearl(request, onSuccess))
    }

    return (
        <MDBContainer className='py-3' fluid>
            <div className='text-center'>
                <h2 className='page-title'>Tích điểm cho User</h2>
            </div>
            <MDBRow className='pb-3 mt-4'>
                <MDBCol md="2">
                    <div className="pt-2">Chọn brand<Required/></div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        options={tenantOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('tenantId', newValue.value);
                                setSelectedTenantId(newValue.value)
                            }
                        }}
                        placeholder='(chọn brand)'
                        className='max-width-400 my-1'
                    />
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Thông tin User<Required/></div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBInput
                        placeholder='Nhập SĐT hoặc mã user'
                        onChange={(e: any) => {
                            const value = e.target.value;
                            setValue('searchText', value)
                            setSearchText(value);
                        }}
                        value={searchText}
                        containerClass='my-0 max-width-400'
                    />
                </MDBCol>
                <MDBCol size='12'>
                    <div className='text-center'>
                        <MDBBtn color="primary" onClick={onClickSearch} disabled={userSelector.loading}>Tìm</MDBBtn>
                        {userSelector.loading &&
                            <MDBSpinner small className='b-primary'/>
                        }
                    </div>
                </MDBCol>
                <MDBCol md="2">
                    <div className="py-2">Tên khách hàng</div>
                </MDBCol>
                <MDBCol md="10">
                    <div className="py-2">{`${user?.lastName || ''} ${user?.firstName || ''}`}</div>
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Số tiền trên bill<Required/></div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBInput
                        {...register('amount', {
                            required: 'Số tiền trên bill là bắt buộc'
                        })}
                        type='number'
                        placeholder='Nhập số tiền trên bill'
                        onChange={(e: any) => {
                            const inputValue = e.target.value;
                            if (inputValue.length < 3) {
                                setAmount(inputValue);
                                return
                            }
                            const numberValue = stringToNumber(e.target.value);
                            setValue('amount', numberValue)
                            const formattedValue = numberToString(numberValue);
                            setAmount(formattedValue);
                        }}
                        value={amount}
                        containerClass='my-0 max-width-400'
                        disabled={user === undefined}
                    />
                    {errors.amount && <ErrorMessage>{errors.amount.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Ghi chú</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBInput
                        {...register('note', {
                            maxLength: {
                                value: 255,
                                message: 'Ghi chú không quá 12 ký tự',
                            }
                        })}
                        placeholder='Nhập ghi chú'
                        onChange={(e: any) => {
                            const value = e.target.value;
                            setValue('note', value)
                            setNote(value);
                        }}
                        value={note}
                        containerClass='my-0 max-width-400'
                        disabled={user === undefined}
                    />
                    {errors.note && <ErrorMessage>{errors.note.message}</ErrorMessage>}
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size='12'>
                    <div className='text-center'>
                        <MDBBtn color="primary" onClick={handleSubmit(onSubmit)} disabled={accumulating || user === undefined}>Tích điểm</MDBBtn>
                        {accumulating &&
                            <MDBSpinner small className='b-primary'/>
                        }
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default React.memo(AccumulatePointContainer);
