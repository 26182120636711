import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/management/material/material.type';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import { toast } from 'react-hot-toast';
import { CreateMaterialRequest, UpdateMaterialRequest } from 'src/core/models/management/material.model';

const materialRepository = Repositories[RepositoryName.MATERIAL];

export const actGetListMaterial = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_MATERIAL_REQUEST, Types.GET_LIST_MATERIAL_SUCCESS, Types.GET_LIST_MATERIAL_FAILURE],
        callAPI: () => materialRepository.getListMaterial()
    })
};

export const actSearchMaterial = (request: SearchAndFilterRequest) => (dispatch: any) : void => {
    dispatch({
        types: [Types.SEARCH_MATERIAL_REQUEST, Types.SEARCH_MATERIAL_SUCCESS, Types.SEARCH_MATERIAL_FAILURE],
        callAPI: () => materialRepository.searchMaterial(request),
    })
}

export const actGetMaterial = (id: number) => (dispatch: any) : void => {
    dispatch({
        types: [Types.GET_MATERIAL_REQUEST, Types.GET_MATERIAL_SUCCESS, Types.GET_MATERIAL_FAILURE],
        callAPI: () => materialRepository.getMaterial(id),
    })
}

export const actResetMaterial = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_MATERIAL
    })
}

export const actGetListMaterialCategory = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_MATERIAL_CATEGORY_REQUEST, Types.GET_LIST_MATERIAL_CATEGORY_SUCCESS, Types.GET_LIST_MATERIAL_CATEGORY_FAILURE],
        callAPI: () => materialRepository.getListCategory()
    })
};

export const actCreateMaterial = (request: CreateMaterialRequest, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    toast.loading('Đang tạo', {
        id: 'materialCreating',
    });
    dispatch({
        types: [Types.CREATE_MATERIAL_REQUEST, Types.CREATE_MATERIAL_SUCCESS, Types.CREATE_MATERIAL_FAILURE],
        callAPI: () => materialRepository.createMaterial(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.dismiss('materialCreating');
                toast.success('Tạo nguyên liệu thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                errorCallback()
                toast.dismiss('materialCreating');
                toast.error('Tạo nguyên liệu thất bại', { duration: 2000 })
            }
        }
    })
}

export const actUpdateMaterial = (request: UpdateMaterialRequest, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    toast.loading('Đang cập nhật', {
        id: 'materialUpdating',
    });
    dispatch({
        types: [Types.UPDATE_MATERIAL_REQUEST, Types.UPDATE_MATERIAL_SUCCESS, Types.UPDATE_MATERIAL_FAILURE],
        callAPI: () => materialRepository.updateMaterial(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.dismiss('materialUpdating');
                toast.success('Cập nhật nguyên liệu thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                errorCallback()
                toast.dismiss('materialUpdating');
                toast.error('Cập nhật nguyên liệu thất bại', { duration: 2000 })
            }
        }
    })
}
