import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { Transaction } from 'src/core/models/management/transaction.model';

export default class TransactionRepository extends BaseRepository {

    getTransactionDetails(id: number): Promise<AxiosResponse<Transaction>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_TRANSACTION_DETAILS).query<Transaction>({id})
    }
}
