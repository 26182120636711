import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import TransactionState from 'src/core/reduxs/management/transaction/transaction.state';
import Types from 'src/core/reduxs/management/transaction/transaction.type';

const initialState: TransactionState = {
    transactionDetails: {
        data: undefined,
        error: null,
        isLoading: false
    }
};

const transactionReducer = createReducer(initialState, {
    [Types.GET_TRANSACTION_DETAILS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            transactionDetails: {
                ...state.transactionDetails,
                isLoading: true,
            }
        };
    },
    [Types.GET_TRANSACTION_DETAILS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            transactionDetails: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_TRANSACTION_DETAILS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            transactionDetails: {
                ...state.transactionDetails,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default transactionReducer;
