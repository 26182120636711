/** COMMON */
import * as Types from 'src/core/reduxs/coffee-core/topping-group/topping-group.type';
import PagingRequest from 'src/core/models/paging-request.model'
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import { ToppingGroupCreateRequest, ToppingGroupUpdateRequest, ToppingGroupDeleteRequest} from 'src/core/models/coffee-core/topping-group/topping-group-request-params.model';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
const toppingGroupsRepository = Repositories[RepositoryName.TOPPING_GROUP];

export const actGetListToppingGroup = (params: PagingRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_TOPPING_GROUPS_REQUEST, Types.GET_TOPPING_GROUPS_SUCCESS, Types.GET_TOPPING_GROUPS_FAILURE],
        callAPI: () => toppingGroupsRepository.getListToppingGroup(params)
    })
};

export const actCreateToppingGroup = (params: ToppingGroupCreateRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_TOPPING_GROUP_REQUEST, Types.CREATE_TOPPING_GROUP_SUCCESS, Types.CREATE_TOPPING_GROUP_FAILURE],
        callAPI: () => toppingGroupsRepository.createToppingGroup(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListToppingGroup({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}

export const actUpdateToppingGroup = (params: ToppingGroupUpdateRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.UPDATE_TOPPING_GROUP_REQUEST, Types.UPDATE_TOPPING_GROUP_SUCCESS, Types.UPDATE_TOPPING_GROUP_FAILURE],
        callAPI: () => toppingGroupsRepository.updateToppingGroup(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListToppingGroup({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}

export const actDeleteToppingGroup = (params: ToppingGroupDeleteRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.DELETE_TOPPING_GROUP_REQUEST, Types.DELETE_TOPPING_GROUP_SUCCESS, Types.DELETE_TOPPING_GROUP_FAILURE],
        callAPI: () => toppingGroupsRepository.deleteToppingGroup(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListToppingGroup({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}


