import { AnyAction } from 'redux';

import { createReducer } from 'src/core/utils/redux';
import * as Types from 'src/core/reduxs/coffee-core/topping-group/topping-group.type';
import ToppingGroupStateType from 'src/core/models/coffee-core/topping-group/topping-group-state.model';

const initialState: ToppingGroupStateType = {
    toppingGroups: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    toppingGroupDetail: {
        data: undefined,
        error: null,
        isLoading: false,
    },
    updateToppingGroup: {
        data: {},
        error: null,
        isLoading: false
    },
    createToppingGroup: {
        data: {},
        error: null,
        isLoading: false
    },
    deleteToppingGroup: {
        data: {},
        error: null,
        isLoading: false
    },
};

const toppingGroupReducer = createReducer(initialState, {
    [Types.GET_TOPPING_GROUPS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            toppingGroups: {
                ...state.toppingGroups,
                isLoading: true,
            }
        };
    },
    [Types.GET_TOPPING_GROUPS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            toppingGroups: {
                ...state.toppingGroups,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_TOPPING_GROUPS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            toppingGroups: {
                ...state.toppingGroups,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.CREATE_TOPPING_GROUP_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createToppingGroup: {
                ...state.createToppingGroup,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_TOPPING_GROUP_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createToppingGroup: {
                ...state.createToppingGroup,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_TOPPING_GROUP_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createToppingGroup: {
                ...state.createToppingGroup,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_TOPPING_GROUP_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateToppingGroup: {
                ...state.updateToppingGroup,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_TOPPING_GROUP_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateToppingGroup: {
                ...state.updateToppingGroup,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_TOPPING_GROUP_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateToppingGroup: {
                ...state.updateToppingGroup,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.DELETE_TOPPING_GROUP_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteToppingGroup: {
                ...state.deleteToppingGroup,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_TOPPING_GROUP_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteToppingGroup: {
                ...state.deleteToppingGroup,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.DELETE_TOPPING_GROUP_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteToppingGroup: {
                ...state.deleteToppingGroup,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default toppingGroupReducer;
