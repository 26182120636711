/** COMMON */
import * as Types from 'src/core/reduxs/coffee-core/firebase/firebase.type';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import NotificationCustom from 'src/core/models/notification-custom.model';

const commonRepository = Repositories[RepositoryName.COMMON];

export const actCreateMobileSession = (deviceToken: string) => (dispatch: any): void => {
    commonRepository.createFirebaseToken(deviceToken)
};

export const actReceiveNotification = (orderId: string, notification: NotificationCustom) => (dispatch: any): void => {
    dispatch({
        type: Types.RECEIVE_NOTIFICATION,
        payload: {
            orderId,
            notification
        }
    })
};

export const actClickNotification = (orderId: string) => (dispatch: any): void => {
    dispatch({
        type: Types.CLICK_NOTIFICATION,
        payload: orderId
    })
};

export const actUnsubscribeAllTopics = (deviceToken: string) => (dispatch: any): void => {
    commonRepository.unsubscribeAllTopics(deviceToken)
};
