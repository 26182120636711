import React from 'react';
import ReactDOM from 'react-dom';
import 'src/shared/assets/scss/index.scss';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import App from 'src/App';
import reportWebVitals from 'src/reportWebVitals';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import history from 'src/core/utils/history';
import appStore from 'src/core/reduxs/appStore';
import { I18nConfig } from 'src/core/utils/constants';
import { QueryParamProvider } from 'use-query-params';
import * as serviceWorkerRegistration from 'src/serviceWorkerRegistration';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';

ReactDOM.render(
    <Provider store={appStore}>
        <IntlProvider locale="vi" messages={I18nConfig['vi']}>
            <Router history={history}>
                <QueryParamProvider adapter={ReactRouter5Adapter}>
                    <App />
                </QueryParamProvider>
            </Router>
        </IntlProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
