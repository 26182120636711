import { MDBBtn, MDBFileInput, MDBIcon, MDBInput } from 'mdbreact'
import React, { useState, useEffect } from 'react'
import Category from 'src/core/models/coffee-core/category/category.model'
import { CategoryUpdateRequest } from 'src/core/models/coffee-core/category/category-request-params.model';
import { useDispatch, useSelector } from 'react-redux';
import * as CategoryAction from 'src/core/reduxs/coffee-core/category/category.action'
import PickDeep from 'src/core/models/pick-deep.modal'
import CategoryStateType from 'src/core/models/coffee-core/category/category-state.model'
import { toast } from 'react-hot-toast'
import { useIntl } from 'react-intl';
import { NOT_AVAILABLE_IMAGE } from 'src/core/utils/constants';
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component';

type Props = {
    isOpen: boolean,
    categoryDetail: Category,
    onModalClosed: () => void
}
const CategoryUpdateModalComponent = (props: Props): JSX.Element => {
    const { categoryDetail, isOpen, onModalClosed } = props;
    const intl = useIntl();
    const [categoryName, setCategoryName] = useState('')
    const [imageFile, setImageFile] = useState<File | null>(null)
    const dispatch = useDispatch()
    const updateCategory: PickDeep<CategoryStateType, 'updateCategory'> = useSelector((state: any) => state.categoryReducer.updateCategory)
    const onCloseModal = () => {
        setImageFile(null)
        onModalClosed()
    }
    useEffect(() => {
        setCategoryName(categoryDetail.name)
    }, [categoryDetail])

    const onSuccessUpdate = () => {
        onCloseModal();
        toast.success(intl.formatMessage({ id: 'CATEGORY.UPDATE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureUpdate = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'CATEGORY.UPDATE.TOAST_FAILURE' }), { duration: 3000 })
    }
    const handleUpdateTopping = () => {
        const formData = new FormData();
        formData.append('id', JSON.stringify(categoryDetail.id))
        formData.append('type', (categoryDetail.type))
        formData.append('name', (categoryName))
        if (imageFile) {
            if (imageFile?.type.includes('image')) {
                formData.append('image', imageFile)
            } else {
                toast.error(intl.formatMessage({ id: 'CATEGORY.UPDATE.TOAST_WARNING_NOT_IMAGE_FILE' }))
                return;
            }
        }
        const params: CategoryUpdateRequest = {
            data: formData,
            successCallback: onSuccessUpdate,
            errorCallback: onFailureUpdate
        }
        console.log(params)
        dispatch(CategoryAction.actUpdateCategory(params))
    }
    return (

        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'CATEGORY.UPDATE.TITLE' })}
            type='update'
        >
            <form
                onSubmit={(event: React.FormEvent) => { event.preventDefault(); handleUpdateTopping() }}
                className='needs-validation was-validated'
            >
                <MDBInput label={intl.formatMessage({ id: 'CATEGORY.UPDATE.LABEL_CODE' })} value={categoryDetail.code} disabled />
                <MDBInput label={intl.formatMessage({ id: 'CATEGORY.UPDATE.LABEL_TYPE' })} value={categoryDetail.type} disabled />
                <MDBInput
                    label={intl.formatMessage({ id: 'CATEGORY.UPDATE.LABEL_NAME' })}
                    required value={categoryName}
                    onChange={(event) => { setCategoryName(event.currentTarget.value) }}
                    disabled={updateCategory.isLoading}
                />
                <MDBFileInput
                    btnTitle={intl.formatMessage({ id: 'CATEGORY.UPDATE.LABEL_BUTTON_CHOOSE_IMAGE' })}
                    btnColor='indigo'
                    textFieldTitle={intl.formatMessage({ id: 'CATEGORY.UPDATE.LABEL_TITLE_CHOOSE_IMAGE' })}
                    getValue={(files: File[]) => setImageFile(files[0])}
                    accept="image/*"
                />
                {
                    imageFile && imageFile.type?.includes('image') && (
                        <img
                            src={URL.createObjectURL(imageFile as Blob)}
                            className='category-image-review'
                        />
                    )
                }
                {
                    !imageFile && (
                        <img
                            src={categoryDetail?.imgUrl || NOT_AVAILABLE_IMAGE}
                            className='category-image-review'
                        />
                    )
                }

                <div className='text-center mb-1-half'>
                    <MDBBtn
                        className='mb-2 '
                        color='indigo'
                        type="submit"
                        disabled={updateCategory.isLoading}
                    >
                        {intl.formatMessage({ id: 'CATEGORY.UPDATE.LABEL_BUTTON' })}
                        <MDBIcon icon='check' className='ml-3' />
                    </MDBBtn>
                </div>
            </form>
        </CustomModal>
    )
}

export default CategoryUpdateModalComponent
