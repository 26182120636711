import React from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader } from 'mdbreact';

type Props = {
    isVisible: boolean,
    setIsVisible: (value: boolean) => void,
    title: string,
    message?: string,
    onClickConfirm: () => void,
    color?: 'primary' | 'success' | 'info' | 'danger'
}

const ConfirmModal = (props: Props): JSX.Element => {
    return (
        <MDBModal
            isOpen={props.isVisible}
            fullHeight={false}
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll
        >
            <MDBModalHeader>{props.title}</MDBModalHeader>
            {
                props.message &&
                <MDBModalBody>
                    {props.message}
                </MDBModalBody>
            }
            <MDBModalFooter>
                <MDBBtn color={props.color || 'primary'} outline onClick={() => props.setIsVisible(false)}>Huỷ</MDBBtn>
                <MDBBtn color={props.color || 'primary'} onClick={props.onClickConfirm}>Xác nhận</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
}

export default ConfirmModal;
