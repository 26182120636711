import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import StoreState from 'src/core/reduxs/management/store/store.state';
import Types from 'src/core/reduxs/management/store/store.type';

const initialState: StoreState = {
    stores: {
        data: [] ,
        error: null,
        isLoading: false,
    }
};

const storeReducer = createReducer(initialState, {
    [Types.GET_LIST_STORE_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            stores: {
                ...state.stores,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_STORE_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            stores: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_STORE_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            stores: {
                ...state.stores,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default storeReducer;
