import { MDBModal, MDBModalHeader, MDBIcon, MDBModalBody } from 'mdbreact';
import React, { ReactNode } from 'react'

type Props = {
    isOpen: boolean;
    onCloseModal: () => void;
    title: string;
    type: 'create' | 'update' | 'delete';
    children: ReactNode
}

const options = [
    {
        type: 'create',
        icon: 'plus-square',
        className: 'green darken-3 white-text'
    },
    {
        type: 'update',
        icon: 'edit',
        className: 'indigo white-text'
    },
    {
        type: 'delete',
        icon: 'trash-alt',
        className: 'danger white-text'
    },
]
const CustomModal = (props: Props): JSX.Element => {
    const { isOpen, onCloseModal, title, children, type } = props
    return (
        <MDBModal
            animation="top"
            fade={true}
            size='md'
            isOpen={isOpen}
            toggle={onCloseModal}
            inline={false}
            noClickableBodyWithoutBackdrop
            overflowScroll
        >
            <MDBModalHeader
                style={{
                    backgroundColor: type === 'delete' ? '#ff3546' : ''
                }}
                className={options.filter((option) => option.type === type)[0]?.className}
            >
                <MDBIcon
                    icon={options.filter((option) => option.type === type)[0]?.icon}
                    className='mr-2'
                />{' '}
                {title}
            </MDBModalHeader>
            <MDBModalBody className='mb-0'>
                {children}
            </MDBModalBody>
        </MDBModal>
    )
}

export default CustomModal
