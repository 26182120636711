import React, { useCallback, useEffect } from 'react';
import { MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow, MDBSpinner } from 'mdbreact';
import ImportDetailCreationListComponent from 'src/features/components/import/import-detail-creation-list.component';
import ImportDetailCreateForm from 'src/features/components/import/import-detail-create-form.component';
import { actGetImportDetails, actUpdateImport } from 'src/core/reduxs/management/import/import.action';
import { useDispatch, useSelector } from 'react-redux';
import { Import, ImportItem, ImportItemRequest, UpdateImportRequest } from 'src/core/models/management/import.model';
import _ from 'lodash';
import {
    actAddImportDetailItem,
    actResetImportDetailItem
} from 'src/core/reduxs/management/import-detail/import-detail.action';
import { toast } from 'react-hot-toast';

type Props = {
    isVisible: boolean,
    setIsVisible: (value: boolean) => void,
    importCode: string,
}

const ImportUpdateModal = (props: Props): JSX.Element => {

    const dispatch = useDispatch();

    const importDetailsSelector = useSelector((state: any) => state.importReducer.importDetails);
    const importDetails: Import = importDetailsSelector.data;
    const importItems: ImportItem[] = useSelector((state: any) => state.importDetailReducer.createImportDetails.items);
    const updating = useSelector((state: any) => state.importReducer.updateImport.isLoading);

    useEffect(() => {
        if (props.isVisible && props.importCode) {
            dispatch(actGetImportDetails(props.importCode));
        }
    }, [props.isVisible, props.importCode]);

    useEffect(() => {
        const importItems = importDetails?.items;
        if (!_.isEmpty(importItems)) {
            dispatch(actResetImportDetailItem())
            for (const item of importItems) {
                const importItem: ImportItem = {
                    material: item.material,
                    quantity: item.quantity,
                    unitPrice: item.unitPrice,
                    price: item.price
                }
                dispatch(actAddImportDetailItem(importItem))
            }
        }
    }, [importDetails]);

    const onClickUpdate = () => {
        if (_.isEmpty(importDetails)) {
            toast.error('Bạn chưa nhập chi tiết phiếu nhập', { duration: 2000 })
            return
        }
        const importItemRequests: ImportItemRequest[] = importItems.map(e => ({
            materialId: e.material.id,
            unitPrice: e.unitPrice,
            quantity: e.quantity
        }))
        const request: UpdateImportRequest = {
            code: props.importCode,
            items: importItemRequests,
        }
        dispatch(actUpdateImport(request, onCreateSuccess))
    }

    const onCreateSuccess = () => {
        dispatch(actResetImportDetailItem())
        dispatch(actGetImportDetails(props.importCode));
        props.setIsVisible(false)
    }

    return (
        <MDBModal
            isOpen={props.isVisible}
            fullHeight={false}
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll
            size='fluid'
        >
            <MDBModalHeader>Cập nhật phiếu nhập</MDBModalHeader>
            <MDBModalBody>
                <MDBRow>
                    <MDBCol size='7'>
                        <ImportDetailCreationListComponent/>
                    </MDBCol>
                    <MDBCol size='5'>
                        <ImportDetailCreateForm/>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color='info' outline onClick={() => props.setIsVisible(false)}>Huỷ</MDBBtn>
                <MDBBtn color='info' onClick={onClickUpdate} disabled={updating}>Cập nhật</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
}

export default ImportUpdateModal;
