import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBDataTable, MDBRow, MDBSpinner, } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import 'src/features/pages/material-management/export/export.style.scss';
import { StringParam, useQueryParam } from 'use-query-params';
import { ExportItem } from 'src/core/models/management/export.model';
import { AppURI, ImportTypes } from 'src/core/utils/constants';
import { Link, useHistory } from 'react-router-dom';
import { currencyFormatter, getScreenShot } from 'src/core/utils/common';
import { Import } from 'src/core/models/management/import.model';
import { actGetImportDetails } from 'src/core/reduxs/management/import/import.action';
import { Income, IncomeItem } from 'src/core/models/management/income.model';
import { actGetIncomeDetails } from 'src/core/reduxs/management/income/income.action';
import TransactionDocumentsComponent from 'src/features/components/transaction/transaction-documents.component';

type DataTable = {
    no: number;
    categoryName: string;
    title: string;
    amount: string;
    [rest: string]: any;
}

const columns = [
    {
        label: 'STT',
        field: 'no',
    },
    {
        label: 'Loại',
        field: 'categoryName',
    },
    {
        label: 'Nội dung',
        field: 'title',
    },
    {
        label: 'Số tiền',
        field: 'amount',
    }
]

const IncomeDetailContainer = (): JSX.Element => {

    const [code, setCode] = useQueryParam('code', StringParam);
    const dispatch = useDispatch();
    const incomeDetailsSelector = useSelector((state: any) => state.incomeReducer.incomeDetails);
    const incomeDetails: Income = incomeDetailsSelector.data;
    const [dataTable, setDataTable] = useState<DataTable[]>([]);

    useEffect(() => {
        if (code) {
            dispatch(actGetIncomeDetails(code));
        }
    }, [code]);

    const renderDataRow = useCallback((incomeItemList: IncomeItem[]) => {
        const data: DataTable[] = [];
        incomeItemList?.forEach((item: IncomeItem, index: number) => {
            const row = {
                no: index + 1,
                categoryName: item.category.name,
                title: item.title,
                amount: currencyFormatter(item.amount)
            };
            data.push(row);
        });
        setDataTable(data);
    }, [])

    useEffect(() => {
        if (incomeDetails) {
            renderDataRow(incomeDetails.items);
        }
    }, [incomeDetails]);

    const renderDataTable = useMemo(() => {
        return (
            <MDBDataTable
                className='common-table'
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không tìm thấy mục thu nào"
                data={{columns: columns, rows: dataTable}}
            />
        )
    }, [dataTable]);

    return (
        <MDBContainer className='py-3' fluid>
            {!incomeDetails || incomeDetailsSelector.isLoading ?
                <div className='m-5 text-center'>
                    <MDBSpinner/>
                </div>
                :
                <Fragment>
                    <div id='income-details' className='px-2 pt-2'>
                        <div className='text-center'>
                            <p className='font-size-20'>Chi tiết phiếu thu</p>
                        </div>
                        <MDBRow>
                            <MDBCol size='12'>
                                <div>
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Mã phiếu</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{incomeDetails.code}</div>
                                        </MDBCol>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Nội dung</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{incomeDetails.title}</div>
                                        </MDBCol>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Cửa hàng</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{incomeDetails.store.name}</div>
                                        </MDBCol>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Tổng số tiền</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{currencyFormatter(incomeDetails.totalAmount)}</div>
                                        </MDBCol>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Ví thu</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{incomeDetails.deposit.name}</div>
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                            </MDBCol>
                            <MDBCol size='12'>
                                <div className="font-weight-normal mt-4">DANH SÁCH MỤC THU</div>
                                <MDBCard className='container-fluid mt-2'>
                                    <MDBCardBody>
                                        {renderDataTable}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </div>
                    <MDBRow className='px-2'>
                        <MDBCol size='12'>
                            <div className='mt-3 text-center'>
                                <MDBBtn
                                    color="dark-green"
                                    onClick={() => getScreenShot('income-details')}
                                >
                                    Chụp màn hình
                                </MDBBtn>
                            </div>
                            <TransactionDocumentsComponent
                                transactionId={incomeDetails.transaction?.id}
                            />
                            <div className='mt-3'>
                                <Link to={AppURI.INCOME}>&larr; Danh sách phiếu thu</Link>
                            </div>
                        </MDBCol>
                    </MDBRow>
                </Fragment>
            }
        </MDBContainer>
    );
}

export default React.memo(IncomeDetailContainer);
