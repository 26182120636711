import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBDatePicker, MDBInput, MDBRow } from 'mdbreact';
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { Coupon, CreateCouponCodesRequest, UserCouponItem } from 'src/core/models/coffee-core/coupon.model';
import {
    actCreateCouponCodes,
    actGetListCouponByTypes,
    actGetListCouponCode
} from 'src/core/reduxs/coffee-core/coupon/coupon.action';
import _ from 'lodash';
import Select from 'react-select';
import Required from 'src/shared/components/required/required.component';
import { formatDateForApi } from 'src/core/utils/common';

type Props = {
    tenantId: number;
}

const CouponCodeCreateForm = (props: Props): JSX.Element => {
    const dispatch = useDispatch()
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const coupons: Coupon[] = useSelector((state: any) => state.couponReducer.coupons.data);

    useEffect(() => {
        dispatch(actGetListCouponByTypes(['PUBLIC'], props.tenantId));
    }, [props.tenantId]);

    const couponOptions = useMemo(() => {
        if (!_.isEmpty(coupons)) {
            return coupons.map((c: Coupon) => ({
                label: c.title,
                value: c.id
            }));
        }
        return [];
    }, [coupons]);

    const onSubmit = (data: any) => {
        const request: CreateCouponCodesRequest = {
            couponId: Number(data.couponId),
            numberOfCodes: Number(data.numberOfCodes),
            expirationDate: data.expirationDate,
            tenantId: props.tenantId
        }
        dispatch(actCreateCouponCodes(request, () => {
            dispatch(actGetListCouponCode(props.tenantId))
        }))
    }

    return (
        <MDBCard className='container-fluid'>
            <MDBCardBody>
                <p className="font-weight-normal mt-3">TẠO MÃ ƯU ĐÃI</p>
                <MDBRow className='pb-3'>
                    <MDBCol md="4">
                        <div className="pt-2">Ưu đãi<Required/></div>
                    </MDBCol>
                    <MDBCol md="8">
                        <Select
                            {...register('couponId', {
                                required: 'Ưu đãi là bắt buộc'
                            })}
                            options={couponOptions}
                            onChange={(newValue) => {
                                if (newValue) {
                                    const couponId = newValue.value
                                    setValue('couponId', couponId);
                                }
                            }}
                            placeholder='(chọn ưu đãi)'
                            className='max-width-400 my-1'
                        />
                        {errors.couponId && <ErrorMessage>{errors.couponId.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol md="4">
                        <div className="pt-2">Số lượng mã<Required/></div>
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBInput
                            {...register('numberOfCodes', {
                                required: 'Số lượng mã là bắt buộc'
                            })}
                            placeholder='Nhập số lượng mã cần tạo'
                            onChange={(e: any) => {
                                setValue('numberOfCodes', e.target.value)
                            }}
                            containerClass='my-0'
                        />
                        {errors.numberOfCodes && <ErrorMessage>{errors.numberOfCodes.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol md="4" className='flex-row'>
                        <div className="pt-2">Ngày hết hạn</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBDatePicker
                            {...register('expirationDate')}
                            clearable
                            format='DD-MM-YYYY'
                            autoOk
                            getValue={(e) => {
                                setValue('expirationDate', e ? formatDateForApi(e) : null)
                            }}
                            className='mt-0'
                            valueDefault={null as any}
                            emptyLabel='(chọn ngày)'
                        />
                        {errors.expirationDate && <ErrorMessage>{errors.expirationDate.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol size='12'>
                        <div className='text-center'>
                            <MDBBtn color="primary" onClick={handleSubmit(onSubmit)}>Tạo mã ưu đãi</MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    )
}

export default React.memo(CouponCodeCreateForm)
