const Types = {
    ACCUMULATE_BLACK_PEARL_REQUEST: 'ACCUMULATE_BLACK_PEARL_REQUEST',
    ACCUMULATE_BLACK_PEARL_SUCCESS: 'ACCUMULATE_BLACK_PEARL_SUCCESS',
    ACCUMULATE_BLACK_PEARL_FAILURE: 'ACCUMULATE_BLACK_PEARL_FAILURE',
}
export default Types;




