import {combineReducers} from 'redux';
import commonReducer from 'src/core/reduxs/common/common.reducer';
import orderReducer from 'src/core/reduxs/coffee-core/order/order.reducer';
import firebaseReducer from 'src/core/reduxs/coffee-core/firebase/firebase.reducer';
import toppingReducer from 'src/core/reduxs/coffee-core/topping/topping.reducer';
import toppingGroupReducer from 'src/core/reduxs/coffee-core/topping-group/topping-group.reducer';
import sizeReducer from 'src/core/reduxs/coffee-core/size/size.reducer';
import sizeGroupReducer from 'src/core/reduxs/coffee-core/size-group/size-group.reducer';
import categoryReducer from 'src/core/reduxs/coffee-core/category/category.reducer';
import productReducer from 'src/core/reduxs/coffee-core/product/product.reducer';
import blackPearlCodeReducer from 'src/core/reduxs/coffee-core/black-pearl-code/black-pearl-code.reducer';
import supplierReducer from 'src/core/reduxs/management/supplier/supplier.reducer';
import stockReducer from 'src/core/reduxs/management/stock/stock.reducer';
import partnerReducer from 'src/core/reduxs/management/partner/partner.reducer';
import materialReducer from 'src/core/reduxs/management/material/material.reducer';
import exportDetailReducer from 'src/core/reduxs/management/export-detail/export-detail.reducer';
import exportReducer from 'src/core/reduxs/management/export/export.reducer';
import importReducer from 'src/core/reduxs/management/import/import.reducer';
import importDetailReducer from 'src/core/reduxs/management/import-detail/import-detail.reducer';
import depositReducer from 'src/core/reduxs/management/deposit/deposit.reducer';
import storeReducer from 'src/core/reduxs/management/store/store.reducer';
import incomeReducer from 'src/core/reduxs/management/income/income.reducer';
import outcomeReducer from 'src/core/reduxs/management/outcome/outcome.reducer';
import transactionReducer from 'src/core/reduxs/management/transaction/transaction.reducer';
import newsReducer from 'src/core/reduxs/coffee-core/news/news.reducer';
import guestOrderReducer from 'src/core/reduxs/coffee-core/guest/guest-order.reducer';
import couponReducer from 'src/core/reduxs/coffee-core/coupon/coupon.reducer';
import tenantReducer from 'src/core/reduxs/coffee-core/tenant/tenant.reducer';
import userReducer from 'src/core/reduxs/coffee-core/user/user.reducer';
import blackPearlReducer from 'src/core/reduxs/coffee-core/black-pearl/black-pearl.reducer';

const appReducers = combineReducers({
    commonReducer,
    userReducer,
    orderReducer,
    firebaseReducer,
    toppingReducer,
    toppingGroupReducer,
    sizeReducer,
    sizeGroupReducer,
    categoryReducer,
    productReducer,
    blackPearlCodeReducer,
    blackPearlReducer,
    supplierReducer,
    stockReducer,
    partnerReducer,
    materialReducer,
    importReducer,
    importDetailReducer,
    exportReducer,
    exportDetailReducer,
    depositReducer,
    storeReducer,
    incomeReducer,
    outcomeReducer,
    transactionReducer,
    newsReducer,
    guestOrderReducer,
    couponReducer,
    tenantReducer,
});

export default appReducers;
