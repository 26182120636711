import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBRow, MDBSpinner, } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import 'src/features/pages/material-management/export/export.style.scss';
import { NumberParam, useQueryParam } from 'use-query-params';
import { actGetNewsDetails } from 'src/core/reduxs/coffee-core/news/news.action';
import { News, NewsTypes } from 'src/core/models/coffee-core/news.model';
import moment from 'moment/moment';
import { AppURI, DATETIME_FORMAT } from 'src/core/utils/constants';
import NewsUpdateModal from 'src/features/components/news/news-update-modal.component';
import { Link } from 'react-router-dom';

const NewsDetailContainer = (): JSX.Element => {

    const [id, setId] = useQueryParam('id', NumberParam);
    const dispatch = useDispatch();
    const newsDetailsSelector = useSelector((state: any) => state.newsReducer.newsDetails);
    const newsDetails: News = newsDetailsSelector.data;
    const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false);

    const newsTypeMap = useMemo(() => {
        const map: { [key: string]: string } = {};
        for (const newsType of NewsTypes) {
            map[newsType.type] = newsType.name;
        }
        return map;
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(actGetNewsDetails(id));
        }
    }, [id]);

    return (
        <MDBContainer className='py-3' fluid>
            <Fragment>
                {!newsDetails || newsDetailsSelector.isLoading ?
                    <div className='m-5 text-center'>
                        <MDBSpinner/>
                    </div>
                    :

                    <div id='income-details' className='px-2 pt-2'>
                        <div className='text-center'>
                            <p className='font-size-20'>Chi tiết tin tức</p>
                        </div>
                        <MDBRow>
                            <MDBCol size='12'>
                                <div>
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Tiêu đề</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{newsDetails.title}</div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Brand</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{newsDetails.tenant.name}</div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Loại tin tức</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{newsDetails.type.name}</div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Danh mục</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{newsDetails.category.name}</div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Mô tả ngắn</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{newsDetails.shortContent}</div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Nội dung</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div style={{
                                                whiteSpace: 'pre-wrap',
                                                borderWidth: 1,
                                                borderColor: 'black'
                                            }}>{newsDetails.content}</div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Ngày đăng</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div>{moment(newsDetails.createdTime).format(DATETIME_FORMAT)}</div>
                                        </MDBCol>
                                    </MDBRow>
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="2">
                                            <div className='font-weight-normal'>Hình ảnh</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            {newsDetails.thumbnail &&
                                                <img
                                                    src={newsDetails.thumbnail}
                                                    alt='image'
                                                    height={400}
                                                />
                                            }
                                        </MDBCol>
                                    </MDBRow>
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='px-2'>
                            <MDBCol size='12'>
                                <div className='mt-3 text-center'>
                                    <MDBBtn
                                        color="primary"
                                        onClick={() => setIsVisibleUpdateModal(true)}
                                    >
                                        Chỉnh sửa
                                    </MDBBtn>
                                </div>
                                <div className='mt-3'>
                                    <Link to={AppURI.NEWS}>&larr; Danh sách tin tức</Link>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>
                }
                {
                    id && <NewsUpdateModal
                        isVisible={isVisibleUpdateModal}
                        setIsVisible={setIsVisibleUpdateModal}
                        newsId={id}
                    />
                }
            </Fragment>
        </MDBContainer>
    );
}

export default React.memo(NewsDetailContainer);
