import {AnyAction} from 'redux';
import {createReducer} from 'src/core/utils/redux';
import * as Types from 'src/core/reduxs/coffee-core/black-pearl-code/black-pearl-code.type';
import BlackPearlCodeState from 'src/core/reduxs/coffee-core/black-pearl-code/black-pearl-code.state';

const initialState: BlackPearlCodeState = {
    blackPearlCodes: {
        data: [] ,
        error: null,
        isLoading: false,
    }
};

const blackPearlCodeReducer = createReducer(initialState, {
    [Types.GET_LIST_BLACK_PEARL_CODE_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            blackPearlCodes: {
                ...state.blackPearlCodes,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_BLACK_PEARL_CODE_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            blackPearlCodes: {
                ...state.blackPearlCodes,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_BLACK_PEARL_CODE_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            blackPearlCodes: {
                ...state.blackPearlCodes,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default blackPearlCodeReducer;
