/** COMMON */
import * as Types from 'src/core/reduxs/coffee-core/size-group/size-group.type';
import PagingRequest from 'src/core/models/paging-request.model'
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import { SizeGroupCreateRequest, SizeGroupUpdateRequest, SizeGroupDeleteRequest} from 'src/core/models/coffee-core/size-group/size-group-request-params.model';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
const sizeGroupsRepository = Repositories[RepositoryName.SIZE_GROUP];

export const actGetListSizeGroup = (params: PagingRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_SIZE_GROUPS_REQUEST, Types.GET_SIZE_GROUPS_SUCCESS, Types.GET_SIZE_GROUPS_FAILURE],
        callAPI: () => sizeGroupsRepository.getListSizeGroup(params)
    })
};

export const actCreateSizeGroup = (params: SizeGroupCreateRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_SIZE_GROUP_REQUEST, Types.CREATE_SIZE_GROUP_SUCCESS, Types.CREATE_SIZE_GROUP_FAILURE],
        callAPI: () => sizeGroupsRepository.createSizeGroup(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListSizeGroup({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}

export const actUpdateSizeGroup = (params: SizeGroupUpdateRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.UPDATE_SIZE_GROUP_REQUEST, Types.UPDATE_SIZE_GROUP_SUCCESS, Types.UPDATE_SIZE_GROUP_FAILURE],
        callAPI: () => sizeGroupsRepository.updateSizeGroup(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListSizeGroup({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}

export const actDeleteSizeGroup = (params: SizeGroupDeleteRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.DELETE_SIZE_GROUP_REQUEST, Types.DELETE_SIZE_GROUP_SUCCESS, Types.DELETE_SIZE_GROUP_FAILURE],
        callAPI: () => sizeGroupsRepository.deleteSizeGroup(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListSizeGroup({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}


