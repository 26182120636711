import BaseRepository from 'src/core/repositories/base.repository';
import { CreateImportRequest } from 'src/core/models/management/import.model';
import * as CONST from 'src/core/utils/constants';
import ResponseCustom from 'src/core/models/response-custom.model';
import { AxiosResponse } from 'axios';
import { Stock } from 'src/core/models/management/stock.model';

export default class DepositRepository extends BaseRepository {

    getListDepositOfStore(storeId: number): Promise<AxiosResponse<Stock[]>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_LIST_DEPOSIT_OF_STORE).query({storeId});
    }
}
