/**LIBRARY */
import React, { useState, useEffect, useMemo, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import {
    MDBContainer,
    MDBDataTable,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBBadge,
    MDBSpinner,
    MDBSelect,
    MDBBtn,
    MDBIcon
} from 'mdbreact';
import { useIntl } from 'react-intl';
import moment from 'moment';
import * as OrderAction from 'src/core/reduxs/coffee-core/order/order.action';
import * as CONST from 'src/core/utils/constants';
import * as COMMON from 'src/core/utils/common';
import { Order } from 'src/core/models/coffee-core/order.model';
import _ from 'lodash';
import Theme from 'src/core/utils/theme';
import Pagination from 'src/shared/components/pagination/pagination.component';
import PagingResponsee from 'src/core/models/paging-response.model'
import PageHeader from 'src/shared/components/page-header/page-header.component'

type DataTable = {
    code: string;
    createdTime: string;
    updatedTime: string;
    note: JSX.Element;
    address: string;
    totalPrice: string;
    status: JSX.Element;
    method: JSX.Element;
    [rest: string]: any
}

type Props = {
    orders: PagingResponsee<Order>,
    isLoading: boolean,
    getOrderDetail: any
}

const columns = [
    {
        label: '#',
        field: 'code',
    },
    {
        label: 'Địa chỉ',
        field: 'address',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Thành tiền',
        field: 'totalPrice',
        sort: 'asc',
        width: 150
    },
    {
        label: 'Ghi chú',
        field: 'note',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Trạng thái',
        field: 'status',
        sort: 'asc',
        width: 200
    },
    {
        label: 'Phương thức',
        field: 'method',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Ngày tạo',
        field: 'createdTime',
        sort: 'asc',
        width: 100
    },
    {
        label: 'Ngày cập nhật',
        field: 'updatedTime',
        sort: 'asc',
        width: 100
    },
]

const initDataTable: DataTable[] = [];

const OrderListComponent = (props: Props, ref: any): JSX.Element => {

    const { isLoading, orders, getOrderDetail } = props;
    const intl = useIntl();
    const dispatch = useDispatch()
    const [spin, setSpin] = useState(false);
    const [dataTable, setDataTable] = useState(initDataTable);
    const [orderStatus, setOrderStatus] = useState(
        new Set<string>([
            CONST.ORDER_STATUS.PENDING.key,
            CONST.ORDER_STATUS.CONFIRMED.key,
            CONST.ORDER_STATUS.PREPARING.key,
            CONST.ORDER_STATUS.DELIVERING.key
        ])
    );

    const prepareDataRow = (listOrder: Order[]) => {
        const data: DataTable[] = [];
        listOrder.forEach((item: Order, index: number) => {
            const colorStatus = _.get(Theme.color.orderStatus, `${item.status}`);
            const colorMethod = _.get(Theme.color.orderMethod, `${item.method}`);

            const row = {
                id: item.id,
                code: item.code || '',
                createdTime: `${moment(item.createdTime).format('DD-MM-YYYY HH:mm')}`,
                updatedTime: `${moment(item.updatedTime).format('DD-MM-YYYY HH:mm')}`,
                note: <p style={{ whiteSpace: 'pre-wrap' }}>{item.note || ''}</p>,
                address: item.address || '',
                totalPrice: COMMON.currencyFormatter(item.totalPrice || 0),
                status: <h5><MDBBadge pill color={colorStatus} className='w-100'>{_.get(CONST.ORDER_STATUS, `${item.status}.value`, '')}</MDBBadge></h5>,
                method: <h5><MDBBadge pill color={colorMethod} className='w-100'>{_.get(CONST.ORDER_METHOD, `${item.method}.value`, '')}</MDBBadge></h5>,
                clickEvent: (row: any) => getOrderDetail(row)
            };
            data.push(row);
        });
        setDataTable(data)
    }

    const onPageChange = (activePage: number) => {
        const params = {
            pageId: activePage - 1,
            orderStatus: _.isEmpty(orderStatus) ? '' : _.toString(Array.from(orderStatus))
        }
        dispatch(OrderAction.actGetListOrder(params))
    }

    useImperativeHandle(
        ref,
        () => {
            return {
                onPageChange: onPageChange
            }
        }
    )

    const onFilterStatus = () => {
        onPageChange(1);
    }

    const onFilterStatusChange = (values: string[]) => {
        if (_.isEqual(orderStatus, new Set(values))) return;

        setOrderStatus(new Set(values));
    }

    const mStatusOptions = useMemo(() => {
        return _.map(CONST.ORDER_STATUS, (status) => {
            return {
                text: status.value,
                value: status.key,
                checked: orderStatus.has(status.key)
            }
        })
    }, [orderStatus])

    const refreshData = () => {
        setSpin(true);
        onPageChange(1);
    }

    useEffect(() => {
        refreshData();
    }, [])

    useEffect(() => {
        prepareDataRow(orders.document);
    }, [orders.document])

    const mDataTable = useMemo(() => {
        if (!isLoading) setSpin(false);
        if (isLoading) return <MDBCardBody className="d-flex justify-content-center"><MDBSpinner small /></MDBCardBody>;

        return (
            <MDBDataTable
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không tìm thấy sản phẩm nào"
                data={{ columns: columns, rows: dataTable }}
            />
        )
    }, [dataTable, isLoading])

    return (
        <div className="table">
            <PageHeader title='ORDER.LABEL.LIST' />
            <MDBContainer className='m-0 p-0' fluid>
                <MDBRow className='m-0 p-0 py-0'>
                    <MDBCol className='m-0 p-0' md='12'>
                        <MDBCard>
                            <MDBRow className='px-3 align-items-center'>
                                <MDBCol md='6'>
                                    <MDBSelect
                                        color="primary"
                                        options={mStatusOptions}
                                        selected="Chọn trạng thái tìm kiếm"
                                        label="Chọn trạng thái"
                                        multiple
                                        selectAll
                                        selectAllLabel="Chọn tất cả"
                                        getValue={onFilterStatusChange}
                                    />
                                </MDBCol>
                                <MDBCol md='3'>
                                    <MDBBtn color='primary' onClick={onFilterStatus}>
                                        Lọc
                                    </MDBBtn>
                                </MDBCol>
                                <MDBCol md='3'>
                                    <h1 className='d-flex align-items-center justify-content-center'>
                                        <MDBIcon icon="sync-alt" onClick={refreshData} spin={spin} />
                                    </h1>
                                </MDBCol>
                            </MDBRow>
                            <MDBCardBody>
                                {mDataTable}
                                <Pagination
                                    activeIndex={props.orders.pageId + 1}
                                    itemCount={props.orders.itemCount}
                                    pageSize={CONST.DEFAULT_PAGE_SIZE}
                                    onPageChange={onPageChange}
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}



export default React.memo(React.forwardRef(OrderListComponent));
