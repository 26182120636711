import { MDBBtn, MDBIcon, } from 'mdbreact'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as SizeAction from 'src/core/reduxs/coffee-core/size/size.action'
import PickDeep from 'src/core/models/pick-deep.modal'
import { toast } from 'react-hot-toast'
import { useIntl } from 'react-intl';
import Size from 'src/core/models/coffee-core/size/size.model';
import SizeStateType from 'src/core/models/coffee-core/size/size-state.model';
import { SizeDeleteRequest } from 'src/core/models/coffee-core/size/size-request-params.model';
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component';


type Props = {
    isOpen: boolean,
    sizeDetail: Size,
    onModalClosed: () => void
}
const SizeDeleteModalComponent = (props: Props): JSX.Element => {
    const { sizeDetail, isOpen, onModalClosed } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const deleteSize: PickDeep<SizeStateType, 'deleteSize'> = useSelector((state: any) => state.sizeReducer.deleteSize)
    const onCloseModal = () => {
        onModalClosed()
    }

    const onSuccessDelete = () => {
        onCloseModal();
        toast.success(intl.formatMessage({ id: 'SIZE.DELETE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureDelete = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'SIZE.DELETE.TOAST_FAILURE' }), { duration: 3000 })
    }
    const handleDeleteSize = () => {
        const params: SizeDeleteRequest = {
            data: {
                id: sizeDetail.id,
            },
            successCallback: onSuccessDelete,
            errorCallback: onFailureDelete
        }
        dispatch(SizeAction.actDeleteSize(params))
    }
    return (
        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'SIZE.DELETE.TITLE' })}
            type='delete'
        >
            <div className='text-center my-3'>{`${intl.formatMessage({ id: 'SIZE.DELETE.DESCRIPTION' })} ${sizeDetail.name}`}</div>
            <div className='text-center mb-1-half'>
                <MDBBtn
                    className='mb-2'
                    outline
                    color="danger"
                    disabled={deleteSize.isLoading}
                    onClick={onCloseModal}
                >
                    {intl.formatMessage({ id: 'SIZE.DELETE.LABEL_CANCEL_BUTTON' })}
                    <MDBIcon icon='times' className='ml-3' />
                </MDBBtn>
                <MDBBtn
                    className='mb-2 '
                    color="danger"
                    onClick={handleDeleteSize}
                    disabled={deleteSize.isLoading}
                >
                    {intl.formatMessage({ id: 'SIZE.DELETE.LABEL_CONFIRM_BUTTON' })}
                    <MDBIcon icon='check' className='ml-3' />
                </MDBBtn>
            </div>
        </CustomModal>
    )
}

export default SizeDeleteModalComponent
