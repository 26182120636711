import * as Types from 'src/core/reduxs/common/common.type';
import * as CONST from 'src/core/utils/constants';
import * as COMMON from 'src/core/utils/common';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import { AxiosResponse } from 'axios';
import LoginInfo from 'src/core/models/login-info.model';
import { UserInfo } from 'src/core/models/user-info.model';
import City from 'src/core/models/city.model';

const commonRepository = Repositories[RepositoryName.COMMON];

export const actLogin = (userName: string, password: string) => (dispatch: any): void => {
    dispatch({
        types: [Types.PERFORM_LOGIN_REQUEST, Types.PERFORM_LOGIN_SUCCESS, Types.PERFORM_LOGIN_FAILURE],
        callAPI: () => commonRepository.login(userName, password),
        callBack: {
            success: (response: AxiosResponse<LoginInfo>) => {
                COMMON.setLocalStorage(CONST.LocalStorage.LOGIN_INFO, response.data);
                dispatch(actGetUserInfo());
            }
        }
    })
};

export const actGetUserInfo = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_USER_INFO_REQUEST, Types.GET_USER_INFO_SUCCESS, Types.GET_USER_INFO_FAILURE],
        callAPI: () => commonRepository.getUserInfo(),
        callBack: {
            success: (response: AxiosResponse<UserInfo>) => {
                COMMON.setLocalStorage(CONST.LocalStorage.USER_INFO, response.data);
            }
        }
    })
};


export const actResetLoginState = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_LOGIN_STATE,
    });
};
