import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import Types from 'src/core/reduxs/management/export/export.type';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import { CreateExportRequest, UpdateExportRequest } from 'src/core/models/management/export.model';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';
import { toast } from 'react-hot-toast';

const exportRepository = Repositories[RepositoryName.EXPORT];

export const actCreateExport = (request: CreateExportRequest, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_EXPORT_REQUEST, Types.CREATE_EXPORT_SUCCESS, Types.CREATE_EXPORT_FAILURE],
        callAPI: () => exportRepository.createExport(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
            },
            failure: (error: AxiosError) => {
                errorCallback()
            }
        }
    })
}

export const actSearchExport = (request: SearchAndFilterRequest) => (dispatch: any) : void => {
    dispatch({
        types: [Types.SEARCH_EXPORT_REQUEST, Types.SEARCH_EXPORT_SUCCESS, Types.SEARCH_EXPORT_FAILURE],
        callAPI: () => exportRepository.searchExport(request),
    })
}

export const actGetExportDetails = (code: string) => (dispatch: any) : void => {
    dispatch({
        types: [Types.GET_EXPORT_DETAILS_REQUEST, Types.GET_EXPORT_DETAILS_SUCCESS, Types.GET_EXPORT_DETAILS_FAILURE],
        callAPI: () => exportRepository.getExportDetails(code),
    })
}

export const actDeleteExport = (code: string, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.DELETE_EXPORT_REQUEST, Types.DELETE_EXPORT_SUCCESS, Types.DELETE_EXPORT_FAILURE],
        callAPI: () => exportRepository.deleteExport(code),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Xoá phiếu xuất thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                errorCallback()
                toast.error('Xoá phiếu xuất thất bại', { duration: 2000 })
            }
        }
    })
}

export const actUpdateExport = (request: UpdateExportRequest, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    toast.loading('Đang cập nhật', {
        id: 'exportUpdating',
    });
    dispatch({
        types: [Types.UPDATE_EXPORT_REQUEST, Types.UPDATE_EXPORT_SUCCESS, Types.UPDATE_EXPORT_FAILURE],
        callAPI: () => exportRepository.updateExport(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.dismiss('exportUpdating');
                toast.success('Cập nhật phiếu xuất thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                errorCallback()
                toast.dismiss('exportUpdating');
                toast.error('Cập nhật phiếu xuất thất bại', { duration: 2000 })
            }
        }
    })
}
