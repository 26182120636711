import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBDataTable, MDBRow, MDBSpinner, } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import 'src/features/pages/material-management/export/export.style.scss';
import { StringParam, useQueryParam } from 'use-query-params';
import { Export, ExportItem } from 'src/core/models/management/export.model';
import { actGetExportDetails } from 'src/core/reduxs/management/export/export.action';
import { AppURI, ExportTypes } from 'src/core/utils/constants';
import { Link, useHistory } from 'react-router-dom';
import { currencyFormatter, getScreenShot } from 'src/core/utils/common';
import TransactionDocumentsComponent from 'src/features/components/transaction/transaction-documents.component';
import ExportUpdateModal from 'src/features/components/export/export-update-modal.component';

type DataTable = {
    no: number;
    materialName: string;
    unitPrice: string;
    quantity: number;
    price: string;
    [rest: string]: any;
}

const columns = [
    {
        label: 'STT',
        field: 'no',
    },
    {
        label: 'Nguyên liệu',
        field: 'materialName',
    },
    {
        label: 'Đơn giá',
        field: 'unitPrice',
    },
    {
        label: 'Số lượng',
        field: 'quantity',
    },
    {
        label: 'Giá',
        field: 'price',
    }
]

const ExportDetailContainer = (): JSX.Element => {

    const history = useHistory();
    const [code, setCode] = useQueryParam('code', StringParam);
    const dispatch = useDispatch();
    const exportDetailsSelector = useSelector((state: any) => state.exportReducer.exportDetails);
    const exportDetails: Export = exportDetailsSelector.data;
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [isVisibleUpdateModal, setIsVisibleUpdateModal] = useState(false);

    useEffect(() => {
        if (code) {
            dispatch(actGetExportDetails(code));
        }
    }, [code]);

    const renderDataRow = useCallback((exportItemList: ExportItem[]) => {
        const data: DataTable[] = [];
        exportItemList?.forEach((item: ExportItem, index: number) => {
            const row = {
                no: index + 1,
                code: item.code,
                materialName: item.material.name,
                unitPrice: currencyFormatter(item.unitPrice),
                quantity: item.quantity,
                price: currencyFormatter(item.price)
            };
            data.push(row);
        });
        setDataTable(data);
    }, [])

    useEffect(() => {
        if (exportDetails) {
            renderDataRow(exportDetails.items);
        }
    }, [exportDetails]);

    const renderDataTable = useMemo(() => {
        return (
            <MDBDataTable
                className='common-table'
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không tìm thấy mục xuất nào"
                data={{columns: columns, rows: dataTable}}
            />
        )
    }, [dataTable]);

    return (
        <Fragment>
            <MDBContainer className='py-3' fluid>
                {!exportDetails || exportDetailsSelector.isLoading ?
                    <div className='m-5 text-center'>
                        <MDBSpinner/>
                    </div>
                    :
                    <Fragment>
                        <div id='export-details' className='px-2 pt-2'>
                            <div className='text-center'>
                                <p className='font-size-20'>Chi tiết phiếu xuất</p>
                            </div>
                            <MDBRow>
                                <MDBCol size='12'>
                                    <div>
                                        <MDBRow className='mt-2'>
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Kho</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                <div>{exportDetails.stock.name}</div>
                                            </MDBCol>
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Mã phiếu</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                <div>{exportDetails.code}</div>
                                            </MDBCol>
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Loại xuất</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                <div>{ExportTypes[exportDetails.type]}</div>
                                            </MDBCol>
                                            {
                                                exportDetails.partner &&
                                                <Fragment>
                                                    <MDBCol md="2">
                                                        <div className='font-weight-normal'>Xuất đến</div>
                                                    </MDBCol>
                                                    <MDBCol md="10">
                                                        <div>{exportDetails.partner.name}</div>
                                                    </MDBCol>
                                                </Fragment>
                                            }
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Nội dung</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                <div>{exportDetails.title}</div>
                                            </MDBCol>
                                            {
                                                exportDetails.actorDeposit &&
                                                <Fragment>
                                                    <MDBCol md="2">
                                                        <div className='font-weight-normal'>Ví chi</div>
                                                    </MDBCol>
                                                    <MDBCol md="10">
                                                        <div>{exportDetails.actorDeposit.name}</div>
                                                    </MDBCol>
                                                </Fragment>
                                            }
                                            <MDBCol md="2">
                                                <div className='font-weight-normal'>Tổng số tiền</div>
                                            </MDBCol>
                                            <MDBCol md="10">
                                                <div>{currencyFormatter(exportDetails.totalPrice)}</div>
                                            </MDBCol>
                                        </MDBRow>
                                    </div>
                                </MDBCol>
                                <MDBCol size='12'>
                                    <div className="font-weight-normal mt-4">DANH SÁCH MỤC XUẤT</div>
                                    <MDBCard className='container-fluid mt-2'>
                                        <MDBCardBody>
                                            {renderDataTable}
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </div>
                        <MDBRow className='px-2'>
                            <MDBCol size='12'>
                                <div className='mt-3 text-center'>
                                    <MDBBtn
                                        color="primary"
                                        onClick={() => setIsVisibleUpdateModal(true)}
                                    >
                                        Chỉnh sửa
                                    </MDBBtn>
                                    <MDBBtn
                                        color="dark-green"
                                        onClick={() => getScreenShot('export-details')}
                                    >
                                        Chụp màn hình
                                    </MDBBtn>
                                </div>
                                <TransactionDocumentsComponent
                                    transactionId={exportDetails.transaction?.id}
                                />
                                <div className='mt-3'>
                                    <Link to={AppURI.EXPORT}>&larr; Danh sách phiếu xuất</Link>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </Fragment>
                }
            </MDBContainer>
            {
                code &&
                <ExportUpdateModal
                    isVisible={isVisibleUpdateModal}
                    setIsVisible={setIsVisibleUpdateModal}
                    exportCode={code}
                />
            }
        </Fragment>
    );
}

export default React.memo(ExportDetailContainer);
