import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import PagingRequest from 'src/core/models/paging-request.model';
import ResponseCustom from 'src/core/models/response-custom.model';
import {
    CategoryCreateRequest,
    CategoryUpdateRequest,
    CategoryDeleteRequest
} from 'src/core/models/coffee-core/category/category-request-params.model';
import Category from 'src/core/models/coffee-core/category/category.model';

export default class CategoryRepository extends BaseRepository {
    constructor() {
        super('');
    }

    getListCategory(params: PagingRequest): Promise<AxiosResponse<Category[]>> {
        const request = {
            ...params,
            itemsPerPage: CONST.DEFAULT_PAGE_SIZE,
            sortBy: 'display_order',
            sortType: 'DESC'
        };
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_CATEGORY).query<Category[]>(request,);
    }

    getListProductCategory(params: PagingRequest): Promise<AxiosResponse<Category[]>> {
        const request = {
            ...params,
            itemsPerPage: CONST.DEFAULT_PAGE_SIZE,
            sortBy: 'display_order',
            sortType: 'DESC',
            type: 'PRODUCT'
        };
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_CATEGORY_BY_TYPE).query<Category[]>(request,);
    }

    getListNewsCategory(params: PagingRequest): Promise<AxiosResponse<Category[]>> {
        const request = {
            ...params,
            itemsPerPage: CONST.DEFAULT_PAGE_SIZE,
            sortBy: 'display_order',
            sortType: 'DESC',
            type: 'NEWS'
        };
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_CATEGORY_BY_TYPE).query<Category[]>(request,);
    }

    createCategory(params: CategoryCreateRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.CREATE_CATEGORY).create<ResponseCustom>(params.data)
    }

    updateCategory(params: CategoryUpdateRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.UPDATE_CATEGORY).create<ResponseCustom>(params.data)
    }

    deleteCategory(params: CategoryDeleteRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.DELETE_CATEGORY).create<ResponseCustom>(params.data)
    }

    getListCategoryByTenantIdAndType(tenantId: number, type: string): Promise<AxiosResponse<Category[]>> {
        return this.setUri(`${CONST.ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/category/getListCategoryByTenantIdAndType`).query({tenantId, type})
    }
}
