export default class PageModel<T> {
    itemsPerPage: number;
    itemCount: number;
    numPages: number;
    pageId: number;
    document: T[]

    constructor(
        itemsPerPage?: number,
        itemCount?: number,
        numPages?: number,
        pageId?: number,
        document?: T[]
    ) {
        this.itemsPerPage = itemsPerPage || 0;
        this.itemCount = itemCount || 0;
        this.numPages = numPages || 0;
        this.pageId = pageId || 0;
        this.document = document || [];
    }
}
