import { MDBBadge, MDBBox, MDBBtn, MDBFileInput, MDBIcon, MDBInput, MDBRow, MDBSelect, MDBSelectInput, MDBSelectOption, MDBSelectOptions, MDBSpinner } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import PickDeep from 'src/core/models/pick-deep.modal'
import { GetProductsByCategoryRequest, ProductUpdateRequest } from 'src/core/models/coffee-core/product/product-request-params.model'
import ProductStateType from 'src/core/models/coffee-core/product/product-state.model'
import CategoryStateType from 'src/core/models/coffee-core/category/category-state.model'
import * as ProductAction from 'src/core/reduxs/coffee-core/product/product.action'
import SizeGroupStateType from 'src/core/models/coffee-core/size-group/size-group-state.model'
import TopppingGroupStateType from 'src/core/models/coffee-core/topping-group/topping-group-state.model';
import _ from 'lodash'
import SizeGroup from 'src/core/models/coffee-core/size-group/size-group.model'
import ToppingGroup from 'src/core/models/coffee-core/topping-group/topping-group.model'
import { NOT_AVAILABLE_IMAGE } from 'src/core/utils/constants'
import PagingRequest from 'src/core/models/paging-request.model'
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component'

type Props = {
    isOpen: boolean,
    productId: number,
    categoryIdFilter: number,
    onModalClosed: () => void,
}
const initChosenCategories: number[] = []

const ProductUpdateModalComponent = (props: Props): JSX.Element => {
    const { isOpen, productId, onModalClosed, categoryIdFilter } = props;
    const intl = useIntl();
    const [productName, setProductName] = useState('')
    const [productPrice, setProductPrice] = useState(0);
    const [productSizeGroupId, setProductSizeGroupId] = useState<number>(0);
    const [productToppingGroupId, setProductToppingGroupId] = useState<number>(0);
    const [chosenCategories, setChosenCategories] = useState(initChosenCategories);
    const [imageFile, setImageFile] = useState<File | null>(null)
    const dispatch = useDispatch();
    const productDetail: PickDeep<ProductStateType, 'productDetail'> = useSelector((state: any) => state.productReducer.productDetail)
    const updateProduct: PickDeep<ProductStateType, 'updateProduct'> = useSelector((state: any) => state.productReducer.updateProduct)
    const productCategories: PickDeep<CategoryStateType, 'productCategories'> = useSelector((state: any) => state.categoryReducer.productCategories)
    const sizeGroups: PickDeep<SizeGroupStateType, 'sizeGroups'> = useSelector((state: any) => state.sizeGroupReducer.sizeGroups)
    const toppingGroups: PickDeep<TopppingGroupStateType, 'toppingGroups'> = useSelector((state: any) => state.toppingGroupReducer.toppingGroups)


    useEffect(() => {
        if (productId > 0) {
            dispatch(ProductAction.actGetDetailProduct(productId))
        }
    }, [productId])

    useEffect(() => {
        if (productDetail.data) {
            setProductName(productDetail.data.name)
            setProductPrice(productDetail.data.price)
            setProductSizeGroupId(productDetail.data.sizeGroup ? productDetail.data.sizeGroup.id : 0)
            setProductToppingGroupId(productDetail.data.toppingGroup ? productDetail.data.toppingGroup.id : 0)
            setChosenCategories(productDetail.data.categories.map((category) => category.id))
        }
    }, [productDetail.data])


    const onCloseModal = () => {
        setProductName('')
        setProductPrice(0)
        setProductSizeGroupId(0)
        setProductToppingGroupId(0)
        setChosenCategories([])
        setImageFile(null)
        onModalClosed();
    }

    const onSuccessUpdate = () => {
        if (categoryIdFilter > 0) {
            const params: GetProductsByCategoryRequest = {
                pageId: 0,
                categoryId: categoryIdFilter
            }
            dispatch(ProductAction.actGetListProductByCategory(params))
        } else {
            const params: PagingRequest = {
                pageId: 0,
            }
            dispatch(ProductAction.actGetListProduct(params))
        }
        onCloseModal();

        toast.success(intl.formatMessage({ id: 'PRODUCT.UPDATE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureUpdate = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'PRODUCT.UPDATE.TOAST_FAILURE' }), { duration: 3000 })
    }

    const handleToggleCategory = (id: number) => {
        if (chosenCategories.includes(id)) {
            const newChosenCategories = chosenCategories.filter((category) => category !== id)
            setChosenCategories(newChosenCategories)
        }
        else {
            setChosenCategories((oldCategories) => [...oldCategories, id])
        }
    }

    const handleChangeSizeGroup = (sizeGroupId: number) => {
        setProductSizeGroupId(sizeGroupId)
    }

    const handleChangeToppingGroup = (toppingGroupId: number) => {
        setProductToppingGroupId(toppingGroupId)
    }

    const handleUpdateProduct = () => {
        const formData = new FormData();

        formData.append('id', JSON.stringify(productId))
        formData.append('name', productName)

        for (const id of chosenCategories) {
            formData.append('categoryIds[]', JSON.stringify(id))
        }

        if (productPrice > 0) {
            formData.append('price', JSON.stringify(productPrice))
        } else {
            toast.error(intl.formatMessage({ id: 'PRODUCT.UPDATE.TOAST_WARNING_MIN_PRICE' }))
            return;
        }

        if (productSizeGroupId) {
            formData.append('sizeGroupId', JSON.stringify(productSizeGroupId))
        } else {
            toast.error(intl.formatMessage({ id: 'PRODUCT.UPDATE.TOAST_WARNING_NOT_SIZE_GROUP' }))
            return;
        }

        if (productToppingGroupId) {
            formData.append('toppingGroupId', JSON.stringify(productToppingGroupId))
        } else {
            toast.error(intl.formatMessage({ id: 'PRODUCT.UPDATE.TOAST_WARNING_NOT_TOPPING_GROUP' }))
            return;
        }

        if (imageFile) {
            if (imageFile.type?.includes('image')) {
                formData.append('image', imageFile)
            } else {
                toast.error(intl.formatMessage({ id: 'PRODUCT.UPDATE.TOAST_WARNING_NOT_IMAGE_FILE' }))
                return;
            }
        }

        const params: ProductUpdateRequest = {
            data: formData,
            successCallback: onSuccessUpdate,
            errorCallback: onFailureUpdate
        }
        dispatch(ProductAction.actUpdateProduct(params))
    }
    return (
        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'PRODUCT.UPDATE.TITLE' })}
            type='update'
        >
            <form
                className='needs-validation was-validated product-create-body-form'
                onSubmit={(event: React.FormEvent) => { event.preventDefault(); handleUpdateProduct() }}
            >
                <MDBBox className='form-inputs'>
                    <MDBBox className='form-input-wrapper'>
                        <MDBInput
                            label={intl.formatMessage({ id: 'PRODUCT.UPDATE.LABEL_NAME' })}
                            outline={true}
                            value={productName}
                            onChange={(event) => { setProductName(event.currentTarget.value) }}
                            required
                            disabled={updateProduct.isLoading}
                        />
                    </MDBBox>
                    <MDBBox className='form-input-wrapper'>
                        <MDBInput
                            label={intl.formatMessage({ id: 'PRODUCT.UPDATE.LABEL_PRICE' })}
                            outline={true}
                            value={productPrice}
                            onChange={(event) => { setProductPrice(_.parseInt(event.currentTarget.value)) }}
                            required
                            disabled={updateProduct.isLoading}
                            type='number'
                            min={1}
                        />
                    </MDBBox>
                    <MDBBox className='form-input-wrapper'>
                        {
                            productDetail.data && productSizeGroupId > 0 && (
                                <MDBSelect
                                    outline
                                    className='m-0 p-0'
                                    label={intl.formatMessage({ id: 'PRODUCT.UPDATE.LABEL_SIZE_GROUP' })}
                                    getValue={(value: any) => { handleChangeSizeGroup(value[0]) }}
                                >
                                    <MDBSelectInput />
                                    <MDBSelectOptions  >
                                        {
                                            sizeGroups && sizeGroups.data &&
                                            sizeGroups.data.map((sizeGroup: SizeGroup) => {
                                                return (
                                                    <MDBSelectOption
                                                        key={sizeGroup.id}
                                                        value={sizeGroup.id}
                                                        checked={sizeGroup.id === productSizeGroupId}
                                                    >
                                                        {sizeGroup.name}
                                                    </MDBSelectOption>
                                                )
                                            })
                                        }
                                    </MDBSelectOptions>
                                </MDBSelect>
                            )
                        }
                        {
                            !productDetail.data && (
                                <MDBSelect
                                    outline
                                    className='m-0 p-0'
                                    label={intl.formatMessage({ id: 'PRODUCT.UPDATE.LABEL_SIZE_GROUP' })}
                                    getValue={(value: any) => { handleChangeSizeGroup(value[0]) }}
                                >
                                    <MDBSelectInput />
                                    <MDBSelectOptions  >
                                        {
                                            sizeGroups && sizeGroups.data &&
                                            sizeGroups.data.map((sizeGroup: SizeGroup) => {
                                                return (
                                                    <MDBSelectOption
                                                        key={sizeGroup.id}
                                                        value={sizeGroup.id}
                                                        checked={sizeGroup.id === productSizeGroupId}
                                                    >
                                                        {sizeGroup.name}
                                                    </MDBSelectOption>
                                                )
                                            })
                                        }
                                    </MDBSelectOptions>
                                </MDBSelect>
                            )
                        }
                    </MDBBox>
                    <MDBBox className='form-input-wrapper'>
                        {
                            productDetail.data && productToppingGroupId > 0 && (
                                <MDBSelect
                                    outline
                                    className='m-0 p-0'
                                    label={intl.formatMessage({ id: 'PRODUCT.UPDATE.LABEL_TOPPING_GROUP' })}
                                    getValue={(value: any) => { handleChangeToppingGroup(value[0]) }}
                                >
                                    <MDBSelectInput />
                                    <MDBSelectOptions >
                                        {
                                            toppingGroups && toppingGroups.data &&
                                            toppingGroups.data.map((toppingGroup: ToppingGroup) => {
                                                return (
                                                    <MDBSelectOption
                                                        key={toppingGroup.id}
                                                        value={toppingGroup.id}
                                                        checked={toppingGroup.id === productToppingGroupId}
                                                    >
                                                        {toppingGroup.name}
                                                    </MDBSelectOption>
                                                )
                                            })
                                        }
                                    </MDBSelectOptions>
                                </MDBSelect>
                            )
                        }
                        {
                            !productDetail.data && (
                                <MDBSelect
                                    outline
                                    className='m-0 p-0'
                                    label={intl.formatMessage({ id: 'PRODUCT.UPDATE.LABEL_TOPPING_GROUP' })}
                                    getValue={(value: any) => { handleChangeToppingGroup(value[0]) }}
                                >
                                    <MDBSelectInput />
                                    <MDBSelectOptions >
                                        {
                                            toppingGroups && toppingGroups.data &&
                                            toppingGroups.data.map((toppingGroup: ToppingGroup) => {
                                                return (
                                                    <MDBSelectOption
                                                        key={toppingGroup.id}
                                                        value={toppingGroup.id}
                                                        checked={toppingGroup.id === productToppingGroupId}
                                                    >
                                                        {toppingGroup.name}
                                                    </MDBSelectOption>
                                                )
                                            })
                                        }
                                    </MDBSelectOptions>
                                </MDBSelect>
                            )
                        }
                    </MDBBox>
                </MDBBox>
                <MDBBox className='form-list-categories'>
                    <MDBBox className='form-list-categories-label'>{intl.formatMessage({ id: 'PRODUCT.UPDATE.LABEL_CATEGORIES' })}</MDBBox>
                    {(productCategories.isLoading || updateProduct.isLoading) && <MDBRow className="d-flex justify-content-center mb-5 w-100 h-100 mt-1"><MDBSpinner small /></MDBRow>}
                    {!productCategories.isLoading && !updateProduct.isLoading && productCategories.data.length === 0 && <p style={{ fontSize: '0.9rem', fontWeight: 300 }}>{intl.formatMessage({ id: 'PRODUCT.UPDATE.LABEL_CATEGORIES_NOT_FOUND' })}</p>}
                    {!productCategories.isLoading && !updateProduct.isLoading && productCategories.data.length > 0 &&
                        (productCategories.data.map((category) => {
                            return (
                                <MDBBadge
                                    key={category.id}
                                    className={(chosenCategories.includes(category.id)) ? 'chosen-badge update' : 'remain-badge'}
                                    onClick={() => handleToggleCategory(category.id)}
                                >
                                    {category.name}
                                </MDBBadge>
                            )
                        }))
                    }
                </MDBBox>
                <MDBBox className='form-input-image'>
                    <MDBFileInput
                        btnTitle={intl.formatMessage({ id: 'CATEGORY.UPDATE.LABEL_BUTTON_CHOOSE_IMAGE' })}
                        btnColor='indigo'
                        textFieldTitle={intl.formatMessage({ id: 'CATEGORY.UPDATE.LABEL_TITLE_CHOOSE_IMAGE' })}
                        getValue={(files: File[]) => setImageFile(files[0])}
                        accept="image/*"
                    />
                    {
                        imageFile?.type?.includes('image') && (
                            <img
                                src={URL.createObjectURL(imageFile as Blob)}
                                className='product-image-review'
                            />
                        )
                    }
                    {
                        !imageFile && (
                            <img
                                src={productDetail?.data?.imageUrl || NOT_AVAILABLE_IMAGE}
                                className='product-image-review'
                            />
                        )
                    }
                </MDBBox>
                <MDBBox className='form-submit-box'>
                    <MDBBtn color='indigo' className='form-submit-btn update' type="submit">
                        {intl.formatMessage({ id: 'PRODUCT.UPDATE.LABEL_BUTTON' })}
                        <MDBIcon icon='edit' className='ml-3' />
                    </MDBBtn>
                </MDBBox>
            </form>
        </CustomModal>
    )
}

export default ProductUpdateModalComponent
