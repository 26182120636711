import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import SideNavigation from 'src/shared/components/side-navigation/side-navigation.component';
import TopNavigation from 'src/shared/components/top-navigation/top-navigation.component';

type State = {
    toggle: boolean,
    windowWidth: number,
    sideNavToggled: boolean,
    breakWidth: number
}

const LayoutWithNav = (props: PropsWithChildren<any>): JSX.Element => {
    const initialState: State = {
        toggle: false,
        windowWidth: 0,
        sideNavToggled: false,
        breakWidth: 1024
    };

    const [state, setState] = useState(initialState);

    const handleResize = () => {
        setState({
            ...state,
            windowWidth: window.innerWidth
        });
    };

    const toggleSideNav = () => {
        if (state.windowWidth < state.breakWidth) {
            setState({
                ...state,
                sideNavToggled: !state.sideNavToggled
            });
        }
    };

    useEffect(() => {
        handleResize();
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [state.windowWidth])


    const dynamicLeftPadding = useMemo(() => {
        return {
            marginLeft: state.windowWidth > state.breakWidth ? '240px' : '0'
        };
    }, [state.windowWidth])

    return (
        <>
            <div className='app'>
                <div>
                    <SideNavigation
                        breakWidth={state.breakWidth}
                        style={{transition: 'all .3s'}}
                        triggerOpening={state.sideNavToggled}
                        onLinkClick={toggleSideNav}
                    />
                </div>
                <div className='flexible-content white-skin'>
                    <TopNavigation
                        toggle={state.windowWidth < state.breakWidth}
                        onSideNavToggleClick={toggleSideNav}
                        className='white-skin'
                    />
                    <main
                        style={{
                            ...dynamicLeftPadding,
                            position: 'relative',
                            top: '5rem',
                            minHeight: 'calc(100vh - 5rem)',
                        }}>
                        {props.children}
                    </main>
                </div>
            </div>
        </>
    )
}

function areEqual() {
    return true;
}

export default React.memo(LayoutWithNav, areEqual);
