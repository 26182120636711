import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import MaterialState from 'src/core/reduxs/management/material/material.state';
import Types from 'src/core/reduxs/management/material/material.type';

const initialState: MaterialState = {
    materials: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    searchedMaterials: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    material: {
        data: undefined,
        error: null,
        isLoading: false
    },
    materialCategories: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    createMaterial: {
        data: {},
        error: null,
        isLoading: false
    },
    updateMaterial: {
        data: {},
        error: null,
        isLoading: false
    },
};

const materialReducer = createReducer(initialState, {
    [Types.GET_LIST_MATERIAL_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            materials: {
                ...state.materials,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_MATERIAL_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            materials: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_MATERIAL_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            materials: {
                ...state.materials,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.SEARCH_MATERIAL_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedMaterials: {
                ...state.searchedMaterials,
                isLoading: true,
            }
        };
    },
    [Types.SEARCH_MATERIAL_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedMaterials: {
                isLoading: false,
                error: null,
                data: action.payload.data
            }
        };
    },
    [Types.SEARCH_MATERIAL_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            searchedMaterials: {
                ...state.searchedMaterials,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.GET_MATERIAL_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            material: {
                ...state.material,
                isLoading: true,
            }
        };
    },
    [Types.GET_MATERIAL_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            material: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_MATERIAL_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            material: {
                ...state.material,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.RESET_MATERIAL]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            material: {
                data: undefined,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.GET_LIST_MATERIAL_CATEGORY_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            materialCategories: {
                ...state.materialCategories,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_MATERIAL_CATEGORY_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            materialCategories: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_MATERIAL_CATEGORY_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            materialCategories: {
                ...state.materialCategories,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.CREATE_MATERIAL_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createMaterial: {
                ...state.createMaterial,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_MATERIAL_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createMaterial: {
                ...state.createMaterial,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_MATERIAL_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createMaterial: {
                ...state.createMaterial,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_MATERIAL_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateMaterial: {
                ...state.updateMaterial,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_MATERIAL_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateMaterial: {
                ...state.updateMaterial,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_MATERIAL_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateMaterial: {
                ...state.updateMaterial,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default materialReducer;
