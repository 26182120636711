import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    MDBBtn,
    MDBCol,
    MDBContainer,
    MDBDatePicker,
    MDBInput,
    MDBInputGroup,
    MDBRow,
    MDBSelect,
    MDBSpinner,
} from 'mdbreact';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as CONST from 'src/core/utils/constants';
import { DATE_FORMAT_FOR_CODE, ImportCreateTypes } from 'src/core/utils/constants';
import { actGetListStock } from 'src/core/reduxs/management/stock/stock.action';
import { Stock } from 'src/core/models/management/stock.model';
import { actGetListPartnerOfStore } from 'src/core/reduxs/management/partner/partner.action';
import { toast } from 'react-hot-toast';
import { formatDateForApi } from 'src/core/utils/common';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { Supplier } from 'src/core/models/supplier.model';
import { actGetListSupplier } from 'src/core/reduxs/management/supplier/supplier.action';
import { CreateImportRequest, ImportItem, ImportItemRequest } from 'src/core/models/management/import.model';
import ImportDetailCreationListComponent from 'src/features/components/import/import-detail-creation-list.component';
import ImportDetailCreateForm from 'src/features/components/import/import-detail-create-form.component';
import { actCreateImport } from 'src/core/reduxs/management/import/import.action';
import { actGetListDepositOfStore } from 'src/core/reduxs/management/deposit/deposit.action';
import { Deposit } from 'src/core/models/management/deposit.model';
import { actResetImportDetailItem } from 'src/core/reduxs/management/import-detail/import-detail.action';
import Select from 'react-select';

const ImportCreateContainer = (): JSX.Element => {

    const history = useHistory();
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const dispatch = useDispatch();
    const stocks: Stock[] = useSelector((state: any) => state.stockReducer.stocks.data);
    const deposits: Deposit[] = useSelector((state: any) => state.depositReducer.storeDeposits.data);
    const suppliers: Supplier[] = useSelector((state: any) => state.supplierReducer.suppliers.data);
    const importDetails: ImportItem[] = useSelector((state: any) => state.importDetailReducer.createImportDetails.items);
    const [selectedStock, setSelectedStock] = useState<Stock | undefined>();
    const [selectedType, setSelectedType] = useState<string | undefined>();
    const [selectedImportTime, setSelectedImportTime] = useState<Date | undefined>();
    const [importCodePrefix, setImportCodePrefix] = useState<string | undefined>('');
    const [isOutcomePaidChecked, setIsOutcomePaidChecked] = useState(true);
    const creating: boolean = useSelector((state: any) => state.importReducer.createImport.loading);

    useEffect(() => {
        dispatch(actGetListStock());
        dispatch(actGetListSupplier())
    }, []);

    useEffect(() => {
        if ('DOI_TAC' === selectedType && selectedStock) {
            dispatch(actGetListPartnerOfStore(selectedStock.store.id));
        }
        setIsOutcomePaidChecked('NHA_C_C' !== selectedType);
    }, [selectedType, selectedStock]);

    useEffect(() => {
        if (selectedStock) {
            dispatch(actGetListDepositOfStore(selectedStock.store.id));
        }
    }, [selectedStock]);

    useEffect(() => {
        if (selectedStock && selectedImportTime) {
            const dateString = moment(selectedImportTime).format(DATE_FORMAT_FOR_CODE);
            setImportCodePrefix(`${selectedStock.store.code}_${dateString}_NHAP_`)
        }
    }, [selectedStock, selectedImportTime]);

    const stockOptions = useMemo(() => {
        if (!_.isEmpty(stocks)) {
            return stocks.map((s: Stock) => ({
                label: s.name,
                value: s.id
            }));
        }
        return [];
    }, [stocks]);

    const depositOptions = useMemo(() => {
        if (!_.isEmpty(deposits)) {
            return deposits.map((d: Deposit) => ({
                label: d.name,
                value: d.id
            }));
        }
        return [];
    }, [deposits]);

    const supplierOptions = useMemo(() => {
        if (!_.isEmpty(suppliers)) {
            return suppliers.map((s: Supplier) => ({
                label: s.name,
                value: s.id
            }));
        }
        return [];
    }, [suppliers]);

    const typeOptions = useMemo(() => {
        return Object.keys(ImportCreateTypes).map(key => ({
            label: ImportCreateTypes[key],
            value: key
        }))
    }, []);

    const onChangeStock = (stockId: number) => {
        const stock = stocks.find(s => s.id === stockId)
        setSelectedStock(stock);
    }

    const onCreateSuccess = useCallback(() => {
        dispatch(actResetImportDetailItem())
        toast.success('Tạo phiếu nhập thành công', { duration: 2000 })
        history.push(CONST.AppURI.IMPORT);
    }, [])

    const onCreateFailure = useCallback(() => {
        toast.error('Tạo phiếu nhập thất bại', { duration: 2000 })
    }, [])

    const onSubmit = (data: any) => {
        if (_.isEmpty(importDetails)) {
            toast.error('Bạn chưa nhập chi tiết phiếu nhập', { duration: 2000 })
            return
        }
        const importItemRequests: ImportItemRequest[] = importDetails.map(e => ({
            materialId: e.material.id,
            unitPrice: e.unitPrice,
            quantity: e.quantity
        }))
        const request: CreateImportRequest = {
            type: data.type,
            stockId: data.stockId,
            actorDepositId: data.actorDepositId,
            supplierId: data.supplierId,
            title: data.title,
            items: importItemRequests,
            importTime: data.importTime,
            code: importCodePrefix + data.code,
            isOutcomePaid: isOutcomePaidChecked
        }
        dispatch(actCreateImport(request, onCreateSuccess, onCreateFailure))
    }

    return (
        <MDBContainer className='py-3' fluid>
            <div className='text-center'>
                <h2 className='page-title'>Tạo phiếu nhập</h2>
            </div>
            <MDBRow className='pb-3'>
                <MDBCol size='12'>
                    <p className="font-weight-normal mt-4">THÔNG TIN PHIẾU NHẬP</p>
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Nội dung</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBInput
                        {...register('title', {
                            required: 'Nội dung là bắt buộc',
                            maxLength: {
                                value: 255,
                                message: 'Nội dung không được nhiều hơn 255 ký tự',
                            }
                        })}
                        placeholder='Nội dung'
                        onChange={(e: any) => {
                            setValue('title', e.target.value)
                        }}
                        containerClass='my-0 max-width-400'
                    />
                    {errors.title && <ErrorMessage>{errors.title.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Kho</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('stockId', {
                            required: 'Kho là bắt buộc'
                        })}
                        options={stockOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                const stockId = newValue.value
                                setValue('stockId', stockId);
                                onChangeStock(stockId)
                            }
                        }}
                        placeholder='(chọn kho)'
                        className='max-width-400 my-1'
                    />
                    {errors.stockId && <ErrorMessage>{errors.stockId.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Loại nhập</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('type', {
                            required: 'Loại nhập là bắt buộc'
                        })}
                        options={typeOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('type', newValue.value);
                                setSelectedType(newValue.value);
                            }
                        }}
                        placeholder='(chọn loại nhập)'
                        className='max-width-400 my-1'
                    />
                    {errors.type && <ErrorMessage>{errors.type.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Ví chi</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('actorDepositId', {
                            required: 'Ví chi là bắt buộc'
                        })}
                        options={depositOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('actorDepositId', newValue.value);
                            }
                        }}
                        placeholder='(chọn ví)'
                        className='max-width-400 my-1'
                    />
                    {errors.actorDepositId && <ErrorMessage>{errors.actorDepositId.message}</ErrorMessage>}
                </MDBCol>
                {
                    'NHA_C_C' === selectedType &&
                    <Fragment>
                        <MDBCol md="2" className='flex-row'>
                            <div className="pt-2">Nhà cung cấp</div>
                        </MDBCol>
                        <MDBCol md="10">
                            <Select
                                {...register('supplierId', {
                                    required: 'Nhà cung cấp là bắt buộc'
                                })}
                                options={supplierOptions}
                                onChange={(newValue) => {
                                    if (newValue) {
                                        setValue('supplierId', newValue.value);
                                    }
                                }}
                                placeholder='(chọn ví)'
                                className='max-width-400 my-1'
                            />
                            {errors.supplierId && <ErrorMessage>{errors.supplierId.message}</ErrorMessage>}
                        </MDBCol>
                    </Fragment>
                }
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Ngày nhập</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBDatePicker
                        {...register('importTime', {
                            required: 'Ngày nhập là bắt buộc'
                        })}
                        clearable
                        format='DD-MM-YYYY'
                        autoOk
                        getValue={(e) => {
                            setValue('importTime', e ? formatDateForApi(e) : null)
                            setSelectedImportTime(e)
                        }}
                        className='mt-0'
                        valueDefault={null as any}
                        emptyLabel='(chọn ngày)'
                    />
                    {errors.importTime && <ErrorMessage>{errors.importTime.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Mã phiếu nhập</div>
                </MDBCol>
                <MDBCol md="10">
                    <div
                        {...register('code', {
                            required: 'Mã phiếu nhập là bắt buộc'
                        })}
                    >
                        <MDBInputGroup
                            material
                            containerClassName="my-0 max-width-400"
                            className='font-weight-bold text-primary'
                            prepend={importCodePrefix}
                            onChange={(e: any) => setValue('code', e.target.value)}
                        />
                    </div>
                    {errors.code && <ErrorMessage>{errors.code.message}</ErrorMessage>}
                </MDBCol>
                {
                    selectedType &&
                    <Fragment>
                        <MDBCol md="2" className='flex-row'>
                            <div className="md-form pt-2">Đã thanh toán</div>
                        </MDBCol>
                        <MDBCol md="10">
                            <MDBInput
                                filled
                                checked={isOutcomePaidChecked}
                                onChange={() => setIsOutcomePaidChecked(!isOutcomePaidChecked)}
                                type="checkbox"
                                containerClass='md-form mb-0 pl-0'
                                id="is-outcome-paid-checkbox"
                            />
                        </MDBCol>
                    </Fragment>
                }
            </MDBRow>
            <MDBRow>
                <MDBCol size='7'>
                    <ImportDetailCreationListComponent/>
                </MDBCol>
                <MDBCol size='5'>
                    <ImportDetailCreateForm/>
                </MDBCol>
                <MDBCol size='12'>
                    <div className='text-center'>
                        <MDBBtn color="primary" onClick={handleSubmit(onSubmit)} disabled={creating}>Tạo phiếu nhập</MDBBtn>
                        {creating &&
                            <MDBSpinner small className='b-primary' />
                        }
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default React.memo(ImportCreateContainer);
