const Theme = {
    color: {
        primary: '#FF852C',
        lightPrimary: '#FBF2DF',
        lighterPrimary: '#FCFBF3',
        darkPrimary: '#8A400D',
        success: '#198754',
        warning: '#ffc107',
        danger: '#dc3545',
        info: '#0dcaf0',
        dark: '#353535',
        light: '#828282',
        lighter: '#cfcfcf',
        lightest: '#f3f4f5',
        white: '#ffffff',
        borderColor: '#a9a9a9',
        goldMember1: '#C7AD56',
        goldMember2: '#7E5B1B',
        orderStatus: {
            PENDING: 'warning-color',
            CONFIRMED: 'primary-color',
            PREPARING: 'default-color',
            DELIVERING: 'secondary-color',
            FINISHED: 'stylish-color',
            CANCELED: 'danger-color'
        },
        dropdownOrderStatus: {
            PENDING: 'warning',
            CONFIRMED: 'primary',
            PREPARING: 'success',
            DELIVERING: 'secondary',
            FINISHED: 'default',
            CANCELED: 'danger'
        },
        orderMethod: {
            DELIVERY: 'success-color',
            TAKE_AWAY: 'info-color'
        }
    }
};

export default Theme;