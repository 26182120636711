import { AnyAction } from 'redux';

import { createReducer } from 'src/core/utils/redux';
import * as Types from 'src/core/reduxs/coffee-core/size-group/size-group.type';
import SizeGroupStateType from 'src/core/models/coffee-core/size-group/size-group-state.model';

const initialState: SizeGroupStateType = {
    sizeGroups: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    updateSizeGroup: {
        data: {},
        error: null,
        isLoading: false
    },
    createSizeGroup: {
        data: {},
        error: null,
        isLoading: false
    },
    deleteSizeGroup: {
        data: {},
        error: null,
        isLoading: false
    },
};

const sizeGroupReducer = createReducer(initialState, {
    [Types.GET_SIZE_GROUPS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            sizeGroups: {
                ...state.sizeGroups,
                isLoading: true,
            }
        };
    },
    [Types.GET_SIZE_GROUPS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            sizeGroups: {
                ...state.sizeGroups,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_SIZE_GROUPS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            sizeGroups: {
                ...state.sizeGroups,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.CREATE_SIZE_GROUP_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createSizeGroup: {
                ...state.createSizeGroup,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_SIZE_GROUP_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createSizeGroup: {
                ...state.createSizeGroup,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_SIZE_GROUP_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createSizeGroup: {
                ...state.createSizeGroup,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_SIZE_GROUP_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateSizeGroup: {
                ...state.updateSizeGroup,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_SIZE_GROUP_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateSizeGroup: {
                ...state.updateSizeGroup,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_SIZE_GROUP_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateSizeGroup: {
                ...state.updateSizeGroup,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.DELETE_SIZE_GROUP_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteSizeGroup: {
                ...state.deleteSizeGroup,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_SIZE_GROUP_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteSizeGroup: {
                ...state.deleteSizeGroup,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.DELETE_SIZE_GROUP_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteSizeGroup: {
                ...state.deleteSizeGroup,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default sizeGroupReducer;
