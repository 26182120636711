import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import { Order } from 'src/core/models/coffee-core/order.model';
import Types from 'src/core/reduxs/coffee-core/guest/guest-order.type';

type GuestOrderState = {
    orderDetails: {
        data: Order | undefined,
        error: any,
        isLoading: boolean,
    },
}

const initialState: GuestOrderState = {
    orderDetails: {
        data: undefined,
        error: null,
        isLoading: false
    },
};

const guestOrderReducer = createReducer(initialState, {
    [Types.GET_ORDER_DETAILS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orderDetails: {
                ...state.orderDetails,
                isLoading: true,
            }
        };
    },
    [Types.GET_ORDER_DETAILS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orderDetails: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_ORDER_DETAILS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orderDetails: {
                ...state.orderDetails,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.RESET_ORDER_DETAILS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orderDetails: {
                data: undefined,
                error: null,
                isLoading: false
            },
        };
    },
})

export default guestOrderReducer;
