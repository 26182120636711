import { MDBBtn, MDBCol, MDBRow, MDBSpinner } from 'mdbreact'
import React, { useEffect, useMemo, useState } from 'react'
import PageHeader from 'src/shared/components/page-header/page-header.component'
import 'src/features/pages/coupon/coupon-code/coupon-code.style.scss'
import QRCode from 'qrcode.react'
import html2canvas from 'html2canvas';
import _ from 'lodash';
import jsPDF from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { DATE_FORMAT } from 'src/core/utils/constants';
import Select from 'react-select';
import { Tenant } from 'src/core/models/tenant.model';
import { actGetListTenant } from 'src/core/reduxs/coffee-core/tenant/tenant.action';
import CouponCodeCreateForm from 'src/features/components/coupon/coupon-code-create-form.component';
import { CouponCode } from 'src/core/models/coffee-core/coupon.model';
import { actGetListCouponCode, actUpdateCouponCodeStatus } from 'src/core/reduxs/coffee-core/coupon/coupon.action';

const CouponCodeContainer = (): JSX.Element => {

    const dispatch = useDispatch();
    const [generatingPDF, setGeneratingPDF] = useState<boolean>(false)
    const couponCodes = useSelector((state: any) => state.couponReducer.couponCodes);
    const newCouponCodes: CouponCode[] = couponCodes.data
    const tenants: Tenant[] = useSelector((state: any) => state.tenantReducer.tenants.data);
    const [selectedTenantId, setSelectedTenantId] = useState<number | undefined>(undefined);

    useEffect(() => {
        dispatch(actGetListTenant());
    }, [])

    const tenantOptions = useMemo(() => {
        if (!_.isEmpty(tenants)) {
            return tenants.map((t) => ({
                label: t.name,
                value: t.id
            }));
        }
        return [];
    }, [tenants]);

    useEffect(() => {
        getListCode();
    }, [selectedTenantId]);

    const getListCode = () => {
        if (selectedTenantId) {
            dispatch(actGetListCouponCode(selectedTenantId))
        }
    }

    const downloadPDF = async (couponCodes: CouponCode[]): Promise<void> => {
        setGeneratingPDF(true)
        const pages = document.querySelectorAll('.print-page');
        const pdf = new jsPDF('portrait', 'mm', 'a4');
        for (let i = 0; i < pages.length; i++) {
            const canvas = await html2canvas(pages[i] as HTMLElement);
            if (i < pages.length - 1) {
                pdf.addImage(canvas.toDataURL('image/png'), 0, 0, 210, 297);
                pdf.addPage();
            } else {
                pdf.addImage(canvas.toDataURL('image/png'), 0, 0, 210, 297);
            }
        }
        pdf.save('coupon_codes');
        setGeneratingPDF(false)

        dispatch(actUpdateCouponCodeStatus(couponCodes.map(b => b.id), 'PRINTED', getListCode))
    }

    const getCouponCodeMap = (couponCodes: CouponCode[]): Map<string, CouponCode[]> => {
        const map = new Map();
        for (const couponCode of couponCodes) {
            if (map.get(couponCode.coupon.title) !== undefined) {
                map.set(couponCode.coupon.title, [...map.get(couponCode.coupon.title), couponCode]);
            } else {
                map.set(couponCode.coupon.title, [couponCode]);
            }
        }
        return map;
    }
    const map = getCouponCodeMap(newCouponCodes);

    let pages: CouponCode[][] = [];
    if (!_.isEmpty(newCouponCodes)) {
        const codes = [];
        for (let i = 0; i < newCouponCodes.length; i++) {
            codes.push(newCouponCodes[i]);
        }
        pages = _.chunk(codes, 20);
    }

    return (
        <div id="accumulate-code">
            <PageHeader title='COUPON_CODE.PAGE.TITLE'/>
            <MDBRow className='pb-3'>
                <MDBCol md="2">
                    <div className="pt-2">Chọn brand</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        options={tenantOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setSelectedTenantId(newValue.value)
                            }
                        }}
                        placeholder='(chọn brand)'
                        className='max-width-400 my-1'
                    />
                </MDBCol>
            </MDBRow>

            {selectedTenantId &&
                <CouponCodeCreateForm tenantId={selectedTenantId}/>
            }

            <div className='my-3'>
                {couponCodes.isLoading &&
                    <MDBRow className="d-flex justify-content-center mb-5"><MDBSpinner small/></MDBRow>}
                {map &&
                    <div>
                        <p>Hiện có:</p>
                        <ul>
                            {Array.from(map.entries()).map((entry) => {
                                const [key, value] = entry
                                return (<li key={key}><span className='font-weight-bolder'>{value.length}</span> mã ưu đãi <span className='font-weight-bolder'>{key}</span></li>)
                            })}
                        </ul>
                        <MDBBtn
                            className='purple-gradient' size='lg' onClick={() => downloadPDF(newCouponCodes)}
                            disabled={_.isEmpty(newCouponCodes)}
                        >
                            {generatingPDF ?
                                <MDBSpinner small className='b-primary'/> : 'Tải xuống'
                            }
                        </MDBBtn>
                    </div>
                }
            </div>
            <div style={{overflow: 'hidden', width: 0, height: 0}}>
                {
                    pages.map((codes, index) => {
                        return (
                            <div key={index} className="mx-auto print-page pt-2" style={{width: 590, height: 840}}>
                                <MDBRow className="w-100 mx-auto">
                                    {
                                        codes.map((code, index) => {
                                            return (
                                                <MDBCol key={code.code} size='3' className='mt-3 mb-2 mx-auto p-0 flex-center'>
                                                    <div className="text-center">
                                                        <div className="bg-white qr-container">
                                                            <QRCode
                                                                id='qrCode'
                                                                value={code.code}
                                                                size={110}
                                                                level='H'
                                                                bgColor='white'
                                                                fgColor='black'
                                                                includeMargin={false}
                                                                renderAs='canvas'
                                                            />
                                                        </div>
                                                        <p className="text-center" style={{fontSize: 10, marginTop: 8, flexWrap: 'wrap', width: 100}}>{code.coupon.title}</p>
                                                        <p className="text-center" style={{fontSize: 10}}>{code.expiredTime ?
                                                            `Hết hạn: ${moment(code.expiredTime).format(DATE_FORMAT)}` : ''}</p>
                                                    </div>
                                                </MDBCol>
                                            )
                                        })
                                    }
                                </MDBRow>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default React.memo(CouponCodeContainer)
