import { MDBCol, MDBRow } from 'mdbreact'
import React, { Component } from 'react'
import PageHeader from 'src/shared/components/page-header/page-header.component'
import SizeGroupCreateFormComponent from 'src/features/components/size-group/size-group-create-form.component'
import SizeGroupListComponent from 'src/features/components/size-group/size-group-list.component'
import SizeListComponent from 'src/features/components/size/size-list.component'
import 'src/features/pages/size/size.style.scss'

export default class Sizes extends Component {
    render() {
        return (
            <div id="size">
                <PageHeader title='SIZE.PAGE.TITLE' />
                <MDBRow className="m-0">
                    <MDBCol md='12' className="p-0 m-0">
                        <SizeGroupListComponent />
                    </MDBCol>
                </MDBRow>
                <MDBRow className="m-0" >
                    <MDBCol lg='7' className="p-0 m-0 space-right2">
                        <SizeListComponent />
                    </MDBCol>
                    <MDBCol lg='5' className="p-0 m-0">
                        <SizeGroupCreateFormComponent />
                    </MDBCol>
                </MDBRow>
            </div>
        )
    }
}
