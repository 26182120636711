const Types = {
    CREATE_OUTCOME_REQUEST: 'CREATE_OUTCOME_REQUEST',
    CREATE_OUTCOME_SUCCESS: 'CREATE_OUTCOME_SUCCESS',
    CREATE_OUTCOME_FAILURE: 'CREATE_OUTCOME_FAILURE',
    ADD_OUTCOME_ITEM: 'ADD_OUTCOME_ITEM',
    REMOVE_OUTCOME_ITEM: 'REMOVE_OUTCOME_ITEM',
    RESET_OUTCOME_ITEM: 'RESET_OUTCOME_ITEM',
    GET_LIST_OUTCOME_CATEGORY_REQUEST: 'GET_LIST_OUTCOME_CATEGORY_REQUEST',
    GET_LIST_OUTCOME_CATEGORY_SUCCESS: 'GET_LIST_OUTCOME_CATEGORY_SUCCESS',
    GET_LIST_OUTCOME_CATEGORY_FAILURE: 'GET_LIST_OUTCOME_CATEGORY_FAILURE',
    SEARCH_OUTCOME_REQUEST: 'SEARCH_OUTCOME_REQUEST',
    SEARCH_OUTCOME_SUCCESS: 'SEARCH_OUTCOME_SUCCESS',
    SEARCH_OUTCOME_FAILURE: 'SEARCH_OUTCOME_FAILURE',
    GET_OUTCOME_DETAILS_REQUEST: 'GET_OUTCOME_DETAILS_REQUEST',
    GET_OUTCOME_DETAILS_SUCCESS: 'GET_OUTCOME_DETAILS_SUCCESS',
    GET_OUTCOME_DETAILS_FAILURE: 'GET_OUTCOME_DETAILS_FAILURE',
    DELETE_OUTCOME_REQUEST: 'DELETE_OUTCOME_REQUEST',
    DELETE_OUTCOME_SUCCESS: 'DELETE_OUTCOME_SUCCESS',
    DELETE_OUTCOME_FAILURE: 'DELETE_OUTCOME_FAILURE',
}

export default Types;