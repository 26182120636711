import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';

import PagingRequest from 'src/core/models/paging-request.model';
import ResponseCustom from 'src/core/models/response-custom.model';
import {SizeGroupCreateRequest, SizeGroupDeleteRequest, SizeGroupUpdateRequest} from 'src/core/models/coffee-core/size-group/size-group-request-params.model';
import SizeGroup from 'src/core/models/coffee-core/size-group/size-group.model';

export default class SizeGroupRepository extends BaseRepository {

    getListSizeGroup(params: PagingRequest): Promise<AxiosResponse<SizeGroup[]>> {
        const request = {
            ...params,
            itemsPerPage: CONST.DEFAULT_PAGE_SIZE,
            sortBy: 'display_order',
            sortType: 'DESC'
        };
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_SIZE_GROUP).query<SizeGroup[]>(request, );
    }

    createSizeGroup(params: SizeGroupCreateRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.CREATE_SIZE_GROUP).create<ResponseCustom>(params.data)
    }

    updateSizeGroup(params: SizeGroupUpdateRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.UPDATE_SIZE_GROUP).create<ResponseCustom>(params.data)
    }

    deleteSizeGroup(params: SizeGroupDeleteRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.DELETE_SIZE_GROUP).create<ResponseCustom>(params.data )
    }
}
