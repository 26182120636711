import React from 'react';
import { Route } from 'react-router-dom';

type Props = {
    component: (props: any) => JSX.Element,
    key: string,
    exact: boolean,
    path: string
}

const RouteWithComponent = ({ component, ...rest }: Props): JSX.Element => {

    return (<Route
        {...rest}
        component={(matchProps: Record<string, unknown>) => (
            component(matchProps)
        )}
    />)
}

export default React.memo(RouteWithComponent);
