import React from 'react';
import Login from 'src/features/pages/login/login.container';
import NotFoundComponent from 'src/shared/components/not-found/not-found.component';
import * as CONST from 'src/core/utils/constants';
import RouteGuard from 'src/shared/hoc/route-guard.hoc';
import HomeContainer from 'src/features/pages/home/home.container';
import OrderContainer from 'src/features/pages/order/order.container'
import ToppingContainer from 'src/features/pages/topping/topping.container';
import ProductContainer from 'src/features/pages/product/product.container';
import SizeContainer from 'src/features/pages/size/size.container';
import CategoryContainer from 'src/features/pages/category/category.container';
import CreateOrderContainer from 'src/features/pages/create-order/create-order.container';
import AccumulateCodeContainer from 'src/features/pages/accumulate/accumulate-code/accumulate-code.container';
import IncomeContainer from 'src/features/pages/material-management/income/income.container';
import ImportContainer from 'src/features/pages/material-management/import/import.container';
import ExportContainer from 'src/features/pages/material-management/export/export.container';
import OutcomeContainer from 'src/features/pages/material-management/outcome/outcome.container';
import ExportCreateContainer from 'src/features/pages/material-management/export/export-create.container';
import ImportCreateContainer from 'src/features/pages/material-management/import/import-create.container';
import IncomeCreateContainer from 'src/features/pages/material-management/income/income-create.container';
import OutcomeCreateContainer from 'src/features/pages/material-management/outcome/outcome-create.container';
import ExportDetailContainer from 'src/features/pages/material-management/export/export-detail.container';
import ImportDetailContainer from 'src/features/pages/material-management/import/import-detail.container';
import IncomeDetailContainer from 'src/features/pages/material-management/income/income-detail.container';
import OutcomeDetailContainer from 'src/features/pages/material-management/outcome/outcome-detail.container';
import MaterialContainer from 'src/features/pages/material-management/material/material.container';
import NewsCreateContainer from 'src/features/pages/news/news-create.container';
import NewsListContainer from 'src/features/pages/news/news-list.container';
import NewsDetailContainer from 'src/features/pages/news/news-detail.container';
import PublicOrderContainer from 'src/features/pages/public-order/public-order.container';
import UserCouponAddContainer from 'src/features/pages/coupon/user-coupon/user-coupon-add.container';
import CouponCodeContainer from 'src/features/pages/coupon/coupon-code/coupon-code.container';
import AccumulatePointContainer from 'src/features/pages/accumulate/accumulate-point/accumulate-point.container';

export const mainRoutes = [
    {
        path: CONST.AppURI.HOME,
        exact: true,
        name: 'Home',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: HomeContainer, ...props })
    },
    {
        path: CONST.AppURI.ORDER,
        exact: true,
        name: 'Order',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: OrderContainer, ...props })
    },
    {
        path: CONST.AppURI.PRODUCT,
        exact: true,
        name: 'Product',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: ProductContainer, ...props })
    },
    {
        path: CONST.AppURI.TOPPING,
        exact: true,
        name: 'Topping',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: ToppingContainer, ...props })
    },
    {
        path: CONST.AppURI.SIZE,
        exact: true,
        name: 'Size',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: SizeContainer, ...props })
    },
    {
        path: CONST.AppURI.CATEGORY,
        exact: true,
        name: 'Category',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: CategoryContainer, ...props })
    },
    {
        path: CONST.AppURI.CREATE_ORDER,
        exact: true,
        name: 'Size',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: CreateOrderContainer, ...props })
    },
    {
        path: CONST.AppURI.ACCUMULATE_CODE,
        exact: true,
        name: 'Accumulate',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: AccumulateCodeContainer, ...props })
    },
    {
        path: CONST.AppURI.ACCUMULATE_POINT,
        exact: true,
        name: 'ACCUMULATE_POINT',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: AccumulatePointContainer, ...props })
    },
    {
        path: CONST.AppURI.INCOME,
        exact: true,
        name: 'Income',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: IncomeContainer, ...props })
    },
    {
        path: CONST.AppURI.INCOME_DETAIL,
        exact: true,
        name: 'INCOME_DETAIL',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: IncomeDetailContainer, ...props })
    },
    {
        path: CONST.AppURI.INCOME_CREATE,
        exact: true,
        name: 'INCOME_CREATE',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: IncomeCreateContainer, ...props })
    },
    {
        path: CONST.AppURI.OUTCOME,
        exact: true,
        name: 'Outcome',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: OutcomeContainer, ...props })
    },
    {
        path: CONST.AppURI.OUTCOME_DETAIL,
        exact: true,
        name: 'OUTCOME_DETAIL',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: OutcomeDetailContainer, ...props })
    },
    {
        path: CONST.AppURI.OUTCOME_CREATE,
        exact: true,
        name: 'OUTCOME_CREATE',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: OutcomeCreateContainer, ...props })
    },
    {
        path: CONST.AppURI.IMPORT,
        exact: true,
        name: 'Import',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: ImportContainer, ...props })
    },
    {
        path: CONST.AppURI.IMPORT_DETAIL,
        exact: true,
        name: 'IMPORT_DETAIL',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: ImportDetailContainer, ...props })
    },
    {
        path: CONST.AppURI.IMPORT_CREATE,
        exact: true,
        name: 'IMPORT_CREATE',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: ImportCreateContainer, ...props })
    },
    {
        path: CONST.AppURI.EXPORT,
        exact: true,
        name: 'Export',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: ExportContainer, ...props })
    },
    {
        path: CONST.AppURI.EXPORT_DETAIL,
        exact: true,
        name: 'EXPORT_DETAIL',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: ExportDetailContainer, ...props })
    },
    {
        path: CONST.AppURI.EXPORT_CREATE,
        exact: true,
        name: 'EXPORT_CREATE',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: ExportCreateContainer, ...props })
    },
    {
        path: CONST.AppURI.MATERIAL,
        exact: true,
        name: 'MATERIAL',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: MaterialContainer, ...props })
    },
    {
        path: CONST.AppURI.NEWS_CREATE,
        exact: true,
        name: 'NEWS_CREATE',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: NewsCreateContainer, ...props })
    },
    {
        path: CONST.AppURI.NEWS,
        exact: true,
        name: 'NEWS',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: NewsListContainer, ...props })
    },
    {
        path: CONST.AppURI.NEWS_DETAILS,
        exact: true,
        name: 'NEWS_DETAILS',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: NewsDetailContainer, ...props })
    },
    {
        path: CONST.AppURI.USER_COUPON_ADD,
        exact: true,
        name: 'USER_COUPON_ADD',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: UserCouponAddContainer, ...props })
    },
    {
        path: CONST.AppURI.COUPON_CODE,
        exact: true,
        name: 'COUPON_CODE',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: CouponCodeContainer, ...props })
    },
];

export const appRoutesWithoutNav = [
    {
        path: CONST.AppURI.LOGIN,
        exact: true,
        name: 'Login',
        main: (props: Record<string, unknown>): JSX.Element => <Login {...props} />,
    },
    {
        path: CONST.AppURI.NOT_FOUND,
        exact: true,
        name: 'Not found',
        main: (props: Record<string, unknown>): JSX.Element => <NotFoundComponent {...props} />,
    },
    {
        path: CONST.AppURI.PUBLIC_ORDER,
        exact: true,
        name: 'Order',
        main: (props: Record<string, unknown>): JSX.Element => <PublicOrderContainer {...props} />,
    },
];
