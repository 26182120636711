/** COMMON */
import Types from 'src/core/reduxs/coffee-core/order/order.type';
import UpdateOrderStatusRequest from 'src/core/models/update-order-status-request.model'
import PagingRequest from 'src/core/models/paging-request.model'
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import { toast } from 'react-hot-toast';
import { OrderItemAddition } from 'src/core/models/coffee-core/order-item.model';
import { CreateOutcomeRequest } from 'src/core/models/management/outcome.model';
import { CreateOrderRequest } from 'src/core/models/coffee-core/order.model';

const ordersRepository = Repositories[RepositoryName.ORDER];

export const actGetListOrder = (params: PagingRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_ORDERS_REQUEST, Types.GET_ORDERS_SUCCESS, Types.GET_ORDERS_FAILURE],
        callAPI: () => ordersRepository.getListOrder(params)
    })
};

export const actGetOrderDetail = (id: number) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_ORDER_REQUEST, Types.GET_ORDER_SUCCESS, Types.GET_ORDER_FAILURE],
        callAPI: () => ordersRepository.getOrderDetail(id),
    })
};

export const actUpdateOrderStatus = (params: UpdateOrderStatusRequest, successCallback = () => {}) => (dispatch: any): void => {
    dispatch({
        types: [Types.UPDATE_ORDER_REQUEST, Types.UPDATE_ORDER_SUCCESS, Types.UPDATE_ORDER_FAILURE],
        callAPI: () => ordersRepository.actUpdateOrderStatus(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback();
                toast.success('Cập nhật trạng thái đơn hàng thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                toast.error('Cập nhật trạng thái đơn hàng thất bại', { duration: 2000 })
            }
        }
    })
};

export const actResetOrderDetail = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_ORDER
    })
}

export const actAddOrderItem = (orderItem: OrderItemAddition) => (dispatch: any): void => {
    dispatch({
        type: Types.ADD_ORDER_ITEM,
        payload: orderItem
    });
};

export const actRemoveOrderItem = (index: number) => (dispatch: any): void => {
    dispatch({
        type: Types.REMOVE_ORDER_ITEM,
        payload: index
    });
};

export const actResetOrderItem = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_ORDER_ITEM,
    });
};

export const actCreateOrder = (request: CreateOrderRequest, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_ORDER_REQUEST, Types.CREATE_ORDER_SUCCESS, Types.CREATE_ORDER_FAILURE],
        callAPI: () => ordersRepository.createOrder(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                toast.success('Tạo đơn hàng thành công', {duration: 2000})
                successCallback()
            },
            failure: (error: AxiosError) => {
                console.log(error)
                const response = error.response?.data;
                if (response?.messages[0]?.code === 'ORDER_IS_BEING_PROCESSED') {
                    toast.error('Tồn tại đơn hàng của khách hàng chưa xử lý', {duration: 2000})
                    return
                }
                toast.error('Tạo đơn hàng thất bại', {duration: 2000})
                errorCallback()
            }
        }
    })
}
