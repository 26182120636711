import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBSelect,
    MDBSpinner,
} from 'mdbreact';
import { AppURI, DATE_FORMAT, DEFAULT_PAGE_SIZE, ImportTypes } from 'src/core/utils/constants';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormatter } from 'src/core/utils/common';
import 'src/features/pages/material-management/import/import.style.scss';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { Import } from 'src/core/models/management/import.model';
import moment from 'moment';
import SearchAndFilterRequest, { Filter } from 'src/core/models/search-and-filter-request.model';
import { actDeleteImport, actSearchImport } from 'src/core/reduxs/management/import/import.action';
import Pagination from 'src/shared/components/pagination/pagination.component';
import { actGetListStock } from 'src/core/reduxs/management/stock/stock.action';
import { Stock } from 'src/core/models/management/stock.model';
import _ from 'lodash';
import ConfirmModal from 'src/shared/components/confirm-modal/confirm-modal.component';
import { Deposit } from 'src/core/models/management/deposit.model';
import { actGetListDepositOfStore } from 'src/core/reduxs/management/deposit/deposit.action';

type DataTable = {
    code: JSX.Element;
    title: JSX.Element;
    type: string;
    stockName: string;
    supplierName: string;
    partnerName: string;
    totalPrice: string;
    actorDepositName: string;
    outcomePaidStatus: JSX.Element;
    importTime: JSX.Element;
    commands: JSX.Element;
    [rest: string]: any;
}

const columns = [
    {
        label: 'Mã phiếu',
        field: 'code',
    },
    {
        label: 'Nội dung',
        field: 'title',
    },
    {
        label: 'Loại',
        field: 'type',
    },
    {
        label: 'Kho',
        field: 'stockName',
    },
    {
        label: 'Nhà cung cấp',
        field: 'supplierName',
    },
    {
        label: 'Đối tác',
        field: 'partnerName',
    },
    {
        label: 'Tổng số tiền',
        field: 'totalPrice',
    },
    {
        label: 'Ví chi',
        field: 'actorDepositName',
    },
    {
        label: 'Trạng thái',
        field: 'outcomePaidStatus',
    },
    {
        label: 'Ngày nhập',
        field: 'importTime',
    },
    {
        label: 'Chi tiết',
        field: 'commands',
    },
]

const ImportContainer = (): JSX.Element => {
    const history = useHistory();

    const dispatch = useDispatch();
    const searchData = useSelector((state: any) => state.importReducer.searchedImports.data);
    const stocks: Stock[] = useSelector((state: any) => state.stockReducer.stocks.data);
    const deposits: Deposit[] = useSelector((state: any) => state.depositReducer.storeDeposits.data);
    const imports: Import[] = searchData.items;
    const isLoading = useSelector((state: any) => state.importReducer.searchedImports.isLoading);
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [queryParams, setQueryParams] = useQueryParams({
        keyword: StringParam,
        actorDepositId: NumberParam,
        isOutcomeNotPaid: StringParam,
    });
    const [page, setPage] = useQueryParam('page', NumberParam);
    const [stockId, setStockId] = useQueryParam('stockId', NumberParam);
    const [isVisibleRemoveModal, setIsVisibleRemoveModal] = useState(false);
    const [selectedImportCode, setSelectedImportCode] = useState<string | undefined>();
    const [isNotPaidChecked, setIsNotPaidChecked] = useState(false);

    useEffect(() => {
        dispatch(actGetListStock());
    }, []);

    useEffect(() => {
        if (stockId) {
            const stock = stocks.find(s => s.id === stockId)
            if (stock) {
                dispatch(actGetListDepositOfStore(stock.store.id));
            }
        }
        changeQueryParams('actorDepositId', undefined);
        onPageChanged(page || 1);
    }, [stockId]);

    useEffect(() => {
        onPageChanged(page || 1);
    }, [queryParams]);

    useEffect(() => {
        renderDataRow(imports);
    }, [imports]);

    const stockOptions = useMemo(() => {
        if (!_.isEmpty(stocks)) {
            return stocks.map((s: Stock) => ({
                text: s.name,
                value: String(s.id)
            }));
        }
        return [];
    }, [stocks]);

    const actorDepositOptions = useMemo(() => {
        if (!_.isEmpty(deposits)) {
            return deposits.map((s: Deposit) => ({
                text: s.name,
                value: String(s.id)
            }));
        }
        return [];
    }, [deposits]);

    const navigateToCreatePage = () => {
        history.push({
            pathname: AppURI.IMPORT_CREATE
        });
    }

    const onPageChanged = (activePage: number) => {
        setPage(activePage);
        const filters: Filter = {}
        if (stockId) {
            filters['stock.id'] = String(stockId)
        }
        if (queryParams.actorDepositId) {
            filters['actorDeposit.id'] = String(queryParams.actorDepositId)
        }
        if (queryParams.keyword) {
            filters['code'] = queryParams.keyword
        }
        if (queryParams.isOutcomeNotPaid) {
            filters['isOutcomePaid'] = 'false'
        }
        const request: SearchAndFilterRequest = {
            pageIndex: activePage - 1,
            pageSize: DEFAULT_PAGE_SIZE,
            filters,
            sorts: ['importTime', 'id']
        }
        dispatch(actSearchImport(request));
    }

    const changeQueryParams = (name: string, value: string | undefined) => {
        setQueryParams({
            ...queryParams,
            [name]: value ? value : undefined,
        });
        setPage(1);
    };

    const reset = () => {
        setStockId(undefined)
        setQueryParams({}, 'push');
        onPageChanged(1);
    };

    const navigateToDetails = (code: string) => {
        window.open(`${AppURI.IMPORT_DETAIL}?code=${code}`, '_blank');
    }

    const onClickRemove = (code: string) => {
        setSelectedImportCode(code)
        setIsVisibleRemoveModal(true)
    }

    const removeImport = () => {
        if (selectedImportCode !== undefined) {
            dispatch(actDeleteImport(selectedImportCode, onDeleteSuccess));
            setIsVisibleRemoveModal(false);
        }
    }

    const onDeleteSuccess = useCallback(() => {
        onPageChanged(1)
        setSelectedImportCode(undefined)
        setIsVisibleRemoveModal(false)
    }, [])

    const renderDataRow = (importList: Import[]) => {
        const data: DataTable[] = [];
        importList?.forEach((item: Import, index: number) => {
            const row = {
                code: <div style={{maxWidth: 200}}>{item.code}</div>,
                title: <div style={{maxWidth: 200}}>{item.title}</div>,
                type: ImportTypes[item.type],
                stockName: item.stockName,
                supplierName: item.supplierName,
                partnerName: item.partnerName,
                totalPrice: currencyFormatter(item.totalPrice),
                actorDepositName: item.actorDepositName,
                outcomePaidStatus: item.isOutcomePaid
                    ? <div className='text-success font-weight-normal'>Đã thanh toán</div>
                    : <div className='text-danger font-weight-normal'>Chưa thanh toán</div>,
                importTime: <div className='text-center'>{item.importTime ? moment(item.importTime).format(DATE_FORMAT) : ''}</div>,
                commands: <div style={{width: 90}}>
                    <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => navigateToDetails(item.code)}>
                        <MDBIcon icon='list' size='lg' className='text-primary'/>
                    </MDBBtn>
                    <MDBBtn flat rounded color="danger" className='my-0 mx-1 p-2' onClick={() => onClickRemove(item.code)}>
                        <MDBIcon size='lg' icon="trash-alt" className='red-text'/>
                    </MDBBtn>
                </div>
            };
            data.push(row);
        });
        setDataTable(data);
    }

    const renderDataTable = useMemo(() => {
        if (isLoading) {
            return <MDBCardBody className="d-flex justify-content-center"><MDBSpinner small/></MDBCardBody>;
        }

        return (
            <MDBDataTable
                className='import-table'
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không tìm thấy phiếu nhập nào"
                data={{columns: columns, rows: dataTable}}
            />
        )
    }, [dataTable, isLoading]);

    return (
        <Fragment>
            <MDBContainer className='py-3' fluid>
                <div className='text-center'>
                    <h2 className='page-title'>Quản lý phiếu nhập</h2>
                    <MDBBtn flat rounded className='m-0' onClick={reset}>
                        <MDBIcon icon='undo' size='lg'/>
                    </MDBBtn>
                </div>
                <MDBRow className='pb-3'>
                    <MDBCol md='2'>
                        <MDBSelect
                            options={stockOptions}
                            selected='(chọn kho)'
                            getValue={(value) => {
                                if (value.length > 0) {
                                    changeQueryParams('stockId', value[0]);
                                }
                            }}
                            className='my-0 max-width-400'
                        />
                    </MDBCol>

                    <MDBCol md='2'>
                        <MDBSelect
                            options={actorDepositOptions}
                            selected='(chọn ví)'
                            getValue={(value) => {
                                if (value.length > 0) {
                                    changeQueryParams('actorDepositId', value[0]);
                                }
                            }}
                            className='my-0 max-width-400'
                        />
                    </MDBCol>

                    <MDBCol md='2'>
                        <MDBInput
                            label='Tìm kiếm'
                            onChange={(e: any) => changeQueryParams('keyword', e.target.value)}
                            containerClass='mt-0'
                        />
                    </MDBCol>

                    <MDBCol md='3'>
                        <MDBInput
                            filled
                            label='Chưa thanh toán'
                            checked={isNotPaidChecked}
                            onChange={() => {
                                const newValue = !isNotPaidChecked;
                                setIsNotPaidChecked(newValue)
                                changeQueryParams('isOutcomeNotPaid', newValue ? 'true' : undefined)
                            }}
                            type="checkbox"
                            containerClass='mt-3'
                            id="is-outcome-paid-checkbox"
                        />
                    </MDBCol>

                    <MDBCol md='3' className='text-right'>
                        <MDBBtn
                            color="success"
                            onClick={navigateToCreatePage}>Tạo phiếu nhập mới</MDBBtn>
                    </MDBCol>

                    <MDBCol md='12'>
                        <MDBCard>
                            <MDBCardBody>
                                {renderDataTable}
                                <Pagination
                                    activeIndex={page || 0}
                                    itemCount={searchData.total}
                                    pageSize={DEFAULT_PAGE_SIZE}
                                    onPageChange={onPageChanged}
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <ConfirmModal
                isVisible={isVisibleRemoveModal}
                setIsVisible={setIsVisibleRemoveModal}
                title='Xoá phiếu nhập'
                message={`Bạn có chắc muốn xoá phiếu nhập ${selectedImportCode}?`}
                color='danger'
                onClickConfirm={removeImport}
            />
        </Fragment>
    );
}

export default React.memo(ImportContainer);
