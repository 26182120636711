import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import * as Types from 'src/core/reduxs/coffee-core/category/category.type';
import CategoryStateType from 'src/core/models/coffee-core/category/category-state.model';

const initialState: CategoryStateType = {
    categories: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    productCategories: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    newsCategories: {
        data: [] ,
        error: null,
        isLoading: false,
    },
    categoryDetail: {
        data: undefined,
        error: null,
        isLoading: false,
    },
    updateCategory: {
        data: {},
        error: null,
        isLoading: false
    },
    createCategory: {
        data: {},
        error: null,
        isLoading: false
    },
    deleteCategory: {
        data: {},
        error: null,
        isLoading: false
    },
};

const categoryReducer = createReducer(initialState, {
    [Types.GET_CATEGORIES_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            categories: {
                ...state.categories,
                isLoading: true,
            }
        };
    },
    [Types.GET_CATEGORIES_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            categories: {
                ...state.categories,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_CATEGORIES_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            categories: {
                ...state.categories,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.GET_PRODUCT_CATEGORIES_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            productCategories: {
                ...state.productCategories,
                isLoading: true,
            }
        };
    },
    [Types.GET_PRODUCT_CATEGORIES_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            productCategories: {
                ...state.productCategories,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_PRODUCT_CATEGORIES_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            productCategories: {
                ...state.productCategories,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.GET_NEWS_CATEGORIES_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            newsCategories: {
                ...state.newsCategories,
                isLoading: true,
            }
        };
    },
    [Types.GET_NEWS_CATEGORIES_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            newsCategories: {
                ...state.newsCategories,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_NEWS_CATEGORIES_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            newsCategories: {
                ...state.newsCategories,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.CREATE_CATEGORY_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createCategory: {
                ...state.createCategory,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_CATEGORY_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createCategory: {
                ...state.createCategory,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.CREATE_CATEGORY_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            createCategory: {
                ...state.createCategory,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.UPDATE_CATEGORY_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateCategory: {
                ...state.updateCategory,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_CATEGORY_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateCategory: {
                ...state.updateCategory,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.UPDATE_CATEGORY_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            updateCategory: {
                ...state.updateCategory,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.DELETE_CATEGORY_REQUEST]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteCategory: {
                ...state.deleteCategory,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_CATEGORY_SUCCESS]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteCategory: {
                ...state.deleteCategory,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.DELETE_CATEGORY_FAILURE]: (state : typeof initialState, action: AnyAction ) => {
        return {
            ...state,
            deleteCategory: {
                ...state.deleteCategory,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default categoryReducer;
