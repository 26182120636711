import { MDBBtn, MDBCol, MDBRow, MDBSpinner } from 'mdbreact'
import React, { useEffect, useMemo, useState } from 'react'
import PageHeader from 'src/shared/components/page-header/page-header.component'
import 'src/features/pages/accumulate/accumulate-code/accumulate-code.style.scss'
import QRCode from 'qrcode.react'
import html2canvas from 'html2canvas';
import _ from 'lodash';
import jsPDF from 'jspdf';
import { useDispatch, useSelector } from 'react-redux';
import {
    actGetListBlackPearlCode,
    actUpdateBlackPearlCodeStatus
} from 'src/core/reduxs/coffee-core/black-pearl-code/black-pearl-code.action';
import { BlackPearlCode } from 'src/core/models/black-pearl-code.model';
import BlackPearlCodeCreateForm from 'src/features/components/black-pearl-code/black-pearl-code-create-form.component';
import moment from 'moment/moment';
import { DATE_FORMAT } from 'src/core/utils/constants';
import Select from 'react-select';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { Tenant } from 'src/core/models/tenant.model';
import { actGetListTenant } from 'src/core/reduxs/coffee-core/tenant/tenant.action';
import { actGetListNewsCategoryByTenant } from 'src/core/reduxs/coffee-core/news/news.action';

const AccumulateCodeContainer = (): JSX.Element => {

    const dispatch = useDispatch();
    const [generatingPDF, setGeneratingPDF] = useState<boolean>(false)
    const blackPearlCodes = useSelector((state: any) => state.blackPearlCodeReducer.blackPearlCodes);
    const newBlackPearlCodes: BlackPearlCode[] = blackPearlCodes.data
    const tenants: Tenant[] = useSelector((state: any) => state.tenantReducer.tenants.data);
    const [selectedTenantId, setSelectedTenantId] = useState<number | undefined>(undefined);

    useEffect(() => {
        dispatch(actGetListTenant());
    }, [])

    useEffect(() => {
        if (selectedTenantId !== undefined) {
            dispatch(actGetListNewsCategoryByTenant(selectedTenantId));
        }
    }, [selectedTenantId])

    const tenantOptions = useMemo(() => {
        if (!_.isEmpty(tenants)) {
            return tenants.map((t) => ({
                label: t.name,
                value: t.id
            }));
        }
        return [];
    }, [tenants]);

    useEffect(() => {
        getListCode();
    }, [selectedTenantId]);

    const getListCode = () => {
        if (selectedTenantId) {
            dispatch(actGetListBlackPearlCode(selectedTenantId))
        }
    }

    const downloadPDF = async (blackPearlCodes: BlackPearlCode[]): Promise<void> => {
        setGeneratingPDF(true)
        const pages = document.querySelectorAll('.print-page');
        const pdf = new jsPDF('portrait', 'mm', 'a4');
        for (let i = 0; i < pages.length; i++) {
            const canvas = await html2canvas(pages[i] as HTMLElement);
            if (i < pages.length - 1) {
                pdf.addImage(canvas.toDataURL('image/png'), 0, 0, 210, 297);
                pdf.addPage();
            } else {
                pdf.addImage(canvas.toDataURL('image/png'), 0, 0, 210, 297);
            }
        }
        pdf.save('black_pearl_codes');
        setGeneratingPDF(false)

        dispatch(actUpdateBlackPearlCodeStatus(blackPearlCodes.map(b => b.id), 'PRINTED', getListCode))
    }

    const getBlackPearlCodeMap = (blackPearlCodes: BlackPearlCode[]): Map<number, BlackPearlCode[]> => {
        const map = new Map();
        for (const blackPearlCode of blackPearlCodes) {
            if (map.get(blackPearlCode.amount) !== undefined) {
                map.set(blackPearlCode.amount, [...map.get(blackPearlCode.amount), blackPearlCode]);
            } else {
                map.set(blackPearlCode.amount, [blackPearlCode]);
            }
        }
        return map;
    }
    const map = getBlackPearlCodeMap(newBlackPearlCodes);

    let pages: BlackPearlCode[][] = [];
    if (!_.isEmpty(newBlackPearlCodes)) {
        const codes = [];
        for (let i = 0; i < newBlackPearlCodes.length; i++) {
            codes.push(newBlackPearlCodes[i]);
        }
        pages = _.chunk(codes, 20);
    }

    return (
        <div id="accumulate-code">
            <PageHeader title='ACCUMULATE_CODE.PAGE.TITLE'/>
            <MDBRow className='pb-3'>
                <MDBCol md="2">
                    <div className="pt-2">Chọn brand</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        options={tenantOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setSelectedTenantId(newValue.value)
                            }
                        }}
                        placeholder='(chọn brand)'
                        className='max-width-400 my-1'
                    />
                </MDBCol>
            </MDBRow>

            {selectedTenantId &&
                <BlackPearlCodeCreateForm tenantId={selectedTenantId}/>
            }

            <div className='my-3'>
                {blackPearlCodes.isLoading &&
                    <MDBRow className="d-flex justify-content-center mb-5"><MDBSpinner small/></MDBRow>}
                {map &&
                    <div>
                        <p>Hiện có:</p>
                        <ul>
                            {Array.from(map.entries()).map((entry) => {
                                const [key, value] = entry
                                return (<li key={key}><span className='font-weight-bolder'>{value.length}</span> mã tích
                                    điểm <span className='font-weight-bolder'>{key} trân châu</span></li>)
                            })}
                        </ul>
                        <MDBBtn
                            className='purple-gradient' size='lg' onClick={() => downloadPDF(newBlackPearlCodes)}
                            disabled={_.isEmpty(newBlackPearlCodes)}
                        >
                            {generatingPDF ?
                                <MDBSpinner small className='b-primary'/> : 'Tải xuống'
                            }
                        </MDBBtn>
                    </div>
                }
            </div>
            <div style={{overflow: 'hidden', width: 0, height: 0}}>
                {
                    pages.map((codes, index) => {
                        return (
                            <div key={index} className="mx-auto print-page pt-2" style={{width: 590, height: 840}}>
                                <MDBRow className="w-100 mx-auto">
                                    {
                                        codes.map((code, index) => {
                                            return (
                                                <MDBCol key={code.code} size='3' className='mt-3 mb-2 mx-auto p-0 flex-center'>
                                                    <div className="text-center">
                                                        <div className="bg-white qr-container">
                                                            <QRCode
                                                                id='qrCode'
                                                                value={code.code}
                                                                size={110}
                                                                level='H'
                                                                bgColor='white'
                                                                fgColor='black'
                                                                includeMargin={false}
                                                                renderAs='canvas'
                                                            />
                                                        </div>
                                                        <p className="text-center" style={{fontSize: 10, marginTop: 8}}>Mã {code.amount} trân
                                                            châu</p>
                                                        <p className="text-center" style={{fontSize: 10}}>Hết
                                                            hạn: {code.expiredTime ? moment(code.expiredTime).format(DATE_FORMAT) : ''}</p>
                                                    </div>
                                                </MDBCol>
                                            )
                                        })
                                    }
                                </MDBRow>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default React.memo(AccumulateCodeContainer)
