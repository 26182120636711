import { ApiURI } from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { User } from 'src/core/models/coffee-core/user.model';

export default class UserRepository extends BaseRepository {

    getUserByCode(code: string, tenantId: number): Promise<AxiosResponse<User>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/user/getUserByCode`).query<User>({code, tenantId})
    }

    getUserByPhone(phone: string, tenantId: number): Promise<AxiosResponse<User>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/user/getUserByPhone`).query<User>({phone, tenantId})
    }
}
