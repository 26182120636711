import Category from 'src/core/models/coffee-core/category/category.model';
import { Tenant } from 'src/core/models/tenant.model';

export interface NewsType {
    id: number;
    type: string;
    name: string;
}

export interface News {
    id: number;
    title: string;
    thumbnail: string;
    shortContent: string;
    content: string;
    createdTime: string;
    type: NewsType;
    category: Category;
    tenant: Tenant;
}

export const NewsTypes: NewsType[] = [
    {
        id: 1,
        type: 'SYSTEM',
        name: 'Hệ thống'
    },
    {
        id: 2,
        type: 'PROMOTION',
        name: 'Khuyến mãi'
    },
]
