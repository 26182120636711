import { MDBBox } from 'mdbreact'
import React from 'react'
import { useIntl } from 'react-intl'

type Props = {
    title: string;
}

const PageHeader = (props: Props): JSX.Element => {
    const intl = useIntl();
    return (
        <>
            <div className='mb-4'>
                <h4>{intl.formatMessage({ id: props.title })}</h4>
            </div>
        </>
    )
}

export default PageHeader
