import { MDBBadge, MDBBox, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBIcon, MDBInput, MDBRow, MDBSpinner } from 'mdbreact'
import React, { useState } from 'react'
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import PickDeep from 'src/core/models/pick-deep.modal';
import { ToppingGroupCreateRequest } from 'src/core/models/coffee-core/topping-group/topping-group-request-params.model';
import ToppingGroupStateType from 'src/core/models/coffee-core/topping-group/topping-group-state.model';
import ToppingStateType from 'src/core/models/coffee-core/topping/topping-state.model';
import * as ToppingGroupAction from 'src/core/reduxs/coffee-core/topping-group/topping-group.action'

const initChosenToppings: number[] = []
const ToppingGroupCreateFormComponent = (): JSX.Element => {
    const intl = useIntl();
    const [chosenToppings, setChosenToppings] = useState(initChosenToppings);
    const [toppingGroupName, setToppingGroupName] = useState('');

    const dispatch = useDispatch();
    const createToppingGroup: PickDeep<ToppingGroupStateType, 'createToppingGroup'> = useSelector((state: any) => state.toppingGroupReducer.createToppingGroup)
    const toppings: PickDeep<ToppingStateType, 'toppings'> = useSelector((state: any) => state.toppingReducer.toppings)

    const handleToggleTopping = (id: number) => {
        if (chosenToppings.includes(id)) {
            const newChosenToppings = chosenToppings.filter((topping) => topping !== id)
            setChosenToppings(newChosenToppings)
        }
        else {
            setChosenToppings((oldToppings) => [...oldToppings, id])
        }
    }

    const createSuccess = () => {
        setChosenToppings(initChosenToppings);
        setToppingGroupName('')
        toast.success(intl.formatMessage({ id: 'TOPPING_GROUP.CREATE.TOAST_SUCCESS' }), { duration: 3000 })
    }

    const handleCreateToppingGroup = () => {
        const params: ToppingGroupCreateRequest = {
            data: {
                name: toppingGroupName,
                toppingIds: chosenToppings
            },
            successCallback: () => createSuccess(),
            errorCallback: () => toast.error(intl.formatMessage({ id: 'TOPPING_GROUP.CREATE.TOAST_FAILURE' }), { duration: 3000 })
        }
        dispatch(ToppingGroupAction.actCreateToppingGroup(params))
    }

    return (
        <MDBCard className="topping-group-create">
            <MDBCardTitle className="topping-group-create-title">
                <MDBBox>
                    {intl.formatMessage({ id: 'TOPPING_GROUP.CREATE.TITLE' })}
                </MDBBox>
            </MDBCardTitle>
            <MDBCardBody className="topping-group-create-body">
                <MDBBox className="topping-group-create-body-form">
                    <form
                        className='needs-validation was-validated'
                        onSubmit={(event: React.FormEvent) => { event.preventDefault(); handleCreateToppingGroup() }}
                    >
                        <MDBBox className='form-inputs'>
                            <MDBInput
                                label={intl.formatMessage({ id: 'TOPPING_GROUP.CREATE.LABEL_NAME' })}
                                outline={true}
                                value={toppingGroupName}
                                onChange={(event) => { setToppingGroupName(event.currentTarget.value) }}
                                required
                                disabled={createToppingGroup.isLoading}
                            />
                        </MDBBox>
                        <MDBBox className='form-list-toppings'>
                            <MDBBox className='form-list-toppings-label'>{intl.formatMessage({ id: 'TOPPING_GROUP.CREATE.LABEL_TOPPINGS' })}</MDBBox>
                            {(toppings.isLoading || createToppingGroup.isLoading) && <MDBRow className="d-flex justify-content-center mb-5 w-100 h-100 mt-1"><MDBSpinner small /></MDBRow>}
                            {!toppings.isLoading && !createToppingGroup.isLoading && toppings.data.length === 0 && <p style={{ fontSize: '0.9rem', fontWeight: 300 }}>{intl.formatMessage({ id: 'TOPPING_GROUP.CREATE.LABEL_TOPPINGS_NOT_FOUND' })}</p>}
                            {!toppings.isLoading && !createToppingGroup.isLoading && toppings.data.length > 0 &&
                                (toppings.data.map((topping) => {
                                    return (
                                        <MDBBadge
                                            key={topping.id}
                                            className={(chosenToppings.includes(topping.id)) ? 'chosen-badge' : 'remain-badge'}
                                            onClick={() => handleToggleTopping(topping.id)}
                                        >
                                            {topping.name}
                                        </MDBBadge>
                                    )
                                }))
                            }
                        </MDBBox>

                        <MDBBox className='form-submit-box'>
                            <MDBBtn color='dark-green' className='form-submit-btn' type="submit">
                                {intl.formatMessage({ id: 'TOPPING_GROUP.CREATE.LABEL_BUTTON' })}
                                <MDBIcon icon='plus' className='ml-3' />
                            </MDBBtn>
                        </MDBBox>
                    </form>
                </MDBBox>
            </MDBCardBody>
        </MDBCard>
    )
}

export default ToppingGroupCreateFormComponent
