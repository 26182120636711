import { MDBBtn, MDBIcon, MDBInput, } from 'mdbreact'
import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import * as SizeAction from 'src/core/reduxs/coffee-core/size/size.action'
import PickDeep from 'src/core/models/pick-deep.modal';
import { toast } from 'react-hot-toast';
import { useIntl } from 'react-intl';
import SizeStateType from 'src/core/models/coffee-core/size/size-state.model';
import { SizeUpdateRequest } from 'src/core/models/coffee-core/size/size-request-params.model';
import Size from 'src/core/models/coffee-core/size/size.model';
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component';


type Props = {
    isOpen: boolean,
    sizeDetail: Size,
    onModalClosed: () => void
}
const SizeUpdateModalComponent = (props: Props): JSX.Element => {
    const { sizeDetail, isOpen, onModalClosed } = props;
    const intl = useIntl();
    const [sizeName, setSizeName] = useState(sizeDetail.name);
    const [sizePrice, setSizePrice] = useState(sizeDetail.price);
    const dispatch = useDispatch()
    const updateSize: PickDeep<SizeStateType, 'updateSize'> = useSelector((state: any) => state.sizeReducer.updateSize)
    const onCloseModal = () => {
        onModalClosed()
    }
    useEffect(() => {
        setSizeName(sizeDetail.name)
        setSizePrice(sizeDetail.price)
    }, [sizeDetail])

    const onSuccessUpdate = () => {
        onCloseModal();
        toast.success(intl.formatMessage({ id: 'SIZE.UPDATE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureUpdate = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'SIZE.UPDATE.TOAST_FAILURE' }), { duration: 3000 })
    }
    const handleUpdateSize = () => {
        if (sizePrice > 0) {
            const params: SizeUpdateRequest = {
                data: [{
                    id: sizeDetail.id,
                    name: sizeName,
                    price: sizePrice
                }],
                successCallback: onSuccessUpdate,
                errorCallback: onFailureUpdate
            }
            dispatch(SizeAction.actUpdateSize(params))
        } else {
            toast.error(intl.formatMessage({ id: 'SIZE.CREATE.TOAST_WARNING_MIN_PRICE' }))
        }

    }
    return (
        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'SIZE.UPDATE.TITLE' })}
            type='update'
        >
            <form
                onSubmit={(event: React.FormEvent) => { event.preventDefault(); handleUpdateSize() }}
                className='needs-validation was-validated'
            >
                <MDBInput label={intl.formatMessage({ id: 'SIZE.UPDATE.LABEL_CODE' })} value={sizeDetail.code} disabled />
                <MDBInput
                    label={intl.formatMessage({ id: 'SIZE.UPDATE.LABEL_NAME' })}
                    required value={sizeName}
                    onChange={(event) => { setSizeName(event.currentTarget.value) }}
                    disabled={updateSize.isLoading}
                />
                <MDBInput
                    label={intl.formatMessage({ id: 'SIZE.UPDATE.LABEL_PRICE' })}
                    required
                    value={sizePrice}
                    onChange={(event) => { setSizePrice(_.parseInt(event.currentTarget.value)) }}
                    type="number"
                    min={1}
                    disabled={updateSize.isLoading} />
                <div className='text-center mb-1-half'>
                    <MDBBtn
                        className='mb-2 '
                        color='indigo'
                        type="submit"
                        disabled={updateSize.isLoading}
                    >
                        {intl.formatMessage({ id: 'SIZE.UPDATE.LABEL_BUTTON' })}
                        <MDBIcon icon='check' className='ml-3' />
                    </MDBBtn>
                </div>
            </form>
        </CustomModal>
    )
}

export default SizeUpdateModalComponent
