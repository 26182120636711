import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import DepositState from 'src/core/reduxs/management/deposit/deposit.state';
import Types from 'src/core/reduxs/management/deposit/deposit.type';

const initialState: DepositState = {
    storeDeposits: {
        data: [] ,
        error: null,
        isLoading: false,
    }
};

const depositReducer = createReducer(initialState, {
    [Types.GET_LIST_DEPOSIT_BY_STORE_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            storeDeposits: {
                ...state.storeDeposits,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_DEPOSIT_BY_STORE_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            storeDeposits: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_DEPOSIT_BY_STORE_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            storeDeposits: {
                ...state.storeDeposits,
                isLoading: false,
                error: action.payload
            }
        };
    },
    [Types.RESET_LIST_DEPOSIT_BY_STORE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            storeDeposits: {
                data: [] ,
                error: null,
                isLoading: false,
            }
        };
    },
})

export default depositReducer;
