import React, { useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBDatePicker, MDBRow, MDBSpinner, } from 'mdbreact';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-hot-toast';
import { formatDateForApi } from 'src/core/utils/common';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Required from 'src/shared/components/required/required.component';
import {
    actAddUserCoupons,
    actGetListCouponByTypes,
    actResetUserCouponItemList
} from 'src/core/reduxs/coffee-core/coupon/coupon.action';
import {
    AddUserCouponsRequest,
    Coupon,
    UserCouponItem,
    UserCouponRequest
} from 'src/core/models/coffee-core/coupon.model';
import UserSearchForm from 'src/features/components/coupon/user-coupon-addition-form.component';
import UserCouponCreateList from 'src/features/components/coupon/user-coupon-addition-list.component';
import { actGetListTenant } from 'src/core/reduxs/coffee-core/tenant/tenant.action';
import { Tenant } from 'src/core/models/tenant.model';

const UserCouponAddContainer = (): JSX.Element => {

    const history = useHistory();
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const dispatch = useDispatch();
    const coupons: Coupon[] = useSelector((state: any) => state.couponReducer.coupons.data);
    const userCouponItems: UserCouponItem[] = useSelector((state: any) => state.couponReducer.userCouponItems.data);
    // const adding: boolean = useSelector((state: any) => state.couponReducer.createOutcome.loading);
    const adding = false;
    const tenants: Tenant[] = useSelector((state: any) => state.tenantReducer.tenants.data);
    const [selectedTenantId, setSelectedTenantId] = useState<number | undefined>(undefined);

    useEffect(() => {
        dispatch(actGetListTenant());
    }, [])

    const tenantOptions = useMemo(() => {
        if (!_.isEmpty(tenants)) {
            return tenants.map((t) => ({
                label: t.name,
                value: t.id
            }));
        }
        return [];
    }, [tenants]);

    useEffect(() => {
        if (selectedTenantId) {
            dispatch(actGetListCouponByTypes(['PUBLIC','CHECK'], selectedTenantId));
        }
    }, [selectedTenantId]);

    const couponOptions = useMemo(() => {
        if (!_.isEmpty(coupons)) {
            return coupons.map((c: Coupon) => ({
                label: c.title,
                value: c.id
            }));
        }
        return [];
    }, [coupons]);


    const onSuccess = () => {
        dispatch(actResetUserCouponItemList())
    }

    const onSubmit = (data: any) => {
        if (_.isEmpty(userCouponItems)) {
            toast.error('Bạn chưa nhập user cần gửi ưu đãi', {duration: 2000})
            return
        }
        const userCouponRequests: UserCouponRequest[] = userCouponItems.map(u => ({
            userId: u.user.id,
            couponQuantity: u.couponQuantity
        }))
        const request: AddUserCouponsRequest = {
            couponId: data.couponId,
            userCouponRequests,
            expirationDate: data.expirationDate,
            tenantId: data.tenantId,
        }
        dispatch(actAddUserCoupons(request, onSuccess))
    }

    return (
        <MDBContainer className='py-3' fluid>
            <div className='text-center'>
                <h2 className='page-title'>Gửi ưu đãi cho User</h2>
            </div>
            <MDBRow className='pb-3 mt-4'>
                <MDBCol md="2">
                    <div className="pt-2">Chọn brand</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        options={tenantOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('tenantId', newValue.value);
                                setSelectedTenantId(newValue.value)
                            }
                        }}
                        placeholder='(chọn brand)'
                        className='max-width-400 my-1'
                    />
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Ưu đãi<Required/></div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('couponId', {
                            required: 'Ưu đãi là bắt buộc'
                        })}
                        options={couponOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                const couponId = newValue.value
                                setValue('couponId', couponId);
                            }
                        }}
                        placeholder='(chọn ưu đãi)'
                        className='max-width-400 my-1'
                    />
                    {errors.couponId && <ErrorMessage>{errors.couponId.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Ngày hết hạn<Required/></div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBDatePicker
                        {...register('expirationDate', {
                            required: 'Ngày hết hạn là bắt buộc'
                        })}
                        clearable
                        format='DD-MM-YYYY'
                        autoOk
                        getValue={(e) => {
                            setValue('expirationDate', e ? formatDateForApi(e) : null)
                        }}
                        className='mt-0'
                        valueDefault={null as any}
                        emptyLabel='(chọn ngày)'
                    />
                    {errors.expirationDate && <ErrorMessage>{errors.expirationDate.message}</ErrorMessage>}
                </MDBCol>
            </MDBRow>
            {selectedTenantId &&
                <MDBRow>
                    <MDBCol size='7'>
                        <UserCouponCreateList/>
                    </MDBCol>
                    <MDBCol size='5'>
                        <UserSearchForm tenantId={selectedTenantId}/>
                    </MDBCol>
                    <MDBCol size='12'>
                        <div className='text-center'>
                            <MDBBtn color="primary" onClick={handleSubmit(onSubmit)} disabled={adding}>Gửi ưu đãi</MDBBtn>
                            {adding &&
                                <MDBSpinner small className='b-primary'/>
                            }
                        </div>
                    </MDBCol>
                </MDBRow>
            }
        </MDBContainer>
    );
}

export default React.memo(UserCouponAddContainer);
