import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import Types from 'src/core/reduxs/coffee-core/tenant/tenant.type';
import TenantState from 'src/core/reduxs/coffee-core/tenant/tenant.state';

const initialState: TenantState = {
    tenants: {
        data: [],
        error: null,
        isLoading: false,
    },
};

const tenantReducer = createReducer(initialState, {
    [Types.GET_LIST_TENANT_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            tenants: {
                ...state.tenants,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_TENANT_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            tenants: {
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_TENANT_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            tenants: {
                ...state.tenants,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default tenantReducer;
