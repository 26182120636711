import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import { ApiURI, DEFAULT_PAGE_SIZE } from 'src/core/utils/constants';
import PagingRequest from 'src/core/models/paging-request.model';
import { News } from 'src/core/models/coffee-core/news.model';

export default class NewsRepository extends BaseRepository {

    createNews(request: FormData): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/news/createNews`).create<ResponseCustom>(request)
    }

    updateNews(request: FormData): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/news/updateNews`).create<ResponseCustom>(request)
    }

    getListNews(params: PagingRequest): Promise<AxiosResponse<News[]>> {
        const request = {
            ...params,
            itemsPerPage: DEFAULT_PAGE_SIZE,
            sortBy: 'createdTime',
            sortType: 'DESC'
        };
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/news/getListNews`).query<News[]>(request);
    }

    getNewsDetails(id: number): Promise<AxiosResponse<News>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/news/getNewsById`).query<News>({id})
    }
}
