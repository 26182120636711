import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { BlackPearlCode, CreateBlackPearlCodesRequest } from 'src/core/models/black-pearl-code.model';
import {AxiosResponse} from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import { ApiURI } from 'src/core/utils/constants';

export default class BlackPearlCodeRepository extends BaseRepository {
    constructor() {
        super('');
    }

    getListBlackPearlCode(status: string, tenantId: number): Promise<AxiosResponse<BlackPearlCode[]>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_BLACK_PEARL_CODE).query<BlackPearlCode[]>({status, tenantId});
    }

    updateBlackPearlCodeStatus(blackPearlCodeIds: number[], status: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/blackPearl/updateBlackPearlCodeStatus`).create<ResponseCustom>({blackPearlCodeIds, status});
    }

    createBlackPearlCodes(request: CreateBlackPearlCodesRequest): Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/admin/blackPearl/createBlackPearlCodes`).create<ResponseCustom>(request);
    }
}
