const Types = {
    CREATE_INCOME_REQUEST: 'CREATE_INCOME_REQUEST',
    CREATE_INCOME_SUCCESS: 'CREATE_INCOME_SUCCESS',
    CREATE_INCOME_FAILURE: 'CREATE_INCOME_FAILURE',
    ADD_INCOME_ITEM: 'ADD_INCOME_ITEM',
    REMOVE_INCOME_ITEM: 'REMOVE_INCOME_ITEM',
    RESET_INCOME_ITEM: 'RESET_INCOME_ITEM',
    GET_LIST_INCOME_CATEGORY_REQUEST: 'GET_LIST_INCOME_CATEGORY_REQUEST',
    GET_LIST_INCOME_CATEGORY_SUCCESS: 'GET_LIST_INCOME_CATEGORY_SUCCESS',
    GET_LIST_INCOME_CATEGORY_FAILURE: 'GET_LIST_INCOME_CATEGORY_FAILURE',
    SEARCH_INCOME_REQUEST: 'SEARCH_INCOME_REQUEST',
    SEARCH_INCOME_SUCCESS: 'SEARCH_INCOME_SUCCESS',
    SEARCH_INCOME_FAILURE: 'SEARCH_INCOME_FAILURE',
    GET_INCOME_DETAILS_REQUEST: 'GET_INCOME_DETAILS_REQUEST',
    GET_INCOME_DETAILS_SUCCESS: 'GET_INCOME_DETAILS_SUCCESS',
    GET_INCOME_DETAILS_FAILURE: 'GET_INCOME_DETAILS_FAILURE',
    DELETE_INCOME_REQUEST: 'DELETE_INCOME_REQUEST',
    DELETE_INCOME_SUCCESS: 'DELETE_INCOME_SUCCESS',
    DELETE_INCOME_FAILURE: 'DELETE_INCOME_FAILURE',
}

export default Types;