import React, { Component } from 'react'
import 'src/features/pages/category/category.style.scss'
import PageHeader from 'src/shared/components/page-header/page-header.component'
import CategoryWrapperComponent from 'src/features/components/category/category-wrapper.component'

export default class Categories extends Component {
    render() {
        return (
            <div id="category">
                <PageHeader title='CATEGORY.PAGE.TITLE' />
                <CategoryWrapperComponent />
            </div>
        )
    }
}


