import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBSelect,
    MDBSpinner,
} from 'mdbreact';
import { AppURI, DATE_FORMAT, DEFAULT_PAGE_SIZE, ExportTypes } from 'src/core/utils/constants';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currencyFormatter } from 'src/core/utils/common';
import 'src/features/pages/material-management/export/export.style.scss';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';
import { Export } from 'src/core/models/management/export.model';
import moment from 'moment';
import Pagination from 'src/shared/components/pagination/pagination.component';
import SearchAndFilterRequest, { Filter } from 'src/core/models/search-and-filter-request.model';
import { actDeleteExport, actSearchExport } from 'src/core/reduxs/management/export/export.action';
import { Stock } from 'src/core/models/management/stock.model';
import { actGetListStock } from 'src/core/reduxs/management/stock/stock.action';
import _ from 'lodash';
import ConfirmModal from 'src/shared/components/confirm-modal/confirm-modal.component';
import { Deposit } from 'src/core/models/management/deposit.model';
import { actGetListDepositOfStore } from 'src/core/reduxs/management/deposit/deposit.action';

type DataTable = {
    id: number;
    code: string;
    type: string;
    title: string;
    stockName: string;
    partnerName: string;
    totalPrice: string;
    actorDepositName: string;
    exportTime: JSX.Element;
    commands: JSX.Element;
    [rest: string]: any;
}

const columns = [
    {
        label: 'ID',
        field: 'id',
    },
    {
        label: 'Mã phiếu',
        field: 'code',
    },
    {
        label: 'Nội dung',
        field: 'title',
    },
    {
        label: 'Loại',
        field: 'type',
    },
    {
        label: 'Kho',
        field: 'stockName',
    },
    {
        label: 'Đối tác',
        field: 'partnerName',
    },
    {
        label: 'Tổng số tiền',
        field: 'totalPrice',
    },
    {
        label: 'Ví thu',
        field: 'actorDepositName',
    },
    {
        label: 'Ngày xuất',
        field: 'exportTime',
    },
    {
        label: 'Chi tiết',
        field: 'commands',
    },
]

const ExportContainer = (): JSX.Element => {
    const history = useHistory();

    const dispatch = useDispatch();
    const searchData = useSelector((state: any) => state.exportReducer.searchedExports.data);
    const stocks: Stock[] = useSelector((state: any) => state.stockReducer.stocks.data);
    const deposits: Deposit[] = useSelector((state: any) => state.depositReducer.storeDeposits.data);
    const exports: Export[] = searchData.items;
    const isLoading = useSelector((state: any) => state.exportReducer.searchedExports.isLoading);
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [queryParams, setQueryParams] = useQueryParams({
        keyword: StringParam,
        actorDepositId: NumberParam,
    });
    const [page, setPage] = useQueryParam('page', NumberParam);
    const [stockId, setStockId] = useQueryParam('stockId', NumberParam);
    const [isVisibleRemoveModal, setIsVisibleRemoveModal] = useState(false);
    const [selectedExportCode, setSelectedExportCode] = useState<string | undefined>();

    useEffect(() => {
        dispatch(actGetListStock());
    }, []);

    useEffect(() => {
        if (stockId) {
            const stock = stocks.find(s => s.id === stockId)
            if (stock) {
                dispatch(actGetListDepositOfStore(stock.store.id));
            }
        }
        changeQueryParams('actorDepositId', undefined);
        onPageChanged(page || 1);
    }, [stockId]);

    useEffect(() => {
        onPageChanged(page || 1);
    }, [queryParams]);

    useEffect(() => {
        renderDataRow(exports);
    }, [exports]);

    const stockOptions = useMemo(() => {
        if (!_.isEmpty(stocks)) {
            return stocks.map((s: Stock) => ({
                text: s.name,
                value: String(s.id)
            }));
        }
        return [];
    }, [stocks]);

    const actorDepositOptions = useMemo(() => {
        if (!_.isEmpty(deposits)) {
            return deposits.map((s: Deposit) => ({
                text: s.name,
                value: String(s.id)
            }));
        }
        return [];
    }, [deposits]);

    const onPageChanged = (activePage: number) => {
        setPage(activePage);
        const filters: Filter = {}
        if (stockId) {
            filters['stock.id'] = String(stockId)
        }
        if (queryParams.actorDepositId) {
            filters['actorDeposit.id'] = String(queryParams.actorDepositId)
        }
        if (queryParams.keyword) {
            filters['code'] = queryParams.keyword
        }
        const request: SearchAndFilterRequest = {
            pageIndex: activePage - 1,
            pageSize: DEFAULT_PAGE_SIZE,
            filters,
            sorts: ['exportTime', 'id']
        }
        dispatch(actSearchExport(request));
    }

    const changeQueryParams = (name: string, value: string | undefined) => {
        setQueryParams({
            ...queryParams,
            [name]: value ? value : undefined,
        });
        setPage(1);
    };

    const reset = () => {
        setStockId(undefined)
        setQueryParams({}, 'push');
        onPageChanged(1);
    };

    const navigateToDetails = (code: string) => {
        window.open(`${AppURI.EXPORT_DETAIL}?code=${code}`, '_blank');
    }

    const navigateToCreatePage = () => {
        history.push({
            pathname: AppURI.EXPORT_CREATE
        });
    }

    const onClickRemove = (code: string) => {
        setSelectedExportCode(code)
        setIsVisibleRemoveModal(true)
    }

    const removeExport = () => {
        if (selectedExportCode !== undefined) {
            dispatch(actDeleteExport(selectedExportCode, onDeleteSuccess));
            setIsVisibleRemoveModal(false);
        }
    }

    const onDeleteSuccess = useCallback(() => {
        onPageChanged(1)
        setSelectedExportCode(undefined)
        setIsVisibleRemoveModal(false)
    }, [])

    const renderDataRow = useCallback((exportList: Export[]) => {
        const data: DataTable[] = [];
        exportList?.forEach((item: Export, index: number) => {
            const row = {
                id: item.id,
                code: item.code,
                type: ExportTypes[item.type],
                title: item.title,
                stockName: item.stockName,
                partnerName: item.partnerName,
                totalPrice: currencyFormatter(item.totalPrice),
                actorDepositName: item.actorDepositName,
                exportTime: <div className='text-center'>{item.exportTime ? moment(item.exportTime).format(DATE_FORMAT) : ''}</div>,
                commands: <div style={{width: 90}}>
                    <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => navigateToDetails(item.code)}>
                        <MDBIcon icon='list' size='lg' className='text-primary'/>
                    </MDBBtn>
                    <MDBBtn flat rounded color="danger" className='my-0 mx-1 p-2' onClick={() => onClickRemove(item.code)}>
                        <MDBIcon size='lg' icon="trash-alt" className='red-text'/>
                    </MDBBtn>
                </div>
            };
            data.push(row);
        });
        setDataTable(data);
    }, [])

    const renderDataTable = useMemo(() => {
        if (isLoading) {
            return <MDBCardBody className="d-flex justify-content-center"><MDBSpinner small/></MDBCardBody>;
        }

        return (
            <MDBDataTable
                className='export-table'
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không tìm thấy phiếu xuất nào"
                data={{columns: columns, rows: dataTable}}
            />
        )
    }, [dataTable, isLoading]);

    return (
        <Fragment>
            <MDBContainer className='py-3' fluid>
                <div className='text-center'>
                    <h2 className='page-title'>Quản lý phiếu xuất</h2>
                    <MDBBtn flat rounded className='m-0' onClick={reset}>
                        <MDBIcon icon='undo' size='lg'/>
                    </MDBBtn>
                </div>
                <MDBRow className='pb-3'>
                    <MDBCol md='2'>
                        <MDBSelect
                            options={stockOptions}
                            selected='(chọn kho)'
                            getValue={(value) => {
                                if (value.length > 0) {
                                    changeQueryParams('stockId', value[0]);
                                }
                            }}
                            className='my-0 max-width-400'
                        />
                    </MDBCol>

                    <MDBCol md='3'>
                        <MDBSelect
                            options={actorDepositOptions}
                            selected='(chọn ví)'
                            getValue={(value) => {
                                if (value.length > 0) {
                                    changeQueryParams('actorDepositId', value[0]);
                                }
                            }}
                            className='my-0 max-width-400'
                        />
                    </MDBCol>

                    <MDBCol md='4'>
                        <MDBInput
                            label='Tìm kiếm'
                            onChange={(e: any) => changeQueryParams('keyword', e.target.value)}
                            containerClass='mt-0'
                        />
                    </MDBCol>

                    <MDBCol md='3' className='text-right'>
                        <MDBBtn
                            color="success"
                            onClick={navigateToCreatePage}>Tạo phiếu xuất mới</MDBBtn>
                    </MDBCol>

                    <MDBCol md='12'>
                        <MDBCard>
                            <MDBCardBody>
                                {renderDataTable}
                                <Pagination
                                    activeIndex={page || 0}
                                    itemCount={searchData.total}
                                    pageSize={DEFAULT_PAGE_SIZE}
                                    onPageChange={onPageChanged}
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <ConfirmModal
                isVisible={isVisibleRemoveModal}
                setIsVisible={setIsVisibleRemoveModal}
                title='Xoá phiếu xuất'
                message={`Bạn có chắc muốn xoá phiếu xuất ${selectedExportCode}?`}
                color='danger'
                onClickConfirm={removeExport}
            />
        </Fragment>
    );
}

export default React.memo(ExportContainer);
