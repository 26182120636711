import { ApiURI } from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { Order } from 'src/core/models/coffee-core/order.model';

export default class GuestOrderRepository extends BaseRepository {

    getOrderDetails(id: number): Promise<AxiosResponse<Order>> {
        return this.setUri(`${ApiURI.COFFEE_CORE_SERVICE_PATH}/guest/orders/${id}`).getAll();
    }
}
