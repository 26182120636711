import { AnyAction } from 'redux';
import { createReducer } from 'src/core/utils/redux';
import Types from 'src/core/reduxs/management/supplier/supplier.type';
import SupplierState from 'src/core/reduxs/management/supplier/supplier.state';

const initialState: SupplierState = {
    suppliers: {
        data: [] ,
        error: null,
        isLoading: false,
    }
};

const supplierReducer = createReducer(initialState, {
    [Types.GET_LIST_SUPPLIER_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            suppliers: {
                ...state.suppliers,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_SUPPLIER_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            suppliers: {
                ...state.suppliers,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_LIST_SUPPLIER_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            suppliers: {
                ...state.suppliers,
                isLoading: false,
                error: action.payload
            }
        };
    },
})

export default supplierReducer;
