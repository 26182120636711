import { MDBCol, MDBRow } from 'mdbreact'
import React, { Component } from 'react'
import ToppingGroupCreateFormComponent from 'src/features/components/topping-group/topping-group-create-form.component'
import ToppingGroupListComponent from 'src/features/components/topping-group/topping-group-list.component'
import ToppingListComponent from 'src/features/components/topping/topping-list.component'
import 'src/features/pages/topping/topping.style.scss'
import PageHeader from 'src/shared/components/page-header/page-header.component'

export default class Toppings extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="topping">
                    <PageHeader title='TOPPING.PAGE.TITLE' />
                    <MDBRow className="m-0">
                        <MDBCol md='12' className="p-0 m-0">
                            <ToppingGroupListComponent />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow className="m-0" >
                        <MDBCol lg='7' className="p-0 m-0 space-right2">
                            <ToppingListComponent />
                        </MDBCol>
                        <MDBCol lg='5' className="p-0 m-0">
                            <ToppingGroupCreateFormComponent />
                        </MDBCol>
                    </MDBRow>
                </div>
            </React.Fragment>
        )
    }
}
