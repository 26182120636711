import React from 'react';
import 'src/shared/assets/scss/App.scss';
import { Redirect, Switch } from 'react-router-dom';
import * as COMMON from 'src/core/utils/common';
import * as ROUTES from 'src/core/utils/routes';
import * as CONST from 'src/core/utils/constants';
import { Toaster } from 'react-hot-toast';
import LayoutWithNav from 'src/shared/hoc/layout-with-nav.component';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { ToastContainer } = require('mdbreact');

function App(): JSX.Element {
    return (
        <>
            <Switch>
                <Redirect
                    exact
                    from={CONST.AppURI.INDEX}
                    to={CONST.AppURI.HOME}
                />
                {COMMON.buildRoutes(ROUTES.appRoutesWithoutNav)}
                <LayoutWithNav>
                    {COMMON.buildRoutes(ROUTES.mainRoutes)}
                </LayoutWithNav>
                <Redirect to={CONST.AppURI.NOT_FOUND}/>
            </Switch>
            <Toaster
                position="top-right"
                reverseOrder={false}
                gutter={8}
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                    },
                    // Default options for specific types
                    success: {
                        duration: 3000,
                        iconTheme: {
                            primary: 'green',
                            secondary: 'black',
                        },
                    },
                }}/>
            <ToastContainer
                hideProgressBar={false}
                closeButton={true}
                newestOnTop={true}
                autoClose={5000}
            />
        </>
    );
}

export default App;
