export const GET_SIZE_GROUPS_REQUEST = 'GET_SIZE_GROUPS_REQUEST';
export const GET_SIZE_GROUPS_SUCCESS = 'GET_SIZE_GROUPS_SUCCESS';
export const GET_SIZE_GROUPS_FAILURE = 'GET_SIZE_GROUPS_FAILURE';

export const GET_SIZE_GROUP_REQUEST = 'GET_SIZE_GROUP_REQUEST';
export const GET_SIZE_GROUP_SUCCESS = 'GET_SIZE_GROUP_SUCCESS';
export const GET_SIZE_GROUP_FAILURE = 'GET_SIZE_GROUP_FAILURE';
export const RESET_SIZE_GROUP = 'RESET_SIZE_GROUP';

export const UPDATE_SIZE_GROUP_REQUEST = 'UPDATE_SIZE_GROUP_REQUEST';
export const UPDATE_SIZE_GROUP_SUCCESS = 'UPDATE_SIZE_GROUP_SUCCESS';
export const UPDATE_SIZE_GROUP_FAILURE = 'UPDATE_SIZE_GROUP_FAILURE';

export const CREATE_SIZE_GROUP_REQUEST = 'CREATE_SIZE_GROUP_REQUEST';
export const CREATE_SIZE_GROUP_SUCCESS = 'CREATE_SIZE_GROUP_SUCCESS';
export const CREATE_SIZE_GROUP_FAILURE = 'CREATE_SIZE_GROUP_FAILURE';

export const DELETE_SIZE_GROUP_REQUEST = 'DELETE_SIZE_GROUP_REQUEST';
export const DELETE_SIZE_GROUP_SUCCESS = 'DELETE_SIZE_GROUP_SUCCESS';
export const DELETE_SIZE_GROUP_FAILURE = 'DELETE_SIZE_GROUP_FAILURE';
