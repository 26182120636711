import { MDBBtn, MDBCard, MDBCardBody, MDBDataTable, MDBIcon } from 'mdbreact';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from 'src/shared/components/confirm-modal/confirm-modal.component';
import { UserCouponItem } from 'src/core/models/coffee-core/coupon.model';
import { actRemoveUserCouponItem } from 'src/core/reduxs/coffee-core/coupon/coupon.action';

type DataTable = {
    no: number;
    userCode: string;
    phone: string;
    name: string;
    couponQuantity: number;
    [rest: string]: any;
}

const columns = [
    {
        label: 'STT',
        field: 'no',
    },
    {
        label: 'Mã user',
        field: 'userCode',
    },
    {
        label: 'SĐT',
        field: 'phone',
    },
    {
        label: 'Tên',
        field: 'name',
    },
    {
        label: 'Số lượng ưu đãi',
        field: 'couponQuantity',
    },
    {
        label: '',
        field: 'command',
    }
]

const UserCouponAdditionList = (): JSX.Element => {
    const dispatch = useDispatch();
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const userCouponItems: UserCouponItem[] = useSelector((state: any) => state.couponReducer.userCouponItems.data);
    const [isVisibleRemoveModal, setIsVisibleRemoveModal] = useState(false);
    const [selectedUserCouponItemIndex, setSelectedUserCouponItemIndex] = useState<number | undefined>();

    const prepareDataRow = useCallback((itemList: UserCouponItem[]) => {
        const data: DataTable[] = [];
        itemList.forEach((item, index) => {
            const row = {
                no: index + 1,
                userCode: item.user.code || '',
                phone: item.user.phone || '',
                name: `${item.user.lastName || ''} ${item.user.firstName || ''}`,
                couponQuantity: item.couponQuantity,
                command: <MDBBtn flat rounded color="danger" className="px-3 py-2 my-0" onClick={() => onClickRemove(index)}>
                    <MDBIcon size='lg' icon="trash-alt" className='red-text'/>
                </MDBBtn>
            }
            data.push(row)
        })
        setDataTable(data)
    }, [])

    const userCouponItemDataTable = useMemo(() => {
        return (
            <Fragment>
                <MDBDataTable
                    className='common-table'
                    searching={false}
                    responsive
                    striped
                    borderless
                    sortable={false}
                    noBottomColumns={true}
                    paging={false}
                    displayEntries={false}
                    info={false}
                    noRecordsFoundLabel='Chưa có mục chi nào'
                    data={{columns: columns, rows: dataTable}
                    }
                />
            </Fragment>
        )
    }, [dataTable])

    useEffect(() => {
        if (userCouponItems) {
            prepareDataRow(userCouponItems)
        }
    }, [userCouponItems, prepareDataRow])

    const onClickRemove = (index: number) => {
        setSelectedUserCouponItemIndex(index);
        setIsVisibleRemoveModal(true);
    }

    const removeImportDetail = () => {
        if (selectedUserCouponItemIndex !== undefined) {
            dispatch(actRemoveUserCouponItem(selectedUserCouponItemIndex));
            setIsVisibleRemoveModal(false);
        }
    }

    return (
        <Fragment>
            <MDBCard className='container-fluid'>
                <MDBCardBody>
                    <p className="font-weight-normal mt-3">DANH SÁCH USER</p>
                    {userCouponItemDataTable}
                </MDBCardBody>
            </MDBCard>
            <ConfirmModal
                isVisible={isVisibleRemoveModal}
                setIsVisible={setIsVisibleRemoveModal}
                title='Xoá user khỏi danh sách'
                message='Bạn có chắc muốn xoá user khỏi danh sách nhận ưu đãi?'
                color='danger'
                onClickConfirm={removeImportDetail}
            />
        </Fragment>
    )
}

export default React.memo(UserCouponAdditionList)
