import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBIcon,
    MDBRow,
    MDBSpinner,
} from 'mdbreact';
import { AppURI, DATETIME_FORMAT, DEFAULT_PAGE_SIZE, NOT_AVAILABLE_IMAGE } from 'src/core/utils/constants';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'src/features/pages/material-management/income/income.style.scss';
import { NumberParam, useQueryParam } from 'use-query-params';
import Pagination from 'src/shared/components/pagination/pagination.component';
import PagingRequest from 'src/core/models/paging-request.model';
import { actSearchNews } from 'src/core/reduxs/coffee-core/news/news.action';
import { News, NewsTypes } from 'src/core/models/coffee-core/news.model';
import PageModel from 'src/core/models/paging-response.model';
import { PageResponse } from 'src/core/models/page-response.model';
import { actGetListDepositOfStore } from 'src/core/reduxs/management/deposit/deposit.action';
import { actGetListTenant } from 'src/core/reduxs/coffee-core/tenant/tenant.action';
import Select from 'react-select';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import _ from 'lodash';
import { Tenant } from 'src/core/models/tenant.model';
import { SelectOption } from 'src/core/models/select-option.model';

type DataTable = {
    id: number;
    title: JSX.Element;
    type: string;
    shortContent: string;
    thumbnail: JSX.Element;
    createdTime: JSX.Element;
    commands: JSX.Element;
    [rest: string]: any;
}

const columns = [
    {
        label: 'ID',
        field: 'id',
    },
    {
        label: 'Tiêu đề',
        field: 'title',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Loại',
        field: 'type',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Mô tả ngắn',
        field: 'shortContent',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Ảnh',
        field: 'thumbnail',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Ngày tạo',
        field: 'createdTime',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Chi tiết',
        field: 'commands',
    },
]

const NewsListContainer = (): JSX.Element => {
    const history = useHistory();

    const dispatch = useDispatch();
    const searchData: PageResponse<News> = useSelector((state: any) => state.newsReducer.searchedNews.data);
    const isLoading = useSelector((state: any) => state.newsReducer.searchedNews.isLoading);
    const tenants: Tenant[] = useSelector((state: any) => state.tenantReducer.tenants.data);
    const [tenantId, setTenantId] = useQueryParam('tenantId', NumberParam);
    const [selectedTenant, setSelectedTenant] = useState<SelectOption | undefined>(undefined)
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [page, setPage] = useQueryParam('page', NumberParam);

    useEffect(() => {
        dispatch(actGetListTenant());
    }, []);

    const tenantOptions = useMemo(() => {
        if (!_.isEmpty(tenants)) {
            return tenants.map((t) => ({
                label: t.name,
                value: t.id
            }));
        }
        return [];
    }, [tenants]);

    useEffect(() => {
        if (tenantId) {
            const tenant = tenantOptions.find(t => t.value === tenantId)
            if (tenant) {
                setSelectedTenant(tenant)
            }
            onPageChanged(page || 1);
        }
    }, [tenantId, tenantOptions]);

    useEffect(() => {
        if (searchData?.content) {
            renderDataRow(searchData.content);
        }
    }, [searchData]);

    const onPageChanged = (activePage: number) => {
        setPage(activePage);
        const request: PagingRequest = {
            pageId: activePage - 1,
            tenantId
        }
        dispatch(actSearchNews(request));
    }

    const reset = () => {
        onPageChanged(1);
    };

    const navigateToCreatePage = () => {
        history.push({
            pathname: AppURI.NEWS_CREATE
        });
    }

    const navigateToDetails = (id: number) => {
        window.open(`${AppURI.NEWS_DETAILS}?id=${id}`, '_blank');
    }

    const newsTypeMap = useMemo(() => {
        const map: { [key: string]: string } = {};
        for (const newsType of NewsTypes) {
            map[newsType.type] = newsType.name;
        }
        return map;
    }, []);

    const renderDataRow = useCallback((newsList: News[]) => {
        const data: DataTable[] = [];
        newsList?.forEach((item: News, index: number) => {
            const row = {
                id: item.id,
                title: <div style={{maxWidth: 200}}>{item.title}</div>,
                type: item.type?.name,
                shortContent: item.shortContent,
                thumbnail: <img width={100} height={100} src={item.thumbnail || NOT_AVAILABLE_IMAGE} alt='thumbnail'/>,
                createdTime: <div
                    className='text-center'>{item.createdTime ? moment(item.createdTime).format(DATETIME_FORMAT) : ''}</div>,
                commands: <div style={{width: 90}}>
                    <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => navigateToDetails(item.id)}>
                        <MDBIcon icon='list' size='lg' className='text-primary'/>
                    </MDBBtn>
                    {/*<MDBBtn flat rounded color="danger" className='my-0 mx-1 p-2'*/}
                    {/*        onClick={() => onClickRemove(item.code)}>*/}
                    {/*    <MDBIcon size='lg' icon="trash-alt" className='red-text'/>*/}
                    {/*</MDBBtn>*/}
                </div>
            };
            data.push(row);
        });
        setDataTable(data);
    }, []);

    const renderDataTable = useMemo(() => {
        if (isLoading) {
            return <MDBCardBody className="d-flex justify-content-center"><MDBSpinner small/></MDBCardBody>;
        }

        return (
            <MDBDataTable
                className='common-table'
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không tìm thấy tin tức nào"
                data={{columns: columns, rows: dataTable}}
            />
        )
    }, [dataTable, isLoading]);

    return (
        <Fragment>
            <MDBContainer className='py-3' fluid>
                <div className='text-center'>
                    <h2 className='page-title'>Quản lý tin tức</h2>
                    <MDBBtn flat rounded className='m-0' onClick={reset}>
                        <MDBIcon icon='undo' size='lg'/>
                    </MDBBtn>
                </div>
                <MDBRow className='pb-3'>
                    <MDBCol md='9'>
                        <Select
                            options={tenantOptions}
                            onChange={(newValue) => {
                                if (newValue) {
                                    setTenantId(newValue.value)
                                }
                            }}
                            value={selectedTenant}
                            placeholder='(chọn brand)'
                            className='max-width-400 my-1'
                        />
                    </MDBCol>
                    <MDBCol md='3' className='text-right'>
                        <MDBBtn
                            color="success"
                            onClick={navigateToCreatePage}>Tạo tin tức mới</MDBBtn>
                    </MDBCol>
                </MDBRow>
                <MDBRow>
                    <MDBCol md='12'>
                        <MDBCard>
                            <MDBCardBody>
                                {renderDataTable}
                                {searchData &&
                                    <Pagination
                                        activeIndex={page || 0}
                                        itemCount={searchData?.totalElements || 0}
                                        pageSize={DEFAULT_PAGE_SIZE}
                                        onPageChange={onPageChanged}
                                    />
                                }
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </Fragment>
    );
}

export default NewsListContainer;
