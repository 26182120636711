import _ from 'lodash';
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact'
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PickDeep from 'src/core/models/pick-deep.modal';

import * as SizeAction from 'src/core/reduxs/coffee-core/size/size.action'
import { toast } from 'react-hot-toast'
import { useIntl } from 'react-intl';
import SizeStateType from 'src/core/models/coffee-core/size/size-state.model';
import { SizeCreateRequest } from 'src/core/models/coffee-core/size/size-request-params.model';
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component';

type Props = {
    isOpen: boolean,
    onModalClosed: () => void
}
const SizeCreateModalComponent = (props: Props): JSX.Element => {
    const intl = useIntl();
    const { isOpen, onModalClosed } = props;
    const dispatch = useDispatch();
    const [sizeName, setSizeName] = useState('')
    const [sizePrice, setSizePrice] = useState(0);
    const createSize: PickDeep<SizeStateType, 'createSize'> = useSelector((state: any) => state.sizeReducer.createSize)

    const onCloseModal = () => {
        onModalClosed()
    }
    const onSuccessCreate = () => {
        onCloseModal();
        toast.success(intl.formatMessage({ id: 'SIZE.CREATE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureCreate = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'SIZE.CREATE.TOAST_FAILURE' }), { duration: 3000 })
    }

    const handleCreate = () => {
        if (sizePrice > 0) {
            const params: SizeCreateRequest = {
                data: [{
                    name: sizeName,
                    price: sizePrice
                }],
                successCallback: onSuccessCreate,
                errorCallback: onFailureCreate
            }
            dispatch(SizeAction.actCreateSize(params))
        } else {
            toast.error(intl.formatMessage({ id: 'SIZE.CREATE.TOAST_WARNING_MIN_PRICE' }))
        }
    }
    return (

        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'SIZE.CREATE.TITLE' })}
            type='create'
        >
            <form
                onSubmit={(event: React.FormEvent) => { event.preventDefault(); handleCreate() }}
                className='needs-validation was-validated'
            >
                <MDBInput
                    label={intl.formatMessage({ id: 'SIZE.CREATE.LABEL_NAME' })}
                    value={sizeName}
                    onChange={(event) => { setSizeName(event.currentTarget.value) }}
                    required
                    disabled={createSize.isLoading}
                />
                <MDBInput
                    label={intl.formatMessage({ id: 'SIZE.CREATE.LABEL_PRICE' })}
                    value={sizePrice}
                    onChange={(event) => { setSizePrice(_.parseInt(event.currentTarget.value)) }}
                    type="number"
                    min={1}
                    required
                    disabled={createSize.isLoading}
                />
                <div className='text-center mb-1-half'>
                    <MDBBtn
                        className='mb-2'
                        color='dark-green'
                        type="submit"
                        disabled={createSize.isLoading}
                    >
                        {intl.formatMessage({ id: 'SIZE.CREATE.LABEL_BUTTON' })}
                        <MDBIcon icon='check' className='ml-3' />
                    </MDBBtn>
                </div>
            </form>
        </CustomModal>
    )
}

export default SizeCreateModalComponent
