import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import Types from 'src/core/reduxs/management/outcome/outcome.type';
import { CreateOutcomeRequest, OutcomeItem } from 'src/core/models/management/outcome.model';
import SearchAndFilterRequest from 'src/core/models/search-and-filter-request.model';
import { toast } from 'react-hot-toast';

const outcomeRepository = Repositories[RepositoryName.OUTCOME];

export const actGetListOutcomeCategory = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_OUTCOME_CATEGORY_REQUEST, Types.GET_LIST_OUTCOME_CATEGORY_SUCCESS, Types.GET_LIST_OUTCOME_CATEGORY_FAILURE],
        callAPI: () => outcomeRepository.getListCategory()
    })
};

export const actAddOutcomeItem = (outcomeItem: OutcomeItem) => (dispatch: any): void => {
    dispatch({
        type: Types.ADD_OUTCOME_ITEM,
        payload: outcomeItem
    });
};

export const actRemoveOutcomeItem = (index: number) => (dispatch: any): void => {
    dispatch({
        type: Types.REMOVE_OUTCOME_ITEM,
        payload: index
    });
};

export const actResetOutcomeItem = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_OUTCOME_ITEM,
    });
};

export const actCreateOutcome = (request: CreateOutcomeRequest, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_OUTCOME_REQUEST, Types.CREATE_OUTCOME_SUCCESS, Types.CREATE_OUTCOME_FAILURE],
        callAPI: () => outcomeRepository.createOutcome(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
            },
            failure: (error: AxiosError) => {
                errorCallback()
            }
        }
    })
}

export const actSearchOutcome = (request: SearchAndFilterRequest) => (dispatch: any) : void => {
    dispatch({
        types: [Types.SEARCH_OUTCOME_REQUEST, Types.SEARCH_OUTCOME_SUCCESS, Types.SEARCH_OUTCOME_FAILURE],
        callAPI: () => outcomeRepository.searchOutcome(request),
    })
}

export const actGetOutcomeDetails = (code: string) => (dispatch: any) : void => {
    dispatch({
        types: [Types.GET_OUTCOME_DETAILS_REQUEST, Types.GET_OUTCOME_DETAILS_SUCCESS, Types.GET_OUTCOME_DETAILS_FAILURE],
        callAPI: () => outcomeRepository.getOutcomeDetails(code),
    })
}

export const actDeleteOutcome = (code: string, successCallback = () => {}, errorCallback = () => {} ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.DELETE_OUTCOME_REQUEST, Types.DELETE_OUTCOME_SUCCESS, Types.DELETE_OUTCOME_FAILURE],
        callAPI: () => outcomeRepository.deleteOutcome(code),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Xoá phiếu chi thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                errorCallback()
                toast.error('Xoá phiếu chi thất bại', { duration: 2000 })
            }
        }
    })
}