import Topping from 'src/core/models/coffee-core/topping/topping.model';

export default class ToppingGroup {
    id: number;
    code: string;
    name: string;
    toppings: Topping[] ;

    constructor(
        id?: number,
        code?: string,
        name?: string,
        toppings?: Topping[]
    ) {
        this.id = id || 0;
        this.code = code || '';
        this.name = name || '';
        this.toppings = toppings || [];
    }
}
