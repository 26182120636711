import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Types from 'src/core/reduxs/management/deposit/deposit.type';

const depositRepository = Repositories[RepositoryName.DEPOSIT];

export const actGetListDepositOfStore = (storeId: number) => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_LIST_DEPOSIT_BY_STORE
    }, dispatch({
        types: [Types.GET_LIST_DEPOSIT_BY_STORE_REQUEST, Types.GET_LIST_DEPOSIT_BY_STORE_SUCCESS, Types.GET_LIST_DEPOSIT_BY_STORE_FAILURE],
        callAPI: () => depositRepository.getListDepositOfStore(storeId)
    }))
};
