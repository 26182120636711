export default class Category {
    id: number;
    code: string;
    name: string;
    type: CategoryType;
    imgUrl: string;

    constructor(
        id?: number,
        code?: string,
        name?: string,
        type?: CategoryType,
        imgUrl?: string
    ) {
        this.id = id || 0;
        this.code = code || '';
        this.name = name || '';
        this.type = type || '';
        this.imgUrl = imgUrl || '';
    }
}

export type CategoryType = 'PRODUCT' | 'NEWS' | ''