import * as Types from 'src/core/reduxs/coffee-core/black-pearl-code/black-pearl-code.type';
import Repositories, {RepositoryName} from 'src/core/repositories/repository-factory';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
import { toast } from 'react-hot-toast';
import { CreateBlackPearlCodesRequest } from 'src/core/models/black-pearl-code.model';

const blackPearlCodeRepository = Repositories[RepositoryName.BLACK_PEARL_CODE];

export const actGetListBlackPearlCode = (tenantId: number, status = 'NEW') => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_BLACK_PEARL_CODE_REQUEST, Types.GET_LIST_BLACK_PEARL_CODE_SUCCESS, Types.GET_LIST_BLACK_PEARL_CODE_FAILURE],
        callAPI: () => blackPearlCodeRepository.getListBlackPearlCode(status, tenantId)
    })
};

export const actUpdateBlackPearlCodeStatus = (blackPearlCodeIds: number[], status: string, successCallback = () => {}) => (dispatch: any): void => {
    dispatch({
        types: [Types.UPDATE_BLACK_PEARL_CODE_STATUS_REQUEST, Types.UPDATE_BLACK_PEARL_CODE_STATUS_SUCCESS, Types.UPDATE_BLACK_PEARL_CODE_STATUS_FAILURE],
        callAPI: () => blackPearlCodeRepository.updateBlackPearlCodeStatus(blackPearlCodeIds, status),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
            }
        }
    })
};

export const actCreateBlackPearlCodes = (request: CreateBlackPearlCodesRequest, successCallback = () => {}) => (dispatch: any): void => {
    dispatch({
        types: [Types.CREATE_BLACK_PEARL_CODES_REQUEST, Types.CREATE_BLACK_PEARL_CODES_SUCCESS, Types.CREATE_BLACK_PEARL_CODES_FAILURE],
        callAPI: () => blackPearlCodeRepository.createBlackPearlCodes(request),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                successCallback()
                toast.success('Tạo mã tích điểm trân châu thành công', { duration: 2000 })
            },
            failure: (error: AxiosError) => {
                toast.error('Tạo mã tích điểm trân châu thất bại', { duration: 2000 })
            }
        }
    })
};
