import _ from 'lodash';
import { MDBBtn, MDBIcon, MDBInput } from 'mdbreact'
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PickDeep from 'src/core/models/pick-deep.modal';
import { ToppingCreateRequest } from 'src/core/models/coffee-core/topping/topping-request-params.model';
import ToppingStateType from 'src/core/models/coffee-core/topping/topping-state.model';
import * as ToppingAction from 'src/core/reduxs/coffee-core/topping/topping.action'
import { toast } from 'react-hot-toast'
import { useIntl } from 'react-intl';
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component';

type Props = {
    isOpen: boolean,
    onModalClosed: () => void
}
const ToppingCreateModalComponent = (props: Props): JSX.Element => {
    const intl = useIntl();
    const { isOpen, onModalClosed } = props;
    const dispatch = useDispatch();
    const [toppingName, setToppingName] = useState('')
    const [toppingPrice, setToppingPrice] = useState(0);
    const createTopping: PickDeep<ToppingStateType, 'createTopping'> = useSelector((state: any) => state.toppingReducer.createTopping)

    const onCloseModal = () => {
        onModalClosed()
    }
    const onSuccessCreate = () => {
        onCloseModal();
        toast.success(intl.formatMessage({ id: 'TOPPING.CREATE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureCreate = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'TOPPING.CREATE.TOAST_FAILURE' }), { duration: 3000 })
    }

    const handleCreate = () => {
        if (toppingPrice > 0) {
            const params: ToppingCreateRequest = {
                data: [{
                    name: toppingName,
                    price: toppingPrice
                }],
                successCallback: onSuccessCreate,
                errorCallback: onFailureCreate
            }
            dispatch(ToppingAction.actCreateTopping(params))
        } else {
            onCloseModal();
            toast.error(intl.formatMessage({ id: 'TOPPING.CREATE.TOAST_WARNING_MIN_PRICE' }))
        }
    }
    return (
        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'TOPPING.CREATE.TITLE' })}
            type='create'
        >
            <form
                onSubmit={(event: React.FormEvent) => { event.preventDefault(); handleCreate() }}
                className='needs-validation was-validated'
            >
                <MDBInput
                    label={intl.formatMessage({ id: 'TOPPING.CREATE.LABEL_NAME' })}
                    outline
                    value={toppingName}
                    onChange={(event) => { setToppingName(event.currentTarget.value) }}
                    required
                    disabled={createTopping.isLoading}
                />
                <MDBInput
                    label={intl.formatMessage({ id: 'TOPPING.CREATE.LABEL_PRICE' })}
                    outline
                    value={toppingPrice}
                    onChange={(event) => { setToppingPrice(_.parseInt(event.currentTarget.value)) }}
                    type="number"
                    min={1}
                    required
                    disabled={createTopping.isLoading}
                />
                <div className='text-center mb-1-half'>
                    <MDBBtn
                        className='mb-2'
                        color='dark-green'
                        type="submit"
                        disabled={createTopping.isLoading}
                    >
                        {intl.formatMessage({ id: 'TOPPING.CREATE.LABEL_BUTTON' })}
                        <MDBIcon icon='check' className='ml-3' />
                    </MDBBtn>
                </div>
            </form>
        </CustomModal>
    )
}

export default ToppingCreateModalComponent
