import i18n_en from 'src/shared/i18n/en.json';
import i18n_vi from 'src/shared/i18n/vi.json';
import notAvailableImage from 'src/shared/assets/img/not-available.png'

export const DEFAULT_PAGE_SIZE = 6;
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_FOR_CODE = 'DDMMYYYY';
export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';
export const VN_PHONE_CODE = '+84';

export const I18nConfig = {
    en: i18n_en,
    vi: i18n_vi
};

export const ApiURI = (() => {
    const AUTH_SERVICE_PATH = '/auth';
    const COFFEE_CORE_SERVICE_PATH = '/coffee-core';
    const THIRD_PARTY_SERVICE_PATH = '/third-party';
    const COFFEE_MANAGEMENT_SERVICE_PATH = '/coffee-management';

    return {
        AUTH_SERVICE_PATH,
        COFFEE_CORE_SERVICE_PATH,
        THIRD_PARTY_SERVICE_PATH,
        COFFEE_MANAGEMENT_SERVICE_PATH,
        AUTH_SERVICE: {
            AUTHENTICATE: AUTH_SERVICE_PATH + '/oauth/token',
        },
        COFFEE_CORE_SERVICE: {
            GUEST: {
                GET_LIST_CITY: COFFEE_CORE_SERVICE_PATH + '/guest/city/getListCity',
            },
            ADMIN: {
                GET_LIST_ORDER: COFFEE_CORE_SERVICE_PATH + '/admin/order/getListOrder',
                UPDATE_ORDER_STATUS: COFFEE_CORE_SERVICE_PATH + '/admin/order/updateOrderStatus',
                GET_ORDER_DETAIL: COFFEE_CORE_SERVICE_PATH + '/admin/order/getOrderDetail',
                GET_LIST_TOPPING: COFFEE_CORE_SERVICE_PATH + '/admin/topping/getListTopping',
                CREATE_TOPPING: COFFEE_CORE_SERVICE_PATH + '/admin/topping/createTopping',
                UPDATE_TOPPING: COFFEE_CORE_SERVICE_PATH + '/admin/topping/updateTopping',
                DELETE_TOPPING: COFFEE_CORE_SERVICE_PATH + '/admin/topping/deleteTopping',
                GET_LIST_TOPPING_GROUP: COFFEE_CORE_SERVICE_PATH + '/admin/toppingGroup/getListToppingGroup',
                CREATE_TOPPING_GROUP: COFFEE_CORE_SERVICE_PATH + '/admin/toppingGroup/createToppingGroup',
                UPDATE_TOPPING_GROUP: COFFEE_CORE_SERVICE_PATH + '/admin/toppingGroup/updateToppingGroup',
                DELETE_TOPPING_GROUP: COFFEE_CORE_SERVICE_PATH + '/admin/toppingGroup/deleteToppingGroup',
                GET_LIST_SIZE: COFFEE_CORE_SERVICE_PATH + '/admin/size/getListSize',
                CREATE_SIZE: COFFEE_CORE_SERVICE_PATH + '/admin/size/createSize',
                UPDATE_SIZE: COFFEE_CORE_SERVICE_PATH + '/admin/size/updateSize',
                DELETE_SIZE: COFFEE_CORE_SERVICE_PATH + '/admin/size/deleteSize',
                GET_LIST_SIZE_GROUP: COFFEE_CORE_SERVICE_PATH + '/admin/sizeGroup/getListSizeGroup',
                CREATE_SIZE_GROUP: COFFEE_CORE_SERVICE_PATH + '/admin/sizeGroup/createSizeGroup',
                UPDATE_SIZE_GROUP: COFFEE_CORE_SERVICE_PATH + '/admin/sizeGroup/updateSizeGroup',
                DELETE_SIZE_GROUP: COFFEE_CORE_SERVICE_PATH + '/admin/sizeGroup/deleteSizeGroup',
                GET_LIST_CATEGORY: COFFEE_CORE_SERVICE_PATH + '/admin/category/getListCategory',
                GET_LIST_CATEGORY_BY_TYPE: COFFEE_CORE_SERVICE_PATH + '/admin/category/getListCategoryByType',
                CREATE_CATEGORY: COFFEE_CORE_SERVICE_PATH + '/admin/category/createCategory',
                UPDATE_CATEGORY: COFFEE_CORE_SERVICE_PATH + '/admin/category/updateCategory',
                DELETE_CATEGORY: COFFEE_CORE_SERVICE_PATH + '/admin/category/deleteCategory',
                GET_LIST_PRODUCT: COFFEE_CORE_SERVICE_PATH + '/admin/product/getListProduct',
                GET_LIST_PRODUCT_BY_CATEGORY: COFFEE_CORE_SERVICE_PATH + '/admin/product/getListProductByCategory',
                GET_DETAIL_PRODUCT: COFFEE_CORE_SERVICE_PATH + '/admin/product/getDetailProduct',
                CREATE_PRODUCT: COFFEE_CORE_SERVICE_PATH + '/admin/product/createProduct',
                UPDATE_PRODUCT: COFFEE_CORE_SERVICE_PATH + '/admin/product/updateProduct',
                DELETE_PRODUCT: COFFEE_CORE_SERVICE_PATH + '/admin/product/deleteProduct',
                GET_LIST_BLACK_PEARL_CODE: COFFEE_CORE_SERVICE_PATH + '/admin/blackPearl/getListBlackPearlCode',

            },
            USER: {
                GET_USER_INFO: COFFEE_CORE_SERVICE_PATH + '/user/getUserInfo',
                GET_ORDER_DETAIL: COFFEE_CORE_SERVICE_PATH + '/user/order/getOrderDetail',
            },
            TODO: 'todos'
        },
        THIRD_PARTY_SERVICE: {
            ADMIN: {
                CREATE_FIREBASE_TOKEN: THIRD_PARTY_SERVICE_PATH + '/admin/firebaseToken/createFirebaseToken'
            },
            GUEST: {
                UNSUBSRIBE_ALL_TOPIC: THIRD_PARTY_SERVICE_PATH + '/guest/unsubscribeAllTopics',
            },
        },
        MANAGEMENT: {
            GET_LIST_SUPPLIER: COFFEE_MANAGEMENT_SERVICE_PATH + '/supplier/getListSupplier',
            GET_LIST_STOCK: COFFEE_MANAGEMENT_SERVICE_PATH + '/stock/getListStock',
            GET_LIST_STORE: COFFEE_MANAGEMENT_SERVICE_PATH + '/store/getListStore',
            GET_LIST_DEPOSIT_OF_STORE: COFFEE_MANAGEMENT_SERVICE_PATH + '/store/getDepositListOfStore',
            GET_LIST_PARTNER_OF_STORE: COFFEE_MANAGEMENT_SERVICE_PATH + '/partner/getListPartnerOfStore',
            GET_LIST_MATERIAL: COFFEE_MANAGEMENT_SERVICE_PATH + '/material/getListMaterials',
            CREATE_EXPORT: COFFEE_MANAGEMENT_SERVICE_PATH + '/export/createExport',
            SEARCH_EXPORT: COFFEE_MANAGEMENT_SERVICE_PATH + '/export/search',
            GET_EXPORT_DETAILS: COFFEE_MANAGEMENT_SERVICE_PATH + '/export/getExportDetail',
            DELETE_EXPORT: COFFEE_MANAGEMENT_SERVICE_PATH + '/export/delete',
            UPDATE_EXPORT: COFFEE_MANAGEMENT_SERVICE_PATH + '/export/updateExport',
            CREATE_IMPORT: COFFEE_MANAGEMENT_SERVICE_PATH + '/import/createImport',
            SEARCH_IMPORT: COFFEE_MANAGEMENT_SERVICE_PATH + '/import/search',
            GET_IMPORT_DETAILS: COFFEE_MANAGEMENT_SERVICE_PATH + '/import/getImportDetail',
            DELETE_IMPORT: COFFEE_MANAGEMENT_SERVICE_PATH + '/import/delete',
            UPDATE_IMPORT_OUTCOME_PAID_STATUS: COFFEE_MANAGEMENT_SERVICE_PATH + '/import/updateOutcomePaidStatus',
            UPDATE_IMPORT: COFFEE_MANAGEMENT_SERVICE_PATH + '/import/updateImport',
            GET_LIST_INCOME_CATEGORY: COFFEE_MANAGEMENT_SERVICE_PATH + '/income/getCategoryList',
            CREATE_INCOME: COFFEE_MANAGEMENT_SERVICE_PATH + '/income/createIncome',
            SEARCH_INCOME: COFFEE_MANAGEMENT_SERVICE_PATH + '/income/search',
            GET_INCOME_DETAILS: COFFEE_MANAGEMENT_SERVICE_PATH + '/income/getIncomeDetail',
            DELETE_INCOME: COFFEE_MANAGEMENT_SERVICE_PATH + '/income/delete',
            GET_LIST_OUTCOME_CATEGORY: COFFEE_MANAGEMENT_SERVICE_PATH + '/outcome/getCategoryList',
            CREATE_OUTCOME: COFFEE_MANAGEMENT_SERVICE_PATH + '/outcome/createOutcome',
            SEARCH_OUTCOME: COFFEE_MANAGEMENT_SERVICE_PATH + '/outcome/search',
            GET_OUTCOME_DETAILS: COFFEE_MANAGEMENT_SERVICE_PATH + '/outcome/getOutcomeDetail',
            DELETE_OUTCOME: COFFEE_MANAGEMENT_SERVICE_PATH + '/outcome/delete',
            GET_TRANSACTION_DETAILS: COFFEE_MANAGEMENT_SERVICE_PATH + '/transaction/getTransactionDetails',
            SEARCH_MATERIAL: COFFEE_MANAGEMENT_SERVICE_PATH + '/material/search',
            GET_MATERIAL_BY_ID: COFFEE_MANAGEMENT_SERVICE_PATH + '/material/getMaterialById',
            GET_LIST_MATERIAL_CATEGORY: COFFEE_MANAGEMENT_SERVICE_PATH + '/material/getCategoryList',
            CREATE_MATERIAL: COFFEE_MANAGEMENT_SERVICE_PATH + '/material/createMaterial',
            UPDATE_MATERIAL: COFFEE_MANAGEMENT_SERVICE_PATH + '/material/updateMaterial',
        }
    };
})();

export const AppURI = {
    INDEX: '/',

    // public
    NOT_FOUND: '/404',
    LOGIN: '/login',
    PUBLIC_ORDER: '/public/orders/:orderId',

    // private
    HOME: '/home',
    ORDER: '/order',
    PRODUCT: '/product',
    TOPPING: '/topping',
    SIZE: '/size',
    CATEGORY: '/category',
    CREATE_ORDER: '/create-order',
    ACCUMULATE_CODE: '/accumulate-code',
    ACCUMULATE_POINT: '/accumulate-point',
    INCOME: '/income',
    INCOME_DETAIL: '/income-detail',
    INCOME_CREATE: '/income/create',
    OUTCOME: '/outcome',
    OUTCOME_DETAIL: '/outcome-detail',
    OUTCOME_CREATE: '/outcome/create',
    IMPORT: '/import',
    IMPORT_DETAIL: '/import-detail',
    IMPORT_CREATE: '/import/create',
    EXPORT: '/export',
    EXPORT_DETAIL: '/export-detail',
    EXPORT_CREATE: '/export/create',
    MATERIAL: '/material',
    NEWS: '/news',
    NEWS_DETAILS: '/news-details',
    NEWS_CREATE: '/news-create',
    USER_COUPON_ADD: '/coupons/user-coupon/add',
    COUPON_CODE: '/coupons/coupon-code'
};

export const HttpStatus = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    REQUEST_TIMEOUT: 408,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,
};

export const LocalStorage = {
    LOGIN_INFO: 'login_info',
    USER_INFO: 'user_info',
    FCM_TOKEN: 'fcm_token'
};

export const Authority = {
    SYS_ADMIN: 'SYS_ADMIN',
    AGENT_ADMIN: 'AGENT_ADMIN'
};

export const ORDER_STATUS: {[key: string]: any} = {
    PENDING: {key: 'PENDING', value: 'Đang chờ'},
    CONFIRMED: {key: 'CONFIRMED', value: 'Đã xác nhận'},
    PREPARING: {key: 'PREPARING', value: 'Đang chuẩn bị'},
    DELIVERING: {key: 'DELIVERING', value: 'Đang giao'},
    FINISHED: {key: 'FINISHED', value: 'Hoàn thành'},
    CANCELED: {key: 'CANCELED', value: 'Đã hủy'}
};

export const ORDER_METHOD = {
    DELIVERY: {key: 'DELIVERY', value: 'Giao tận nơi'},
    TAKE_AWAY: {key: 'TAKE_AWAY', value: 'Tự đến lấy'},
    AT_STORE: {key: 'AT_STORE', value: 'Dùng tại quán'}
};

export const ResponseType = {
    MESSAGE_SUCCESS: 'SUCCESS',
    MESSAGE_INFO: 'INFO',
    MESSAGE_WARNING: 'WARNING',
    MESSAGE_ERROR: 'ERROR',
    MESSAGE_REDIRECT: 'REDIRECT'
};

export const CATEGORY_TYPE = {
    PRODUCT: 'PRODUCT',
    NEWS: 'NEWS',
}

export const NOT_AVAILABLE_IMAGE = notAvailableImage

export const ExportTypes: { [key: string]: string } = {
    SU_DUNG: 'Sử dụng',
    DOI_TAC: 'Đối tác',
    BAN_NL: 'Bán nguyên liệu',
    CHUYEN_DOI: 'Chuyển đổi',
    HAO_HUT: 'Hao hụt',
    TON_KHO: 'Tồn kho',
}

export const ExportCreateTypes: { [key: string]: string } = {
    SU_DUNG: 'Sử dụng',
    DOI_TAC: 'Đối tác',
    BAN_NL: 'Bán nguyên liệu',
}

export const ImportCreateTypes: { [key: string]: string } = {
    NHA_C_C: 'Nhà cung cấp',
    TON_KHO: 'Tồn kho',
    NHAP_CHUYEN: 'Chuyển đổi',
}

export const ImportTypes: { [key: string]: string } = {
    NHA_C_C: 'Nhà cung cấp',
    TON_KHO: 'Tồn kho',
    NHAP_CHUYEN: 'Chuyển đổi',
    DOI_TAC: 'Đối tác',
}

export const IncomeTypes: { [key: string]: string } = {
    BAN_NL: 'Bán nguyên liệu',
    DOI_TAC: 'Đối tác',
    CHUYEN_DOI: 'Chuyển đổi'
}

export const OutcomeTypes: { [key: string]: string } = {
    MUA_NL: 'Mua nguyên liệu',
    DOI_TAC: 'Đối tác',
    CHUYEN_DOI: 'Chuyển đổi'
}

export const MaterialLevels: { [key: string]: string } = {
    1: 'Thô',
    2: 'Set',
    3: 'Thành phẩm'
}

Object.freeze(ApiURI);
Object.freeze(AppURI);
Object.freeze(HttpStatus);
Object.freeze(LocalStorage);
Object.freeze(I18nConfig);
Object.freeze(Authority);
Object.freeze(ORDER_STATUS);
Object.freeze(ORDER_METHOD);
Object.freeze(ResponseType);
