import { MDBBox, MDBBtn, MDBCard, MDBCardBody, MDBCardTitle, MDBDataTable, MDBIcon, MDBRow, MDBSpinner } from 'mdbreact';
import React, { useMemo, useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as COMMON from 'src/core/utils/common';
import PickDeep from 'src/core/models/pick-deep.modal';
import * as SizeAction from 'src/core/reduxs/coffee-core/size/size.action'
import PagingRequest from 'src/core/models/paging-request.model';
import Size from 'src/core/models/coffee-core/size/size.model';
import SizeStateType from 'src/core/models/coffee-core/size/size-state.model';
import SizeCreateModalComponent from 'src/features/components/size/size-create-modal.component';
import SizeUpdateModalComponent from 'src/features/components/size/size-update-modal.component';
import SizeDeleteModalComponent from 'src/features/components/size/size-delete-modal.component';
type SizeDataTable = {
    code: string;
    name: string;
    price: string;
    edit: JSX.Element;
    [rest: string]: any;
}




const initSizeDataTable: SizeDataTable[] = [];
const initSizeDetail = new Size();
const SizeListComponent = (): JSX.Element => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const columns = [
        {
            label: '#',
            field: 'code',
        },
        {
            label: intl.formatMessage({ id: 'SIZE.LIST.LABEL_NAME' }),
            field: 'name',
        },
        {
            label: intl.formatMessage({ id: 'SIZE.LIST.LABEL_PRICE' }),
            field: 'price'
        },
        {
            label: intl.formatMessage({ id: 'SIZE.LIST.LABEL_EDIT' }),
            field: 'edit',

        }
    ]
    const [dataTable, setDataTable] = useState(initSizeDataTable);
    const sizes: PickDeep<SizeStateType, 'sizes'> = useSelector((state: any) => state.sizeReducer.sizes)

    const [sizeDetail, setSizeDetail] = useState<Size>(initSizeDetail);
    const [isOpenSizeUpdateModal, setIsOpenSizeUpdateModal] = useState(false);

    const [isOpenCreateSizeModal, setIsOpenCreateSizeModal] = useState(false)
    const [isOpenDeleteSizeModal, setIsOpenDeleteSizeModal] = useState(false)

    const prepareDataRow = useCallback((listSize: Size[]) => {
        if (_.isEmpty(listSize)) return;

        const data: SizeDataTable[] = [];
        listSize.forEach((item: Size) => {
            const row = {
                id: item.id,
                code: item.code || '',
                name: item.name || '',
                price: COMMON.currencyFormatter(item.price || 0),
                edit: (
                    <>
                        <MDBRow className="size-list-item">
                            <button className="size-list-item-btn edit" onClick={() => { openUpdateSizeModal(item) }}>
                                <MDBIcon icon="edit" className="size-list-item-icon edit" />
                            </button>
                            <button className="size-list-item-btn delete" onClick={() => { openDeleteSizeModal(item) }}>
                                <MDBIcon icon="trash-alt" className="size-list-item-icon delete" />
                            </button>
                        </MDBRow>
                    </>
                ),
            }
            data.push(row)
        })
        setDataTable(data)
    }, [])

    const openUpdateSizeModal = (item: Size) => {
        if (item) {
            setSizeDetail(item);
            setIsOpenSizeUpdateModal(true);
        }
    }

    const openDeleteSizeModal = (item: Size) => {
        if (item) {
            setSizeDetail(item);
            setIsOpenDeleteSizeModal(true);
        }
    }

    const onModalUpdateClosed = () => {
        setIsOpenSizeUpdateModal(false);
        setSizeDetail(initSizeDetail);
    }
    const onModalDeleteClosed = () => {
        setIsOpenDeleteSizeModal(false);
        setSizeDetail(initSizeDetail);
    }

    const onModalCreateClosed = () => {
        setIsOpenCreateSizeModal(false);
    }


    const sizeDataTable = useMemo(() => {
        return (
            <MDBDataTable
                responsive
                striped
                borderless
                searching={true}
                sortable={false}
                noBottomColumns={true}
                paging={true}
                entries={5}
                pagesAmount={5}
                paginationLabel={[intl.formatMessage({ id: 'SIZE.LIST.LABEL_PREVIOUS_PAGE' }), intl.formatMessage({ id: 'SIZE.LIST.LABEL_NEXT_PAGE' })]}
                displayEntries={false}
                info={false}
                noRecordsFoundLabel={intl.formatMessage({ id: 'SIZE.LIST.LABEL_NOT_FOUND_SIZE' })}
                data={{ columns: columns, rows: dataTable }
                }
            />
        )
    }, [dataTable])

    useEffect(() => {
        const params: PagingRequest = {
            pageId: 0,
        }
        dispatch(SizeAction.actGetListSize(params))
    }, [dispatch])

    useEffect(() => {
        if (sizes.data) {
            prepareDataRow(sizes.data)
        }
    }, [sizes.data, prepareDataRow])
    return (
        <MDBCard className="size-list-container" >
            <MDBCardTitle className="size-list-title">
                <MDBBox className="size-list-title-text" >
                    {intl.formatMessage({ id: 'SIZE.LIST.TITLE' })}
                </MDBBox>

                <MDBBtn size="sm" floating color='dark-green' onClick={() => { setIsOpenCreateSizeModal(true) }} >
                    <MDBIcon icon="plus" className="size-list-title-btn-add" />
                </MDBBtn>
            </MDBCardTitle>
            <MDBCardBody className="m-0 p-0">
                {sizes.isLoading && <MDBRow className="d-flex justify-content-center mb-5"><MDBSpinner small /></MDBRow>}
                {!sizes.isLoading && sizes.data && sizeDataTable}
            </MDBCardBody>
            <SizeUpdateModalComponent isOpen={isOpenSizeUpdateModal} sizeDetail={sizeDetail} onModalClosed={onModalUpdateClosed} />
            <SizeDeleteModalComponent isOpen={isOpenDeleteSizeModal} sizeDetail={sizeDetail} onModalClosed={onModalDeleteClosed} />
            <SizeCreateModalComponent isOpen={isOpenCreateSizeModal} onModalClosed={onModalCreateClosed} />
        </ MDBCard>
    )
}

export default SizeListComponent
