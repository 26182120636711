import React, { Fragment, useEffect, useState } from 'react';
import { MDBBtn, MDBCol, MDBInput, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { actGetDetailProduct } from 'src/core/reduxs/coffee-core/product/product.action';
import Product from 'src/core/models/coffee-core/product/product.model';
import Required from 'src/shared/components/required/required.component';
import Topping from 'src/core/models/coffee-core/topping/topping.model';
import _ from 'lodash';
import Size from 'src/core/models/coffee-core/size/size.model';
import { currencyFormatter } from 'src/core/utils/common';
import { OrderItemAddition } from 'src/core/models/coffee-core/order-item.model';
import { useForm } from 'react-hook-form';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { actAddOrderItem } from 'src/core/reduxs/coffee-core/order/order.action';

type Props = {
    isVisible: boolean,
    setIsVisible: (value: boolean) => void,
    productId: number,
}

const AddOrderItemModal = (props: Props): JSX.Element => {

    const dispatch = useDispatch();
    const productDetailsSelector = useSelector((state: any) => state.productReducer.productDetail);
    const productDetails: Product = productDetailsSelector.data;
    const {register, handleSubmit, setValue,
        getValues, formState: {errors}, reset} = useForm();
    const adding = useSelector((state: any) => state.importReducer.updateImport.isLoading);
    const [selectedSize, setSelectedSize] = useState<Size | undefined>(undefined);
    const [toppings, setToppings] = useState<Topping[]>([]);
    const [toppingExtra, setToppingExtra] = useState<number>(0);
    const [quantity, setQuantity] = useState<number>(1);

    useEffect(() => {
        if (props.isVisible && props.productId) {
            dispatch(actGetDetailProduct(props.productId));
            setValue('quantity', 1);
            setValue('size', undefined);
        }
    }, [props.isVisible, props.productId]);

    const onSubmit = (data: any) => {
        const orderItem: OrderItemAddition = {
            product: productDetails,
            size: data.size,
            toppings: toppings.sort((a, b) => a.id - b.id),
            note: data.note,
            quantity: data.quantity,
            amount: calculateAmount()
        }
        dispatch(actAddOrderItem(orderItem))
        onClose()
    }

    const onAddTopping = (selectedTopping: Topping) => {
        const newToppings = [...toppings];
        let newToppingExtra = toppingExtra;
        const toppingIndex = _.findIndex(toppings, (topping) => topping.id === selectedTopping.id);
        if (toppingIndex > -1) {
            newToppings.splice(toppingIndex, 1);
            newToppingExtra -= selectedTopping.price;
        } else {
            newToppings.push(selectedTopping);
            newToppingExtra += selectedTopping.price;
        }
        setToppings(newToppings);
        setToppingExtra(newToppingExtra);
    };

    const calculateAmount = () => {
        return quantity * (productDetails.price + (selectedSize?.price || 0) + toppingExtra);
    }

    const onClose = () => {
        setSelectedSize(undefined);
        setToppings([]);
        setToppingExtra(0);
        setQuantity(1);
        props.setIsVisible(false);
    };

    return (
        <MDBModal
            isOpen={props.isVisible}
            fullHeight={false}
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll
            size='md'
        >
            {
                productDetails &&
                <Fragment>
                    <MDBModalHeader>{productDetails.name}</MDBModalHeader>
                    <MDBModalBody>
                        <MDBRow>
                            <MDBCol size='12'>
                                <p className="font-weight-normal">Size <Required/></p>
                                {productDetails.sizeGroup?.sizes.map((size, index) => (
                                    <MDBInput
                                        {...register('size', {
                                            required: 'Size là bắt buộc'
                                        })}
                                        onClick={() => {
                                            setValue('size', size)
                                            setSelectedSize(size)
                                        }}
                                        checked={selectedSize?.id === size.id}
                                        label={size.name}
                                        type="radio"
                                        key={size.id}
                                        id={`size${size.id}`}
                                    />
                                ))
                                }
                                {errors.size && <ErrorMessage>{errors.size.message}</ErrorMessage>}
                            </MDBCol>
                        </MDBRow>
                        {productDetails.toppingGroup && productDetails.toppingGroup.toppings.length > 0 &&
                            <MDBRow>
                                <Fragment>
                                    <MDBCol size='12'>
                                        <p className="font-weight-normal mt-2">Topping</p>
                                    </MDBCol>
                                    {productDetails.toppingGroup.toppings.map((topping, index) => (
                                        <MDBCol md="6" key={topping.id}>
                                            <MDBInput
                                                filled
                                                checked={_.findIndex(toppings, (item) => item.id === topping.id) > -1}
                                                onChange={() => onAddTopping(topping)}
                                                label={topping.name}
                                                type="checkbox"
                                                id={`topping${topping.id}`}
                                            />
                                        </MDBCol>
                                    ))}
                                </Fragment>
                            </MDBRow>
                        }
                        <MDBRow>
                            <MDBCol md="4">
                                <p className="font-weight-normal mt-2">Số lượng <Required/></p>
                            </MDBCol>
                            <MDBCol md="8">
                                <MDBInput
                                    {...register('quantity', {
                                        required: 'Số lượng là bắt buộc',
                                        min: {
                                            value: 1,
                                            message: 'Số lượng tối thiểu phải là 1'
                                        }
                                    })}
                                    type='number'
                                    placeholder='Nhập số lượng'
                                    onChange={(e: any) => {
                                        setValue('quantity', Number(e.target.value))
                                        setQuantity(Number(e.target.value))
                                    }}
                                    valueDefault={1}
                                    containerClass='my-0 max-width-200'
                                />
                                {errors.quantity && <ErrorMessage>{errors.quantity.message}</ErrorMessage>}
                            </MDBCol>
                            <MDBCol md="4">
                                <p className="font-weight-normal mt-2">Ghi chú</p>
                            </MDBCol>
                            <MDBCol md="8">
                                <MDBInput
                                    {...register('note', {})}
                                    onChange={(e: any) => {
                                        setValue('note', e.target.value)
                                    }}
                                    containerClass='my-0'
                                />
                            </MDBCol>
                            <MDBCol size='12'>
                                <p className="font-weight-normal mt-3">Thành tiền: <span
                                    className='font-weight-bold'>{currencyFormatter(calculateAmount())}</span></p>
                            </MDBCol>
                        </MDBRow>
                    </MDBModalBody>
                </Fragment>
            }
            <MDBModalFooter>
                <MDBBtn color='success' outline onClick={onClose}>Huỷ</MDBBtn>
                <MDBBtn color='success' onClick={handleSubmit(onSubmit)} disabled={adding}>Thêm</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
}

export default AddOrderItemModal;
