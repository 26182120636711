import { AnyAction } from 'redux';
import { Order } from 'src/core/models/coffee-core/order.model';
import PagingReponse from 'src/core/models/paging-response.model'
import { createReducer } from 'src/core/utils/redux';
import Types from 'src/core/reduxs/coffee-core/order/order.type';
import OrderState from 'src/core/reduxs/coffee-core/order/order.state';
import { OrderItemAddition } from 'src/core/models/coffee-core/order-item.model';
import _ from 'lodash';

const initialState: OrderState = {
    orders: {
        data: new PagingReponse<Order>(),
        error: null,
        isLoading: false,
    },
    orderDetail: {
        data: undefined,
        error: null,
        isLoading: false,
    },
    updateOrder: {
        data: {},
        error: null,
        isLoading: false
    },
    orderItems: {
        data: []
    },
    createOrder: {
        data: {},
        error: null,
        isLoading: false
    },
};

const orderReducer = createReducer(initialState, {
    [Types.GET_ORDERS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orders: {
                ...state.orders,
                isLoading: true,
            }
        };
    },
    [Types.GET_ORDERS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orders: {
                ...state.orders,
                isLoading: false,
                error: null,
                data: action.payload
            }
        };
    },
    [Types.GET_ORDERS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orders: {
                ...state.orders,
                isLoading: false,
                error: action.payload
            }
        };
    },

    [Types.GET_ORDER_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orderDetail: {
                ...state.orderDetail,
                isLoading: true
            }
        };
    },
    [Types.GET_ORDER_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orderDetail: {
                ...state.orderDetail,
                isLoading: false,
                data: action.payload,
                error: null
            }
        };
    },
    [Types.GET_ORDER_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orderDetail: {
                ...state.orderDetail,
                isLoading: false,
                data: {},
                error: action.payload
            }
        };
    },

    [Types.UPDATE_ORDER_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateOrder: {
                ...state.updateOrder,
                isLoading: true
            }
        };
    },
    [Types.UPDATE_ORDER_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateOrder: {
                ...state.updateOrder,
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.UPDATE_ORDER_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateOrder: {
                ...state.updateOrder,
                data: {},
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.RESET_ORDER]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orderDetail: {
                data: {},
                error: null,
                isLoading: false,
            },
            updateOrder: {
                data: {},
                error: null,
                isLoading: false
            }
        };
    },
    [Types.ADD_ORDER_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const orderItem: OrderItemAddition = action.payload;
        let data = [...state.orderItems.data];
        if (data.length > 0) {
            let isSameItem = false;
            for (const item of data) {
                if (item.product.id === orderItem.product.id && item.size.id === orderItem.size.id
                    && item.note === orderItem.note && _.isEqual(item.toppings, orderItem.toppings)) {
                    item.quantity += orderItem.quantity;
                    item.amount += orderItem.amount;
                    isSameItem = true;
                    break;
                }
            }
            if (!isSameItem) {
                data = [...data, orderItem];
            }
        } else {
            data = [orderItem];
        }

        return {
            ...state,
            orderItems: {
                data
            }
        };
    },
    [Types.REMOVE_ORDER_ITEM]: (state: typeof initialState, action: AnyAction) => {
        const index = action.payload;
        const data = [...state.orderItems.data];
        data.splice(index, 1);

        return {
            ...state,
            orderItems: {
                data
            }
        };
    },
    [Types.RESET_ORDER_ITEM]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            orderItems: {
                data: []
            }
        };
    },
    [Types.CREATE_ORDER_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createOrder: {
                ...state.createOrder,
                isLoading: true
            }
        };
    },
    [Types.CREATE_ORDER_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createOrder: {
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.CREATE_ORDER_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createOrder: {
                data: {},
                error: action.payload,
                isLoading: false
            }
        };
    },
})

export default orderReducer;
