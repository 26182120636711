import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBDatePicker, MDBInput, MDBInputGroup, MDBRow, MDBSpinner, } from 'mdbreact';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import * as CONST from 'src/core/utils/constants';
import { DATE_FORMAT_FOR_CODE } from 'src/core/utils/constants';
import { toast } from 'react-hot-toast';
import { formatDateForApi } from 'src/core/utils/common';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { actGetListSupplier } from 'src/core/reduxs/management/supplier/supplier.action';
import IncomeItemCreationListComponent from 'src/features/components/income/income-item-creation-list.component';
import IncomeItemCreateFormComponent from 'src/features/components/income/income-item-create-form.component';
import { actGetListStore } from 'src/core/reduxs/management/store/store.action';
import { Store } from 'src/core/models/management/store.model';
import { actGetListDepositOfStore } from 'src/core/reduxs/management/deposit/deposit.action';
import { Deposit } from 'src/core/models/management/deposit.model';
import { CreateIncomeRequest, IncomeItem, IncomeItemRequest } from 'src/core/models/management/income.model';
import { actCreateIncome, actResetIncomeItem } from 'src/core/reduxs/management/income/income.action';
import Select from 'react-select';

const IncomeCreateContainer = (): JSX.Element => {

    const history = useHistory();
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const dispatch = useDispatch();
    const stores: Store[] = useSelector((state: any) => state.storeReducer.stores.data);
    const deposits: Deposit[] = useSelector((state: any) => state.depositReducer.storeDeposits.data);
    const incomeItems: IncomeItem[] = useSelector((state: any) => state.incomeReducer.createIncomeItems.data);
    const [selectedStore, setSelectedStore] = useState<Store | undefined>();
    const [selectedIncomeTime, setSelectedIncomeTime] = useState<Date | undefined>();
    const [incomeCodePrefix, setIncomeCodePrefix] = useState<string | undefined>('');
    const creating: boolean = useSelector((state: any) => state.incomeReducer.createIncome.loading);
    let depositSelectRef: any = null;

    useEffect(() => {
        dispatch(actGetListStore());
        dispatch(actGetListSupplier())
    }, []);

    useEffect(() => {
        if (selectedStore) {
            dispatch(actGetListDepositOfStore(selectedStore.id));
            resetDepositSelect()
        }
    }, [selectedStore]);

    useEffect(() => {
        if (selectedStore && selectedIncomeTime) {
            const dateString = moment(selectedIncomeTime).format(DATE_FORMAT_FOR_CODE);
            setIncomeCodePrefix(`${selectedStore.code}_${dateString}_THU_`)
        }
    }, [selectedStore, selectedIncomeTime]);

    const resetDepositSelect = () => {
        setValue('depositId', undefined);
        depositSelectRef?.clearValue()
    }

    const storeOptions = useMemo(() => {
        if (!_.isEmpty(stores)) {
            return stores.map((s: Store) => ({
                label: s.name,
                value: s.id
            }));
        }
        return [];
    }, [stores]);

    const depositOptions = useMemo(() => {
        if (!_.isEmpty(deposits)) {
            return deposits.map((d: Deposit) => ({
                label: d.name,
                value: d.id
            }));
        }
        return [];
    }, [deposits]);

    const onChangeStore = (storeId: number) => {
        const store = stores.find(s => s.id === storeId)
        setSelectedStore(store);
    }

    const onCreateSuccess = useCallback(() => {
        dispatch(actResetIncomeItem())
        toast.success('Tạo phiếu thu thành công', { duration: 2000 })
        history.push(CONST.AppURI.INCOME);
    }, [])

    const onCreateFailure = useCallback(() => {
        toast.error('Tạo phiếu thu thất bại', { duration: 2000 })
    }, [])

    const onSubmit = (data: any) => {
        if (_.isEmpty(incomeItems)) {
            toast.error('Bạn chưa nhập mục thu', { duration: 2000 })
            return
        }
        const incomeItemRequests: IncomeItemRequest[] = incomeItems.map(i => ({
            categoryId: i.category.id,
            title: i.title,
            amount: i.amount
        }))
        const request: CreateIncomeRequest = {
            type: data.type,
            storeId: data.storeId,
            depositId: data.depositId,
            title: data.title,
            items: incomeItemRequests,
            incomeTime: data.incomeTime,
            code: incomeCodePrefix + data.code
        }
        dispatch(actCreateIncome(request, onCreateSuccess, onCreateFailure))
    }

    return (
        <MDBContainer className='py-3' fluid>
            <div className='text-center'>
                <h2 className='page-title'>Tạo phiếu thu</h2>
            </div>
            <MDBRow className='pb-3'>
                <MDBCol size='12'>
                    <p className="font-weight-normal mt-4">THÔNG TIN PHIẾU THU</p>
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Nội dung</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBInput
                        {...register('title', {
                            required: 'Nội dung là bắt buộc',
                            maxLength: {
                                value: 255,
                                message: 'Nội dung không được nhiều hơn 255 ký tự',
                            }
                        })}
                        placeholder='Nội dung'
                        onChange={(e: any) => {
                            setValue('title', e.target.value)
                        }}
                        containerClass='my-0 max-width-400'
                    />
                    {errors.title && <ErrorMessage>{errors.title.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Cửa hàng</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('storeId', {
                            required: 'Cửa hàng là bắt buộc'
                        })}
                        options={storeOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                const storeId = newValue.value
                                setValue('storeId', storeId);
                                onChangeStore(storeId);
                            }
                        }}
                        placeholder='(chọn cửa hàng)'
                        className='max-width-400 my-1'
                    />
                    {errors.storeId && <ErrorMessage>{errors.storeId.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2">
                    <div className="pt-2">Ví thu</div>
                </MDBCol>
                <MDBCol md="10">
                    <Select
                        {...register('depositId', {
                            required: 'Ví thu là bắt buộc'
                        })}
                        ref={ref => {
                            depositSelectRef = ref;
                        }}
                        options={depositOptions}
                        onChange={(newValue) => {
                            if (newValue) {
                                setValue('depositId', newValue.value);
                            }
                        }}
                        placeholder='(chọn ví)'
                        className='max-width-400 my-1'
                    />
                    {errors.depositId && <ErrorMessage>{errors.depositId.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Ngày thu</div>
                </MDBCol>
                <MDBCol md="10">
                    <MDBDatePicker
                        {...register('incomeTime', {
                            required: 'Ngày thu là bắt buộc'
                        })}
                        clearable
                        format='DD-MM-YYYY'
                        autoOk
                        getValue={(e) => {
                            setValue('incomeTime', e ? formatDateForApi(e) : null)
                            setSelectedIncomeTime(e)
                        }}
                        className='mt-0'
                        valueDefault={null as any}
                        emptyLabel='(chọn ngày)'
                    />
                    {errors.incomeTime && <ErrorMessage>{errors.incomeTime.message}</ErrorMessage>}
                </MDBCol>
                <MDBCol md="2" className='flex-row'>
                    <div className="pt-2">Mã phiếu thu</div>
                </MDBCol>
                <MDBCol md="10">
                    <div
                        {...register('code', {
                            required: 'Mã phiếu thu là bắt buộc'
                        })}>
                        <MDBInputGroup
                            material
                            containerClassName="my-0 max-width-400"
                            className='font-weight-bold text-primary'
                            prepend={incomeCodePrefix}
                            onChange={(e: any) => setValue('code', e.target.value)}
                        />
                    </div>
                    {errors.code && <ErrorMessage>{errors.code.message}</ErrorMessage>}
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol size='7'>
                    <IncomeItemCreationListComponent/>
                </MDBCol>
                <MDBCol size='5'>
                    <IncomeItemCreateFormComponent/>
                </MDBCol>
                <MDBCol size='12'>
                    <div className='text-center'>
                        <MDBBtn color="primary" onClick={handleSubmit(onSubmit)} disabled={creating}>Tạo phiếu thu</MDBBtn>
                        {creating &&
                            <MDBSpinner small className='b-primary' />
                        }
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default React.memo(IncomeCreateContainer);
