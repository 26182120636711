/** COMMON */
import * as Types from 'src/core/reduxs/coffee-core/category/category.type';
import PagingRequest from 'src/core/models/paging-request.model'
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import {CategoryCreateRequest ,CategoryDeleteRequest,CategoryUpdateRequest} from 'src/core/models/coffee-core/category/category-request-params.model';
import { AxiosError, AxiosResponse } from 'axios';
import ResponseCustom from 'src/core/models/response-custom.model';
const categoriesRepository = Repositories[RepositoryName.CATEGORY];

export const actGetListCategory = (params: PagingRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_CATEGORIES_REQUEST, Types.GET_CATEGORIES_SUCCESS, Types.GET_CATEGORIES_FAILURE],
        callAPI: () => categoriesRepository.getListCategory(params)
    })
};

export const actGetListProductCategory = (params: PagingRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_PRODUCT_CATEGORIES_REQUEST, Types.GET_PRODUCT_CATEGORIES_SUCCESS, Types.GET_PRODUCT_CATEGORIES_FAILURE],
        callAPI: () => categoriesRepository.getListCategoryByTenantIdAndType(1, 'PRODUCT')
    })
};

export const actGetListNewsCategory = (params: PagingRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_NEWS_CATEGORIES_REQUEST, Types.GET_NEWS_CATEGORIES_SUCCESS, Types.GET_NEWS_CATEGORIES_FAILURE],
        callAPI: () => categoriesRepository.getListNewsCategory(params)
    })
};


export const actCreateCategory = (params: CategoryCreateRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.CREATE_CATEGORY_REQUEST, Types.CREATE_CATEGORY_SUCCESS, Types.CREATE_CATEGORY_FAILURE],
        callAPI: () => categoriesRepository.createCategory(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListProductCategory({pageId: 0}))
                dispatch(actGetListNewsCategory({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}

export const actUpdateCategory = (params: CategoryUpdateRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.UPDATE_CATEGORY_REQUEST, Types.UPDATE_CATEGORY_SUCCESS, Types.UPDATE_CATEGORY_FAILURE],
        callAPI: () => categoriesRepository.updateCategory(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListProductCategory({pageId: 0}))
                dispatch(actGetListNewsCategory({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}

export const actDeleteCategory = (params: CategoryDeleteRequest ) => (dispatch: any) : void => {
    dispatch({
        types: [Types.DELETE_CATEGORY_REQUEST, Types.DELETE_CATEGORY_SUCCESS, Types.DELETE_CATEGORY_FAILURE],
        callAPI: () => categoriesRepository.deleteCategory(params),
        callBack: {
            success: (response: AxiosResponse<ResponseCustom>) => {
                dispatch(actGetListProductCategory({pageId: 0}))
                dispatch(actGetListNewsCategory({pageId: 0}))
                params.successCallback()
            },
            failure: (error: AxiosError) => {
                params.errorCallback()
            }
        }
    })
}


