import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBRow } from 'mdbreact';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { actAddUserCouponItem} from 'src/core/reduxs/coffee-core/coupon/coupon.action';
import { User } from 'src/core/models/coffee-core/user.model';
import { actGetUserByCode, actGetUserByPhone } from 'src/core/reduxs/coffee-core/user/user.action';
import { UserCouponItem } from 'src/core/models/coffee-core/coupon.model';

type Props = {
    tenantId: number
}

const UserCouponAdditionForm = (props: Props): JSX.Element => {
    const dispatch = useDispatch()
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const [searchText, setSearchText] = useState<string>('');

    const onSubmit = (data: any) => {
        let searchValue = data.searchText.replaceAll(' ', '');
        if (searchValue.startsWith('USER-')) {
            dispatch(actGetUserByCode(searchValue, props.tenantId, onSearchSuccess));
            return
        }
        if (searchValue.startsWith('0')) {
            searchValue = '+84' + searchValue.substring(1);
        }
        dispatch(actGetUserByPhone(searchValue, props.tenantId, onSearchSuccess));
    }

    const onSearchSuccess = (user: User) => {
        const data = getValues();
        const item: UserCouponItem = {
            user,
            couponQuantity: data.couponQuantity
        }
        dispatch(actAddUserCouponItem(item))
        setSearchText('')
        setValue('searchText', '')
    }

    return (
        <MDBCard className='container-fluid'>
            <MDBCardBody>
                <p className="font-weight-normal mt-3">THÊM USER NHẬN ƯU ĐÃI</p>
                <MDBRow className='pb-3'>
                    <MDBCol md="4">
                        <div className="pt-2">Thông tin user</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBInput
                            {...register('searchText', {
                                required: 'Cần nhập nội dung tìm kiếm',
                                maxLength: {
                                    value: 12,
                                    message: 'Nội dung tìm kiếm không quá 12 ký tự',
                                }
                            })}
                            placeholder='Nhập SĐT hoặc mã user'
                            onChange={(e: any) => {
                                const value = e.target.value;
                                setValue('searchText', value)
                                setSearchText(value);
                            }}
                            value={searchText}
                            containerClass='my-0'
                        />
                        {errors.searchText && <ErrorMessage>{errors.searchText.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol md="4">
                        <div className="pt-2">Số lượng ưu đãi</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBInput
                            {...register('couponQuantity', {
                                required: 'Số lượng ưu đãi là bắt buộc'
                            })}
                            type='number'
                            placeholder='Nhập số lượng ưu đãi'
                            onChange={(e: any) => {
                                setValue('couponQuantity', Number(e.target.value))
                            }}
                            containerClass='my-0'
                        />
                        {errors.couponQuantity && <ErrorMessage>{errors.couponQuantity.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol size='12'>
                        <div className='text-center'>
                            <MDBBtn color="primary" onClick={handleSubmit(onSubmit)}>Thêm</MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    )
}

export default React.memo(UserCouponAdditionForm)
