import { MDBBadge, MDBBox, MDBIcon, MDBInput, MDBRow, MDBSpinner } from 'mdbreact'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import PickDeep from 'src/core/models/pick-deep.modal'
import { SizeGroupUpdateRequest } from 'src/core/models/coffee-core/size-group/size-group-request-params.model'
import SizeGroupStateType from 'src/core/models/coffee-core/size-group/size-group-state.model'
import SizeGroup from 'src/core/models/coffee-core/size-group/size-group.model'
import SizeStateType from 'src/core/models/coffee-core/size/size-state.model'
import Size from 'src/core/models/coffee-core/size/size.model'
import * as SizeGroupAction from 'src/core/reduxs/coffee-core/size-group/size-group.action'
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component'

type Props = {
    isOpen: boolean,
    sizeGroupDetail: SizeGroup,
    onModalClosed: () => void,
}

const SizeGroupUpdateModalComponent = (props: Props): JSX.Element => {
    const { isOpen, sizeGroupDetail, onModalClosed } = props;
    const intl = useIntl();
    const [sizeGroupName, setSizeGroupName] = useState(sizeGroupDetail.name)
    const [chosenSizes, setChosenSizes] = useState(sizeGroupDetail.sizes?.map((size: Size) => size.id) || [])
    const dispatch = useDispatch();
    const updateSizeGroup: PickDeep<SizeGroupStateType, 'updateSizeGroup'> = useSelector((state: any) => state.sizeGroupReducer.updateSizeGroup)
    const sizes: PickDeep<SizeStateType, 'sizes'> = useSelector((state: any) => state.sizeReducer.sizes)
    useEffect(() => {
        setSizeGroupName(sizeGroupDetail.name)
        setChosenSizes(sizeGroupDetail.sizes?.map((size: Size) => size.id) || [])
    }, [sizeGroupDetail])

    const onCloseModal = () => {
        onModalClosed();
    }

    const onSuccessUpdate = () => {
        onCloseModal();
        toast.success(intl.formatMessage({ id: 'SIZE_GROUP.UPDATE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureUpdate = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'SIZE_GROUP.UPDATE.TOAST_FAILURE' }), { duration: 3000 })
    }

    const handleToggleSize = (id: number) => {
        if (chosenSizes.includes(id)) {
            const newChosenSizes = chosenSizes.filter((size) => size !== id)
            setChosenSizes(newChosenSizes)
        }
        else {
            setChosenSizes((oldSizes) => [...oldSizes, id])
        }
    }

    const handleUpdateSizeGroup = () => {
        const params: SizeGroupUpdateRequest = {
            data: {
                id: sizeGroupDetail.id,
                name: sizeGroupName,
                sizeIds: chosenSizes
            },
            successCallback: onSuccessUpdate,
            errorCallback: onFailureUpdate
        }
        dispatch(SizeGroupAction.actUpdateSizeGroup(params))
    }
    return (
        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'SIZE_GROUP.UPDATE.TITLE' })}
            type='update'
        >
            <form
                className='needs-validation was-validated size-group-create-body-form'
                onSubmit={(event: React.FormEvent) => { event.preventDefault(); handleUpdateSizeGroup() }}
            >
                <MDBBox className='form-inputs'>
                    <MDBInput
                        label={intl.formatMessage({ id: 'SIZE_GROUP.UPDATE.LABEL_NAME' })}
                        outline={true}
                        value={sizeGroupName}
                        onChange={(event) => { setSizeGroupName(event.currentTarget.value) }}
                        required
                        disabled={updateSizeGroup.isLoading}
                    />
                </MDBBox>
                <MDBBox className='form-list-sizes'>
                    <MDBBox className='form-list-sizes-label'>{intl.formatMessage({ id: 'SIZE_GROUP.UPDATE.LABEL_SIZES' })}</MDBBox>
                    {(sizes.isLoading || updateSizeGroup.isLoading) && <MDBRow className="d-flex justify-content-center mb-5 w-100 h-100 mt-1"><MDBSpinner small /></MDBRow>}
                    {!sizes.isLoading && !updateSizeGroup.isLoading && sizes.data.length === 0 && <p style={{ fontSize: '0.9rem', fontWeight: 300 }}>{intl.formatMessage({ id: 'SIZE_GROUP.UPDATE.LABEL_SIZES_NOT_FOUND' })}</p>}
                    {!sizes.isLoading && !updateSizeGroup.isLoading && sizes.data.length > 0 &&
                        (sizes.data.map((size) => {
                            return (
                                <MDBBadge
                                    key={size.id}
                                    className={(chosenSizes.includes(size.id)) ? 'chosen-badge update' : 'remain-badge'}
                                    onClick={() => handleToggleSize(size.id)}
                                >
                                    {size.name}
                                </MDBBadge>
                            )
                        }))
                    }
                </MDBBox>

                <MDBBox className='form-submit-box'>
                    <button className='form-submit-btn update' type="submit">
                        {intl.formatMessage({ id: 'SIZE_GROUP.UPDATE.LABEL_BUTTON' })}
                        <MDBIcon icon='edit' className='ml-3' />
                    </button>
                </MDBBox>
            </form>
        </CustomModal>
    )
}

export default SizeGroupUpdateModalComponent
