import { MDBCol, MDBRow } from 'mdbreact';
import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { actGetTransactionDetails } from 'src/core/reduxs/management/transaction/transaction.action';
import { Transaction } from 'src/core/models/management/transaction.model';
import { AppURI } from 'src/core/utils/constants';

const TransactionDocumentsComponent = ({transactionId}: {transactionId: number}): JSX.Element => {

    const dispatch = useDispatch();
    const transaction: Transaction = useSelector((state: any) => state.transactionReducer.transactionDetails.data);

    useEffect(() => {
        if (transactionId) {
            dispatch(actGetTransactionDetails(transactionId))
        }
    },[transactionId]);

    if (!transactionId || !transaction) {
        return <></>
    }

    return (
        <MDBRow>
            <MDBCol size='12'>
                <div className="font-weight-normal mt-4">CÁC PHIẾU THUỘC GIAO DỊCH</div>
                <div>
                    <MDBRow className='mt-2'>
                        {
                            transaction.exports.length > 0 &&
                            <Fragment>
                                <MDBCol md="2">
                                    <div>Phiếu xuất</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <a href={`${AppURI.EXPORT_DETAIL}?code=${transaction.exports[0].code}`} target='_blank' rel='noreferrer'>
                                        {transaction.exports[0].code}
                                    </a>
                                </MDBCol>
                            </Fragment>
                        }
                        {
                            transaction.imports.length > 0 &&
                            <Fragment>
                                <MDBCol md="2">
                                    <div>Phiếu nhập</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <a href={`${AppURI.IMPORT_DETAIL}?code=${transaction.imports[0].code}`} target='_blank' rel='noreferrer'>
                                        {transaction.imports[0].code}
                                    </a>
                                </MDBCol>
                            </Fragment>
                        }
                        {
                            transaction.incomes.length > 0 &&
                            <Fragment>
                                <MDBCol md="2">
                                    <div>Phiếu thu</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <a href={`${AppURI.INCOME_DETAIL}?code=${transaction.incomes[0].code}`} target='_blank' rel='noreferrer'>
                                        {transaction.incomes[0].code}
                                    </a>
                                </MDBCol>
                            </Fragment>
                        }
                        {
                            transaction.outcomes.length > 0 &&
                            <Fragment>
                                <MDBCol md="2">
                                    <div>Phiếu chi</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <a href={`${AppURI.OUTCOME_DETAIL}?code=${transaction.outcomes[0].code}`} target='_blank' rel='noreferrer'>
                                        {transaction.outcomes[0].code}
                                    </a>
                                </MDBCol>
                            </Fragment>
                        }
                    </MDBRow>
                </div>
            </MDBCol>
        </MDBRow>
    )
}

export default TransactionDocumentsComponent
