import { MDBBtn, MDBIcon } from 'mdbreact'
import React from 'react'
import Topping from 'src/core/models/coffee-core/topping/topping.model'
import { ToppingDeleteRequest } from 'src/core/models/coffee-core/topping/topping-request-params.model';
import { useDispatch, useSelector } from 'react-redux';
import * as ToppingAction from 'src/core/reduxs/coffee-core/topping/topping.action'
import PickDeep from 'src/core/models/pick-deep.modal'
import ToppingStateType from 'src/core/models/coffee-core/topping/topping-state.model'
import { toast } from 'react-hot-toast'
import { useIntl } from 'react-intl';
import CustomModal from 'src/shared/components/custom-modal/custom-modal.component';


type Props = {
    isOpen: boolean,
    toppingDetail: Topping,
    onModalClosed: () => void
}
const ToppingDeleteModalComponent = (props: Props): JSX.Element => {
    const { toppingDetail, isOpen, onModalClosed } = props;
    const intl = useIntl();
    const dispatch = useDispatch();
    const deleteTopping: PickDeep<ToppingStateType, 'deleteTopping'> = useSelector((state: any) => state.toppingReducer.deleteTopping)
    const onCloseModal = () => {
        onModalClosed()
    }

    const onSuccessDelete = () => {
        onCloseModal();
        toast.success(intl.formatMessage({ id: 'TOPPING.DELETE.TOAST_SUCCESS' }), { duration: 3000 })
    }
    const onFailureDelete = () => {
        onCloseModal();
        toast.error(intl.formatMessage({ id: 'TOPPING.DELETE.TOAST_FAILURE' }), { duration: 3000 })
    }
    const handleDeleteTopping = () => {
        const params: ToppingDeleteRequest = {
            data: {
                id: toppingDetail.id,
            },
            successCallback: onSuccessDelete,
            errorCallback: onFailureDelete
        }
        dispatch(ToppingAction.actDeleteTopping(params))
    }
    return (
        <CustomModal
            isOpen={isOpen}
            onCloseModal={onCloseModal}
            title={intl.formatMessage({ id: 'TOPPING.DELETE.TITLE' })}
            type='delete'
        >
            <div className='text-center my-3'>{`${intl.formatMessage({ id: 'TOPPING.DELETE.DESCRIPTION' })} ${toppingDetail.name}`}</div>
            <div className='text-center mb-1-half'>
                <MDBBtn
                    className='mb-2'
                    outline
                    color="danger"
                    disabled={deleteTopping.isLoading}
                    onClick={onCloseModal}
                >
                    {intl.formatMessage({ id: 'TOPPING.DELETE.LABEL_CANCEL_BUTTON' })}
                    <MDBIcon icon='times' className='ml-3' />
                </MDBBtn>
                <MDBBtn
                    className='mb-2 '
                    color="danger"
                    onClick={handleDeleteTopping}
                    disabled={deleteTopping.isLoading}
                >
                    {intl.formatMessage({ id: 'TOPPING.DELETE.LABEL_CONFIRM_BUTTON' })}
                    <MDBIcon icon='check' className='ml-3' />
                </MDBBtn>
            </div>
        </CustomModal>
    )
}

export default ToppingDeleteModalComponent
