import SizeGroup from 'src/core/models/coffee-core/size-group/size-group.model';
import ToppingGroup from 'src/core/models/coffee-core/topping-group/topping-group.model';
import Category from 'src/core/models/coffee-core/category/category.model';

export default class Product {
    id: number;
    name: string;
    price: number;
    imageUrl: string;
    sizeGroup?: SizeGroup;
    toppingGroup?: ToppingGroup;
    categories: Category[];

    constructor(
        id?: number,
        name?: string,
        price?: number,
        imageUrl?: string,
        sizeGroup?: SizeGroup,
        toppingGroup?: ToppingGroup,
        categories?: Category[],
    ) {
        this.id = id || 0;
        this.name = name || '';
        this.price = price || 0;
        this.imageUrl = imageUrl || '';
        this.sizeGroup = sizeGroup;
        this.toppingGroup = toppingGroup;
        this.categories = categories || []
    }
}
