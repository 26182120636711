import React from 'react';
import { AppURI } from 'src/core/utils/constants';
import { MDBIcon, MDBSideNav, MDBSideNavCat, MDBSideNavLink, MDBSideNavNav, } from 'mdbreact';
import Logo from 'src/shared/assets/img/logo-crush.png';
import _ from 'lodash';
import 'src/shared/components/side-navigation/side-navigation.style.scss'

type Props = {
    onLinkClick: () => void,
    breakWidth: number,
    triggerOpening: boolean,
    style: Record<string, unknown>
}

class SideNavigation extends React.Component<Props> {
    // render MDBSideNav Link
    rSNL(to: string, text: string, icon = '', topLevel = true): JSX.Element {
        return (
            <MDBSideNavLink topLevel={topLevel} to={to} onClick={this.props.onLinkClick}>
                {!_.isEmpty(icon) && <MDBIcon icon={`${icon} mr-2`}/>}
                {text}
            </MDBSideNavLink>
        );
    }

    render(): JSX.Element {
        return (
            <div className='white-skin'>
                <MDBSideNav
                    logo={Logo}
                    mask={'strong' as any}
                    fixed
                    breakWidth={this.props.breakWidth}
                    triggerOpening={this.props.triggerOpening}
                    style={{
                        transition: 'padding-left .3s'
                    }}
                    href={AppURI.INDEX}
                >
                    <MDBSideNavNav>
                        {/*{this.rSNL(AppURI.NOT_FOUND, 'Thông tin chi nhánh', 'store')}*/}
                        <MDBSideNavCat
                            name="Quản lý đơn hàng"
                            id="order-management"
                            icon="file-invoice-dollar"
                        >
                            {this.rSNL(AppURI.CREATE_ORDER, 'Tạo đơn hàng')}
                            {this.rSNL(AppURI.ORDER, 'Danh sách đơn hàng')}
                        </MDBSideNavCat>
                        {this.rSNL(AppURI.CATEGORY, 'Quản lý danh mục', 'list-alt')}
                        {this.rSNL(AppURI.TOPPING, 'Quản lý topping', 'cookie-bite')}
                        {this.rSNL(AppURI.SIZE, 'Quản lý size', 'ruler-combined')}
                        {this.rSNL(AppURI.PRODUCT, 'Quản lý sản phẩm', 'coins')}
                        <MDBSideNavCat
                            name="Tin tức"
                            id="news-management"
                            icon="newspaper"
                        >
                            {this.rSNL(AppURI.NEWS, 'Danh sách tin tức')}
                            {this.rSNL(AppURI.NEWS_CREATE, 'Tạo tin tức')}
                        </MDBSideNavCat>
                        <MDBSideNavCat
                            name="Quản lý nguyên liệu"
                            id="material-management-nav-group"
                            icon="file-invoice-dollar"
                            className='collapse show'
                        >
                            {this.rSNL(AppURI.EXPORT, 'Phiếu xuất')}
                            {this.rSNL(AppURI.IMPORT, 'Phiếu nhập')}
                            {this.rSNL(AppURI.INCOME, 'Phiếu thu')}
                            {this.rSNL(AppURI.OUTCOME, 'Phiếu chi')}
                            {this.rSNL(AppURI.MATERIAL, 'Nguyên liệu')}
                        </MDBSideNavCat>
                        <MDBSideNavCat
                            name="Tích điểm"
                            id="accumulate-management-nav-group"
                            icon="qrcode"
                            className='collapse show'
                        >
                            {this.rSNL(AppURI.ACCUMULATE_CODE, 'Tạo mã tích điểm')}
                            {this.rSNL(AppURI.ACCUMULATE_POINT, 'Tích điểm cho user')}
                        </MDBSideNavCat>
                        {/*{this.rSNL(AppURI.NOT_FOUND, 'Thống kê', 'chart-bar')}*/}
                        <MDBSideNavCat
                            name="Ưu đãi"
                            id="coupon-management-nav-group"
                            icon="gift"
                            className='collapse show'
                        >
                            {this.rSNL(AppURI.USER_COUPON_ADD, 'Gửi ưu đãi cho user')}
                            {this.rSNL(AppURI.COUPON_CODE, 'Tạo mã ưu đãi')}
                        </MDBSideNavCat>
                    </MDBSideNavNav>
                </MDBSideNav>
            </div>
        );
    }
}

export default SideNavigation;
