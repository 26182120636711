export const GET_TOPPING_GROUPS_REQUEST = 'GET_TOPPING_GROUPS_REQUEST';
export const GET_TOPPING_GROUPS_SUCCESS = 'GET_TOPPING_GROUPS_SUCCESS';
export const GET_TOPPING_GROUPS_FAILURE = 'GET_TOPPING_GROUPS_FAILURE';

export const GET_TOPPING_GROUP_REQUEST = 'GET_TOPPING_GROUP_REQUEST';
export const GET_TOPPING_GROUP_SUCCESS = 'GET_TOPPING_GROUP_SUCCESS';
export const GET_TOPPING_GROUP_FAILURE = 'GET_TOPPING_GROUP_FAILURE';
export const RESET_TOPPING_GROUP = 'RESET_TOPPING_GROUP';

export const UPDATE_TOPPING_GROUP_REQUEST = 'UPDATE_TOPPING_GROUP_REQUEST';
export const UPDATE_TOPPING_GROUP_SUCCESS = 'UPDATE_TOPPING_GROUP_SUCCESS';
export const UPDATE_TOPPING_GROUP_FAILURE = 'UPDATE_TOPPING_GROUP_FAILURE';

export const CREATE_TOPPING_GROUP_REQUEST = 'CREATE_TOPPING_GROUP_REQUEST';
export const CREATE_TOPPING_GROUP_SUCCESS = 'CREATE_TOPPING_GROUP_SUCCESS';
export const CREATE_TOPPING_GROUP_FAILURE = 'CREATE_TOPPING_GROUP_FAILURE';

export const DELETE_TOPPING_GROUP_REQUEST = 'DELETE_TOPPING_GROUP_REQUEST';
export const DELETE_TOPPING_GROUP_SUCCESS = 'DELETE_TOPPING_GROUP_SUCCESS';
export const DELETE_TOPPING_GROUP_FAILURE = 'DELETE_TOPPING_GROUP_FAILURE';
