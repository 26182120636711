import Types from 'src/core/reduxs/management/import-detail/import-detail.type';
import { ImportItem } from 'src/core/models/management/import.model';

export const actAddImportDetailItem = (importDetail: ImportItem) => (dispatch: any): void => {
    dispatch({
        type: Types.ADD_IMPORT_DETAIL_ITEM,
        payload: importDetail
    });
};

export const actRemoveImportDetailItem = (index: number) => (dispatch: any): void => {
    dispatch({
        type: Types.REMOVE_IMPORT_DETAIL_ITEM,
        payload: index
    });
};

export const actResetImportDetailItem = () => (dispatch: any): void => {
    dispatch({
        type: Types.RESET_IMPORT_DETAIL_ITEM,
    });
};
