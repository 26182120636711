import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';
import { Supplier } from 'src/core/models/supplier.model';

export default class SupplierRepository extends BaseRepository {

    getListSupplier(): Promise<AxiosResponse<Supplier[]>> {
        return this.setUri(CONST.ApiURI.MANAGEMENT.GET_LIST_SUPPLIER).getAll();
    }
}
