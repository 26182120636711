import React, { useEffect } from 'react';
import { MDBBtn, MDBCol, MDBModal, MDBModalBody, MDBModalFooter, MDBModalHeader, MDBRow } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-hot-toast';
import { Export, ExportItem, ExportItemRequest, UpdateExportRequest } from 'src/core/models/management/export.model';
import { actGetExportDetails, actUpdateExport } from 'src/core/reduxs/management/export/export.action';
import {
    actAddExportDetailItem,
    actResetExportDetailItem
} from 'src/core/reduxs/management/export-detail/export-detail.action';
import ExportDetailCreationListComponent from 'src/features/components/export/export-detail-creation-list.component';
import ExportDetailCreateForm from 'src/features/components/export/export-detail-create-form.component';

type Props = {
    isVisible: boolean,
    setIsVisible: (value: boolean) => void,
    exportCode: string,
}

const ExportUpdateModal = (props: Props): JSX.Element => {

    const dispatch = useDispatch();

    const exportDetailsSelector = useSelector((state: any) => state.exportReducer.exportDetails);
    const exportDetails: Export = exportDetailsSelector.data;
    const exportItems: ExportItem[] = useSelector((state: any) => state.exportDetailReducer.createExportDetails.items);
    const updating = useSelector((state: any) => state.exportReducer.updateExport.isLoading);

    useEffect(() => {
        if (props.isVisible && props.exportCode) {
            dispatch(actGetExportDetails(props.exportCode));
        }
    }, [props.isVisible, props.exportCode]);

    useEffect(() => {
        const exportItems = exportDetails?.items;
        if (!_.isEmpty(exportItems)) {
            dispatch(actResetExportDetailItem())
            for (const item of exportItems) {
                const exportItem: ExportItem = {
                    material: item.material,
                    quantity: item.quantity,
                    unitPrice: item.unitPrice,
                    price: item.price
                }
                dispatch(actAddExportDetailItem(exportItem))
            }
        }
    }, [exportDetails]);

    const onClickUpdate = () => {
        if (_.isEmpty(exportDetails)) {
            toast.error('Bạn chưa nhập chi tiết phiếu xuất', { duration: 2000 })
            return
        }
        const exportItemRequests: ExportItemRequest[] = exportItems.map(e => ({
            materialId: e.material.id,
            unitPrice: e.unitPrice,
            quantity: e.quantity
        }))
        const request: UpdateExportRequest = {
            code: props.exportCode,
            items: exportItemRequests,
        }
        dispatch(actUpdateExport(request, onCreateSuccess))
    }

    const onCreateSuccess = () => {
        dispatch(actResetExportDetailItem())
        dispatch(actGetExportDetails(props.exportCode));
        props.setIsVisible(false)
    }

    return (
        <MDBModal
            isOpen={props.isVisible}
            fullHeight={false}
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll
            size='fluid'
        >
            <MDBModalHeader>Cập nhật phiếu xuất</MDBModalHeader>
            <MDBModalBody>
                <MDBRow>
                    <MDBCol size='7'>
                        <ExportDetailCreationListComponent/>
                    </MDBCol>
                    <MDBCol size='5'>
                        <ExportDetailCreateForm/>
                    </MDBCol>
                </MDBRow>
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn color='info' outline onClick={() => props.setIsVisible(false)}>Huỷ</MDBBtn>
                <MDBBtn color='info' onClick={onClickUpdate} disabled={updating}>Cập nhật</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
}

export default ExportUpdateModal;
