const Types = {
    GET_LIST_NEWS_CATEGORY_REQUEST: 'GET_LIST_NEWS_CATEGORY_REQUEST',
    GET_LIST_NEWS_CATEGORY_SUCCESS: 'GET_LIST_NEWS_CATEGORY_SUCCESS',
    GET_LIST_NEWS_CATEGORY_FAILURE: 'GET_LIST_NEWS_CATEGORY_FAILURE',
    CREATE_NEWS_REQUEST: 'CREATE_NEWS_REQUEST',
    CREATE_NEWS_SUCCESS: 'CREATE_NEWS_SUCCESS',
    CREATE_NEWS_FAILURE: 'CREATE_NEWS_FAILURE',
    UPDATE_NEWS_REQUEST: 'UPDATE_NEWS_REQUEST',
    UPDATE_NEWS_SUCCESS: 'UPDATE_NEWS_SUCCESS',
    UPDATE_NEWS_FAILURE: 'UPDATE_NEWS_FAILURE',
    SEARCH_NEWS_REQUEST: 'SEARCH_NEWS_REQUEST',
    SEARCH_NEWS_SUCCESS: 'SEARCH_NEWS_SUCCESS',
    SEARCH_NEWS_FAILURE: 'SEARCH_NEWS_FAILURE',
    GET_NEWS_DETAILS_REQUEST: 'GET_NEWS_DETAILS_REQUEST',
    GET_NEWS_DETAILS_SUCCESS: 'GET_NEWS_DETAILS_SUCCESS',
    GET_NEWS_DETAILS_FAILURE: 'GET_NEWS_DETAILS_FAILURE',
}

export default Types;
