import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosResponse } from 'axios';

import PagingRequest from 'src/core/models/paging-request.model';
import ResponseCustom from 'src/core/models/response-custom.model';
import Topping from 'src/core/models/coffee-core/topping/topping.model';
import {ToppingCreateRequest ,ToppingDeleteRequest,ToppingUpdateRequest} from 'src/core/models/coffee-core/topping/topping-request-params.model';

export default class ToppingRepository extends BaseRepository {

    getListTopping(params: PagingRequest): Promise<AxiosResponse<Topping[]>> {
        const request = {
            ...params,
            itemsPerPage: CONST.DEFAULT_PAGE_SIZE,
            sortBy: 'display_order',
            sortType: 'DESC'
        };
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.GET_LIST_TOPPING).query<Topping[]>(request, );
    }

    createTopping(params: ToppingCreateRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.CREATE_TOPPING).create<ResponseCustom>(params.data)
    }

    updateTopping (params: ToppingUpdateRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.UPDATE_TOPPING).create<ResponseCustom>(params.data)
    }

    deleteTopping (params: ToppingDeleteRequest) : Promise<AxiosResponse<ResponseCustom>> {
        return this.setUri(CONST.ApiURI.COFFEE_CORE_SERVICE.ADMIN.DELETE_TOPPING).create<ResponseCustom>(params.data )
    }
}
