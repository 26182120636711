import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBInput, MDBRow } from 'mdbreact';
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from 'src/shared/components/error-message/error-message.component';
import { useForm } from 'react-hook-form';
import { CreateBlackPearlCodesRequest } from 'src/core/models/black-pearl-code.model';
import {
    actCreateBlackPearlCodes,
    actGetListBlackPearlCode
} from 'src/core/reduxs/coffee-core/black-pearl-code/black-pearl-code.action';
import { Tenant } from 'src/core/models/tenant.model';
import { actGetListTenant } from 'src/core/reduxs/coffee-core/tenant/tenant.action';
import { actGetListNewsCategoryByTenant } from 'src/core/reduxs/coffee-core/news/news.action';
import _ from 'lodash';

type Props = {
    tenantId: number;
}

const BlackPearlCodeCreateForm = (props: Props): JSX.Element => {
    const dispatch = useDispatch()
    const {register, handleSubmit, setValue, getValues, formState: {errors}} = useForm();
    const tenants: Tenant[] = useSelector((state: any) => state.tenantReducer.tenants.data);
    const [selectedTenantId, setSelectedTenantId] = useState<number | undefined>(undefined);

    useEffect(() => {
        dispatch(actGetListTenant());
    }, [])

    useEffect(() => {
        if (selectedTenantId !== undefined) {
            dispatch(actGetListNewsCategoryByTenant(selectedTenantId));
        }
    }, [selectedTenantId])

    const tenantOptions = useMemo(() => {
        if (!_.isEmpty(tenants)) {
            return tenants.map((t) => ({
                label: t.name,
                value: t.id
            }));
        }
        return [];
    }, [tenants]);

    const onSubmit = (data: any) => {
        const request: CreateBlackPearlCodesRequest = {
            blackPearlAmount: Number(data.blackPearlAmount),
            numberOfCodes: Number(data.numberOfCodes),
            tenantId: props.tenantId
        }
        dispatch(actCreateBlackPearlCodes(request, () => {
            dispatch(actGetListBlackPearlCode(props.tenantId))
        }))
    }

    return (
        <MDBCard className='container-fluid'>
            <MDBCardBody>
                <p className="font-weight-normal mt-3">TẠO MÃ TÍCH ĐIỂM TRÂN CHÂU</p>
                <MDBRow className='pb-3'>
                    <MDBCol md="4">
                        <div className="pt-2">Số lượng trân châu</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBInput
                            {...register('blackPearlAmount', {
                                required: 'Số lượng trân châu là bắt buộc'
                            })}
                            placeholder='Nhập số lượng trân châu'
                            onChange={(e: any) => {
                                setValue('blackPearlAmount', e.target.value)
                            }}
                            containerClass='my-0'
                        />
                        {errors.blackPearlAmount && <ErrorMessage>{errors.blackPearlAmount.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol md="4">
                        <div className="pt-2">Số lượng mã</div>
                    </MDBCol>
                    <MDBCol md="8">
                        <MDBInput
                            {...register('numberOfCodes', {
                                required: 'Số lượng mã là bắt buộc'
                            })}
                            placeholder='Nhập số lượng mã cần tạo'
                            onChange={(e: any) => {
                                setValue('numberOfCodes', e.target.value)
                            }}
                            containerClass='my-0'
                        />
                        {errors.numberOfCodes && <ErrorMessage>{errors.numberOfCodes.message}</ErrorMessage>}
                    </MDBCol>
                    <MDBCol size='12'>
                        <div className='text-center'>
                            <MDBBtn color="primary" onClick={handleSubmit(onSubmit)}>Tạo mã tích trân châu</MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBCardBody>
        </MDBCard>
    )
}

export default React.memo(BlackPearlCodeCreateForm)
